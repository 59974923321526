import moment from "moment"

export default Object.freeze({
    install(Vue) {
        Vue.prototype.isMobileApp = function () {
            try {
                return window.getDeviceType() === 'ios' || window.getDeviceType() === 'android'
            } catch (e) {
                return false
            }
        }

        Vue.prototype.setFCMToken = function () {
            let fcmToken = ''
            if(!this.isMobileApp()) {
                return
            }

            fcmToken = window.getFCMToken()

            let formData = new FormData()
            formData.append('using', false)
            formData.append('fcm', fcmToken)

            this.$post(this.$USER_MYPAGE_EXTRA_FCM_TOKEN, 'setFCMToken', formData, true, () => {
                //
            }, () => {
                //
            }, true)
        }

        Vue.prototype.escapeTag = function (content) {
            if(content === null || content === undefined) {
                return ''
            }

            if(content.indexOf('<') !== -1) {
                return content
            }

            return content.replace(/\r|\n/g, '<br/>');
        }

        // 토스트 스타일 알림
        Vue.prototype.notify = function (type, msg) {
            this.$root.$children[0].$refs.Alert.notify(type, msg)
        }

        Vue.prototype.refreshTokenRate = 5*60*1000
        Vue.prototype.refreshToken = function() {
            let refreshToken = localStorage.getItem('refreshToken')
            let accessToken = localStorage.getItem('accessToken')

            if(!refreshToken) {
                return
            }

            let formData = new FormData()
            formData.append('refreshToken', refreshToken)

            this.$post(this.$USER_LOGIN_OAUTH_TOKEN, 'refreshToken', formData, false, (result) => {
                localStorage.setItem('refreshToken', result.data[0].refreshToken)

                if(accessToken !== result.data[0].accessToken) {
                    localStorage.setItem('accessToken', result.data[0].accessToken)
                }

                setTimeout(() => {
                    this.refreshToken()
                }, this.refreshTokenRate)
            }, (result) => {
                this.notify('error', result.message)

                this.$router.replace({name: 'Login'})
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('accessToken')
                this.$store.state.isLoggedIn = false
            }, false)
        }

        Vue.prototype.refreshTokenShot = function(cb = null) {
            let refreshToken = localStorage.getItem('refreshToken')
            if(!refreshToken) {
                this.$router.replace({name: 'Login'})
                localStorage.removeItem('accessToken')
                this.$store.state.isLoggedIn = false
                return
            }
            let formData = new FormData()
            formData.append('refreshToken', refreshToken)
            this.$post(this.$USER_LOGIN_OAUTH_TOKEN, 'refreshTokenShot', formData, false, (result) => {
                localStorage.setItem('accessToken', result.data[0].accessToken)
                localStorage.setItem('refreshToken', result.data[0].refreshToken)

                if(cb) {
                    cb()
                }
            }, () => {
                this.$router.replace({name: 'Login'})
                localStorage.removeItem('accessToken')
                this.$store.state.isLoggedIn = false
            }, false)
        }

        Vue.prototype.calendarOptions = function () {
            let lang = localStorage.getItem('lang')
            if(lang === 'cn') {
                lang = 'zh_cn'
            }

            return {
                format: 'YYYY-MM-DD',
                locale: moment.locale(`${lang}`),
                icons: {
                    // time: "fa fa-clock",
                    // date: "fa fa-calendar",
                    // up: "fa fa-arrow-up",
                    // down: "fa fa-arrow-down",
                    previous: "fa fa-chevron-left",
                    next: "fa fa-chevron-right",
                    // today: "fa fa-clock-o",
                    // clear: "fa fa-trash-o",
                    // close: 'fa fa-close',
                }
            }
        }

        Vue.prototype.fullnameToTicker = function(fullname) {
            let ticker = ''
            let tickers = JSON.parse(sessionStorage.getItem('tickers'))
            if(tickers) {
                tickers.forEach(t => {
                    if(t.name === fullname) {
                        ticker = t.ticker
                    }
                })
                return ticker
            }
        }

        Vue.prototype.tradeStateInLang = function (state) {
            if(state === 'ACCEPT_READY') {
                return 'waitingAccept'
            } else if(state === 'WAITING') {
                return 'doTrading'
            } else if(state === 'DEPOSITED') {
                return 'waiting'
            } else if(state === 'TRADED') {
                return 'traded'
            } else if(state === 'CANCEL') {
                return 'tradeCancel'
            } else if(state === 'DECLINED') {
                return 'tradeRefuse'
            }
        }

        Vue.prototype.postStateInLang = function (state, boardIsBuy = null) {
            if(state === 'OPEN') {
                if(boardIsBuy) {
                    return 'doBuy'
                } else {
                    return 'doSell'
                }
            }
        }



        Vue.prototype.getCoinFullName = function(coin) {
            if(coin === 'BTC') {
                return this.$t('message.bitcoin')
            } else if(coin === 'ETH') {
                return this.$t('message.ethereum')
            } else if(coin === 'USDT') {
                return this.$t('message.usdt')
            } else if(coin === 'USD') {
                return this.$t('message.usd')
            } else if(coin === 'USDPAY') {
                return this.$t('message.usdpay')
            } else if(coin === 'REAP') {
                return this.$t('message.reap')
            } else if(coin === 'KRW') {
                return this.$t('message.krw')
            } else if(coin === 'WKX') {
                return this.$t('message.wkx')
            } else if(coin === 'TRX') {
                return this.$t('message.trx')
            } else if(coin === 'WIKI') {
                return this.$t('message.wikiexcoin')
            } else {
                return coin
            }
        }

        Vue.filter('numberWithCommas3', function (x) {
            if(!(x+'').includes('.')) {
                return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.000'
            } else {
                let parts = x.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                parts[1] = parts[1] + '000'
                parts[1] = parts[1].substr(0, 3)
                return parts.join('.')
            }
        })

        Vue.prototype.getCoinImg = function(coin) {
            return `/img/${coin.toLowerCase()}_coin.png`
        }

        Vue.filter('numberWithCommas', function (x) {
            if(!(x+'').includes('.')) {
                return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else {
               return `${((x+'').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(x+'').split('.')[1]}`
            }
        })

        Vue.prototype.subDec = function(num, tickerName, view = true) {
            if(num === undefined || isNaN(num) || !isFinite(num) || !num) {
                num = 0
            }
            let Big = require('big-js')
            num = new Big(num)
            let maxDecimal = 2

            let tickers = JSON.parse(sessionStorage.getItem('tickers'))
            if(tickerName && tickers){
                tickers.forEach(ticker => {
                    if(ticker.ticker === tickerName) {
                        if(view) {
                            maxDecimal = ticker.maxDecimalView
                        } else {
                            maxDecimal = ticker.maxDecimalFacto
                        }
                    }
                })
            }

            return num.toFixed(maxDecimal)
        }

        Vue.prototype.getMyBalance = function(callback, coin) {
            this.$get(`${this.$WALLET_BALANCE_TICKER}?ticker=${coin}`, 'GetWalletAvailble', true, (result) => {
                result.data[0].available = this.subDec(result.data[0].available, coin, false)
                result.data[0].inOrders = this.subDec(result.data[0].inOrders, coin, false)

                callback({ success: true, myBalance: result.data[0] })
            }, (result) => {
                callback({ success: false, myBalance: result })
            })
        }

        Vue.prototype.getUserProfile = function (callback) {
            this.$get(this.$USER_MYPAGE_PROFILE, 'GetUserProfile' + Math.random(), true, (result) => {
                callback({ success: true, userProfile: result.data[0] })
            }, (result) => {
                callback({ success: false, userProfile: result })
            })
        }

        Vue.prototype.getNickname = function (callback, idx) {
            this.$get(`${this.$OTC_NICKNAME}?userIdx=${idx}`, 'GetNickname_' + Math.random(), true, (result) => {
                callback({ success: true, nickname: result.data[0] })
            }, (result) => {
                callback({ success: false, nickname: result.error })
            })
        }

        Vue.prototype.formatDate = function(timeStamp, dateType) {
            function makeTwoDigits(number) {
                return number >= 10 ? number : '0' + number
            }

            let d = new Date(timeStamp)
            let year = d.getFullYear()
            let month = makeTwoDigits(d.getMonth() + 1)
            let date = makeTwoDigits(d.getDate())
            let hour = makeTwoDigits(d.getHours())
            let min = makeTwoDigits(d.getMinutes())
            let sec = makeTwoDigits(d.getSeconds())
            if(dateType === 'full') {
                return `${year}-${month}-${date} ${hour}:${min}:${sec}`
            } else if(dateType === 'date'){
                return `${year}-${month}-${date}`
            } else {
                return `${hour}:${min}:${sec}`
            }
        }

        // Vue.prototype.getOtcState = function(state) {
        //     if(state === 'WAITING') {
        //         return this.$t('message.wait')
        //     } else if (state === 'DEPOSITED') {
        //         return this.$t('message.deposited')
        //     } else if (state === 'TRADED') {
        //         return this.$t('message.completed')
        //     } else if (state === 'TIMEOUT') {
        //         return this.$t('message.tradeDone')
        //     } else if(state === 'CANCEL'){
        //         return this.$t('message.orderCancelled')
        //     }
        // }

        Vue.prototype.getSymbol = function(ticker) {
            let symbol = ''
            let tickers = JSON.parse(sessionStorage.getItem('tickers'))
            if(tickers) {
                tickers.forEach(t => {
                    if(t.ticker === ticker) {
                        symbol = t.symbol
                    }
                })
                return symbol
            }
        }

        // 필요없는 소수점 뒤 0 빼기
        Vue.prototype.toFloat = function(number) {
            let newNum = number + ''
            if(newNum === '0' || !newNum.includes('.')) {
                return newNum
            }

            for(let i=newNum.length - 1; i>=0; i--) {
                if(newNum[i] === '0') {
                    newNum = newNum.slice(0, newNum.length - 1)
                } else if(newNum[i] === '.'){
                    newNum = newNum.slice(0, newNum.length - 1)
                    break
                } else {
                    break
                }
            }
            return newNum
        }
    }
})
