<template>
  <div class="cv-container-sub">
    <div class="mp_info_form">
      <div class="form-horizontal">
        <div class="form-group row border-bottom m-0 pb-2">
          <div class="col-12">
            <input type="text" class="form-control-plaintext" :value="userProfile.nickName" disabled>
            <span class="d-block text-muted small">{{ $t('message.nickname') }}</span>
          </div>
        </div>
        <div class="form-group row border-bottom m-0 py-2">
          <div class="col-12">
            <input type="text" class="form-control-plaintext" :value="userProfile.email" disabled>
            <span class="d-block text-muted small">{{ $t('message.email') }}</span>
          </div>
        </div>
        <div class="form-group row border-bottom m-0 py-2">
          <div class="col-12">
            <input type="text" class="form-control-plaintext" :value="userProfile.phone" disabled>
            <span class="d-block text-muted small">{{ $t('message.phone') }}</span>
          </div>
        </div>
        <div class="row border-bottom py-2 mx-0" @click="$router.push({ name: 'ChangePwd' })">
          <a class="col-12 px-0">
            <div class="row mx-0">
              <label class="col-10 col-form-label cv-icon">{{ $t('message.pwdChange') }}</label>
              <div class="col-2 text-right align-self-center">
                <i class="xi-angle-right"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Account",

  data() {
    return {
      userProfile: {
        email: '',
        nickName: '',
        phone: ''
      }
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.myInfo'), true, false)

    this.getUserProfile((result) => {
      if(result.success) {
        this.userProfile = result.userProfile
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.myInfo'), true, false)
      }
    },
  },

}
</script>

<style scoped>

</style>
