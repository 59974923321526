<template>
  <div class="intro">
    <Modal ref="Modal">
      <div class="modal_contents" v-show="showFindEmail">
        <div class="login_modal">
          <div v-show="showForm">
            <div>
              <div class="form_phone">
                <input :placeholder="$t('message.placeholderPhoneNo')" v-model="phone"/>
              </div>
            </div>
            <div class="modal_notice">{{ $t('message.emailForCode') }}</div>
            <a class="modal_close_btn login_find" @click="sendCode">{{ $t('message.getVerificationCode') }}</a>
          </div>
        </div>
        <div class="login_modal">
          <div v-show="showInputCode">
            <input type="text" :placeholder="$t('message.putAuthCodeSentToPhone')" v-model="smsVerifyCode"/>
            <a class="modal_close_btn login_find" @click="confirmCode">{{ $t('message.confirm') }}</a>
          </div>
        </div>
        <div class="login_modal">
          <div v-show="showEmail">
            <input type="text" class="" :value="emailFound" disabled/>
          </div>
        </div>
      </div>
      <div class="modal_contents" v-show="showFindPwd">
        <div class="login_modal">
          <select class="wallet_address_list" v-model="authMethod" :disabled="smsAuthSuccess">
            <option value="email">{{ $t('message.email') }}</option>
            <option value="sms">{{ $t('message.phone') }}</option>
          </select>
          <input type="text" :placeholder="$t('message.placeholderEmail')" v-show="authMethod === 'email'" v-model="email"/>
          <div class="form_phone" v-show="authMethod === 'sms' && !smsAuthSuccess">
            <input :placeholder="$t('message.placeholderPhoneNo')" v-model="phone"/>
          </div>
        </div>
        <div class="login_modal">
          <div class="modal_notice" v-show="authMethod === 'email'">{{ $t('message.pwdLinkSend') }}</div>
        </div>
        <a class="modal_close_btn login_find" @click="sendLink" v-show="authMethod === 'email'">{{ $t('message.getLink') }}</a>
        <a class="modal_close_btn login_find" @click="sendLink" v-show="authMethod === 'sms' && !smsAuthSuccess">{{ $t('message.getVerificationCode') }}</a>
        <div class="login_modal" v-show="smsAuthSuccess">
          <input type="text" :placeholder="$t('message.placeholderCodeFromPhone')" v-model="authCode"/>
          <a class="modal_close_btn login_find" @click="confirmPhone">{{ $t('message.confirm') }}</a>
        </div>
      </div>
    </Modal>
    <div class="cv-loading p-4 pt-5">
      <div class="mt-4 mb-3">
        <router-link :to="{name: 'Dashboard'}">
          <img src="/img/logo_white.png" alt="intro-logo">
        </router-link>
        <div class="mt-4 text-white intro-desc" v-html="$t('message.introDesc')"></div>
      </div>
      <div class="mt-4 pt-3 mb-2">
        <form class="form-horizontal">
          <div class="input-group mb-2">
            <input type="text" name="username" class="form-control input-lg" :placeholder="$t('message.placeholderEmail')" autocomplete="on" v-model="email"/>
          </div>
          <div class="input-group mb-2">
            <input type="password" name="current-password" class="form-control input-lg" :placeholder="$t('message.placeholderPwd')" autocomplete="on" v-model="pwd" @keyup.enter="login"/>
          </div>
          <div class="mt-2" @click="login">
            <button type="button" class="btn btn-orange btn-block">{{ $t('message.login') }}</button>
          </div>
        </form>
      </div>
      <div class="clearfix mt-3 mb-5 pb-5">
        <div class="float-left">
          <a class="text-white-50" @click="findEmail">{{ $t('message.findID') }}</a>
          <a class="ml-2 text-white-50" @click="findPwd">{{ $t('message.findPwd') }}</a>
        </div>
        <div class="float-right" @click="$router.push({ name: 'Signup' })">
          <a class="text-white">{{ $t('message.signup') }}</a>
        </div>
      </div>
      <div class="fixed-bottom p-4 mb-0">
        <LanguageHorizon/>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageHorizon from "@/components/cmp/language/Lang_Horizon"
import Modal from '@/components/cmp/modal/Modal'

export default {
  name: "Login",

  data() {
    return {
      email: '',
      pwd: '',
      phone: '',
      countryCode: 'KR',
      showForm: true,
      smsVerifyCode: '',
      emailFound: '',
      showEmail: false,
      showInputCode: false,
      showFindEmail: false,
      showFindPwd: false,
      authMethod: 'email',
      sms: '',
      authCode: '',
      smsAuthSuccess: false,
    }
  },

  components: {
    'LanguageHorizon': LanguageHorizon,
    'Modal': Modal,
  },

  mounted() {
    this.$parent.showHeader(false)
    this.$parent.showFooter(false)
  },

  methods: {
    sendLink() {
      if(this.authMethod === 'email') {
        let formData = new FormData()
        formData.append('email', this.email)

        this.$post(this.$USER_LOSS_PASSWORD_EMAIL, 'findPwd', formData, false, (result) => {
          this.notify('success', result.message)
          this.$router.push({ name: 'Login' })
        }, (result) => {
          this.notify('error', result.message)
        })
      } else {
        let formData = new FormData()
        formData.append('phone', this.phone)
        formData.append('countryCode', this.countryCode)

        this.$post(this.$USER_LOSS_PASSWORD_SMS, 'findPwd', formData, false, (result) => {
          this.notify('success', result.message)
          this.smsAuthSuccess = true
        }, (result) => {
          console.log(result)
          this.notify('error', result.message)
        })
      }
    },

    confirmPhone() {
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('code', this.authCode)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_LOSS_PASSWORD_SMS_CERT, 'findPwdSetNewPwd', formData, false, (result) => {
        this.$router.push({ name: 'NewPwd', params: { veriCode: result.data[0].verifyCode, authMethod: 'phone' }})
      }, (result) => {
        console.log(result)
      })
    },

    sendCode() {
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_LOSS_EMAIL, 'findEmailByPhone', formData, false, (result) => {
        this.notify('success', result.message)
        this.showForm = false
        this.showInputCode = true
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    confirmCode() {
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('code', this.smsVerifyCode)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_LOSS_EMAIL_CERT, 'findUserEmail', formData, false, (result) => {
        this.emailFound = result.data[0].email
        this.showEmail = true
        this.showInputCode = false
        this.smsVerifyCode = ''
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    findEmail() {
      console.log(111111)
      this.showFindEmail = true
      this.showForm = true
      this.showInputCode = false
      this.showEmail = false
      this.showFindPwd = false
      this.phone = ''
      this.$refs.Modal.showDialog(this.$t('message.findEmail'), '', '', ()=>{}, ()=>{})
    },

    findPwd() {
      this.showFindEmail = false
      this.showFindPwd = true
      this.phone = ''
      this.smsAuthSuccess = false
      this.authCode = ''
      this.$refs.Modal.showDialog(this.$t('message.findPwd'), '', '', ()=>{}, ()=>{})
    },

    login() {
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('password', this.pwd)

      this.$post(this.$USER_LOGIN, 'userLogin', formData, false, async (result) => {
        /*if (window.PasswordCredential) {
          const cr = await navigator.credentials.create({
            id: this.email,
            password: this.pwd
          });
          await navigator.credentials.store(cr)
        }*/

        if(result.data[0].accessToken) {
          this.notify('success', result.message)
          localStorage.setItem('accessToken', result.data[0].accessToken)
          localStorage.setItem('refreshToken', result.data[0].refreshToken)

          this.refreshToken()
          this.setFCMToken()

          this.$store.state.isLoggedIn = true
          this.$router.back()
        } else if(result.data[0].secondAuth) {
          if(result.data[0].secondAuthMethod === 'OTP') {
            this.$router.push({ name: 'OtpLogin', params: { secondAuthMethod: result.data[0].secondAuthMethod, verifyCode: result.data[0].verifyCode }})
          } else if(result.data[0].secondAuthMethod === 'PIN') {
            this.$router.push({ name: 'PinLogin', params: { secondAuthMethod: result.data[0].secondAuthMethod, verifyCode: result.data[0].verifyCode }})
          } else if (result.data[0].secondAuthMethod === 'PHONE') {
            this.$router.push({ name: 'PhoneLogin', params: { secondAuthMethod: result.data[0].secondAuthMethod, verifyCode: result.data[0].verifyCode }})
          }
        }
      }, (result) => {
        this.notify('error', result.message)
      })
    }
  },

  beforeDestroy() {
    this.$parent.showHeader(true)
    this.$parent.showFooter(true)
  },
}
</script>

<style scoped>

</style>
