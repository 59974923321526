<template>
  <div class="px-2 py-2 clearfix" style="padding-top: 45px !important;">
    <div class="d-flex col-12 justify-content-between px-0 pb-2">
      <button class="w-48 btn btn-basic btn-md" @click="goToOtcOffer('sell')">{{ $t('message.postSellOffer') }}</button>
      <button class="w-48 btn btn-basic btn-md" @click="goToOtcOffer('buy')">{{ $t('message.postBuyOffer') }}</button>
    </div>
    <hr>
<!--    <div class="form-group row mx-0 py-2 mb-0">-->
<!--      <label class="col-form-label text-left col-4">{{ $t('message.curr') }}</label>-->
<!--      <select class="form-control col-8" @change="changeCurrency($event)" v-model="selectedCurrency">-->
<!--        <option v-for="currency in currencyArr" :value="currency" :key="currency">{{ currency }}</option>-->
<!--      </select>-->
<!--    </div>-->
    <div class="row col-form-label mx-0">
      <div class="input-group">
        <label class="col-4 col-form-label text-left">{{ $t('message.coin') }} {{ $t('message.search') }}</label>
        <div class="input-group-prepend">
          <span class="input-group-text rounded-0 bg-white">
            <i class="xi-search search_btn"></i>
          </span>
        </div>
        <input type="text" class="form-control border-left-0 rounded-0 search-control pl-0" :placeholder="$t('message.search')">
      </div>
    </div>
    <hr>
    <div class="d-flex col-12 justify-content-between px-0 pt-2 pb-2">
      <button
          class="col-6 btn btn-outline-basic1 btn-md"
          :class="{ active: offerType === 'buy' }"
          @click="changeOfferType($event, 'buy')"
      >
        {{ $t('message.buy') }}
      </button>
      <button
          class="col-6 btn btn-outline-basic1 btn-md"
          :class="{ active: offerType === 'sell' }"
          @click="changeOfferType($event, 'sell')"
      >
        {{ $t('message.sell') }}
      </button>
    </div>
    <div class="form-group row mx-0 py-2 mb-0">
      <select class="form-control w-100" @change="changeCoin($event)" v-model="selectedCoin">
        <option v-for="coin in coinArr" :key="coin" :value="coin">{{ coin }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",

  data() {
    return {
      selectedCurrency: '',
      selectedCoin: '',
      offerType: this.$route.params.offerType ? this.$route.params.offerType : 'buy',
      currencyArr: [],
      coinArr: [],
      offerList: [],
      marketPairList: [],
      marketPairs: [],
    }
  },

  mounted() {
    if(!this.$store.getters.getLoginState) {
      this.notify('error', '로그인 시 이용 가능합니다.')
      this.$router.push({ name: 'Login' })
      return
    }

    this.$get(this.$OTC_LIST_MARKET, 'otcGetListMarket', true, (result) => {
      this.marketPairList = result.data

      this.$emit('getPairList', this.marketPairList)

      let tempCoinArr = []
      let tempCurrencyArr = []
      this.marketPairList.forEach((marketPair) => {
        if(marketPair.marketType !== 'OTC') {
          return
        }
        this.marketPairs.push(marketPair.marketPair)
        tempCoinArr.push(marketPair.marketPair.split('-')[0])
        tempCurrencyArr.push(marketPair.marketPair.split('-')[1])
      })

      this.coinArr = [...new Set(tempCoinArr)]
      this.$emit('getCoinArr', this.coinArr)

      this.currencyArr = [...new Set(tempCurrencyArr)]
      this.$emit('getCurrencyArr', this.currencyArr)

      this.selectedCoin = this.marketPairs[0].split('-')[0]
      this.selectedCurrency = this.marketPairs[0].split('-')[1]

      this.$emit('changePair', this.selectedCoin, this.selectedCurrency)
    }, () => {})
  },

  methods: {
    goToOtcOffer(offerType) {
      if(this.$route.path !== '/otc/register') {
        this.$router.push({ name: 'OfferRegister', params: { offerType: offerType }})
      } else {
        this.$emit('changeOfferType', offerType)
      }
    },

    goToOtcHome(offerType) {
      if(offerType) {
        this.$router.push({ name: 'OfferList', params: { offerType: offerType }})
      } else {
        this.$router.push({ name: 'OfferList' })
      }
      document.querySelector('.invest_coinsBox').scrollIntoView()
    },

    changeCoin(e) {
      this.selectedCoin = e.currentTarget.value
      this.getAvailableCurr()

      if(this.$route.path !== '/otc/list') {
        this.goToOtcHome()
      }
    },

    // changeCurrency(e) {
    //   this.selectedCurrency = e.currentTarget.value
    //   this.getAvailableCoins()
    //
    //   if(this.$route.path !== '/otc/list') {
    //     this.goToOtcHome()
    //   }
    // },

    changeOfferType(e, type) {
      this.offerType = type
      if(this.$route.path !== '/otc/list') {
        this.goToOtcHome(this.offerType)
      } else {
        this.$emit('changeOfferType', this.offerType)
      }

      if(!e.currentTarget.classList.contains('active')) {
        e.currentTarget.parentNode.getElementsByClassName('active')[0].classList.remove('active')
        e.currentTarget.classList.add('active')
      }
    },

    // getAvailableCoins() {
    //   let tmpCoinArr = []
    //
    //   this.marketPairs.forEach((pair => {
    //     if(this.selectedCurrency === pair.split('-')[1]) {
    //       tmpCoinArr.push(pair.split('-')[0])
    //     }
    //   }))
    //   this.coinArr = [...new Set(tmpCoinArr)]
    //   this.$emit('getCoinArr', this.coinArr)
    //
    //   if(!this.coinArr.includes(this.selectedCoin)) {
    //     this.selectedCoin = this.coinArr[0]
    //   }
    //   this.$emit('changePair', this.selectedCoin, this.selectedCurrency)
    // },

    getAvailableCurr() {
      let tmpCurrArr = []

      this.marketPairs.forEach((pair => {
        if(this.selectedCoin === pair.split('-')[0]) {
          tmpCurrArr.push(pair.split('-')[1])
        }
      }))
      this.currencyArr = [...new Set(tmpCurrArr)]
      this.$emit('getCurrencyArr', this.currencyArr)
      if(!this.currencyArr.includes(this.selectedCurrency)) {
        this.selectedCurrency = this.coinArr[0]
      }
      this.$emit('changePair', this.selectedCoin, this.selectedCurrency)
    }
  },
}
</script>

<style scoped>

</style>
