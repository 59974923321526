<template>
  <div class="invest_coinsBox invest_trade__box history">
    <div class="position-relative pb-2">
      <div class="mt-5">
        <div class="bg-white">
          <div class="h6 mb-0">
            <div>
              <ul class="nav nav-pills nav-fill justify-content-center invest_navBox">
                <li class="nav-item" @click="$router.push({ name: 'HistoryGeneral' })">
                  <a class="nav-link" :class="{ active: $route.path === '/history/general' }">{{ $t('message.exchange') }}</a>
                </li>
                <li class="nav-item" @click="$router.push({ name: 'HistoryOtcSellOffers' })">
                  <a class="nav-link" :class="{ active: $route.path !== '/history/general' }">{{ $t('message.otc') }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="trade_listTable outer">
            <div class="px-2 pb-3 invest_tradeAllBox clearfix">
              <span class="col-12 h5 float-left text-left px-1 pt-2 mb-0">{{ $t('message.myOfferTrades') }}</span>
            </div>
            <div class="inner">
              <table>
                <thead>
                  <tr class="fst_inner">
                    <th rowspan="2" class="lft_td__color">{{ $t('message.nickname') }}</th>
                    <th class="lft_td__color next_leftTitle">{{ $t('message.tradeAmt') }}</th>
<!--                    <th class="lft_td__color">{{ $t('message.totalTradePrice') }}</th>-->
                    <th rowspan="2" class="lft_td__color">{{ $t('message.state') }}</th>
                    <th rowspan="2" class="rit_td__color">{{ $t('message.detail') }}</th>
                  </tr>
                  <tr class="fst_inner">
                    <th class="lft_td__color">{{ $t('message.tradeTimeLeft') }}</th>
                  </tr>
                </thead>
                <tbody :class="{ 'border-bottom-bold': tradeList.length - 1 === tradeIdx }" v-for="(trade, tradeIdx) in tradeList" :key="trade.tradeIdx">
                  <tr>
                    <td rowspan="2" class="text-center" :key="rerenderKey">{{ trade.nickname }}</td>
                    <td class="text-center">{{ trade.amount }} {{ trade.marketPair.split('-')[0] }}</td>
<!--                    <td class="text-center">{{ trade.totalPrice | numberWithCommas }} {{ trade.marketPair.split('-')[1] }}</td>-->
                    <td rowspan="2" class="text-center">{{ getOtcState(trade.otcTradeState) }}</td>
                    <td rowspan="2" class="text-center outline_btn2 border-right-none">
                      <span class="blue_f p-1 border-right-none" @click="$router.push({ name: 'OfferProcess', params: { tradeIdx: trade.tradeIdx }})">{{ $t('message.viewDetail') }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center dblue_01 fwb border-top-light">{{ trade.otcTradeState === 'WAITING' ? timeDesc : '' }}</td>
                  </tr>
                </tbody>
                <tbody v-show="!tradeList.length">
                  <tr>
                    <td colspan="4" class="border-right-none text-center">{{ $t('message.noTradeHistory') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "OfferDetailList",

  data() {
    return {
      tradeList: [],
      timeLimit: 0,
      offerIdx: this.$route.params.offerIdx,
      rerenderKey: 0,
    }
  },

  computed: {
    timeDesc: function() {
      let timeFormat = ''
      if(this.timeLimit) (
          timeFormat = this.timeLimit.format('mm:ss')
      )
      return timeFormat
    },
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.myTradingHistory'), false, true)

    if(this.offerIdx) {
      sessionStorage.setItem('offerIdx', this.offerIdx)
    } else {
      this.offerIdx = sessionStorage.getItem('offerIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('offerIdx')
  },

  mounted() {
    this.$parent.focusMenu(1)
    localStorage.setItem('focusMenu', 1)

    this.getTradeList(1)
  },

  methods: {
    getTradeList(page) {
      let formData = new FormData()
      let limit = 10

      formData.append('offerIdx', this.offerIdx)
      formData.append('limit', limit)
      formData.append('page', page)

      this.$post(this.$OTC_TRADE_LIST, 'HistoryOtcSellOfferPostList', formData, true, (result) => {
        this.tradeList = result.data

        this.tradeList.forEach((trade) => {
          this.getNickname((result) => {
            if(result.success) {
              trade.nickname = result.nickname
              this.rerenderKey += 1
            } else {
              console.log(result.nickname)
            }
          }, trade.userIdx)

          trade.totalPrice = trade.amount * trade.priceToGo

          if(trade.otcTradeState === 'WAITING') {
            let date = trade.createdAt.split('T')[0]
            let time = trade.createdAt.split('T')[1].split('.')[0]

            let dateSplit = date.split('-')
            let timeSplit = time.split(':')
            let milliSec = trade.createdAt.split('T')[1].split('.')[1]
            let newDate = new Date(dateSplit[0], dateSplit[1] - 1 , dateSplit[2], timeSplit[0], timeSplit[1], timeSplit[2], milliSec)
            let timeStamp = newDate.getTime()
            let timeEnd = timeStamp + (15 * 60 * 1000)
            this.timeLimit = moment(timeEnd - Date.now())
            let timeInterval = setInterval(() => {
              this.timeLimit = moment(this.timeLimit.subtract(1, 'seconds'))
              if(this.timeLimit.format('mm:ss') === '00:00') {
                clearInterval(timeInterval)
              }
            }, 1000)
          }
        })
      }, (result) => {
        console.log(result)
      })
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.myTradingHistory'), false, true)
      }
    },
  },
}
</script>

<style scoped>

</style>
