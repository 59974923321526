<template>
  <div class="cv-container-sub cv-enable">
    <div class="h5 text-uppercase text-center mb-0 bg-basicGray text-basic py-2">OTP {{ $t('message.verificationApplied') }}</div>
    <div class="p-3">
      <div class="text-center otp_ebTitleSub">
        <p class="mb-0" v-html="$t('message.otpDesc1')"></p>
      </div>
      <div class="cv-mypage-tit h5 cv-otp-bdTitle text-center">
        <span class="text-white bg-basic h6 px-2 py-1">{{ $t('message.step1') }}</span> {{ $t('message.otpDown') }}
      </div>
      <p class="mt-3 mb-0 timeOTP_box text-center" v-html="$t('message.otpDesc3')"></p>
      <div class="row p-3 otp_box">
        <div class="col-12 text-center">
          <div class="row">
            <div class="col-12 py-3 bg-light border border-bottom-0 android_container">
              <div class="row">
                <div class="col-12">
                  <img src="/img/otp_img2.png" :alt="$t('message.playStore')" class="mr-md-3 mr-0 otp_imgBox">
                </div>
                <div class="col-12 text-center otp_contentBox">
                  <h5 class="mt-0 font-weight-bold">{{ $t('message.android') }}</h5>
                  <p class="">{{ $t('message.searchPlayStore') }}</p>
                  <a :href="otpData.playStore" target="_blank" class="btn btn-point">
                    {{ $t('message.playStore') }} OTP
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 py-3 bg-light border ios_container">
              <div class="row">
                <div class="col-12">
                  <img src="/img/otp_img.png" :alt="$t('message.appStore')" class="mr-md-3 mr-0 otp_imgBox">
                </div>
                <div class="col-12 text-center otp_contentBox">
                  <h5 class="mt-0 font-weight-bold">{{ $t('message.iPhone') }}</h5>
                  <p class="">{{ $t('message.searchAppStore') }}</p>
                  <a :href="otpData.appStore" target="_blank" class="btn btn-basic">
                    {{ $t('message.appStore') }} OTP
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 col-12">
          <div class="row">
            <div class="col-12 text-center px-0 d-flex">
              <div>
                <div class="pb-3"><img src="/img/googleotp01.jpg" class="w-75" alt="otp1"></div>
                <div class="pb-3"><img src="/img/googleotp02.jpg" class="w-75" alt="otp2"></div>
              </div>
              <div>
                <div class="pb-3"><img src="/img/googleotp03.jpg" class="w-75" alt="otp3"></div>
                <div class="pb-3"><img src="/img/googleotp04.jpg" class="w-75" alt="otp4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="cv-mypage-tit h5 cv-otp-bdTitle text-center pt-2 pb-3">
        <span class="text-white bg-basic h6 px-2 py-1">{{ $t('message.step2') }}</span> {{ $t('message.qrAuth') }}</div>
      <div class="border p-3">
        <p class="mb-0 text-center" v-html="$t('message.qrDesc1')"></p>
        <div class="text-center">
          <qrcode :value=otpData.url :options="{ width: 200 }"></qrcode>
        </div>
        <div class="fa-2 text-center otp_directCode">
          <span class="mb-1 d-block" v-html="$t('message.qrDesc2')"></span>
          <code>{{ otpData.key }}</code>
        </div>
        <hr/>
        <p class="mb-1 text-center otp_directCode">{{ $t('message.qrDesc3') }}</p>
        <p class="text-center otp_directCode">
          <code>ubit ({{ $t('message.yourId') }})</code>
        </p>
        <div class="form-horizontal text-center px-0">
          <input type="hidden" name="_token">
          <div class="input-group col-md-6 offset-md-3 px-0 ">
            <input type="text" class="form-control input-lg" maxlength="6" :placeholder="$t('message.placeholderOTP')" v-model="optCode">
            <div class="input-group-append" @click="confirm">
              <button class="btn btn-basic input-lg">{{ $t('message.auth') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Security_Info",

  data() {
    return {
      otpData: {
        key: "",
        url: "",
      },
      optCode: '',
      email: '',
    }
  },

  created() {
    this.$parent.setHeaderTitle('OTP' + this.$t('message.auth'), false, true)

    this.$get(this.$USER_MYPAGE_SECURITY_OTP, 'myPageSecurityOtpGetOTP', true, (result) => {
      this.otpData = result.data[0]
    }, (result) => {
      console.log(result)
    })

    this.getUserProfile((result) => {
      if(result.success) {
        this.email = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    confirm() {
      let formData = new FormData()
      formData.append('key', this.otpData.key)
      formData.append('code', this.optCode.toString())

      this.$post(this.$USER_MYPAGE_SECURITY_OTP, 'myPageSecurityOptPostCode', formData, true, (result) => {
        this.notify('success', result.message)
        this.$router.push({ name: 'Security' })
      }, (result) => {
        this.notify('error', result.message)
      })
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle('OTP' + this.$t('message.auth'), false, true)
      }
    },
  }
}
</script>

<style scoped>

</style>
