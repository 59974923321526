<template>
  <div class="cv_wallet">
    <Modal ref="Modal">
      <div>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">TXID</dt>
          <dd class="wallet_form_text t_left dblue_02">{{ transfer.txid === 'safe-withdraw-pending' ? $t('message.waitForSafeWith') : transfer.txid }}</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.curr') }}</dt>
          <dd class="wallet_form_text t_left">{{ transfer.tickerName.charAt(0).toUpperCase() + transfer.tickerName.slice(1) }}</dd>
        </dl>
        <h6 class="modal_sub_tit left price_blue mt-3">From</h6>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">ID</dt>
          <dd class="wallet_form_text t_left">{{ transfer.tx_type.indexOf('IN') >= 0 ? id : userEmail }}</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.quantity') }}</dt>
          <dd class="wallet_form_text t_left">{{ toFloat(transfer.amount) | numberWithCommas }} {{ transfer.tickerName }}</dd>
        </dl>
        <h6 class="modal_sub_tit left price_blue mt-3">To</h6>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">ID</dt>
          <dd class="wallet_form_text t_left">{{ transfer.tx_type.indexOf('OUT') >= 0 ? id : userEmail }}</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.quantity') }}</dt>
          <dd class="wallet_form_text t_left">{{ Number(transfer.amount) - Number(transfer.feeAmount) | numberWithCommas }} {{ transfer.tickerName }}</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.fees') }}</dt>
          <dd class="wallet_form_text t_left">{{ toFloat(transfer.feeAmount) }} {{ transfer.tickerName }}</dd>
        </dl>
      </div>
    </Modal>
    <ul class="maintab">
      <li class="maintab_li w-50" :class="{ active: tab1Active }" @click="activeTab(1)">{{ $t('message.assetStatus') }}</li>
      <li class="maintab_li w-50" :class="{ active: tab2Active }" @click="activeTab(2)">{{ $t('message.coinDepWith') }}</li>
    </ul>
    <div class="list-group w-100 wallet-list" v-show="tab1Active">
      <div class="row mx-0">
        <div class="col-12 px-0 input_box">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text rounded-0 bg-white border-left-0"><i class="xi-search search_btn"></i></span>
            </div>
            <input
                type="text"
                class="form-control border-left-0 border-right-0 rounded-0 search-control"
                :placeholder="$t('message.search')"
                v-model="coin"
                @keyup.enter="searchCoin"
            >
          </div>
        </div>
        <div class="col-12 border-bottom-bold total_price row mx-0">
          <span class="h5 mb-0 col-4 px-0 align-self-center">{{ $t('message.totalAsset') }}</span>
          <span class="h5 font-weight-bold mb-0 text-basic col-8 px-0 text-right">{{ subDec(allAsset, 'UBIT') | numberWithCommas }} UBIT</span>
        </div>
        <div class="col-12 px-0 checkbox">
          <div class="row mx-0 py-1 border-bottom-bold">
            <div class="form-group form-check mb-0 pl-3 d-block col-6 py-2 pr-0">
              <label class="check">{{ $t('message.hideWallet') }}
                <input type="checkbox" v-model="checkbox" @change="toggleCheckbox">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-6 py-2 text-right" style="font-size: 12px;">
              {{ $t('message.tradeAvailable') }}
              <span class="text-primary">{{ this.coins.length }}</span>{{ $t('message.cnt') }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row border-bottom-bold" style="cursor:pointer" v-for="balance in balanceList" :key="balance.coin">
          <div class="col-5 py-3 align-self-center pr-0" @click="goToDetail(balance.coin)">
            <div class="coin_img_wrap">
              <img :src="getCoinImg(balance.coin)" :alt="balance.coin" @error="hideImg($event)" width="18">
            </div>
            <span class="tit">{{ getCoinFullName(balance.coin) }}</span>
          </div>
          <div class="text-right col-7 pl-0 py-3" @click="goToDetail(balance.coin)">
            <div class="position-relative" style="padding-right: 20px;">
              <span class="d-block">{{ balance.balance | numberWithCommas }} <span class="font-weight-normal">{{ balance.coin }}</span></span>
<!--                  <span class="text-muted d-block small" :key="rerenderKey">{{ balance.totalAsset | numberWithCommas }} <span>USD</span></span>-->
              <span class="text-muted small" v-show="balance.coin !== 'UBIT'">{{ subDec(balance.exchangeUbit, 'UBIT') }} <span>UBIT</span></span>
              <i class="xi-angle-right position-absolute text-basic" style="right: 0; top: 50%; transform: translateY(-50%);"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-group w-100 wallet-list" v-show="tab2Active">
      <div class="trade_listTable outer">
        <div class="px-2 pb-2 pt-3 clearfix">
          <h5>{{ $t('message.statements') }}</h5>
        </div>
        <div class="otc_listBox mx-2">
          <table class="table otc_table mb-0 table-bordered">
            <thead class="thead-light">
            <tr>
              <th rowspan="2" class="p-1 text-center">{{ $t('message.sort') }}</th>
              <th class="p-1 text-center">{{ $t('message.quantity') }}</th>
              <th class="p-1 text-center">{{ $t('message.fees') }}</th>
            </tr>
            <tr>
              <th class="p-1 text-center">{{ $t('message.time') }}</th>
              <th class="p-1 text-center">{{ $t('message.moreInfo') }}</th>
            </tr>
            </thead>
            <tbody v-for="(transfer, idx) in transferList" :key="idx">
            <tr>
              <td rowspan="2"
                  class="p-1 text-center"
                  :class="{ price_blue: transfer.type.slice(-2) === $t('message.withdrawal'), price_red: transfer.type.slice(-2) === $t('message.deposit') }">
                {{ transfer.type }}
              </td>
              <td class="p-1 text-right">{{ toFloat(transfer.amount) }} {{ fullnameToTicker(transfer.tickerName) }}</td>
              <td class="p-1 text-right">{{ toFloat(transfer.feeAmount) }} {{ fullnameToTicker(transfer.tickerName) }}</td>
            </tr>
            <tr>
              <td class="p-1 text-center">{{ transfer.date }}</td>
              <td class="p-1 text-center">
                <a class="outline_s_btn" v-show="transfer.tx_type === 'TX_IN' || transfer.tx_type === 'TX_OUT' || transfer.tx_type === 'HOUSE_IN' || transfer.tx_type === 'HOUSE_OUT'" @click="openDetail(transfer)">{{ $t('message.blockChain') }}</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="buttonwrap t_center">
          <paginate
              :page-count="pageLast"
              :page-range="3"
              :margin-pages="2"
              :click-handler="getTransferList"
              :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :first-last-button=true
              :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)
import Loader from '@/components/cmp/Loader'
import Modal from '@/components/cmp/modal/Modal'
import Big from "big-js";

export default {
  name: "Coin_List",

  components: {
    'Modal': Modal,
  },

  data() {
    return {
      tab1Active: false,
      tab2Active: false,
      checkbox: false,
      coins: [],
      coin: '',
      balanceList: [],
      rerenderKey: 0,
      loader: this.$loading.show({
        backgroundColor: '#65686F',
        opacity: 0.5,
        zIndex: 999,
      }, {
        default: this.$createElement(Loader)
      }),
      allAsset: 0,
      transferList: [],
      transfer: {
        amount: '',
        date: 0,
        feeAmount: '',
        opposite: '',
        tickerName: '',
        txid: '',
        type: '',
        tx_type: '',
        fromSpot: true,
      },
      pageLast: 0,
      transferAmount: 0,
      userEmail: '',
      marketList: [],
      defaultSecondary: '',
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.manageMyAsset'), false, false)

    this.getTransferList(1)

    this.getUserProfile((result) => {
      if(result.success) {
        this.userEmail = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })

    let tabNo = sessionStorage.getItem('walletTabNo')
    if(tabNo) {
      if(tabNo === '1') {
        this.tab1Active = true
      } else {
        this.tab2Active = true
      }
    } else {
      this.tab1Active = true
      sessionStorage.setItem('walletTabNo', 1)
    }

    this.$get(this.$HOME_SECONDARY, 'MarketGetHomeSecondApp', false, (result) => {
      let secondCurrArr = result.data
      this.defaultSecondary = secondCurrArr[0]
      this.getMarketList()
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$parent.focusMenu(2)
    localStorage.setItem('focusMenu', 2)

    if(!this.$store.getters.getLoginState) {
      this.notify('error', this.$t('message.chkAfterLogin'))
      this.$router.push({ name: 'Login' })
      return
    }

    this.getWallet()

    this.$parent.showFooter(true)
  },

  beforeDestroy() {
    this.loader.hide()
  },

  methods: {
    checkId(tx_type) {
      if (tx_type.indexOf('HOUSE') >= 0) {
        this.id = this.$t('message.inWallet')
      } else {
        this.id = this.$t('message.exWallet')
      }
    },

    checkModalTitle(tx_type) {
      if ('TX_IN' === tx_type || 'HOUSE_IN' === tx_type) {
        this.modalTitle = this.$t('message.walletDeposit')
      } else if ('TX_OUT' === tx_type || 'HOUSE_OUT' === tx_type) {
        this.modalTitle = this.$t('message.walletWithdrawal')
      }
    },

    getMarketList() {
      this.$get(`${this.$HOME_MARKET}?secondary=${this.defaultSecondary}`, 'getUbitCurr', false, (result) => {
        this.marketList = result.data
      }, (result) => {
        console.log(result)
      })
    },

    goToGeneralMarket(coin) {
      let marketPair = `BTC-${this.defaultSecondary}`
      this.marketList.forEach(market => {
        if(market.marketPair.split('-')[0] === coin) {
          marketPair = `${coin}-${this.defaultSecondary}`
        }
      })

      localStorage.setItem('tradeTab', 1)
      this.$router.push({ name: 'GeneralExchange', params: { marketPair: marketPair } })
    },

    activeTab(tabNo) {
      if(tabNo === 1) {
        this.tab1Active = true
        this.tab2Active = false
        sessionStorage.setItem('walletTabNo', 1)
      } else {
        this.tab1Active = false
        this.tab2Active = true
        sessionStorage.setItem('walletTabNo', 2)
      }
    },

    openDetail(transfer) {
      this.transfer = transfer
      this.checkModalTitle(this.transfer.tx_type)
      this.checkId(this.transfer.tx_type)
      this.$refs.Modal.showDialog(transfer.type === this.$t('message.exWithdrawal') ? this.$t('message.walletWithdrawal') : this.$t('message.walletDeposit'), '', this.$t('message.close'), () => {}, ()=>{})
    },

    goToDetail(coin) {
      sessionStorage.setItem('withdrawalCoin', coin)
      this.$router.push({ name: 'CoinDetail' })
    },

    hideImg(e) {
      e.currentTarget.classList.add('hidden')
    },

    toggleCheckbox() {
      if(this.checkbox) {
        this.balanceList = this.balanceList.filter((balance) => (
            balance.balance !== 0 && balance.available !== 0
        ))
      } else {
        this.allAsset = 0
        this.getWallet()
      }
    },

    getWallet() {
      this.balanceList = []
      this.$get(this.$WALLET_BALANCE, 'AssetWalletGetTotal', true, (result) => {
        this.loader.hide()

        let list = result.data[0]
        this.coins = Object.keys(list)

        let total = new Big(0)

        this.coins.forEach((coin) => {
          let exchange = new Big(list[coin].balance).mul(new Big(list[coin].exchangeUbit))
          this.balanceList.push({
            coin: coin,
            balance: list[coin].balance,
            available: list[coin].available,
            exchangeUbit: this.subDec(exchange.toFixed(20))
          })

          if(coin === 'UBIT') {
            total = total.plus(new Big(list[coin].available))
          } else {
            total = total.plus(exchange)
          }
        })

        this.allAsset = this.subDec(total.toFixed(20))
        this.$forceUpdate()
      }, (result) => {
        console.log(result)
      })
    },

    getExchangeRate(bal) {
      this.$get(`${this.$OTC_DISPLAY_USDKRW}`, 'walletGetUSDKRW', true, (result) => {
        let rate = result.data[0].rate
        bal.totalAsset = this.subDec(rate * bal.balance, 'USD')
        this.allAsset += Number(bal.totalAsset)
        this.rerenderKey += 1
      }, (result) => {
        console.log(result)
      })
    },

    searchCoin() {
      this.balanceList = []
      this.coin = this.coin.toUpperCase()

      if(this.coin.trim() === '') {
        this.coin = ''
        this.getWallet()
      } else {
        this.$get(`${this.$WALLET_BALANCE_TICKER}?ticker=${this.coin}`, 'AssetWalletGetBalance', true, (result) => {
          let list = result.data[0]
          this.balanceList.push({
            coin: this.coin,
            balance: list.balance,
            available: list.available,
            exchangeUbit: this.subDec(list.balance * list.exchangeUbit)
          })
        }, (result) => {
          console.log(result)
        })
      }
    },

    getTransferList(page) {
      this.transferList = []
      let formData = new FormData()
      let limit = 10

      this.$get(`${this.$WALLET_TRANSFER_COIN}?limit=${limit}&page=${page}`, 'AssetWalletTotalTransfer', formData, (result) => {
        this.pageLast = Math.ceil(result.total / limit)
        this.transferList = result.data

        this.transferList.forEach((transfer) => {
          transfer.tx_type = transfer.type;
          transfer.type = this.getTransferType(transfer.type, transfer.txid)
          transfer.date = this.formatDate(transfer.date * 1000, 'full')
        })
      }, (result) => {
        console.log(result)
      })
    },

    getTransferType(type, txid) {
      let transferType = type

      if(type === 'HOUSE_IN') {
        transferType = this.$t('message.inDeposit')
      } else if (type === 'HOUSE_OUT') {
        transferType = this.$t('message.inWithdrawal')
      } else if (type === 'TX_IN') {
        transferType = this.$t('message.exDeposit')
      } else if(type === 'TX_OUT') {
        transferType = this.$t('message.exWithdrawal')
      } else if (type === 'ESCROW_IN') {
        transferType = this.$t('message.escrowIn')
      } else if(type === 'ESCROW_OUT'){
        transferType = this.$t('message.escrowOut')
      } else if(type === 'ADMIN_IN') {
        transferType = this.$t('message.inDeposit')
      } else if(type === 'ADMIN_OUT') {
        if(txid === 'internal') {
          transferType = this.$t('message.inWithdrawal')
        } else {
          transferType = this.$t('message.exWithdrawal')
        }
      }

      return transferType
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.manageMyAsset'), false, false)
        this.getTransferList(1)
      }
    },
  }
}
</script>

<style scoped>

</style>
