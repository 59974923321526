<template>
  <div class="invest_coinsBox invest_trade__box">
    <Modal ref="Modal">
      <div class="px-2 py-2 clearfix">
        <h2 class="mb-3">
          <span class="active">{{ $t('message.tradeRequest') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
          <span class="grey">{{ $t('message.tradeApp') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
          <span class="grey">{{ $t('message.depositAccept') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
          <span class="grey">{{ $t('message.traded') }}</span>
        </h2>
        <div class="support_viewrap">
          <div class="mk_t_content">
            <table class="table_o_1 my-2">
              <thead>
              <tr>
                <th>{{ post.writerNickname }}</th>
                <th>({{ $t('message.postTrade') }} {{ writerInfo.allCnt }} | {{ $t('message.traded') }} {{ writerInfo.soldoutCnt }})</th>
              </tr>
              </thead>
            </table>
          </div>
          <div class="purchase_offer">
            <dl class="table_line">
              <div class="notice">
                <p class="fs18">{{ $t('message.tradeRequest1') }}</p>
                <p class="fs18">{{ $t('message.tradeRequest2') }}</p>
              </div>
            </dl>
            <hr/>
            <dl class="table_line">
              <dt class="table_line_title mb-1">
                <span>{{ $t('message.tradeAmt') }}</span>
              </dt>
              <dd class="position-relative">
                <input type="text" class="explain pr pr-5 form-control" v-model="amount"/>
                <span class="coin">{{ !post.coin.includes('UNLISTED-') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}</span>
              </dd>
            </dl>
            <dl class="table_line">
              <dt class="table_line_title mb-1">
                <span>{{ $t('message.price') }}</span>
              </dt>
              <dd>
                <input type="text" class="explain pr-5 form-control" v-model="price"/>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </Modal>
    <div class="mt-5">
      <div class="trade_listTable outer">
        <div class="px-2 py-2 clearfix">
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" @change="($event)=>{this.$router.replace({name:$event.currentTarget.value})}">
              <option value="OtcSuggestionMarketList" :selected="tabNo === 0">{{ $t('message.otcMarket') }}</option>
              <option value="OtcSuggestionHistoryList" :selected="tabNo === 1">{{ $t('message.tradingHis') }}</option>
              <option value="OtcSuggestionChatList" :selected="tabNo === 2">{{ $t('message.otcChat') }}</option>
              <option value="OtcSuggestionMypostList" :selected="tabNo === 3">{{ $t('message.myPost') }}</option>
            </select>
          </div>
          <span class="col-12 h5 float-left text-left px-0 mt-1">[{{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}
            <span :class="{ 'price_red': post.stateVal === 'doBuy', 'price_blue': post.stateVal === 'doSell' }">{{ $t(`message.${post.stateVal}`) }}</span>]
            <span class="fwm">{{ post.title }}</span>
          </span>
          <div class="text-right" v-show="post.amIWriter" :key="rerenderkey + Math.random()">
            <a class="outline_s_btn m_left10" @click="completeTrade" v-show="post.state === 'OPEN'">{{ $t('message.endOfSale') }}</a>
            <a class="outline_s_btn m_left10" @click="updateTrade" v-show="post.state === 'OPEN'">{{ $t('message.modify') }}</a>
            <a class="outline_s_btn m_left10" @click="deleteTrade" v-show="post.state === 'OPEN' || post.state === 'SOLDOUT'">{{ $t('message.delete') }}</a>
          </div>
          <div class="mk_t_content">
            <table class="table_o_1 my-2">
              <thead>
              <tr>
                <th>{{ post.writerNickname }}</th>
                <th>({{ $t('message.postTrade') }} {{ writerInfo.allCnt }} | {{ $t('message.traded') }} {{ writerInfo.soldoutCnt }})</th>
                <th>
                  <div
                      class="middle_btn1 text-center border border-primary mr-1"
                      @click="openModal"
                      :key="rerenderkey"
                      v-show="!post.amIWriter && (post.state === 'OPEN' || post.state === 'TRADING')"
                  >
                    <a class="price_blue">{{ $t('message.tradeRequest') }}</a>
                  </div>
                </th>
              </tr>
              </thead>
            </table>
          </div>
          <div class="form-group text-left my-0 mx-0 mb-3">
            <p>{{ $t('message.priceWanted') }}: {{ post.exchanges === 'NONE' ? $t('message.negoPrice') : post.exchanges }} {{ post.exchanges === 'NONE' ? '' : (post.markUp >= 0 ? $t('message.standard') + ' +' : $t('message.standard') + ' ') + post.markUp + '%' }}</p>
            <br/>
            <p>{{ $t('message.tradeType') }}: {{ post.tradeMethod === 0 ? $t('message.safeTran') : $t('message.directTran') }}</p>
            <br/>
            <p>{{ $t('message.howToPay') }}</p>
          </div>
          <hr/>
          <div class="form-group row my-0 mx-0 mb-0">
            <p class="text-left py-2 px-0 col-12" v-html="post.content"></p>
          </div>
          <button class="col-12 btn btn-basic btn-md mt-2" @click="$router.back()">{{ $t('message.list') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/cmp/modal/Modal'

export default {
  name: "OtcSuggestionMarketDetail",

  data() {
    return {
      fileName: '',
      msg: '',
      price: '',
      amount: '',
      isTradeRequested: false,
      rerenderkey: 0,
      post: {
        idx: 0,
        state: '',
        boardIsBuy: true,
        coin: '',
        title: '',
        writerUserIdx: 0,
        createdAt: 0,
        writerNickname: '',
        tradeMethod: 0,
        amIWriter: false,
        stateVal: '',
        applicantInputAmount: 0,
        applicantInputPrice: 0,
        applicantUserIdx: 0,
        chatroom: [],
        content: '',
        exchanges: '',
        markUp: 0,
        offersList: [],
        chatroomIdx: null
      },
      writerInfo: {
        allCnt: 0,
        soldoutCnt: 0
      },
      postIdx: this.$route.params.postIdx,
      tabNo: this.$route.params.tabNo,
      myIdx: null,
      chatInterval: null,
      lastTimestamp: 0,
      msgAll: [],
      tmpMsg: [],
      ifImgError: false,
      imgFile: null,
      rgx: /^[0-9]*\.?[0-9]*$/ // 정수 및 소수점 형식만 허용
    }
  },

  components: {
    'Modal': Modal,
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.otcMarket'), false, true)

    if(!this.postIdx) {
      this.$router.back()
      return
    }

    this.getPostDetail()
  },

  beforeDestroy() {
    clearInterval(this.chatInterval)
  },

  mounted() {
    this.$parent.focusMenu(3)
  },

  methods: {
    openModal() {
      let coin = !this.post.coin.includes('UNLISTED') ? this.post.coin : this.post.coin.split('-')[1] + '(직접거래)'
      let state = this.$t(`message.${this.post.stateVal}`)

      this.$refs.Modal.showDialog(`[${coin} ${state}] ${this.post.title}`, '거래요청', this.$t('message.cancel'), () => {
        if(this.amount * 1 <= 0 || this.price * 1 <= 0 ) {
          this.notify('error', this.$t('message.shouldBeMoreThanZero'))
          return
        }
        this.requestTrade()
      }, ()=>{}, true, '', false)
    },

    createChatroom() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)
      this.$post(this.$OTC_TEMP_CHATROOM, 'otcCreateChat', formData, true, (result) => {
        this.post.chatroomIdx = result.data[0].chatroomIdx
      }, (result) => {
        console.log(result)
      })
    },

    requestTrade() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('amount', this.amount)
      formData.append('price', this.price)

      this.$post(this.$OTC_TEMP_TRADE_REQUEST, 'PostTradeMarket', formData, true, () => {
        this.createChatroom();
        this.notify('success', this.$t('message.tradeRequestDone'))
        this.$router.push({ name: 'OtcSuggestionHistoryList', params: { tabNo: 1 } })
      }, (result) => {
        console.log(result)
      })
    },

    getPostDetail() {
      this.$get(`${this.$OTC_TEMP_LIST}/${this.postIdx}`, 'OtcDetailGetLIST', true, (result) => {
        this.post = result.data[0]

        this.getUserProfile((result) => {
          if(result.success) {
            this.myIdx = result.userProfile.userIdx

            this.whatAmI()
          } else {
            console.log(result.userProfile)
          }
        })

        if(this.post.boardIsBuy) {
          this.post.stateVal = 'doBuy'
        } else {
          this.post.stateVal = 'doSell'
        }

        if(this.post.state === 'SOLDOUT') {
          this.post.stateVal = 'endOfSale'
        }

        this.post.content = this.post.content.replaceAll('\n', '<br/>')

        this.getWriterInfo()
      }, (result) => {
        console.log(result)
      })
    },

    getWriterInfo() {
      let formData = new FormData()
      formData.append('writerIdx', this.post.writerUserIdx)
      this.$post(this.$OTC_TEMP_COUNT, 'otcDetailMarket', formData, true, (result) => {
        this.writerInfo = result.data[0]
      }, (result) => {
        console.log(result)
      })
    },

    whatAmI() {
      this.post.amIWriter = this.myIdx === this.post.writerUserIdx
      this.rerenderkey += 1
    },

    completeTrade() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)
      this.$post(this.$OTC_TEMP_TRADE_DONE, 'otcDetailTradeDone', formData, true, (result) => {
        console.log(result)
        this.notify('success', this.$t('message.didTradeDone'))
        this.$router.back()
      }, (result) => {
        console.log(result)
      })
    },

    updateTrade() {
      this.$router.push({ name: 'OtcSuggestionWrite', params: { post: this.post }})
    },

    deleteTrade() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)

      this.$post(this.$OTC_TEMP_DELETE, 'otcDetailDelete', formData, true, (result) => {
        console.log(result)
        this.notify('success', this.$t('message.deleted'))
        this.$router.back()
      }, (result) => {
        console.log(result)
      })
    }
  },

  watch: {
    'amount': {
      handler() {
        if(!this.amount.toString().match(this.rgx)) {
          this.amount = this.amount.toString().slice(0, this.amount.length - 1)
          return
        }
      }
    },

    'price': {
      handler() {
        if(!this.price.toString().match(this.rgx)) {
          this.price = this.price.toString().slice(0, this.price.length - 1)
          return
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
