<template>
  <div class="invest_coinsBox invest_trade__box">
    <div class="mt-5">
      <div class="trade_listTable outer">
        <div class="px-2 py-2 clearfix">
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" @change="($event)=>{this.$router.replace({name:$event.currentTarget.value})}">
              <option value="OtcSuggestionMarketList" selected>{{ $t('message.otcMarket') }}</option>
              <option value="OtcSuggestionHistoryList">{{ $t('message.tradingHis') }}</option>
              <option value="OtcSuggestionChatList">{{ $t('message.otcChat') }}</option>
              <option value="OtcSuggestionMypostList">{{ $t('message.myPost') }}</option>
            </select>
          </div>
          <div class="d-flex col-12 justify-content-between px-0 pb-2">
            <button class="w-48 btn btn-md" @click="switchOtcType(undefined)" :class="{'btn-basic':isAll && !isBuy}">{{ $t('message.all') }}</button>
            <button class="w-48 btn btn-md" @click="switchOtcType(false)" :class="{'btn-basic':!isAll && !isBuy}">{{ $t('message.doSell') }}</button>
            <button class="w-48 btn btn-md" @click="switchOtcType(true)" :class="{'btn-basic':!isAll && isBuy}">{{ $t('message.doBuy') }}</button>
          </div>
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" @change="selectRow(false)" v-model="activeCoin">
              <option v-for="(cnt) in cntByCoin" :key="cnt.coin" :value="cnt.coin">
                {{ cnt.coin === 'ALL' ? $t('message.all') : cnt.coin.includes('UNLISTED') ? cnt.coin.split('-')[1] + '('+ $t('message.directTran') +')' : cnt.coin }} ({{ cnt.count }}{{ $t('message.counting') }})
              </option>
            </select>
          </div>
          <button class="col-12 btn btn-basic btn-md mt-2" @click="$router.push({ name: 'OtcSuggestionWrite' })">{{ $t('message.registration') }}</button>
          <div class="row col-form-label mx-0">
            <div class="input-group">
              <select class="form-control w-25 mr-2" v-model="searchFilter" @change="searchFilter === 'all' ? keyword = '' : ''">
                <option value="all">{{ $t('message.all') }}</option>
                <option value="title">{{ $t('message.title') }}</option>
                <option value="nickname">{{ $t('message.nickname') }}</option>
                <option value="safeTran">{{ $t('message.safeTran') }}</option>
              </select>
              <div class="input-group-prepend">
              <span class="input-group-text rounded-0 bg-white">
                <i class="xi-search search_btn"></i>
              </span>
              </div>
              <input style="width: 34%;" type="text" class="form-control border-left-0 rounded-0 search-control pl-0" v-model="keyword" :disabled="searchFilter === 'all'" @keyup.enter="getPostList(1)"/>
              <button class="w-25 ml-2 btn btn-md" @click="getPostList(1)">{{ $t('message.search') }}</button>
            </div>
          </div>
        </div>
        <div class="otc_listBox mx-2">
          <table class="table otc_table mb-0 table-bordered">
            <thead class="thead-light">
            <tr>
              <th rowspan="2" class="p-1">{{ $t('message.sort') }}</th>
              <th rowspan="2" class="p-1">{{ $t('message.title') }}</th>
              <th rowspan="2" class="p-1">{{ $t('message.time') }}</th>
              <th class="p-1">{{ $t('message.nickname') }}</th>
            </tr>
            <tr>
              <th scope="col">
                <input type="checkbox" class="point" v-model="isTrustUser" @change="getPostList(1)"/>
<!--                <em class="m_left5">Trust User</em>-->
                <span class="trust-mark">T</span>
              </th>
            </tr>
            </thead>
            <tbody v-for="(post, index) in postList" :key="'post' + index" @click="goToDetail(post)">
              <tr>
                <td rowspan="2" class="p-1">{{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}
                  <span :class="{
                    'price_red': post.state !== 'SOLDOUT' && post.stateVal === 'doBuy',
                    'price_blue': post.state !== 'SOLDOUT' && post.stateVal === 'doSell' }"
                  >
                    {{ post.state === 'SOLDOUT' ? $t('message.endOfSale') : $t(`message.${post.stateVal}`) }}
                  </span>
                </td>
                <td rowspan="2" class="p-1">{{ post.title }}</td>
                <td rowspan="2" class="p-1">{{ post.timeGap }}</td>
                <td class="p-1">{{ post.writerNickname }}</td>
              </tr>
              <tr>
                <td class="t_right text-center"><span class="trust-mark" v-show="post.writerStateTrust">T</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="buttonwrap t_center">
          <paginate
              :page-count="pageLast"
              :page-range="3"
              :margin-pages="2"
              :click-handler="getPostList"
              :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :first-last-button=true
              :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import OtcMenuPC from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_pc'

export default {
  name: "OtcSuggestionMarketList",

  data() {
    return {
      pageLast: 0,
      searchListPageLast: 0,
      isBuy: false,
      cntByCoin: [],
      postList: [],
      searchFilter: 'all',
      keyword: '',
      limit: 10,
      activeCoin: '',
      isTrustUser: false,
      page: 1,
      isAll: true
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.otcMarket'), false, false)

    this.isBuy = sessionStorage.getItem('otcSuggestionListType') === 'true'

    let coinFilter = sessionStorage.getItem('otcCoinFilter')
    if(coinFilter) {
      this.activeCoin = coinFilter
    }

    this.$get(this.$OTC_TEMP_TICKERS + '?isEscrow=1', 'getOTCTempTickers', true, (result) => {
      this.otcList = result.data

      this.getPostList(1)
      this.getCoinList()
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$parent.focusMenu(3)
  },

  methods: {
    async switchOtcType(isBuy) {
      if (isBuy === undefined) {
        this.isAll = true;
        this.isBuy = false;
      } else {
        this.isAll = false;
        this.isBuy = isBuy;
      }
      this.activeCoin = 'ALL'
      await this.getCoinList()
      await this.getPostList(1)

      sessionStorage.setItem('otcSuggestionListType', isBuy)
      this.selectRow(true)
    },

    goToDetail(post) {
      this.$router.push({ name: 'OtcSuggestionMarketDetail', params: { postIdx: post.idx, tabNo: 0 } })
    },

    selectRow(isDefaultCoin) {
      if(isDefaultCoin) {
        this.activeCoin = this.cntByCoin[0].coin
      }

      this.cntByCoin.forEach(coinObj => {
        if(coinObj.coin === this.activeCoin) {
          this.activeCoin = coinObj.coin
          sessionStorage.setItem('otcCoinFilter', coinObj.coin)
        }
      })

      this.getPostList(1)
    },

    getPostList(page = 1) {
      let formData = new FormData()
      formData.append('isBuy', this.isBuy)
      formData.append('all', this.isAll);
      this.activeCoin !== 'ALL' ? formData.append('coin', this.activeCoin) : ''
      formData.append('filter', this.searchFilter.toUpperCase())
      formData.append('keyword', this.keyword)
      formData.append('limit', this.limit)
      formData.append('page', page)
      formData.append('state', 'OPEN')
      formData.append('state', 'TRADING')
      //formData.append('state', 'SOLDOUT')
      this.isTrustUser ? formData.append('stateTrust', this.isTrustUser) : ''

      this.$post(this.$OTC_TEMP_LIST, 'otcTempList', formData, false, (result) => {
        this.postList = result.data
        this.pageLast = Math.ceil(result.total / this.limit)

        let currentDate = new Date()
        let timestamp = currentDate.getTime() / 1000

        this.postList.forEach(post => {
          let timeGap = timestamp - post.createdAt / 1000

          // 24시간 = 86400초
          if(timeGap < 1) {
            post.timeGap = this.$t('message.before0')
          } else if(timeGap < 86400) {
            post.timeGap = this.secondsToHms(timeGap)
          } else if(timeGap >= 86400) {
            post.timeGap = this.secondsToDay(timeGap)
          }

          if(post.boardIsBuy) {
            post.stateVal = 'doBuy'
          } else {
            post.stateVal = 'doSell'
          }

          if(post.state === 'SOLDOUT') {
            post.stateVal = 'endOfSale'
          }
        })
      }, (result) => {
        console.log(result)
      })
    },

    secondsToHms(timeGap) {
      let h = Math.floor(timeGap % (3600*24) / 3600)
      let m = Math.floor(timeGap % 3600 / 60)
      let s = Math.floor(timeGap % 60)

      let hDisplay = h > 0 ? h + this.$t('message.time') + ' ' : ''
      let mDisplay = m > 0 ? m + this.$t('message.min') + ' ' : ''
      let sDisplay = s > 0 ? s + this.$t('message.sec') + ' ' : ''
      return hDisplay + mDisplay + sDisplay + this.$t('message.before')
    },

    secondsToDay(timeGap) {
      let d = Math.floor(timeGap / (3600*24))

      let dDisplay = d > 0 ? d + this.$t('message.day') + ' ' : ''
      return dDisplay + this.$t('message.before')
    },

    getCoinList() {
      this.$get(`${this.$OTC_TEMP_COUNT}?isBuy=${this.isBuy}&isAll=${this.isAll}`, 'otcSuggestionOtcListAll', false, (result) => {
        this.cntByCoin = []
        for (let i = 0; i < this.otcList.length; i++) {
          let found = false
          for (let j = 0; j < result.data.length; j++) {
            if(this.otcList[i] === result.data[j].coin) {
              this.cntByCoin.push(result.data[j])
              found = true

              break
            }
          }

          if(found === false) {
            this.cntByCoin.push({
              coin: this.otcList[i],
              count: 0,
            })
          }
        }
        let cntAll = 0
        this.cntByCoin.forEach(coinObj => {
          for (let j = 0; j < result.data.length; j++) {
            if('UNLISTED-' + coinObj.coin === result.data[j].coin) {
              coinObj.count += result.data[j].count
              break;
            }
          }
          cntAll += coinObj.count
        })

        this.cntByCoin.unshift({
          coin: 'ALL',
          count: cntAll,
        })

        let coinFilter = sessionStorage.getItem('otcCoinFilter')
        if(coinFilter) {
          this.cntByCoin.forEach(coinObj => {
            if(coinFilter === coinObj.coin) {
              this.activeCoin = coinObj.coin
            }
          })
        } else {
          this.activeCoin = 'ALL'
          sessionStorage.setItem('otcCoinFilter', 'ALL')
        }
      }, (result) => {
        console.log(result)
      })
    }
  }
}
</script>

<style scoped>

</style>
