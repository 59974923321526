<template>
  <div class="invest_coinsBox invest_trade__box">
    <Modal ref="Modal">
      <div>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.sort') }}</dt>
          <dd class="wallet_form_text t_left">{{ isBuy === 'true' ? $t('message.doBuy') : $t('message.doSell') }}</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.tradeType') }}</dt>
          <dd class="wallet_form_text t_left">{{ tradeMethod === '0' ? $t('message.safeTran') : $t('message.directTran') }}</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.coin') }}</dt>
          <dd class="wallet_form_text t_left">{{ selectedCoin.includes('UNLISTED') ? selectedCoin.split('-')[1] + '(직접거래)' : selectedCoin }}</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.unitPrice') }}</dt>
          <dd class="wallet_form_text t_left">
            {{ selectedExchange === 'NONE' ? $t('message.negoPrice') : selectedExchange }}
            {{ selectedExchange === 'NONE' ? '' : (markUp >= 0 ? $t('message.directTran') + ' +' : $t('message.directTran') + ' ') + markUp + '%' }}
          </dd>
        </dl>
      </div>
      <div class="checks otc_offer_ckh">
        <input type="checkbox" id="check_03" v-model="confirmChk" @change="toggleChk" />
        <label for="check_03">{{ $t('message.confirmChk') }}</label>
      </div>
    </Modal>
    <div class="mt-5">
      <div class="trade_listTable outer text-left p-3">
        <h5 class="sell-color">{{ isForUpdate ? $t('message.postModify') : $t('message.otcSug') }}</h5>
        <hr>
        <div class="py-2 clearfix">
          <span>{{ $t('message.sort') }}</span>
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" v-model="isBuy">
              <option value="false">{{ $t('message.doSell') }}</option>
              <option value="true">{{ $t('message.doBuy') }}</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="py-2 clearfix">
          <span>{{ $t('message.tradeType') }}</span>
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" v-model="tradeMethod" @change="getCoinList" :disabled="isForUpdate">
              <option value="0">{{ $t('message.safeTran') }}</option>
              <option value="1">{{ $t('message.directTran') }}</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="py-2 clearfix">
          <div class="form-group row mx-0 py-2 mb-0">
            <span class="fwb">{{ $t('message.safeTran') }}:</span><p>{{ $t('message.safeTradeDes') }}</p>
            <span class="fwb mt-1">{{ $t('message.directTran') }}:</span><p>{{ $t('message.directTradeDes') }}</p>
          </div>
        </div>
        <hr>
        <div class="py-2 clearfix">
          <span>{{ $t('message.coin') }}</span>
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" v-model="selectedCoin" v-show="tradeMethod === '0'" :disabled="isForUpdate">
              <option v-for="listedCoin in listedCoinArr" :key="listedCoin" :value="listedCoin">{{ listedCoin }}</option>
            </select>
            <select class="form-control w-100" v-model="selectedCoin" v-show="tradeMethod === '1'" :disabled="isForUpdate">
              <option v-for="unlistedCoin in unlistedCoinArr" :key="unlistedCoin" :value="unlistedCoin">
                {{ unlistedCoin.split('-')[1] + '('+ $t('message.directTran') +')' }}
              </option>
            </select>
          </div>
        </div>
        <div class="py-2 clearfix">
          <span>{{ $t('message.tradeLimit2') }}</span>
          <div class="form-group row mx-0 py-2 mb-0">
            <input type="text" class="form-control w-100" :placeholder="$t('message.enterTradeLimit')" v-model="tradeLimit"/>
          </div>
        </div>
        <hr>
        <div class="py-2 clearfix">
          <span>{{ $t('message.tradePrice') }}</span>
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" @change="changeExchange($event)" v-model="selectedExchange">
              <option v-for="exchange in exchangeList" :key="exchange" :value="exchange">
                {{ exchange === 'NONE' ? $t('message.negoPrice') : exchange }}
              </option>
            </select>
          </div>
          <div class="price_4" v-show="selectedExchange !== 'NONE'">
            <div class="price_4_wrap">
              <label>{{ $t('message.exchanges')}}</label>
              <p>{{ $t('message.markUp')}}(%)</p>
              <div class="amount">
                <div class="input-group">
                  <input class="form-control" type="number" placeholder="0.000" v-model="markUp">

                  <div class="btnwrap">
                    <div class="btn_minus" @click="markUp -= 0.001"></div>
                    <div class="btn_plus" @click="markUp += 0.001"></div>
                  </div>
                </div>
              </div>
              <span class="t_left">* {{ $t('message.markUpDesc')}}</span>
            </div>
          </div>
        </div>
        <hr>
        <div class="py-2 clearfix">
          <span>{{ $t('message.title') }}</span>
          <div class="form-group row mx-0 py-2 mb-0">
            <input type="text" class="form-control w-100" :placeholder="$t('message.enterTit')" v-model="title"/>
          </div>
        </div>
        <hr>
        <div class="py-2 clearfix">
          <span>{{ $t('message.content') }}</span>
          <div class="form-group row mx-0 py-2 mb-0">
            <textarea
                class="form-control w-100"
                cols="30"
                rows="10"
                placeholder="내용을 입력하세요.
개인정보에 해당하는 전화번호 또는 계좌번호 등을 노출하지 마시길 바랍니다."
                v-model="content">
              </textarea>
          </div>
        </div>
        <hr>
        <div class="py-2 clearfix checks otc_offer_ckh">
          <input type="checkbox" id="chkAgree" v-model="isTermsChecked">
          <label for="chkAgree" v-html="$t('message.agreeChk')"></label>
        </div>
        <div class="d-flex col-12 justify-content-between px-0 pb-2">
          <button class="w-48 btn btn-md btn-basic" @click="confirmOfferSuggestion">{{ $t('message.confirm') }}</button>
          <button class="w-48 btn btn-md" @click="$router.back()">{{ $t('message.cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/cmp/modal/Modal'

export default {
  name: "OtcWrite",

  components: {
    'Modal': Modal,
  },

  data() {
    return {
      isTermsChecked: false,
      selectedCoin: '',
      listedCoinArr: [],
      unlistedCoinArr: [],
      exchangeList: [],
      selectedExchange: '',
      price: 'NONE',
      markUp: 0.000,
      confirmChk: false,
      title: '',
      content: '',
      isBuy: 'false',
      tradeMethod: '0',
      tradeLimit: '',
      post: {
        idx: 0,
        state: '',
        boardIsBuy: true,
        coin: '',
        title: '',
        writerUserIdx: 0,
        createdAt: 0,
        nickname: '',
        timeGap: '',
        tradeMethod: 0,
      },
      isForUpdate: false,
    }
  },

  created() {
    this.$parent.setHeaderTitle('OTC 마켓', false, true)
    if(this.$route.params.post) {
      this.post = this.$route.params.post
      this.isForUpdate = true

      this.isBuy = this.post.boardIsBuy.toString()
      this.tradeMethod = this.post.tradeMethod.toString()
      this.selectedCoin = this.post.coin
      this.markUp = this.post.markUp
      this.title = this.post.title
      this.content = this.post.content
      this.tradeLimit = this.post.tradeAvailable
    }

    this.getCoinList()

    this.$get(this.$OTC_LIST_EXCHANGES, 'otcOfferGetExchangeListSuggestion', true, (result) => {
      this.exchangeList = result.data

      if(this.$route.params.post) {
        this.selectedExchange = this.post.exchanges
      } else {
        this.selectedExchange = this.exchangeList[0]
      }
      this.changeExchange()
    }, (result) => {
      console.log(result)
    })
  },

  methods: {
    getCoinList() {
      let isEscrow = true
      this.tradeMethod === '0' ? isEscrow = true : isEscrow = false

      this.$get(`${this.$OTC_TEMP_TICKERS}?isEscrow=${isEscrow}`, 'otcWriteGetTickers', true, (result) => {
        if(isEscrow) {
          this.listedCoinArr = result.data
          if(!this.isForUpdate) {
            this.selectedCoin = this.listedCoinArr[0]
          }
        } else {
          this.unlistedCoinArr = result.data
          if(!this.isForUpdate) {
            this.selectedCoin = this.unlistedCoinArr[0]
          }
        }
      }, (result) => {
        console.log(result)
      })
    },

    changeExchange() {
      if (this.selectedExchange === 'NONE') {
        this.price = 'NONE'
      } else {
        this.price = this.selectedExchange + this.markUp.toString()
      }
    },

    confirmOfferSuggestion() {
      if(!this.title || !this.content) {
        this.notify('error', this.$t('message.warningEmptyBlank'))
        return
      }

      if(!this.isTermsChecked) {
        this.notify('error', this.$t('message.chkTerms'))
        return
      }

      this.confirmChk = false
      this.$refs.Modal.showDialog(this.$t('message.otcSuggestion'), null, this.$t('message.cancel'), ()=>{}, ()=>{})
    },

    toggleChk() {
      if(this.confirmChk) {
        this.$refs.Modal.showDialog(this.$t('message.otcSuggestion'), `${this.isForUpdate ? this.$t('message.modify') : this.$t('message.registration') }`, this.$t('message.cancel'), () => {
          let formData = new FormData()

          formData.append('isBuy', this.isBuy)
          formData.append('coin', this.selectedCoin)
          formData.append('exchanges', this.selectedExchange)
          this.selectedExchange !== 'NONE' ? formData.append('markUp', this.markUp) : ''
          formData.append('tradeMethod', this.tradeMethod)
          formData.append('title', this.title)
          formData.append('content', this.content)
          formData.append('tradeLimit', this.tradeLimit)

          if(!this.isForUpdate) {
            this.$post(this.$OTC_TEMP_ADD, 'otcOfferSuggestionAdd', formData, true, (result) => {
              console.log(result)
              this.notify('success', this.$t('message.postReg'))
              this.$router.push({ name: 'OtcSuggestionMarketList' })
            }, (result) => {
              console.log(result)
            })
          } else {
            formData.append('idx', this.post.idx)

            this.$post(this.$OTC_TEMP_UPDATE, 'otcOfferSuggestionUpdate', formData, true, (result) => {
              console.log(result)
              this.notify('success', this.$t('message.postMod'))
              this.$router.push({ name: 'OtcSuggestionMarketList' })
            }, (result) => {
              console.log(result)
            })
          }
        }, ()=>{})
      } else {
        this.$refs.Modal.showDialog(this.$t('message.otcSuggestion'), null, this.$t('message.cancel'), ()=>{}, ()=>{})
      }
    }
  },

  watch: {
    'markUp': {
      handler() {
        this.markUp = Math.round(this.markUp * 1000) / 1000
      }
    },
  }
}
</script>

<style scoped>

.btnwrap {
  display: inline-flex;
  margin-left: 10px;
}
.btn_minus {
  position: relative;
  width: 35px;
  height: 35px;
  border: 1px solid #1E6AD0;
  cursor: pointer;
}
.btn_minus::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 2px;
  background: #0055D0;
}
.btn_plus {
  position: relative;
  width: 35px;
  height: 35px;
  border: 1px solid #D90000;
  margin-left: 10px;
  cursor: pointer;
}
.btn_plus::before,
.btn_plus::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 2px;
  background: #D90000;
}
.btn_plus::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
</style>
