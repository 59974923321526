import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoggedIn: false,
        requestedTime: 0,
        isSubscribed: true,
    },

    getters: {
        getLoginState(state) {
            state.isLoggedIn = localStorage.getItem('accessToken') !== '' && localStorage.getItem('accessToken') !== null
            return state.isLoggedIn
        },

        getRequestedTime(state) {
            return state.requestedTime
        },

        getSubscribeState(state) {
            return state.isSubscribed
        }
    },
})
