import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Dashboard from "../components/Dashboard"
import Intro from "../components/Intro"
import GeneralExchange from "../components/General_Exchange"
import HistoryGeneral from "../components/history/general/General"
import HistoryOtcBuyOffers from "../components/history/otc/Buy_Offers"
import HistoryOtcSellOffers from "../components/history/otc/Sell_Offers"
import HistoryOtcBuyState from "../components/history/otc/Buy_State"
import HistoryOtcSellState from "../components/history/otc/Sell_State"
import OfferDetailList from "../components/history/otc/Offer_Detail_List"
import MyInfo from "../components/my_info/My_Info"
import Account from "../components/my_info/my_page/account/Account"
import ChangePwd from "../components/my_info/my_page/account/Change_Pwd"
import Level from "../components/my_info/my_page/level/Level"
import AuthPin from "../components/my_info/my_page/level/Auth_Pin"
import AuthId from "../components/my_info/my_page/level/Auth_Id"
import Security from "../components/my_info/my_page/security/Security"
import SecurityInfo from "../components/my_info/my_page/security/Security_Info"
import LoginInfo from "../components/my_info/my_page/Login_Info"
import Inquiry from "../components/my_info/my_page/inquiry/Inquiry"
import InquiryRegist from "../components/my_info/my_page/inquiry/Inquiry_Regist"
import InquiryDetail from "../components/my_info/my_page/inquiry/Inquiry_Detail"
import Advanced from "../components/my_info/my_page/Advanced"
import OfferList from "../components/otc_exchange/Offer_List"
import OfferRegister from "../components/otc_exchange/Offer_Register"
import OfferApply from "../components/otc_exchange/Offer_Apply"
import OfferProcess from "../components/otc_exchange/Offer_Process"
import CoinList from "../components/wallet/Coin_List"
import CoinDetail from "../components/wallet/Coin_Detail"
import Deposit from "../components/wallet/trade/Deposit"
import Withdrawal from "../components/wallet/trade/Withdrawal"
import WithdrawalAddressManage from "../components/wallet/trade/Address_Manage"
import Login from "../components/login/Login"
import OtpLogin from "../components/login/Otp_Login"
import PhoneLogin from "../components/login/Phone_Login"
import PinLogin from "../components/login/Pin_Login"
import Signup from "../components/Signup"
import NewPwd from "../components/find_pwd/New_Pwd"

// image
Vue.prototype.$IMAGE = "/image"

//otc 제안
import OtcSuggestionMarketList from "../components/otc_suggestion/otc_suggestion_market/otc_suggestion_market_list"
import OtcSuggestionHistoryList from "../components/otc_suggestion/otc_suggestion_history/otc_suggestion_history_list"
import OtcSuggestionMypostList from "../components/otc_suggestion/otc_suggestion_mypost/otc_suggestion_mypost_list"
import OtcSuggestionChatList from "../components/otc_suggestion/otc_suggestion_chat/otc_suggestion_chat_list"
import OtcSuggestionMarketDetail from "../components/otc_suggestion/otc_suggestion_market/otc_suggestion_market_detail"
import OtcSuggestionChatDetail from "../components/otc_suggestion/otc_suggestion_chat/otc_suggestion_chat_detail"
import OtcSuggestionWrite from "../components/otc_suggestion/otc_suggestion_write"
import OtcSuggestionClaim from "../components/otc_suggestion/otc_suggestion_claim"
import OtcSuggestionHistoryDetail from "../components/otc_suggestion/otc_suggestion_history/otc_suggestion_history_detail"

//고객지원
import CustomerSupportCenter from "../components/customer_support/center/main"
import CustomerSupportList from "../components/customer_support/center/list"
import CustomerSupportView from "../components/customer_support/center/view"
import CustomerSupportCoinView from "../components/customer_support/center/coin_notice/view"
import CustomerSupportCoinRequest from "../components/customer_support/coin_request"
import CustomerSupportFee from "../components/customer_support/fee"
import CustomerSupportUseInfo from "../components/customer_support/use_info"
import CustomerSupportFAQList from "../components/customer_support/center/faq/list"
import CustomerSupportFAQView from "../components/customer_support/center/faq/view"
import CustomerSupportAuthList from "../components/customer_support/center/auth/list"
import CustomerSupportAuthView from "../components/customer_support/center/auth/view"
import CustomerSupportTradeList from "../components/customer_support/center/trade/list"
import CustomerSupportTradeView from "../components/customer_support/center/trade/view"
import CustomerSupportTransferList from "../components/customer_support/center/transfer/list"
import CustomerSupportTransferView from "../components/customer_support/center/transfer/view"

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Intro',
            component: Intro
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
            path: '/exchange',
            name: 'GeneralExchange',
            component: GeneralExchange
        },
        {
            path: '/history/general',
            name: 'HistoryGeneral',
            component: HistoryGeneral
        },
        {
            path: '/history/otc/buy/offers',
            name: 'HistoryOtcBuyOffers',
            component: HistoryOtcBuyOffers
        },
        {
            path: '/history/otc/sell/offers',
            name: 'HistoryOtcSellOffers',
            component: HistoryOtcSellOffers
        },
        {
            path: '/history/otc/buy/state',
            name: 'HistoryOtcBuyState',
            component: HistoryOtcBuyState
        },
        {
            path: '/history/otc/sell/state',
            name: 'HistoryOtcSellState',
            component: HistoryOtcSellState
        },
        {
            path: '/history/otc/offer/detail',
            name: 'OfferDetailList',
            component: OfferDetailList
        },
        {
            path: '/mypage',
            name: 'MyInfo',
            component: MyInfo
        },
        {
            path: '/mypage/account',
            name: 'Account',
            component: Account
        },
        {
            path: '/mypage/account/changepwd',
            name: 'ChangePwd',
            component: ChangePwd
        },
        {
            path: '/mypage/level',
            name: 'Level',
            component: Level
        },
        {
            path: '/mypage/level/pin',
            name: 'AuthPin',
            component: AuthPin
        },
        {
            path: '/mypage/level/id',
            name: 'AuthId',
            component: AuthId
        },
        {
            path: '/mypage/security',
            name: 'Security',
            component: Security
        },
        {
            path: '/mypage/security/info',
            name: 'SecurityInfo',
            component: SecurityInfo
        },
        {
            path: '/mypage/login',
            name: 'LoginInfo',
            component: LoginInfo
        },
        {
            path: '/mypage/inquiry',
            name: 'Inquiry',
            component: Inquiry
        },
        {
            path: '/mypage/inquiry/register',
            name: 'InquiryRegist',
            component: InquiryRegist
        },
        {
            path: '/mypage/inquiry/detail',
            name: 'InquiryDetail',
            component: InquiryDetail
        },
        {
            path: '/mypage/advanced',
            name: 'Advanced',
            component: Advanced
        },
        {
            path: '/otc/list',
            name: 'OfferList',
            component: OfferList
        },
        {
            path: '/otc/register',
            name: 'OfferRegister',
            component: OfferRegister,
            meta: { keepAlive: true }
        },
        {
            path: '/otc/apply',
            name: 'OfferApply',
            component: OfferApply
        },
        {
            path: '/otc/process',
            name: 'OfferProcess',
            component: OfferProcess
        },
        {
            path: '/wallet/list',
            name: 'CoinList',
            component: CoinList
        },
        {
            path: '/wallet/detail',
            name: 'CoinDetail',
            component: CoinDetail
        },
        {
            path: '/wallet/trade/deposit',
            name: 'Deposit',
            component: Deposit
        },
        {
            path: '/wallet/trade/withdrawal',
            name: 'Withdrawal',
            component: Withdrawal,
            props: true,
            meta: { keepAlive: true }
        },
        {
            path: '/wallet/trade/addressmanage',
            name: 'WithdrawalAddressManage',
            component: WithdrawalAddressManage
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/login/otp',
            name: 'OtpLogin',
            component: OtpLogin
        },
        {
            path: '/login/phone',
            name: 'PhoneLogin',
            component: PhoneLogin
        },
        {
            path: '/login/pin',
            name: 'PinLogin',
            component: PinLogin
        },
        {
            path: '/signup',
            name: 'Signup',
            component: Signup
        },
        {
            path: '/findpwd/newpwd',
            name: 'NewPwd',
            component: NewPwd
        },

        //      otc 제안
        {
            path: '/otc/suggestion/markets',
            name: 'OtcSuggestionMarketList',
            component: OtcSuggestionMarketList
        },
        {
            path: '/otc/suggestion/historys',
            name: 'OtcSuggestionHistoryList',
            component: OtcSuggestionHistoryList
        },
        {
            path: '/otc/suggestion/history/:tradeIdx',
            name: 'OtcSuggestionHistoryDetail',
            component: OtcSuggestionHistoryDetail
        },
        {
            path: '/otc/suggestion/myposts',
            name: 'OtcSuggestionMypostList',
            component: OtcSuggestionMypostList
        },
        {
            path: '/otc/suggestion/chats',
            name: 'OtcSuggestionChatList',
            component: OtcSuggestionChatList
        },
        {
            path: '/otc/suggestion/market/:postIdx',
            name: 'OtcSuggestionMarketDetail',
            component: OtcSuggestionMarketDetail
        },
        {
            path: '/otc/suggestion/chat/:chatroomIdx',
            name: 'OtcSuggestionChatDetail',
            component: OtcSuggestionChatDetail
        },
        {
            path: '/otc/suggestion/write',
            name: 'OtcSuggestionWrite',
            component: OtcSuggestionWrite
        },
        {
            path: '/otc/suggestion/claim',
            name: 'OtcSuggestionClaim',
            component: OtcSuggestionClaim
        },

        {
            path: '/customer/center/main',
            name: 'CustomerSupportCenter',
            component: CustomerSupportCenter
        },
        {
            path: '/customer/center/list',
            name: 'CustomerSupportList',
            component: CustomerSupportList
        },
        {
            path: '/customer/center/view',
            name: 'CustomerSupportView',
            component: CustomerSupportView,
        },
        {
            path: '/customer/center/coin/view',
            name: 'CustomerSupportCoinView',
            component: CustomerSupportCoinView,
        },
        {
            path: '/customer/center/faq/list',
            name: 'CustomerSupportFAQList',
            component: CustomerSupportFAQList,
        },
        {
            path: '/customer/center/faq/view',
            name: 'CustomerSupportFAQView',
            component: CustomerSupportFAQView,
        },
        {
            path: '/customer/center/auth/list',
            name: 'CustomerSupportAuthList',
            component: CustomerSupportAuthList,
        },
        {
            path: '/customer/center/auth/view',
            name: 'CustomerSupportAuthView',
            component: CustomerSupportAuthView,
        },
        {
            path: '/customer/center/trade/list',
            name: 'CustomerSupportTradeList',
            component: CustomerSupportTradeList,
        },
        {
            path: '/customer/center/trade/view',
            name: 'CustomerSupportTradeView',
            component: CustomerSupportTradeView,
        },
        {
            path: '/customer/center/transfer/list',
            name: 'CustomerSupportTransferList',
            component: CustomerSupportTransferList,
        },
        {
            path: '/customer/center/transfer/view',
            name: 'CustomerSupportTransferView',
            component: CustomerSupportTransferView,
        },
        {
            path: '/customer/coinrequest',
            name: 'CustomerSupportCoinRequest',
            component: CustomerSupportCoinRequest
        },
        {
            path: '/customer/fee',
            name: 'CustomerSupportFee',
            component: CustomerSupportFee
        },
        {
            path: '/customer/use',
            name: 'CustomerSupportUseInfo',
            component: CustomerSupportUseInfo
        },
  ],
    scrollBehavior() {
        document.getElementById('app').scrollIntoView()
    }
})
