<template>
  <div class="cv-container-sub">
    <table class="table table-sm mb-0 border-bottom text-center">
      <colgroup>
        <col width="33%">
        <col width="33%">
        <col width="auto">
<!--        <col width="auto">-->
      </colgroup>
      <thead class="thead-light">
      <tr>
        <th>{{ $t('message.date') }}</th>
        <th>{{ $t('message.action') }}</th>
        <th>{{ $t('message.login') }} IP</th>
<!--        <th>{{ $t('message.moreInfo') }}</th>-->
      </tr>
      </thead>
      <tbody>
        <tr v-for="(activity, idx) in activityList" :key="idx">
          <td>{{ activity.date }}<br /></td>
          <td>{{ activity.browser }}<br /></td>
          <td>{{ activity.address }}<br /></td>
<!--          <td>-->
<!--            <a href="https://xn&#45;&#45;c79as89aj0e29b77z.xn&#45;&#45;3e0b707e/kor/whois/whois.jsp" target="_blank" style="color: #1E6AD0">-->
<!--              <button class="btn btn-sm btn-outline-primary">-->
<!--                WHOIS {{ $t('message.lookup') }}-->
<!--              </button>-->
<!--            </a>-->
<!--          </td>-->
        </tr>
      </tbody>
    </table>
    <div class="buttonwrap text-center" v-show="activityList.length !== 0">
      <paginate
          :page-count="pageLast"
          :page-range="2"
          :margin-pages="1"
          :click-handler="getActList"
          :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :first-last-button=true
          :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :container-class="'pagination'">
      </paginate>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login_Info",

  data() {
    return {
      activityList: [],
      pageLast: 0,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.recentActivity'), false, true)
  },

  mounted() {
    this.$parent.showFooter(false)

    this.getActList(1)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    getActList(page) {
      let limit = 10
      this.$get(`${this.$USER_MYPAGE_LOGIN_LOG}?page=${page}&limit=${limit}`, 'myPageRecentActivity', true, (result) => {
        this.activityList = result.data

        this.activityList.map(act => {
          act.date = this.formatDate(act.createdAt, 'full')
        })

        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.recentActivity'), false, true)
      }
    },
  }
}
</script>

<style scoped>

</style>
