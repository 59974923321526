<template>
  <div class="cv-container-sub pb-3">
    <div class="px-3 bbs-right">
      <div class="form-horizontal">
        <input type="hidden">
        <div class="cv-div-tbl1 mypage_security_selectBox">
          <div id="use_pin" class="form-group row m-0 right-inner-addon">
            <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.oldPwd') }}</label>
            <div class="col-12 px-0">
              <input type="password" class="form-control" v-model="oldPwd" :placeholder="$t('message.placeholderOldPwd')"/>
            </div>
            <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.newPwd') }}</label>
            <div class="col-12 px-0">
              <input :type="passwordType" class="form-control" v-model="newPwd" :placeholder="$t('message.placeholderNewPwd')"/>
              <a class="pwi" @click="hiddenPassword"><i class="fa fa-eye fa-lg"></i></a>
            </div>
            <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.newPwdConfirm') }}</label>
            <div class="col-12 px-0">
              <input :type="passwordConfirmType" class="form-control" v-model="newPwdConfirm" :placeholder="$t('message.placeholderConfirmPwd')" />
              <a class="pwci" @click="hiddenPasswordConfirm"><i class="fa fa-eye fa-lg"></i></a>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-basic btn-block mb-3" @click="changePwd">{{ $t('message.pwdChange') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePwd",

  data() {
    return {
      oldPwd: '',
      newPwd: '',
      newPwdConfirm: '',
      passwordType: 'password',
      passwordConfirmType: 'password'
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.pwdChange'), false, true)
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    logout() {
      this.$get(this.$USER_LOGIN_LOGOUT, 'Logout', true, (result) => {
        console.log(result.message)
        this.notify('success', this.$t('message.success'))
        this.$store.state.isLoggedIn = false
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        this.$router.push({name:'Dashboard'})
      }, (result) => {
        console.log(result)
      })
    },

    changePwd() {
      if(this.oldPwd === '' || this.newPwd === '' || this.newPwdConfirm === '') {
        this.notify('error', this.$t('message.warningEmptyBlank'))
        return
      }

      let formData = new FormData()
      formData.append('existingPassword', this.oldPwd)
      formData.append('newPassword', this.newPwd)
      formData.append('newPasswordConfirm', this.newPwdConfirm)

      this.$put(this.$USER_MYPAGE_PROFILE_PASSWORD, 'myPageInfoChangeUserPwd', formData, true, (result) => {
        this.notify('success', result.message)
        this.oldPwd = ''
        this.newPwd = ''
        this.newPwdConfirm = ''
        this.logout()
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    hiddenPassword() {
      if ('password' === this.passwordType) {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },

    hiddenPasswordConfirm() {
      if ('password' === this.passwordConfirmType) {
        this.passwordConfirmType = 'text';
      } else {
        this.passwordConfirmType = 'password';
      }
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.pwdChange'), false, true)
      }
    },
  },
}


</script>

<style scoped>
/* 클릭 이벤트로 비밀번호 숨기기 보여주기 */
.right-inner-addon {
  position: relative;
}

.right-inner-addon > input {
  margin-right: 40px;
}


.pwi {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
}

 .pwci {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 1;
}

</style>
