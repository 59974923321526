<template>
  <div class="cv-container-sub pb-3">
    <div class="px-3 bbs-right">
      <div class="cv-div-tbl1 mypage_security_selectBox">
        <div class="form-group row m-0">
          <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.optCode') }}</label>
          <div class="col-12 px-0">
            <input type="number" class="form-control input-lg" :placeholder="$t('message.optCode')" v-model="otpCode" @keyup.enter="login">
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button type="submit" class="btn btn-basic btn-block mb-3 input-lg" @click="login">{{ $t('message.login') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptLogin",
  data() {
    return {
      otpCode: '',
      authMethod: this.$route.params.secondAuthMethod,
    }
  },

  created() {
    if(!this.authMethod) {
      this.$router.back()
    }

    this.$parent.setHeaderTitle(this.$t('message.2ndAuth') + '(OTP)', false, true)
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    login() {
      let formData = new FormData()
      formData.append('otpCode', this.otpCode)
      formData.append('verifyCode', this.$route.params.verifyCode)

      this.$post(this.$USER_LOGIN_OTP, 'loginOTP', formData, false, (result) => {
        localStorage.setItem('accessToken', result.data[0].accessToken)
        localStorage.setItem('refreshToken', result.data[0].refreshToken)

        this.refreshToken()
        this.setFCMToken()

        this.$store.state.isLoggedIn = true
        this.$router.push({ name: 'Dashboard' })
      }, (result) => {
        this.notify('error', result.message)
      })
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.2ndAuth') + '(OTP)', false, true)
      }
    },
  },


}
</script>

<style scoped>

</style>
