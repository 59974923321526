<template>
  <div class="invest_coinsBox invest_trade__box history">
    <div class="position-relative pb-2">
      <div class="mt-5">
        <div class="bg-white">
          <div class="h6 mb-0">
            <div>
              <ul class="nav nav-pills nav-fill justify-content-center invest_navBox">
                <li class="nav-item" @click="$router.push({ name: 'HistoryGeneral' })">
                  <a class="nav-link" :class="{ active: $route.path === '/history/general' }">{{ $t('message.exchange') }}</a>
                </li>
                <li class="nav-item" @click="$router.push({ name: 'HistoryOtcSellOffers' })">
                  <a class="nav-link" :class="{ active: $route.path !== '/history/general' }">{{ $t('message.otc') }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="trade_listTable outer">
            <div class="px-2 pb-3 invest_tradeAllBox clearfix">
              <SelectOtcHistory/>
              <span class="col-12 h5 float-left text-left px-1 pt-2 mb-0">{{ $t('message.sellOrderSum') }}</span>
            </div>
            <div class="inner">
              <table>
                <thead>
                <tr class="fst_inner">
                  <th class="lft_td__color">{{ $t('message.tradingNum') }}</th>
                  <th class="lft_td__color">{{ $t('message.postedBy') }}</th>
                  <th class="lft_td__color">{{ $t('message.fees') }}</th>
<!--                  <th class="lft_td__color">{{ $t('message.totalTradePrice') }}</th>-->
                  <!--                          <th>{{ $t('message.tradeUnitPrice') }}</th>-->
                  <th class="lft_td__color">{{ $t('message.state') }}</th>
                  <th rowspan="2" class="rit_td__color">{{ $t('message.detail') }}</th>
                </tr>
                <tr class="fst_inner">
                  <th class="lft_td__color">{{ $t('message.coin') }}</th>
                  <th class="lft_td__color">{{ $t('message.tradedBy') }}</th>
                  <th class="lft_td__color">{{ $t('message.tradeAmt') }}</th>
                  <th class="lft_td__color">{{ $t('message.tradeTime') }}</th>
                </tr>
                </thead>
                <tbody :class="{ 'border-bottom-bold': tradeList.length - 1 ===  tradeIdx }" v-for="(trade, tradeIdx) in tradeList" :key="trade.tradeIdx">
                  <tr>
                    <td>{{ trade.tradeIdx }}</td>
                    <td :key="rerenderKey">{{ trade.offerNickname }}</td>
                    <td>{{ subDec((trade.amount * (trade.feeRate / 100)), trade.marketPair.split('-')[0]) }} {{ trade.marketPair.split('-')[0] }}<br>
                      ({{ trade.feeRate }} %)</td>
<!--                    <td>{{ subDec((trade.amount * trade.priceToGo), trade.marketPair.split('-')[1]) | numberWithCommas }} {{ trade.marketPair.split('-')[1] }}</td>-->
                    <!--                          <td>{{ trade.priceToGo | numberWithCommas }} {{ trade.marketPair.split('-')[1] }}</td>-->
                    <td>{{ getOtcState(trade.otcTradeState) }}</td>
                    <td rowspan="2" class="outline_btn2" style="border-bottom: 1px solid #ccc;">
                      <span class="blue_f fs10 p-1" @click="goDetail(trade.tradeIdx)">{{ $t('message.viewDetail') }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-top-light">{{ trade.marketPair.split('-')[0] }}</td>
                    <td class="border-top-light">{{ trade.myNickname }}</td>
                    <td class="border-top-light">
                      {{ trade.amount }} {{ trade.marketPair.split('-')[0] }}
                    </td>
                    <td class="border-top-light">{{ trade.createdAt }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr v-show="!tradeList.length">
                    <td colspan="5" style="text-align: center;">{{ $t('message.noTradeHistory') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectOtcHistory from './Menu'

export default {
  name: "SellState",

  components: {
    'SelectOtcHistory': SelectOtcHistory
  },

  data() {
    return {
      tradeList: [],
      rerenderKey: 0,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.myTradingHistory'), false, false)
  },

  mounted() {
    this.$parent.focusMenu(1)
    localStorage.setItem('focusMenu', 1)

    this.getSellList(1)
  },

  methods: {
    getSellList(page) {
      let formData = new FormData()
      let limit = 10

      // formData.append('isBuy', false)
      formData.append('limit', limit)
      if(typeof page !== 'number') {
        page = 1
      }
      formData.append('page', page)

      this.$post(this.$OTC_TRADE_LOG, 'HistoryOtcSellStateGetTradeList', formData,true, (result) => {
        this.tradeList = result.data

        this.tradeList.map((trade) => {
          let date = new Date(trade.createdAt)
          let userTimezoneOffset = date.getTimezoneOffset() * 60000
          let timestamp = date.getTime() - userTimezoneOffset
          trade.createdAt = this.formatDate(timestamp, 'full')

          this.getNickname((result) => {
            if(result.success) {
              trade.myNickname = result.nickname
              this.rerenderKey += 1
            } else {
              console.log(result.nickname)
            }
          }, trade.userIdx)

          this.getNickname((result) => {
            if(result.success) {
              trade.offerNickname = result.nickname
              this.rerenderKey += 1
            } else {
              console.log(result.nickname)
            }
          }, trade.userIdxOffer)
        })
      }, (result) => {
        console.log(result)
      })
    },

    goDetail(tradeIdx) {
      this.$router.push({ name: 'OfferProcess', params: { tradeIdx: tradeIdx }})
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.myTradingHistory'), false, false)
      }
    },
  },
}
</script>

<style scoped>

</style>
