<template>
  <div class="cv-container-sub cv-level-wrap pb-3">
    <div class="px-3">
      <p class="mt-2">{{ noticeDetail.title }}</p>
      <table class="table_m">
        <tr>
          <th class="t_left">{{ $t('message.createdTime') }}</th>
          <td><input type="text" v-model="noticeDetail.date" disabled></td>
        </tr>
      </table>
      <div class="view_content">
        <p v-html="escapeTag(noticeDetail.contents)"></p>
        <img v-show="noticeDetail.file" :src="noticeDetail.file" :alt="$t('message.file')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "centerView",
  data() {
    return {
      noticeIdx: this.$route.params.articleIdx,
      noticeDetail: {},
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.announcement'), false, true)

    if(this.noticeIdx) {
      sessionStorage.setItem('noticeIdx', this.noticeIdx)
    } else {
      this.noticeIdx = sessionStorage.getItem('noticeIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('noticeIdx')
  },

  mounted() {
    this.$parent.showFooter(false)

    this.$get(`${this.$BOARD_DETAIL}/${this.noticeIdx}`, this.$options.name + '_mountedDetail', false, (result) => {
      this.noticeDetail = result.data[0]
      this.noticeDetail.date = this.formatDate(this.noticeDetail.createdAt, 'full')
    }, (result) => {
      this.notify('error', result.message)
    })
  },
}
</script>

<style scoped>

</style>
