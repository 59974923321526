<template>
  <div class="invest_coinsBox invest_trade__box">
    <Modal ref="Modal">
      <div v-show="showAddDesc">
        <span v-html="$t('message.extraNoteOtcOffer')"></span>
        <div class="descbox">
          <em>{{ $t('message.bankAcc') }} : xxxx xxxx xxxx xxxx<br>
            {{ $t('message.aliPay') }} : xxxxxxx<br>
            {{ $t('message.wechatPay') }} : xxxxx</em>
        </div>
      </div>
      <div v-show="showConfirmOffer">
        <div>
          <dl class="wallet_form2">
            <dt class="wallet_form_tit">{{ $t('message.coin') }}</dt>
            <dd class="wallet_form_text t_left">{{ selectedCoin }}</dd>
          </dl>
<!--          <dl class="wallet_form2">-->
<!--            <dt class="wallet_form_tit">{{ $t('message.tradeType') }}</dt>-->
<!--            <dd class="wallet_form_text t_left">{{ tradeType === 'splitTrade' ? $t('message.split') : $t('message.full') }}</dd>-->
<!--          </dl>-->
          <dl class="wallet_form2">
            <dt class="wallet_form_tit">{{ $t('message.quantity') }}</dt>
            <dd class="wallet_form_text t_left">{{ amount | numberWithCommas }} {{ selectedCoin }}</dd>
          </dl>
          <dl class="wallet_form2" v-show="offerType === 'sell'">
            <dt class="wallet_form_tit">{{ $t('message.fees') }}({{ fee }}%)</dt>
            <dd class="wallet_form_text t_left">{{ Number(amount) * fee }}{{ selectedCoin }}</dd>
          </dl>
          <dl class="wallet_form2" v-show="offerType === 'sell'">
            <dt class="wallet_form_tit">{{ $t('message.ActualTransaction') }}</dt>
            <dd class="wallet_form_text t_left">{{ Number(amount) + (Number(amount) * fee) | numberWithCommas }}{{ selectedCoin }}</dd>
          </dl>
<!--          <dl class="wallet_form2" v-show="tradeType === 'splitTrade'">-->
<!--            <dt class="wallet_form_tit">{{ $t('message.minAmount') }}</dt>-->
<!--            <dd class="wallet_form_text t_left">{{ minAmount | numberWithCommas }} {{ selectedCoin }}</dd>-->
<!--          </dl>-->
          <dl class="wallet_form2">
            <dt class="wallet_form_tit">{{ $t('message.unitPrice') }}</dt>
<!--            <dd class="wallet_form_text t_left">{{ subDec(Number(price), selectedCurrency) | numberWithCommas }} {{ selectedCurrency }}</dd>-->
            <dd class="wallet_form_text t_left">{{ selectedExchange === 'NONE' ? '협상가' : selectedExchange }} {{ selectedExchange === 'NONE' ? '' : (markUp >= 0 ? '기준 +' : '기준 ') + markUp + '%' }}</dd>
          </dl>
<!--          <dl class="wallet_form2">-->
<!--            <dt class="wallet_form_tit">{{ $t('message.totalTradeAmount') }}</dt>-->
<!--            <dd class="wallet_form_text t_left">{{ subDec((price * Number(amount)), selectedCurrency) | numberWithCommas }} {{ selectedCurrency }}</dd>-->
<!--          </dl>-->
          <dl class="wallet_form2">
            <dt class="wallet_form_tit">{{ $t('message.myBalance') }}</dt>
            <dd class="wallet_form_text t_left">{{ myBalance | numberWithCommas }} {{ selectedCoin }}</dd>
          </dl>
          <dl class="wallet_form2" v-show="offerType === 'sell'">
            <dt class="wallet_form_tit">{{ $t('message.balanceAfterPosting') }}</dt>
            <dd class="wallet_form_text t_left">{{ myBalance | numberWithCommas }}{{ selectedCoin }} – {{ Number(amount) + (Number(amount) * fee) | numberWithCommas }} {{ selectedCoin }} =
              <em class="dblue_02">{{ myBalance - (Number(amount) + (Number(amount) * fee)) | numberWithCommas }} {{ selectedCoin }}</em>
            </dd>
          </dl>
        </div>
        <div class="checks otc_offer_ckh">
          <input type="checkbox" id="check_03" v-model="confirmChk" @change="toggleChk" />
          <label for="check_03">{{ $t('message.confirmChk') }}</label>
        </div>
      </div>
    </Modal>
    <div class="mt-5">
      <div class="trade_listTable outer">
        <Search
            @changeOfferType="changeOfferType"
            @getCurrencyArr="getCurrencyArr"
            @getCoinArr="getCoinArr"
        />

        <!-------------- 매도 오퍼 등록 ---------------->
        <div class="px-2 pt-2 clearfix otc_offer">
          <span class="col-12 h5 float-left text-left px-0" v-if="offerType === 'buy'">{{ $t('message.postBuyOffer')}}</span>
          <span class="col-12 h5 float-left text-left px-0" v-else>{{ $t('message.postSellOffer')}}</span>
          <hr style="display: block; margin-top: 35px;">
          <div class="form-group row col-12 m-0 py-2 px-0">
            <label class="col-form-label col-12 text-left px-0">{{ $t('message.coin') }}</label>
            <div class="col d-flex px-0">
              <select class="col-5 border form-control" v-model="selectedCoin" @change="changeCoin">
                <option v-for="coin in coinArr" :key="coin" :value="coin">{{ coin }}</option>
              </select>

<!--              <div class="i_change col-2 px-0">-->
<!--                <img src="/img/icon_reverse.svg" alt="change">-->
<!--              </div>-->
<!--              <select class="col-5 border form-control" v-model="selectedCurrency">-->
<!--                <option v-for="currency in currencyArr" :value="currency" :key="currency">{{ currency }}</option>-->
<!--              </select>-->

            </div>
          </div>
          <hr>
<!--          <label class="d-flex py-2">{{ $t('message.tradeType')}}</label>-->
<!--          <div class="d-flex align-items-center py-2">-->
<!--            <label class="label_checkradio position-relative">-->
<!--              <input type="radio" value="splitTrade" v-model="tradeType">-->
<!--              <span class="radio_icon"></span>-->
<!--              <span class="radio_text">{{ $t('message.split')}}</span>-->
<!--            </label>-->
<!--            <label class="label_checkradio position-relative pl-2">-->
<!--              <input type="radio" value="fullTrade" v-model="tradeType">-->
<!--              <span class="radio_icon"></span>-->
<!--              <span class="radio_text">{{ $t('message.full')}}</span>-->
<!--            </label>-->
<!--          </div>-->
<!--          <hr>-->
          <div class="form-group row mx-0 mb-2">
            <label class="col-form-label col-12 mt-2 px-0 text-left">{{ $t('message.quantity')}}</label>
            <div class="col-12 px-0">
              <div class="input-group user_input">
                <input type="text" class="form-control text-right" v-model="amount">
                <span>{{ selectedCoin }}</span>
              </div>
            </div>
          </div>
          <hr>
<!--          <div class="form-group row mx-0 mb-2" v-show="tradeType === 'splitTrade'">-->
<!--            <label class="col-form-label col-12 mt-2 px-0 text-left">{{ $t('message.minAmount')}}</label>-->
<!--            <div class="col-12 px-0">-->
<!--              <div class="input-group user_input">-->
<!--                <input type="text" class="form-control text-right" v-model="minAmount">-->
<!--                <span>{{ selectedCoin }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <hr v-show="tradeType === 'splitTrade'">
<!--          <label class="d-flex py-2">{{ $t('message.tradePrice')}}</label>-->
<!--          <div class="d-flex align-items-center py-2">-->
<!--            <label class="label_checkradio position-relative">-->
<!--              <input type="radio" value="floatingPrice" v-model="priceType" @change="changePriceType">-->
<!--              <span class="radio_icon"></span>-->
<!--              <span class="radio_text">{{ $t('message.dynamicPrice')}}</span>-->
<!--            </label>-->
<!--            <label class="label_checkradio position-relative pl-2">-->
<!--              <input type="radio" value="fixedPrice" v-model="priceType" @change="changePriceType">-->
<!--              <span class="radio_icon"></span>-->
<!--              <span class="radio_text">{{ $t('message.staticPrice')}}</span>-->
<!--            </label>-->
<!--          </div>-->
          <div class="form-group row col-12 m-0 py-2 px-0">
            <label class="col-form-label col-12 text-left px-0">{{ $t('message.exchanges')}}</label>
            <select class="w-100 border form-control" @change="changeExchange($event)" v-model="selectedExchange">
              <option v-for="exchange in exchangeList" :key="exchange" :value="exchange">{{ exchange === 'NONE' ? '협상가' : exchange }}</option>
            </select>
          </div>
<!--          <div class="form-group row mx-0 mb-2">-->
<!--            <label class="col-form-label text-left col-12 mt-2 px-0">{{ $t('message.price')}}</label>-->
<!--            <div class="col-12 px-0">-->
<!--              <div class="inputwrap2 red_price grey_back">-->
<!--                <input type="number" class="price_red" v-model="price" :disabled="priceType === 'floatingPrice'">-->
<!--                <span>{{ selectedCurrency }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <label class="col-form-label text-left col-12 mt-2 px-0">{{ $t('message.minMarketPrice')}}</label>-->
<!--            <div class="col-12 px-0">-->
<!--              <div class="inputwrap2 grey_back">-->
<!--                <input type="text" disabled="disabled">-->
<!--                <span>{{ selectedCurrency }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="form-group row mx-0 mb-2" v-show="priceType === 'floatingPrice'">-->
          <div class="form-group row mx-0 mb-2" v-show="selectedExchange !== 'NONE'">
            <label class="col-form-label col-12 pt-0 px-0 text-left">{{ $t('message.markUp')}}(%)</label>
            <div class="col-12 px-0">
              <div class="input-group">
                <input type="text" class="form-control text-right" placeholder="0.000" v-model="markUp" autocomplete="off">
                <button class="btn ipt_comBtn btn-sm bg-white rounded-0 border-left-0" type="button" @click="markUp -= 0.001">-</button>
                <button class="btn ipt_comBtn ipt_pls_btn btn-sm bg-white ipt_rnd_con border-left-0" type="button" @click="markUp += 0.001">+</button>
              </div>
            </div>
            <div class="text-center wallet-history-check">* {{ $t('message.markUpDesc')}}</div>
          </div>
          <hr>
<!--          <label class="col-form-label text-left col-12 mt-2 px-0">{{ $t('message.totalTradePrice')}}</label>-->
<!--          <div class="col-12 px-0">-->
<!--            <div class="inputwrap2 grey_back">-->
<!--              <input type="text" disabled="disabled" v-model="totalPrice">-->
<!--              <span>{{ selectedCurrency }}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <hr>-->
          <div class="col-form-label d-flex align-items-center col-12 pt-0 px-0 text-left" @click="openAddDesc">
            {{ $t('message.extraNote')}}
            <button class="btn p-0 border-0 text-basic btn-link" style="line-height: 0;">
              <i class="xi-help-o" style="font-size: 16px;"></i>
            </button>
          </div>
          <textarea cols="30" rows="10" :placeholder="$t('message.offerMemo')" class="explain" v-model="memo"></textarea>
          <hr>
          <label class="col-form-label text-left col-12 mt-2 px-0">{{ $t('message.myBalance')}}</label>
          <div class="col-12 px-0">
            <div class="inputwrap2 grey_back">
              <input type="text" disabled="disabled" :value="myBalance | numberWithCommas">
              <span>{{ selectedCoin }}</span>
            </div>
          </div>
<!--          <hr>-->
<!--          <label class="col-form-label text-left col-12 mt-2 px-0">{{ $t('message.payMethod')}}</label>-->

<!--          <dd class="checks payment" v-show="payOptions.BANK.state || payOptions.PPS.state || payOptions.ALIPAY.state || payOptions.WECHAT.state">-->
<!--            <div v-show="payOptions.BANK.state">-->
<!--              <input type="checkbox" v-model="bank" id="bank"/>-->
<!--              <label for="bank">{{ $t('message.bankTrans')}}</label>-->
<!--            </div>-->
<!--            <div v-show="payOptions.PPS.state">-->
<!--              <input type="checkbox" v-model="pps" id="pps"/>-->
<!--              <label for="pps">{{ $t('message.ppsBank')}}</label>-->
<!--            </div>-->
<!--            <div v-show="payOptions.ALIPAY.state">-->
<!--              <input type="checkbox" v-model="alipay" id="alipay"/>-->
<!--              <label for="alipay">{{ $t('message.aliPay')}}</label>-->
<!--            </div>-->
<!--            <div v-show="payOptions.WECHAT.state">-->
<!--              <input type="checkbox" v-model="wechat" id="wechat"/>-->
<!--              <label for="wechat">{{ $t('message.wechatPay')}}</label>-->
<!--            </div>-->
<!--          </dd>-->

          <div class="px-0 pt-2 pb-2" v-show="!payOptions.BANK.state && !payOptions.PPS.state && !payOptions.ALIPAY.state && !payOptions.WECHAT.state">
            <p class="text-left pb-2">{{ $t('message.noPayMethodDesc')}}</p>
            <keep-alive>
              <button class="col-12 btn btn-outline-primary btn-md" @click="$router.push({ name: 'Advanced', params: { route: 'fromOtcOffer' }})">{{ $t('message.registration')}}</button>
            </keep-alive>
          </div>

          <hr>
          <div class="p-2">
            <div class="alert offer_notice rounded-0 text-left">
              <p class="pl-2 mb-1">{{ $t('message.offerRegistDesc1')}}</p>
              <p class="pl-2 mt-3" style="word-break: keep-all; word-wrap: break-word;">{{ $t('message.offerRegistDesc2')}}</p>
            </div>
          </div>
          <div class="d-flex col-12 justify-content-between px-0 pb-2">
            <button class="w-48 btn btn-basic btn-md" @click="confirmOffer">{{ $t('message.confirm') }}</button>
            <button class="w-48 btn btn-secondary btn-md" @click="$router.push({ name: 'OfferList' })">{{ $t('message.cancel')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from './Search'
import Modal from '@/components/cmp/modal/Modal'

export default {
  name: "Offer_Register",

  components: {
    'Search': Search,
    'Modal': Modal
  },

  data() {
    return {
      showAddDesc: false,
      showConfirmOffer: false,
      confirmChk: false,
      tradeType: 'splitTrade',
      amount: 0,
      minAmount: '',
      priceType: 'floatingPrice',
      markUp: 0.000,
      exchangeList: [],
      selectedCoin: '',
      selectedCurrency: '',
      selectedExchange: '',
      price: 'NONE',
      memo: '',
      bank: false,
      pps: false,
      alipay: false,
      wechat: false,
      fee: 0,
      myBalance: 0,
      offerType: this.$route.params.offerType,
      currencyArr: [],
      coinArr: [],
      payOptions: {
        WECHAT: {
          state: false
        },
        BANK: {
          state: false
        },
        PPS: {
          state: false
        },
        ALIPAY: {
          state: false
        }
      },
      totalPrice: 0,
    }
  },

  computed: {
    modalTitle() {
      if(this.offerType === 'buy') {
        return this.$t('message.postBuyOffer')
      } else {
        return this.$t('message.postSellOffer')
      }
    }
  },

  activated() {
    this.$parent.setHeaderTitle(this.$t('message.otc'), false, true)
    this.$parent.focusMenu(3)
    localStorage.setItem('focusMenu', 3)

    this.offerType = this.$route.params.offerType

    this.$get(`${this.$USER_MYPAGE_OPTION_PAY}?method=BANK&method=PPS&method=ALIPAY&method=WECHAT`, 'OtcOfferGetOptionPay', true, (result) => {
      if(Object.keys(result.data[0]).length !== 0) {
        this.payOptions = result.data[0]
      }
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    setTimeout(() => {
      this.selectedCoin = this.coinArr[0]
      this.selectedCurrency = this.currencyArr[0]
      this.selectedExchange = this.exchangeList[0]
      this.getMyOtcBalance()
    }, 500)

    this.$get(this.$OTC_LIST_EXCHANGES, 'otcOfferGetExchangeList', true, (result) => {
      this.exchangeList = result.data
      this.exchangeList.map((exchange, idx) => {
        if(exchange === 'THIS') {
          this.exchangeList[idx] = 'UBIT'
        }
      })
    }, (result) => {
      console.log(result)
    })
  },

  methods: {
    changeCoin() {
      this.getMyOtcBalance()
    },

    getCurrencyArr(currencyArr) {
      this.currencyArr = currencyArr
    },

    getCoinArr(coinArr) {
      this.coinArr = coinArr
    },

    changeOfferType(offerType) {
      this.offerType = offerType
    },

    changePriceType() {
      if(this.priceType === 'floatingPrice') {
        this.changeExchange()
      }
    },

    getMyOtcBalance() {
      this.getMyBalance((result) => {
        if(result.success) {
          this.myBalance = result.myBalance.available
        }
      }, this.selectedCoin)
    },

    changeExchange() {
      // if(this.selectedExchange === 'BITFINEX') {
      //   let marketPair = `${this.selectedCoin}usd`
      //   marketPair = marketPair.toLowerCase()
      //
      //   this.$get(`${this.$OTC_DISPLAY_TICKER_BITFINEX}/${marketPair}`, 'otcOfferGetBitfinex', true, (result) => {
      //     let usdPrice = result.data[0].mid
      //
      //     if(this.selectedCurrency === 'KRW') {
      //       this.$get(`${this.$OTC_DISPLAY_USDKRW}`, 'otcOfferGetUSDKRW', true, (result) => {
      //         let rate = result.data[0].rate
      //         this.price = this.subDec(rate * usdPrice, this.selectedCurrency)
      //       }, (result) => {
      //         console.log(result)
      //       })
      //     } else {
      //       // 환율계산하는 api 화폐당 더 나와야함
      //     }
      //   }, (result) => {
      //     this.notify('error', result.message)
      //   })
      // } else if(this.selectedExchange === 'UPBIT') {
      //   let marketPair = `${this.selectedCurrency}-${this.selectedCoin}`
      //   marketPair = marketPair.toUpperCase()
      //
      //   this.$get(`${this.$OTC_DISPLAY_TICKER_UPBIT}/${marketPair}`, 'otcOfferGetUpbit', true, (result) => {
      //     let midPrice = result.data[0].mid
      //
      //     if(midPrice && this.selectedCurrency === 'KRW') {
      //       this.price = this.subDec(midPrice, this.selectedCurrency)
      //     } else {
      //       // 환율계산하는 api 화폐당 더 나와야함
      //     }
      //   }, (result) => {
      //     this.notify('error', result.message)
      //   })
      // } else if(this.selectedExchange === 'BINANCE') {
      //   let marketPair = `${this.selectedCoin}${this.selectedCurrency}`
      //
      //   this.$get(`${this.$OTC_DISPLAY_TICKER_BINANCE}/${marketPair}`, 'otcOfferGetBinance', true, (result) => {
      //     let midPrice = result.data[0].price
      //     console.log(midPrice)
      //   }, (result) => {
      //     this.notify('error', result.message)
      //   })
      // } else {
      //   this.price = 0
      // }

      if (this.selectedExchange === 'NONE') {
        this.price = 'NONE'
      } else {
        this.price = this.selectedExchange + this.markUp.toString()
      }
    },

    openAddDesc() {
      this.showAddDesc = true
      this.showConfirmOffer = false
      this.$refs.Modal.showDialog(this.$t('message.extraNote'), null, this.$t('message.close'), ()=>{}, ()=>{})
    },

    confirmOffer() {
      if(!this.selectedCurrency || !this.selectedCoin || !this.amount || !this.price) {
        this.notify('error', this.$t('message.warningEmptyBlank'))
        return
      }
      // if(!this.bank && !this.pps && !this.alipay && !this.wechat) {
      //   this.notify('error', this.$t('message.selectPayMethod'))
      //   return
      // }

      this.showAddDesc = false
      this.showConfirmOffer = true
      this.confirmChk = false
      this.$refs.Modal.showDialog(this.modalTitle, null, this.$t('message.cancel'), ()=>{}, ()=>{})
    },

    toggleChk() {
      if(this.confirmChk) {
        this.$refs.Modal.showDialog(this.modalTitle, this.$t('message.confirm'), this.$t('message.cancel'), () => {
          let formData = new FormData()

          let isBuy
          this.offerType === 'buy' ? isBuy = true : isBuy = false

          let isSplit
          this.tradeType === 'splitTrade' ? isSplit = true : isSplit = false

          let isPriceRef
          this.priceType === 'floatingPrice' ? isPriceRef = true : isPriceRef = false

          formData.append('marketPair', `${this.selectedCoin}-${this.selectedCurrency}`)
          formData.append('isBuy', isBuy)
          formData.append('isSplit', isSplit)
          if(isSplit) {
            formData.append('minUnit', this.minAmount.toString())
          }
          formData.append('totalAmount', this.amount.toString())
          formData.append('isPriceRef', isPriceRef)
          if(!isPriceRef) {
            formData.append('price', this.price.toString())
          }
          if(isPriceRef) {
            formData.append('markUp', this.markUp.toString())
            formData.append('exchanges', this.selectedExchange)
          }
          formData.append('memo', this.memo.toString())
          formData.append('bank ', this.bank)
          formData.append('pps ', this.pps)
          formData.append('alipay', this.alipay)
          formData.append('wechat', this.wechat)

          this.$post(this.$OTC_OFFER, 'otcOfferPostOffer', formData, true, (result) => {
            this.getUserProfile((result) => {
              if(result.success) {
                // let nickname = result.userProfile.nickName
                this.$router.push({ name: 'OfferList' })

                this.amount = 0
                this.minAmount = 0
                this.selectedExchange = this.exchangeList[0]
                this.markUp = 0
                this.price = 'NONE'
                this.memo = ''
                this.bank = false
                this.pps = false
                this.alipay = false
                this.wechat = false
              } else {
                console.log(result.userProfile)
              }
            })
            this.notify('success', result.message)
          }, (result) => {
            console.log(result)
          })
        }, ()=>{})
      } else {
        this.$refs.Modal.showDialog(this.modalTitle, null, this.$t('message.cancel'), ()=>{}, ()=>{})
      }
    }
  },

  watch: {
    'markUp': {
      handler() {
        this.markUp = Math.round(this.markUp * 1000) / 1000
      }
    },

    'amount': {
      handler() {
        this.totalPrice = Number(this.amount) * this.price
      }
    },

    'price': {
      handler() {
        this.totalPrice = Number(this.amount) * this.price
      }
    },

    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.otc'), false, true)
      }
    },
  }

}
</script>

<style scoped>

</style>
