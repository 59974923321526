import Vue from "vue"

const socket = new WebSocket(process.env.VUE_APP_DOMAIN_WS + '/bin')

const emitter = new Vue({
    methods:{
        send(message){
            setTimeout(() => {
                if (socket.readyState === 1) {
                    socket.send(message)
                } else {
                    setTimeout(() => {
                        socket.send(message)
                    }, 5)
                }
            }, 5)
        }
    }
})

socket.onmessage = function(msg){
    emitter.$emit("message", msg.data)
}
socket.onerror = async function(err){
    await emitter.$emit("error", err)
}


export default emitter
