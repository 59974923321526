<template>
  <div class="cv-container my-page bg-gray-100">
    <div class="form-horizontal">
      <div class="row mx-0 px-3">
        <div class="col-12 px-0 font-weight-bold">
          <div class="form-control-plaintext">
            <div class="row">
              <div class="col-9 text-basic form-lg">
                <span>{{ email }} ({{ $t('message.authDone') }})</span>
              </div>
              <div class="col-3 pl-0 align-self-center">
                <router-link :to="{ name: 'Account' }" class="btn btn-basic btn-sm btn-block form-lg">{{ $t('message.account') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white mb-3 px-3 py-2">
      <div class="col-12 text-center">
        <div class="row mp_level_wrap">
          <div class="col-3 px-0 mp_level" v-for="lvNo in ['LV1', 'LV2', 'LV3', 'LV4']" :key="lvNo">
            <a class="btn btn-sm btn-block form-lg" :class="{ 'btn-basic': lv === lvNo }">{{ lvNo }}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 회원정보 -->
    <div class="bg-white mb-3">
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'Level' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.membershipLevel') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'Security' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.security') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'LoginInfo' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.recentActivity') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'Inquiry' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.myInquiries') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'Advanced' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.advancedOptions') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="bg-white mb-3">
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'CustomerSupportList' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.announcement') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'CustomerSupportCenter' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.customerCenter') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'CustomerSupportFee' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.feeInfo') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="form-group row m-0 p-1" @click="$router.push({ name: 'CustomerSupportCoinRequest' })">
        <label class="col-10 col-form-label cv-icon">{{ $t('message.requestNewCoin') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="form-group row m-0 p-1" @click="shop()" >
        <label class="col-10 col-form-label cv-icon">{{ $t('message.goToShop') }}</label>
        <div class="col-2 text-right align-self-center">
          <a>
            <i class="xi-angle-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="px-3 py-3 bg-white mb-3" @click="logout">
      <a class="h6 text-red d-block mb-0">{{ $t('message.logout') }}</a>
    </div>
    <div class="px-3 py-3 bg-white mb-5 pb-5" @click="intercom">
      <a class="h6 text-primary d-block mb-5">{{ $t('message.intercom') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyInfoMain",

  data() {
    return {
      email: '',
      lv: '',
    }
  },

  created() {
    if(!localStorage.getItem('accessToken')) {
      this.$router.push({ name: 'Login' })
      return
    }

    this.$parent.setHeaderTitle(this.$t('message.myInfo'), false, false)

    this.getUserProfile((result) => {
      if(result.success) {
        this.lv = result.userProfile.level
        this.email = result.userProfile.email

        this.$intercom.boot({
          user_id: result.userProfile.userIdx,
          name: result.userProfile.nickName,
          email: result.userProfile.email,
          hide_default_launcher: true
        })
      } else {
        console.log(result.userProfile)
      }
    })

    localStorage.setItem('focusMenu', 4)
  },

  mounted() {
    this.$parent.showFooter(true)

    this.$parent.focusMenu(4)
    localStorage.setItem('focusMenu', 4)
  },

  methods: {
    shop() {
      window.openWeb('http://zmishop.com')
    },
    intercom() {
      this.$intercom.show()
    },
    logout() {
      this.$get(this.$USER_LOGIN_LOGOUT, 'Logout', true, (result) => {
        this.notify('success', result.message)
        this.$store.state.isLoggedIn = false
        localStorage.removeItem('accessToken')
        // sessionStorage.removeItem('tickers')
        // sessionStorage.removeItem('marketPairs')
        localStorage.removeItem('refreshToken')
        this.$router.push({ name: 'Dashboard' })
      }, (result) => {
        console.log(result)
      })
    }
  }


}
</script>

<style scoped>

</style>
