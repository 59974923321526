<template>
  <div class="lang-verti" @click="open = !open">
    <div class="dropdown lang d-inline">
      <span class="dropdown-toggle">
        <img class="kr" alt="flag" :src="flag"/>
      </span>
      <div class="dropdown-menu" :class="{ active: open }">
        <a class="dropdown-item" @click="setLang('ko')"><img src="/img/kr.png" alt="kr"/></a>
        <a class="dropdown-item" @click="setLang('cn')"><img src="/img/cn.png" alt="cn"/></a>
        <a class="dropdown-item" @click="setLang('en')"><img src="/img/en.png" alt="en"/></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LangVerti",

  data() {
    return {
      flag: '',
      open: false,
    }
  },

  mounted() {
    let defaultLang = localStorage.getItem('lang')
    this.selectLang(defaultLang)
  },

  methods: {
    selectLang(lang) {
      if(lang === 'ko') {
        this.flag = '/img/kr.png'
      } else if (lang === 'cn') {
        this.flag = '/img/cn.png'
      } else {
        this.flag = '/img/en.png'
      }
    },

    setLang(lang) {
      this.selectLang(lang)
      this.$root.$i18n.locale = lang
      localStorage.setItem('lang', this.$root.$i18n.locale)
    },
  },
}
</script>

<style scoped>

</style>
