<template>
  <div class="invest_coinsBox invest_trade__box">
    <div class="mt-5">
      <div class="trade_listTable outer">
        <div class="px-2 py-2 clearfix">
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" @change="($event)=>{this.$router.replace({name:$event.currentTarget.value})}">
              <option value="OtcSuggestionMarketList">{{ $t('message.otcMarket') }}</option>
              <option value="OtcSuggestionHistoryList">{{ $t('message.tradingHis') }}</option>
              <option value="OtcSuggestionChatList">{{ $t('message.otcChat') }}</option>
              <option value="OtcSuggestionMypostList" selected>{{ $t('message.myPost') }}</option>
            </select>
          </div>
          <button class="col-12 btn btn-basic btn-md mt-2" @click="$router.push({ name: 'OtcSuggestionWrite' })">{{ $t('message.registration') }}</button>
          <div class="row col-form-label mx-0">
            <div class="input-group">
              <select class="form-control w-25 mr-2" v-model="searchFilter" @change="searchFilter === 'all' ? keyword = '' : ''">
                <option value="all">{{ $t('message.all') }}</option>
                <option value="title">{{ $t('message.title') }}</option>
                <option value="nickname">{{ $t('message.nickname') }}</option>
              </select>
              <div class="input-group-prepend">
              <span class="input-group-text rounded-0 bg-white">
                <i class="xi-search search_btn"></i>
              </span>
              </div>
              <input style="width: 34%;" type="text" class="form-control border-left-0 rounded-0 search-control pl-0" v-model="keyword" :disabled="searchFilter === 'all'" @keyup.enter="getPostList(1)"/>
              <button class="w-25 ml-2 btn btn-md" @click="getPostList(1)">{{ $t('message.search') }}</button>
            </div>
          </div>
        </div>
        <div class="otc_listBox mx-2">
          <table class="table otc_table mb-0 table-bordered">
            <thead class="thead-light">
            <tr>
              <th rowspan="2" class="p-1">{{ $t('message.sort') }}</th>
              <th class="p-1">{{ $t('message.title') }}</th>
              <th class="p-1">{{ $t('message.nickname') }}</th>
            </tr>
            <tr>
              <th class="p-1">{{ $t('message.time') }}</th>
              <th class="p-1">{{ $t('message.endOfSale') }}</th>
            </tr>
            </thead>
            <tbody v-for="(post, index) in postList" :key="'post' + index" @click="goToDetail(post)">
            <tr>
              <td rowspan="2" class="p-1">{{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}
                <span :class="{ 'price_red': post.stateVal === 'doBuy', 'price_blue': post.stateVal === 'doSell', 'orange': post.stateVal === 'doTrading' }">
                  {{ $t(`message.${post.stateVal}`) }}
                </span>
              </td>
              <td class="p-1">{{ post.title }}</td>
              <td class="p-1">{{ post.writerNickname }}</td>
            </tr>
            <tr>
              <td class="p-1">{{ post.timeGap }}</td>
              <td class="p-1">
                <a class="outline_s_btn" v-show="post.state === 'OPEN'" @click="cancelSale($event, post.idx)">
                  {{ $t('message.endOfSale') }}
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="buttonwrap t_center">
          <paginate
              :page-count="pageLast"
              :page-range="3"
              :margin-pages="2"
              :click-handler="getPostList"
              :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :first-last-button=true
              :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OtcSuggestionMypostList",

  data() {
    return {
      pageLast: 0,
      postList: [],
      searchFilter: 'all',
      keyword: '',
      limit: 10,
      myIdx: null,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.otcMarket'), false, false)
    this.getUserProfile((result) => {
      if(result.success) {
        this.myIdx = result.userProfile.userIdx
        this.getPostList(1)
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.$parent.focusMenu(3)
  },

  methods: {
    cancelSale(e, idx) {
      e.stopPropagation()
      let formData = new FormData()
      formData.append('idx', idx)
      this.$post(this.$OTC_TEMP_TRADE_DONE, 'otcMypostListDone', formData, true, (result) => {
        console.log(result)
        this.notify('success', this.$t('message.didTradeDone'))
        this.getPostList(1)
      }, (result) => {
        console.log(result)
      })
    },

    goToDetail(post) {
      this.$router.push({ name: 'OtcSuggestionMarketDetail', params: { postIdx: post.idx, tabNo: 3 } })
    },

    getPostList(page) {
      let formData = new FormData()
      formData.append('filter', this.searchFilter.toUpperCase())
      formData.append('keyword', this.keyword)
      formData.append('limit', this.limit)
      formData.append('page', page)
      formData.append('state', 'OPEN')
      formData.append('state', 'TRADING')
      formData.append('state', 'SOLDOUT')
      formData.append('userIdx', this.myIdx)

      // 내가 작성한 글만 조회
      this.$post(this.$OTC_TEMP_LIST, 'otcTempMyPosts', formData, true, (result) => {
        this.pageLast = Math.ceil(result.total / this.limit)
        this.postList = result.data

        let currentDate = new Date()
        let timestamp = currentDate.getTime() / 1000

        this.postList.forEach(post => {
          let timeGap = timestamp - post.createdAt / 1000
          // 24시간 = 86400초
          if(timeGap < 86400) {
            post.timeGap = this.secondsToHms(timeGap)
          } else if(timeGap >= 86400) {
            post.timeGap = this.secondsToDay(timeGap)
          }

          if(post.state === 'OPEN') {
            if(post.boardIsBuy) {
              post.stateVal = 'doBuy'
            } else {
              post.stateVal = 'doSell'
            }
          } else if (post.state === 'SOLDOUT') {
            post.stateVal = 'endOfSale'
          } else if(post.state === 'TRADING') {
            post.stateVal = 'doTrading'
          }
        })
      }, (result) => {
        console.log(result)
      })
    },

    secondsToHms(timeGap) {
      let h = Math.floor(timeGap % (3600*24) / 3600)
      let m = Math.floor(timeGap % 3600 / 60)
      let s = Math.floor(timeGap % 60)

      let hDisplay = h > 0 ? h + this.$t('message.time') + ' ' : ''
      let mDisplay = m > 0 ? m + this.$t('message.min') + ' ' : ''
      let sDisplay = s > 0 ? s + this.$t('message.sec') + ' ' : ''
      return hDisplay + mDisplay + sDisplay + this.$t('message.before')
    },

    secondsToDay(timeGap) {
      let d = Math.floor(timeGap / (3600*24))

      let dDisplay = d > 0 ? d + this.$t('message.day') + ' ' : ''
      return dDisplay + this.$t('message.before')
    }
  }
}
</script>

<style scoped>

</style>
