<template>
  <div class="cv_wallet_sub">
    <div class="wallet-content wallet-withdrawal-content" style="display: block;">
      <div class="px-3">
        <div class="form-group row mb-2">
          <label class="col-12 col-form-label">{{ $t('message.withdrawalAddress') }}</label>
          <div class="col-12 input-group">
            <input type="text" class="form-control" :placeholder="$t('message.enterAddressTo')" v-model="addressToSend">
            <div class="input-group-append" @click="isBookOpen = !isBookOpen" >
              <button class="btn btn-basic" type="button" style="height: 35px;" v-show="isBookOpen">{{ $t('message.address') }}{{ $t('message.list') }}
                <i class="fas fa-chevron-down"></i>
              </button>
              <button class="btn btn-basic" type="button" style="height: 35px;" v-show="!isBookOpen">{{ $t('message.address') }}{{ $t('message.list') }}
                <i class="fas fa-chevron-up"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="wallet_content1 mk_t_content my-3 book" v-show="isBookOpen">
          <div :key="rerenderKey" class="alert rounded-0 mb-2 input-group" role="alert">
            <div class="w-100 address-con"
                 :class="{ 'border-bottom': addressBook.length - 1 !== idx, 'mt-3': idx }"
                 v-for="(addressObj, idx) in addressBook"
                 :key="addressObj.idx"
            >
              <p class="d-flex align-items-center"><span class="w-25">{{ $t('message.title') }}</span><input type="text" class="form-control w-75" v-model="addressObj.name" :disabled="!addressObj.isInputActive" /></p>
              <p class="d-flex align-items-center"><span class="w-25">{{ $t('message.address') }}</span><input type="text" class="form-control w-75" v-model="addressObj.address" :disabled="!addressObj.isInputActive" /></p>
              <div class="w-100">
                <p class="d-flex align-items-center justify-content-center"><a class="outline_s_btn w-100 text-center" @click="addressToSend = addressObj.address; isBookOpen = false">{{ $t('message.select') }}</a></p>
              </div>
            </div>
            <p class="text-center w-100" v-show="!addressBook.length">저장된 출금주소가 없습니다.</p>
            <div class="w-100">
              <p class="d-flex align-items-center justify-content-center">
                <keep-alive>
                  <router-link :to="{ name: 'WithdrawalAddressManage' }" class="btn btn-basic btn-block w-100 text-center">
                    {{ $t('message.manageBook') }}
                  </router-link>
                </keep-alive>
              </p>
            </div>
          </div>
        </div>

        <div class="form-group mb-0 d-flex justify-content-between">
          <label class="left-icon">{{ $t('message.availWithdrawal') }}</label>
          <span class="text-right">
            <span>{{ myBalance | numberWithCommas }}</span> <sub class="text-muted">{{ coin }}</sub>
          </span>
        </div>
        <div class="form-group row mb-2">
          <div class="col-12">
            <div class="input-group">
              <input type="number" class="form-control" maxlength="26" :placeholder="$t('message.enterAmount')" autocomplete="off" v-model="amount">
              <div class="input-group-append" @click="setMax">
                <button class="btn btn-basic" type="button" style="height: 35px;">{{ $t('message.max') }}</button>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div class="form-group mb-2">
          <label class="left-icon col-12 col-form-label bg-gray-200 px-2">{{ $t('message.withdrawal') }}{{ $t('message.fees') }}</label>
          <div class="col-12 px-0 border">
            <div class="font-weight-bold px-2 pt-1">
              <span class="mr-1">{{ percentage ? subDec(fee * 100, coin) : subDec(fee, coin) }}</span>
              <sub class="text-muted">{{ percentage ? '%' : coin }}</sub>
            </div>
            <div class="px-2 pb-1">
              <span class="mr-1 fs10">{{ $t('message.whenInternal') }} 0 {{ percentage ? '%' : coin }}</span>
            </div>
          </div>
        </div>
        <div class="form-group mb-2">
          <label class="left-icon col-12 col-form-label bg-gray-200 px-2">{{ $t('message.totalWithdrawal') }}</label>
          <div class="col-12 px-0 border">
            <div class="font-weight-bold px-2 pt-1">
              <span class="text-danger mr-1">{{ getTotal() | numberWithCommas }}</span>
              <sub class="text-muted">{{ coin }}</sub>
            </div>
            <div class="px-2 pb-1">
              <span class="mr-1 fs10">{{ $t('message.whenInternal') }} {{ amount }} {{ coin }}</span>
            </div>
          </div>
        </div>
        <div v-if="security === 'PIN'" class="form-group row mb-2">
          <label class="col-12 col-form-label">{{ $t('message.pinCode') }}</label>
          <div class="col-12">
            <div class="input-group">
              <input type="password" class="form-control" :placeholder="$t('message.enterPin')" v-model="code">
            </div>
          </div>
        </div>
        <div v-else-if="security === 'PHONE'" class="form-group row mb-2">
          <label class="col-12 col-form-label">{{ $t('message.verificationCode') }}</label>
          <div class="col-12">
            <div class="input-group">
              <input type="password" class="form-control" :placeholder="$t('message.placeholderPutAuthCode')" v-model="code">
              <div class="input-group-append" @click="getCode">
                <button class="btn btn-basic" type="button" style="height: 35px;">{{ $t('message.authCode') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="security === 'OTP'" class="form-group mb-2">
          <label class="col-12 col-form-label">{{ $t('message.optCode') }}</label>
          <div class="col-12">
            <div class="input-group">
              <input type="password" class="form-control" :placeholder="$t('message.placeholderOTP')" v-model="code">
            </div>
          </div>
        </div>
        <hr/>
      </div>
      <div class="px-3 pb-3">
        <div class="alert alert-dark rounded-0 mb-0" role="alert">
          <div class="alert-heading">※{{ $t('message.refNote') }}※</div>
          <ul class="pl-3">
            <li>{{ $t('message.1time') }} {{ $t('message.minLimit') }}: {{ minAmount | numberWithCommas }} {{ coin }}</li>
            <li v-show="maxAmount">{{ $t('message.maxWithdraw') }}: {{ maxAmount | numberWithCommas }} {{ coin }}</li>
            <li v-show="!maxAmount">{{ $t('message.maxWithdraw') }}: {{ $t('message.unlimited') }}</li>
            <li>{{ $t('message.withNote1') }}</li>
            <li>{{ $t('message.withNote2') }}</li>
          </ul>

          <br>
          <table style="width:100%; text-align: center">
            <tr>
              <th>{{ $t('message.lv')}}</th>
              <th>{{ $t('message.maxWithdraw')}}</th>
              <th>{{ $t('message.maxDayWithdraw')}}</th>
            </tr>
            <tr v-for="maxWithdrawalAmount in maxWithdrawalAmounts" v-bind:key="maxWithdrawalAmount.level">
              <td>{{maxWithdrawalAmount.level == 0 ? '∞' : maxWithdrawalAmount.level}}</td>
              <td>{{maxWithdrawalAmount.dayOnceWithdrawLimit == 0 ? '∞' : maxWithdrawalAmount.dayOnceWithdrawLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} {{ coin }}</td>
              <td>{{maxWithdrawalAmount.dayMaxWithdrawLimit == 0 ? '∞' : maxWithdrawalAmount.dayMaxWithdrawLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} {{ coin }}</td>
            </tr>
          </table>
        </div>
        <div class="mt-3">
          <button type="submit" class="btn btn-basic btn-block" @click="withdraw">{{ $t('message.withdrawal') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Big from "big-js";

export default {
  name: "Withdrawal",
  data() {
    return {
      maxAmount: 0,
      addressBook: [],
      addressBookTitle: '',
      addressBookAddress: '',
      rerenderKey: 0,
      isBookOpen: false,
      coin: '',
      myBalance: 0,
      amount: 0,
      fee: 0,
      addressToSend: '',
      minAmount: 0,
      withdrawLimit: {
        maxDayWithdraw: 0,
        maxOnceWithdraw: 0,
        withdrawCurrency: ''
      },
      percentage: true,
      maxWithdrawalAmounts: [],
      security: 'PIN',
      code: '',
    }
  },

  activated() {
    this.isBookOpen = false
    this.coin = sessionStorage.getItem('withdrawalCoin')
    this.getAddressBook()
    this.$parent.showFooter(false)
    this.addressToSend = ''
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.doWithdrawal'), false, true)
    this.coin = sessionStorage.getItem('withdrawalCoin')
    this.getBalance()

    this.$get(`${this.$WALLET_FEE}?ticker=${this.coin}`, 'AssetWalletDetailGetFee', true, (result) => {
      this.fee = result.data[0].fee
      this.percentage = result.data[0].percentage
      this.minAmount = result.data[0].minWithdrawAmount
      this.maxAmount = result.data[0].maxWithdrawAmount
    }, (result) => {
      console.log(result)
    })

    this.$get(`${this.$USER_MYPAGE_MAX_WITHDRAWAL_AMOUNT}?ticker=${this.coin}`, 'UserMyPageMaxWithdrawalAmount', true, (result) => {
      this.maxWithdrawalAmounts = result.data;
    }, (result) => {
      console.log(result)
    })

    this.$get(this.$USER_MYPAGE_SECURITY, 'myPageGetSecurityMethod', true, (result) => {
      this.security = result.data[0].security
    }, (result) => {
      console.log(result)
    })
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    getCode() {
      let formData = new FormData()

      this.$post(this.$WALLET_SMS, 'AssetWalletDetailPostSMS', formData, true, (result) => {
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    getAddressBook() {
      this.$get(`${this.$WALLET_ADDRESS_WITHDRAW}?ticker=${this.coin}`, 'walletDetailGetAddress', true, (result) => {
        this.addressBook = result.data
        this.addressBook.forEach(addressObj => {
          addressObj.isInputActive = false
        })
      }, (result) => {
        console.log(result)
      })
    },

    withdraw() {
      if(!this.amount || !this.addressToSend || !this.code) {
        this.notify('error', this.$t('message.enterAll'))
        return
      }

      let formData = new FormData()
      // formData.append('marketType', this.exchange.toUpperCase())
      formData.append('ticker', this.coin)
      formData.append('amount', this.amount)
      formData.append('addressTo', this.addressToSend)
      formData.append('code', this.code)

      this.$post(this.$WALLET_WITHDRAW, 'AssetWalletDetailPostWithdraw', formData, true, (result) => {
        this.notify('success', result.message)
        this.getBalance()
        this.amount = 0
        this.addressToSend = ''
        this.code = ''
      }, (result) => {
        console.log(result)
      })
    },

    getBalance() {
      this.getMyBalance((result) => {
        if(result.success) {
          this.myBalance = result.myBalance.available
        }
      }, this.coin)
    },

    setMax() {
      Big.RM = 0
      let myBalance = new Big(this.myBalance)
      let fee = new Big(this.fee)

      if(this.percentage) {
        this.amount = myBalance.div(fee.plus(1))
      } else {
        this.amount = myBalance.minus(fee)
      }

      if(this.amount < 0 || (this.myBalance < this.minAmount)) {
        this.amount = this.subDec(0, this.coin)
      }
    },

    getTotal() {
      // Number(amount) + (Number(amount) * fee * 0.01)
      Big.RM = 0

      let amount = this.amount

      if(typeof amount === 'string') {
        if(amount.includes(',')) {
          amount = amount.replaceAll(',', '')
        }
      }

      if(amount) {
        amount = new Big(amount)
      } else {
        amount = new Big(0)
      }

      let fee = new Big(this.fee)
      let total = new Big(0)

      if(this.percentage) {
        total = amount.plus(amount.times(fee))
      } else {
        total = amount.plus(fee)
      }

      return total
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.doWithdrawal'), false, true)
      }
    },
  }
}
</script>

<style scoped>

</style>
