<template>
  <div class="cv-container-sub cv-level-wrap pb-3">
    <div class="px-3">
      <p class="mt-2">{{ coinNoticeDetail.title }}</p>
      <table class="table_m">
        <tr>
          <th class="t_left">{{ $t('message.createdTime') }}</th>
          <td><input type="text" v-model="coinNoticeDetail.date" disabled></td>
        </tr>
      </table>
      <div class="view_content">
        <p v-html="escapeTag(coinNoticeDetail.contents)"></p>
        <img v-show="coinNoticeDetail.file" :src="coinNoticeDetail.file" :alt="$t('message.file')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoinNoticeView",
  data() {
    return {
      coinNoticeIdx: this.$route.params.articleIdx,
      coinNoticeDetail: {},
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.coinAnn'), false, true)

    if(this.coinNoticeIdx) {
      sessionStorage.setItem('coinNoticeIdx', this.coinNoticeIdx)
    } else {
      this.coinNoticeIdx = sessionStorage.getItem('coinNoticeIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('coinNoticeIdx')
  },

  mounted() {
    this.$parent.showFooter(false)

    this.$get(`${this.$BOARD_DETAIL}/${this.coinNoticeIdx}`, this.$options.name + '_mountedDetail', false, (result) => {
      this.coinNoticeDetail = result.data[0]
    }, (result) => {
      this.notify('error', result.message)
    })
  },
}
</script>

<style scoped>

</style>
