<template>
  <div class="dropdown market_radio col-12 px-0">
    <select class="border custom-select" v-model="historyOption" @change="historyOptionChange">
<!--      <option value="sellOffer">{{ $t('message.sellOfferSum') }}</option>-->
<!--      <option value="buyOffer">{{ $t('message.buyOfferSum') }}</option>-->
<!--      <option value="sellState">{{ $t('message.sellOrderSum') }}</option>-->
<!--      <option value="buyState">{{ $t('message.buyOrderSum') }}</option>-->

      <option value="sellOffer">OTC {{ $t('message.inOrdersQuantity') }}</option>
      <option value="sellState">OTC {{ $t('message.orderHistory') }}</option>
    </select>
  </div>
</template>

<script>


export default {
  name: "Menu",

  data() {
    return {
      historyOption: 'sellOffer'
    }
  },

  mounted() {
    console.log(this.$route.path)
    if(this.$route.path === '/history/otc/buy/offers') {
      this.historyOption = 'buyOffer'
    } else if(this.$route.path === '/history/otc/sell/offers') {
      this.historyOption = 'sellOffer'
    } else if(this.$route.path === '/history/otc/sell/state') {
      this.historyOption = 'sellState'
    } else {
      this.historyOption = 'buyState'
    }
  },

  methods: {
    historyOptionChange() {
      this.$emit('historyOptionChange', this.historyOption)

      if(this.historyOption === 'sellOffer') {
        this.$router.push({ name: 'HistoryOtcSellOffers' })
      } else if(this.historyOption === 'buyOffer') {
        this.$router.push({ name: 'HistoryOtcBuyOffers' })
      } else if(this.historyOption === 'sellState') {
        this.$router.push({ name: 'HistoryOtcSellState' })
      } else {
        this.$router.push({ name: 'HistoryOtcBuyState' })
      }
    }
  }
}
</script>

<style scoped>

</style>
