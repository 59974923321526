<template>
  <div class="cv-container-sub cv-level-wrap pb-3">
    <div class="px-3">
      <p class="mt-2">{{ transferDetail.title }}</p>
      <table class="table_m">
        <tr>
          <th class="t_left">{{ $t('message.createdTime') }}</th>
          <td><input type="text" v-model="transferDetail.date" disabled></td>
        </tr>
      </table>
      <div class="view_content">
        <p v-html="escapeTag(transferDetail.contents)"></p>
        <img v-show="transferDetail.file" :src="transferDetail.file" :alt="$t('message.file')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "transferView",
  data() {
    return {
      transferIdx: this.$route.params.articleIdx,
      transferDetail: {},
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.deposit') + "/" + this.$t('message.withdrawal'), false, true)

    if(this.transferIdx) {
      sessionStorage.setItem('transferIdx', this.transferIdx)
    } else {
      this.transferIdx = sessionStorage.getItem('transferIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('transferIdx')
  },

  mounted() {
    this.$parent.showFooter(false)
    
    this.$get(`${this.$BOARD_DETAIL}/${this.transferIdx}`, this.$options.name + '_mountedDetail', false, (result) => {
      this.transferDetail = result.data[0]
      this.transferDetail.date = this.formatDate(this.transferDetail.createdAt, 'full')
    }, (result) => {
      this.notify('error', result.message)
    })
  },
}
</script>

<style scoped>

</style>
