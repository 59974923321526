<template>
  <div class="cv-container-sub pb-3 pt-3">
    <div class="px-3 bbs-right">
      <div>
        <input type="text" class="form-control input-lg" :placeholder="$t('message.placeholderProfileImg')" v-model="certiFileName" disabled>
        <div class="upload-group">
          <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
          <a><i class="fas fa-folder-open"></i></a>
        </div>
      </div>
      <div class="mt-3">
        <button class="btn btn-basic btn-block input-lg mb-3" @click="doIdAuth">{{ $t('message.idUpload') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthId",

  data() {
    return {
      certiFileName: '',
      certiFile: {},

    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.idUpload'), false, true)
  },

  methods: {
    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.certiFile = file[0]
      this.certiFileName = file[0].name
    },

    doIdAuth() {
      if(!this.certiFileName) {
        this.notify('error', this.$t('message.doAttach'))
        return
      }

      let formData = new FormData()
      formData.append('idCard', this.certiFile)

      this.$post(this.$USER_MYPAGE_SECURITY_IDCARD, 'myPageLevelPostID', formData, true, (result) => {
        this.notify('success', result.message)
        this.$router.back()
      }, (result) => {
        this.notify('error', result.message)
      })
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.idUpload'), false, true)

        this.getForm()
      }
    },
  }
}
</script>

<style scoped>

</style>
