import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./commonjs/store"
import i18n from '@/lang'

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import '@fortawesome/fontawesome-free/js/all.js'

import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
Vue.component('datePicker', datePicker)

import VueIntercom from 'vue-intercom'
Vue.use(VueIntercom, { appId: 'yhewitx6' })

import VueCountryCode from "vue-country-code-select"
Vue.use(VueCountryCode)

import Vuex from 'vuex'
Vue.use(Vuex)

import VueQrcode from '@chenfengyuan/vue-qrcode'
Vue.component(VueQrcode.name, VueQrcode)

import api from './commonjs/api'
Vue.use(api)

import common from "@/commonjs/common"
Vue.use(common)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  i18n,
  router,
  store
}).$mount('#app')
