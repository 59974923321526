<template>
  <div class="cv-container-sub pb-3">
    <div class="px-3 bbs-right">
      <div class="form-horizontal">
        <div class="cv-div-tbl1 mypage_security_selectBox">
          <div id="use_pin" class="form-group row m-0">
            <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.pwd') }} {{ $t('message.confirm') }}</label>
            <div class="col-12 px-0">
              <input type="password" class="form-control input-lg" v-model="pwd" :placeholder="$t('message.placeholderOldPwd')"/>
            </div>
            <label class="col-12 col-form-label cv-icon px-0">PIN</label>
            <div class="col-12 px-0">
              <input type="password" class="form-control input-lg" v-model="pin" maxlength="6" :placeholder="$t('message.enterSixDigits')"/>
            </div>
            <label class="col-12 col-form-label cv-icon px-0">PIN {{ $t('message.confirm') }}</label>
            <div class="col-12 px-0">
              <input type="password" class="form-control input-lg" v-model="pinConfirm" maxlength="6" :placeholder="$t('message.enterSixDigits')">
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-basic btn-block input-lg" @click="doPinAuth">{{ $t('message.setSecurity') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Auth_Pin",

  data() {
    return {
      pwd: '',
      pin: '',
      pinConfirm: '',
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.pinSetting'), false, true)
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    doPinAuth() {
      let formData = new FormData()
      formData.append('existingPassword', this.pwd)
      formData.append('pin', this.pin)
      formData.append('pinConfirm', this.pinConfirm)

      this.$post(this.$USER_MYPAGE_SECURITY_PIN, 'MyPageInfoPostSecurity', formData, true, (result) => {
        this.pwd = ''
        this.pin = ''
        this.pinConfirm = ''
        this.notify('success', result.message)
        this.$router.push({ name: 'Level' })
      }, (result) => {
        this.notify('error', result.message)
        this.pwd = ''
        this.pin = ''
        this.pinConfirm = ''
      })
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.pinSetting'), false, true)
      }
    },
  }
}
</script>

<style scoped>

</style>
