<template>
  <div class="cv-container-sub pb-3">
    <div class="px-3 bbs-right">
      <div class="cv-div-tbl1 mypage_security_selectBox">
        <div>
          <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.phoneNo') }}</label>
          <div class="col-12 px-0 middle_con">
            <input type="number" class="form-control input-lg" :placeholder="$t('message.placeholderPhoneNo')" v-model="phone" />
            <div class="outline_m_btn input" @click="sendCode"><a>{{ $t('message.getVerificationCode') }}</a></div>
          </div>
        </div>
        <div v-show="showInputSmsCode">
          <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.verificationCode') }}</label>
          <div class="col-12 px-0">
            <input type="number" class="form-control input-lg" :placeholder="$t('message.verificationCode')" v-model="authCode" @keyup.enter="login">
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button type="submit" class="btn btn-basic btn-block mb-3 input-lg" @click="login">{{ $t('message.login') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhoneLogin",
  data() {
    return {
      pinCode: '',
      countryCode: 'KR',
      phone: '',
      showInputSmsCode: false,
      authCode: '',
      authMethod: this.$route.params.secondAuthMethod
    }
  },

  created() {
    if(!this.authMethod) {
      this.$router.back()
    }

    this.$parent.setHeaderTitle(this.$t('message.2ndAuth') + '(PHONE)', false, true)
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    sendCode() {
      let formData = new FormData()

      formData.append('phone', this.phone)
      formData.append('verifyCode', this.$route.params.verifyCode)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_LOGIN_SMS, 'loginSms', formData, false, (result) => {
        this.showInputSmsCode = true
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    login() {
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('code', this.authCode)
      formData.append('verifyCode', this.$route.params.verifyCode)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_LOGIN_SMS_CERT, 'loginSmsCert', formData, false, (result) => {
        localStorage.setItem('accessToken', result.data[0].accessToken)
        localStorage.setItem('refreshToken', result.data[0].refreshToken)

        this.refreshToken()
        this.setFCMToken()

        this.$store.state.isLoggedIn = true
        this.$router.push({ name: 'Dashboard' })
      }, (result) => {
        this.notify('error', result.message)
      })
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.2ndAuth') + '(PHONE)', false, true)
      }
    },
  },
}
</script>

<style scoped>

</style>
