<template>
  <div class="cv-container cv-home">
    <div class="home_markets">
      <div class="input_box px-3 py-2 bg-gray-100">
        <div class="input-group border-gray-200 border">
          <div class="input-group-prepend">
            <span class="input-group-text px-3 rounded-0 bg-white border-0">
              <i class="xi-search search_btn"></i>
            </span>
          </div>
          <input type="text" class="form-control border-0 rounded-0 search-control" v-model="keyword" :placeholder="$t('message.searchEx')" @keyup.enter=searchCoin />
        </div>
      </div>
      <div class="tab_design1 pb-5">
        <div class="secondary_market clearfix px-3 py-3">
          <div class="float-left w-100">
            <ul class="nav nav-pills nav-fill">
              <li class="nav-item" v-for="curr in secondCurr" :key="curr">
                <a class="nav-link btn btn-outline-secondary py-2" :class="{ active: currentSecond === curr && currentSecond !== 'favorite' }" @click="activeTab(curr)">{{ curr }} {{ $t('message.market') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link btn btn-outline-secondary py-2" :class="{ active: currentSecond === 'favorite' }" @click="activeTab('favorite')">{{ $t('message.favorite') }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="px-3 bg-gray-100 border-gray-200 border-top border-bottom">
          <table class="table table-sm mb-0 cv-home-table cv-home-table1">
            <colgroup>
              <col width="7%">
              <col width="auto">
              <col width="19%">
              <col width="18%">
              <col width="34%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="text-left"><i class="xi-star-o" id="star_list"></i></th>
                <th scope="col" class="text-left">{{ $t('message.coin') }}</th>
                <th scope="col" class="text-right">{{ $t('message.latest') }}</th>
                <th scope="col" class="text-right">{{ $t('message.netChange') }}</th>
                <th class="text-right">{{ $t('message.transactionAmount') }} </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="px-3">
          <table class="table table-sm cv-home-table cv-home-table2" v-show="marketList">
            <colgroup>
              <col width="7%">
              <col width="auto">
              <col width="19%">
              <col width="18%">
              <col width="34%">
            </colgroup>
            <tbody>
            <tr class="bookmark-coin" @click="goToTrade(market.marketPair)" v-for="(market, idx) in marketList" :key="idx" v-show="!(currentSecond === 'favorite' && !market.fav)">
              <td class="bookmark" @click.stop="setFav(market.marketPair, market.fav)">
  <!--                      active 클래스: 노란별-->
                <i class="xi-star-o active" v-show="market.fav"></i>
                <i class="xi-star-o" v-show="!market.fav"></i>
              </td>
              <td class="text-left">
                <a class="float-left h6 mb-0 d-block">
                  <div class="float-left pt-1 pt-sm-0">
                    <div class="coin_title font-weight-bold">{{ getCoinFullName(market.marketPair.split('-')[0]) }}</div>
                    <small class="text-muted">{{ market.marketPair.replace('-', '/') }}</small>
                  </div>
                </a>
              </td>
              <td class="text-right">
                <span :class="{ price_red: market.changeRatio > 0, price_blue: market.changeRatio < 0 }">
                  {{ subDec(market.present, market.marketPair.split('-')[1]) | numberWithCommas }}
                </span>
              </td>
              <td class="text-right" :class="{ price_red: market.changeRatio > 0, price_blue: market.changeRatio < 0 }">
                <span v-show="market.changeRatio > 0">+</span>
                <span class="">{{ subDec(market.changeRatio) }}%</span>
              </td>
              <td class="text-right">{{ market.tradeCost ? market.tradeCost : 0 }}
                <small class="text-muted">{{ market.marketPair.split('-')[1] }}</small>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p v-show="!marketList.length">{{ $t('message.noHistory') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Socket from "@/commonjs/socket"

export default {
  name: "Dashboard",

  data() {
    return {
      secondCurr: [],
      marketList: [],
      currentSecond: '',
      keyword: '',
      dataToSend: {
        type: '',
        pair: [],
      },
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.exchangeTit'), false, false)

    this.$get(this.$HOME_SECONDARY, 'MarketGetHomeSecond', false, (result) => {
      this.secondCurr = result.data
      this.currentSecond = this.secondCurr[0]
      this.getSecondCurrMarket('', true)
    }, (result) => {
      console.log(result)
    })

    Socket.$on("message", this.handleMessage)
  },

  mounted() {
    this.$parent.focusMenu(0)
    localStorage.setItem('focusMenu', 0)
  },

  methods: {
    searchCoin() {
      this.keyword = this.keyword.toUpperCase()
      this.getSecondCurrMarket(this.keyword)
    },

    getPairList() {
      if(this.currentSecond !== 'favorite') {
        this.getSecondCurrMarket()
      } else {
        this.marketList = []
        this.secondCurr.forEach((curr) => {
          this.getAllMarket(curr)
        })
      }
    },

    setFav(pair, fav) {
      if(this.$store.getters.getLoginState) {
        let formData = new FormData
        formData.append('marketPair', pair)
        formData.append('marketPairSave', !fav)

        this.$post(this.$USER_MYPAGE_FAV, 'setFav', formData, true, (result) => {
          console.log(result)
          this.getPairList()
        }, (result) => {
          console.log(result)
        })
      } else {
        this.notify('error', this.$t('message.afterLogin'))
      }
    },

    handleMessage(msg) {
      let promise = new Promise(resolve => {
        resolve(msg.text())
      })
      promise.then((value) => {
        let jsonData = JSON.parse(value)
        if (jsonData.t === 'p') {
          this.getPriceData(jsonData.p, jsonData.m[0])
        } else if(jsonData.t === 'v') {
          //거래대금, 거래량
          this.getTradeCost(jsonData.m[0])
        }
      })
    },

    getTradeCost(jsonMsg) {
      let pairsData = Object.keys(jsonMsg)
      this.marketList.forEach(market => {
        pairsData.forEach(pair => {
          if(pair === market.marketPair) {
            let tradeCost = jsonMsg[pair].value
            market.tradeCost = this.numberToLang(tradeCost)
          }
        })
      })
      this.$forceUpdate()
    },

    getPriceData(pair, price) {
      this.marketList.forEach((market) => {
        if(market.marketPair === pair) {
          market.present = price
          market.changeRatio = (market.present - market.lastPrice) / market.lastPrice * 100
          if(market.changeRatio === undefined || isNaN(market.changeRatio)) {
            market.changeRatio = 0
          }
        }
      })
    },

    sendData(isFromFav = false) {
      let pairDataToSend = []

      if(isFromFav) {
        this.marketList.forEach(market => {
          if(market.fav) {
            pairDataToSend.push(market.marketPair)
          }
        })
      } else {
        this.marketList.forEach(market => {
          pairDataToSend.push(market.marketPair)
        })
      }

      this.dataToSend = {
        type: 'set',
        pair: pairDataToSend,
      }
      let uint8array = new TextEncoder("utf-8").encode(JSON.stringify(this.dataToSend))
      Socket.send(uint8array)
    },

    // getTradeCost() {
    //   let pairs = []
    //   let tradeCosts = []
    //   this.$get(`${this.$HOME_MARKET_VALUE}?secondary=${this.currentSecond}`, 'GeneralMarketGetValue', false, (result) => {
    //     pairs = Object.keys(result.data[0])
    //     tradeCosts = Object.values(result.data[0])
    //
    //     this.marketList.map((market) => {
    //
    //       pairs.forEach((pair, pairIdx) => {
    //         if(pair === market.marketPair) {
    //           market.tradeCost = tradeCosts[pairIdx]
    //           market.tradeCost = this.numberToLang(market.tradeCost)
    //         }
    //       })
    //     })
    //     this.$mount()
    //   }, (result) => {
    //     console.log(result)
    //   })
    // },

    numberToLang(number) {
      let format = {
        ko: {
          4: {slice: [1, 3], name: '천'},
          5: {slice: [1, 3], name: '만'},
          6: {slice: [2, 4], name: '만'},
          7: {slice: [3, 5], name: '만'},
          8: {slice: [1, 3], name: '백만'},
          9: {slice: [1, 3], name: '억'},
          10: {slice: [2, 4], name: '억'},
          11: {slice: [1, 3], name: '백억'},
          12: {slice: [1, 3], name: '천억'},
          13: {slice: [1, 3], name: '조'}
        },
        en: {
          4: {slice: [1, 3], name: 'K'},
          5: {slice: [1, 3], name: 'K'},
          6: {slice: [2, 4], name: 'K'},
          7: {slice: [3, 5], name: 'M'},
          8: {slice: [1, 3], name: 'M'},
          9: {slice: [1, 3], name: 'M'},
          10: {slice: [2, 4], name: 'B'},
          11: {slice: [1, 3], name: 'B'},
          12: {slice: [1, 3], name: 'B'},
          13: {slice: [1, 3], name: 'T'}
        },
      }

      let lang = this.$root.$i18n.locale
      if(!lang || (lang !== 'ko' && lang !== 'en')) {
        lang = 'en'
      }

      if(number.includes('.')) {
        number = number.split('.')[0]
      }

      if(number.length < 4) {
        number = this.subDec(number)
        return number
      }

      return [number.slice(0, format[lang][number.length].slice[0]), '.', number.slice(format[lang][number.length].slice[0], format[lang][number.length].slice[1])].join('') + format[lang][number.length].name
    },

    getSecondCurrMarket(keyword, isCallSendData) {
      let url = `${this.$HOME_MARKET}?secondary=${this.currentSecond}`
      if(keyword) {
        url += `&primary=${this.keyword}`
      }

      this.marketList = []
      this.$get(url, 'MainGetHomeMarket', false, (result) => {
        this.marketList = result.data

        if(isCallSendData) {
          this.sendData()
        }

        if(!this.marketList.length) {
          return
        }

        this.marketList.map((market) => {
          if(market.present !== '0' && market.lastPrice !== '0') {
            market.changeRatio = (Number(market.present) - Number(market.lastPrice)) / Number(market.lastPrice) * 100
          }
        })

        if(this.$store.getters.getLoginState) {
          this.$get(this.$USER_MYPAGE_FAV, 'getFav', true, (result) => {
            let favList = result.data

            this.marketList.map((market) => {
              favList.forEach(fav => {
                if(fav === market.marketPair) {
                  market.fav = true
                  this.$mount()
                }
              })
            })
          }, (result) => {
            console.log(result)
          })
        }

        // this.getTradeCost()
      }, (result) => {
        console.log(result)
      })
    },

    goToTrade(marketPair) {
      this.$router.push({ name: 'GeneralExchange', params: { marketPair: marketPair }})
    },

    activeTab(ticker) {
      this.currentSecond = ticker
      if(ticker === 'favorite') {
        this.marketList = []
        this.secondCurr.forEach((curr) => {
          this.getAllMarket(curr)
        })
      } else {
        this.getSecondCurrMarket('', true)
      }
    },

    getAllMarket(secondCurr) {
      this.$get(`${this.$HOME_MARKET}?secondary=${secondCurr}`, 'MainGetHomeMarket' + secondCurr, false, (result) => {
        this.marketList = [...this.marketList, ...result.data]

        this.marketList.map((market) => {
          if(market.present !== '0' && market.lastPrice !== '0') {
            market.changeRatio = (Number(market.present) - Number(market.lastPrice)) / Number(market.lastPrice) * 100
          }
        })

        this.$get(this.$USER_MYPAGE_FAV, 'getFav', true, (result) => {
          let favList = result.data

          this.marketList.map((market) => {
            market.fav = false
            favList.forEach(fav => {
              if(fav === market.marketPair) {
                market.fav = true
                this.$mount()
              }
            })
          })
          this.sendData(true)
        }, (result) => {
          console.log(result)
        })

        // let pairs = []
        // let tradeCosts = []
        // this.$get(`${this.$HOME_MARKET_VALUE}?secondary=${secondCurr}`, 'GeneralMarketGetValue' + secondCurr, false, (result) => {
        //   pairs = Object.keys(result.data[0])
        //   tradeCosts = Object.values(result.data[0])
        //
        //   this.marketList.map((market) => {
        //     pairs.forEach((pair, pairIdx) => {
        //       if(pair === market.marketPair) {
        //         let tradeCost = tradeCosts[pairIdx]
        //         market.tradeCost = this.numberToLang(tradeCost)
        //       }
        //     })
        //   })
        //   this.$mount()
        // }, (result) => {
        //   console.log(result)
        // })
      }, (result) => {
        console.log(result)
      })
    },
  },

  beforeDestroy() {
    Socket.$off("message", this.handleMessage)
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.exchangeTit'), false, false)
      }
    },
  }
}
</script>

<style scoped>

</style>
