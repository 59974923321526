import historyProvider from './historyProvider'
import stream from './stream'

const supportedResolutions = ["1", "3", "5", "15", "30", "60", "120", "240", "D"]

const config = {
    supported_resolutions: supportedResolutions
};

export default {
	onReady: cb => {
		//console.log('=====onReady running')
		setTimeout(() => cb(config), 0)

	},
	// eslint-disable-next-line no-unused-vars
	searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
		//console.log('====Search Symbols running')
	},
	// eslint-disable-next-line no-unused-vars
	resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
		// expects a symbolInfo object in response
		//console.log('======resolveSymbol running')
		// console.log('resolveSymbol:',{symbolName})
		let split_data = symbolName.split(/[:-]/)
		// console.log({split_data})
		let symbol_stub = {
			name: symbolName,
			description: '',
			type: 'crypto',
			session: '24x7',
			timezone: 'Etc/UTC',
			ticker: symbolName,
			exchange: '',
			minmov: 1,
			pricescale: 100000000,
			has_intraday: true,
			intraday_multipliers: ['1', '60'],
			supported_resolution:  supportedResolutions,
			volume_precision: 8,
			data_status: 'streaming',
		}

		if (split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
			symbol_stub.pricescale = 1
		}
		setTimeout(function() {
			onSymbolResolvedCallback(symbol_stub)
			// console.log('Resolving that symbol....', symbol_stub)
		}, 0)


		// onResolveErrorCallback('Not feeling it today')

	},
	getBars: function(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
		//console.log('=====getBars running')
		// console.log('function args',arguments)
		//console.log(`Requesting bars between ${new Date(from * 1000)} and ${new Date(to * 1000)}`)
		let promise = historyProvider.getBars(symbolInfo, resolution, from, to, firstDataRequest)

		if(typeof promise.then === 'function') {
			promise.then(bars => {
				if (bars.length) {
					onHistoryCallback(bars, {noData: false})
				} else {
					onHistoryCallback(bars, {noData: true})
				}
			}).catch(err => {
				console.log({err})
				onErrorCallback(err)
			})
		} else {
			onHistoryCallback([], {noData: true})
		}

	},
	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
		// console.log('=====subscribeBars runnning')
		stream.subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback)
	},
	unsubscribeBars: subscriberUID => {
		//console.log('=====unsubscribeBars running')

		stream.unsubscribeBars(subscriberUID)
	},
	// eslint-disable-next-line no-unused-vars
	calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
		sessionStorage.setItem('chartResolution', resolution)
		//optional
		//console.log('=====calculateHistoryDepth running')
		// while optional, this makes sure we request 24 hours of minute data at a time
		// CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
		return resolution < 60 ? {resolutionBack: 'D', intervalBack: '1'} : undefined
	},
	// eslint-disable-next-line no-unused-vars
	getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		//optional
		//console.log('=====getMarks running')
	},
	// eslint-disable-next-line no-unused-vars
	getTimeScaleMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		//optional
		//console.log('=====getTimeScaleMarks running')
	},
	// eslint-disable-next-line no-unused-vars
	getServerTime: cb => {
		//console.log('=====getServerTime running')
	}
}
