<template>
  <div class="invest_trade__box cv-container-sub pb-3">
    <div class="px-3">
      <div class="bg-white cv-mypage">
        <div class="cv-mypage-tit h5">{{ $t('message.myInquiries') }}</div>
        <router-link :to="{ name: 'InquiryRegist' }" class="btn btn-basic btn-block form-lg" style="font-size: 13px;">1:1 {{ $t('message.doInquiry') }}</router-link>
      </div>
      <div class="inner">
        <table class="table table-sm mt-3 border-bottom">
          <colgroup>
            <col width="50%">
            <col width="17%">
            <col width="20%">
            <col width="auto">
            <col width="auto">
          </colgroup>
          <thead class="thead-light">
            <tr class="fst_inner border-top-bold">
              <th rowspan="2" class="text-center lft_td__color border-right-bold border-bottom-bold border-top-bold">{{ $t('message.title') }}</th>
              <th rowspan="2" class="text-center lft_td__color next_leftTitle border-right-bold border-bottom-bold border-top-bold">{{ $t('message.date') }}</th>
              <th rowspan="2" class="text-center lft_td__color border-right-bold border-bottom-bold border-top-bold">{{ $t('message.inquiryStatus') }}</th>
              <th class="text-center lft_td__color border-bottom-bold border-top-bold">{{ $t('message.delete') }}</th>
            </tr>
            <tr class="fst_inner">
              <th class="text-center lft_td__color border-bottom-bold">{{ $t('message.moreInfo') }}</th>
            </tr>
          </thead>
          <tbody :class="{ 'border-bottom-bold': qnaList.length - 1 === qnaIdx }" v-for="(qna, qnaIdx) in qnaList" :key="qna.idx">
            <tr>
              <td rowspan="2" class="text-left border-right-light border-top-bold" style="word-break:break-all">{{ qna.title }}</td>
              <td rowspan="2" class="text-center border-right-light border-top-bold">{{ qna.date }}</td>
              <td rowspan="2" class="text-center border-right-light border-top-bold">{{ qna.state ? $t('message.answered') : $t('message.answering') }}</td>
              <td class="text-center border-top-bold outline_btn2">
                <span class="blue_f px-1" @click="deleteQna(qna.idx)">{{ $t('message.delete') }}</span>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <button class="btn btn-sm btn-outline-primary" @click="$router.push({ name: 'InquiryDetail', params: { qnaIdx: qna.idx }})">{{ $t('message.moreInfo') }}</button>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td rowspan="4" v-show="!qnaList.length">{{ $t('message.noData') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 내 문의영역/ 테이블 하단 버튼 페이징-->
      <div class="buttonwrap t_center">
        <paginate v-show="qnaList.length" :key="rerenderKey"
          :page-count="pageLast"
          :page-range="3"
          :margin-pages="2"
          :click-handler="getQnaList"
          :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :first-last-button=true
          :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :container-class="'pagination'">
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Inquiry",

  data() {
    return {
      qnaList: [],
      qnaInfo: [],
      rerenderKey: 0,
      pageLast: 0,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.myInquiries'), false, true)
  },

  mounted() {
    this.$parent.showFooter(false)

    this.getQnaList(1)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    getQnaList(page) {
      try {
        let limit = 10
        this.$get(`${this.$USER_MYPAGE_QNA}?page=${page}&limit=${limit}`, 'myPageQnaGetList', true, (result) => {
          this.qnaList = result.data

          this.qnaList.map(qna => {
            qna.date = this.formatDate(qna.createdAt, 'full')
          })

          this.pageLast = Math.ceil(result.total / limit)
        }, (result) => {
          this.notify('error', result.message)
        })
      } catch (e) {
        console.log(e)
      }

    },

    deleteQna(idx) {
      this.$axios_delete(`${this.$USER_MYPAGE_QNA}/${idx}`, 'QnaListDelete', true, (result) => {
        this.notify('success', result.message)
        this.rerenderKey += 1
        this.getQnaList(1)
      }, (result) => {
        this.notify('success', result.message)
      })
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.myInquiries'), false, true)
      }
    },
  },
}
</script>

<style scoped>

</style>
