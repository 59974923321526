<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="informwrap">
        <div class="inform_image">
          <img src="/img/inform.svg" :alt="$t('message.preparing')">
        </div>
        <h6 class="dblue_02 fs24 fwb">{{ $t('message.pagePrepared') }}</h6>
        <p>{{ $t('message.pageReady1') }}</p>
        <p>{{ $t('message.pageReady2') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerSupportUseInfo"
}
</script>

<style scoped>

</style>
