<template>
  <div class="invest_coinsBox invest_trade__box">
    <div class="mt-5">
      <div class="trade_listTable outer">
        <div class="px-2 py-2 clearfix">
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" @change="($event)=>{this.$router.replace({name:$event.currentTarget.value})}">
              <option value="OtcSuggestionMarketList">{{ $t('message.otcMarket') }}</option>
              <option value="OtcSuggestionHistoryList">{{ $t('message.tradingHis') }}</option>
              <option value="OtcSuggestionChatList" selected>{{ $t('message.otcChat') }}</option>
              <option value="OtcSuggestionMypostList">{{ $t('message.myPost') }}</option>
            </select>
          </div>
        </div>
        <div class="otc_listBox mx-2">
          <table class="table otc_table mb-0 table-bordered">
            <colgroup>
              <col style="width: auto;">
              <col style="width: 20%;">
              <col style="width: 43%;">
              <col style="width: 12%;">
            </colgroup>
            <thead class="thead-light">
            <tr>
              <th rowspan="2" class="p-1">{{ $t('message.sort') }}</th>
              <th class="p-1">{{ $t('message.date') }}</th>
              <th rowspan="2" class="p-1">{{ $t('message.content') }}</th>
              <th class="p-1">{{ $t('message.oppoNick') }}</th>
            </tr>
            </thead>
            <tbody v-for="(chat, idx) in chatList" :key="'chat' + idx" @click="goToChatroom(chat)">
            <tr>
              <td rowspan="2" class="p-1">{{ !chat.coin.includes('UNLISTED') ? chat.coin : chat.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}
                <span :class="{ 'price_red': chat.boardIsBuy, 'price_blue': !chat.boardIsBuy }">{{ chat.boardIsBuy ? $t('message.doBuy') : $t('message.doSell') }}</span>
              </td>
              <td class="p-1" height=25>{{ chat.createdAt }}</td>
              <td rowspan="2" class="p-1">{{ chat.content !== 'file' ? chat.content : $t('message.fileAttach') }}</td>
              <td class="p-1" height=25>{{ chat.oppositeNickname }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="buttonwrap t_center">
          <paginate
              :page-count="pageLast"
              :page-range="3"
              :margin-pages="2"
              :click-handler="getList"
              :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :first-last-button=true
              :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OtcSuggestionChatList",

  data() {
    return {
      pageLast: 0,
      chatList: [],
      limit: 10,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.otcMarket'), false, false)
    this.getList(1)
  },

  mounted() {
    this.$parent.focusMenu(3)
  },

  methods: {
    goToChatroom(chat) {
      this.$router.push({ name: 'OtcSuggestionChatDetail',
        params: {
          'chatroomIdx': chat.chatroomIdx,
          'post': chat,
          'tabNo': 2
        }
      })
    },

    getList(page) {
      let formData = new FormData()
      formData.append('limit', this.limit)
      formData.append('page', page)

      this.$post(this.$OTC_TEMP_CHATROOM_LIST, 'otcTempMyListForChat', formData, true, (result) => {
        this.pageLast = Math.ceil(result.total / this.limit)
        this.chatList = result.data

        this.chatList.forEach(chat => {
          chat.createdAt = this.formatDate(chat.createdAt, 'full')
        })
      }, (result) => {
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>

</style>
