<template>
  <div class="cv-container-sub cv-level-wrap py-3 otc-claim">
    <div class="px-3 inq-regist">
      <h5 class="sell-color">{{ $t('message.claimTit') }}</h5>
      <table class="table_inquiry">
        <colgroup>
          <col style="width: 14%;">
          <col style="width: 86%;">
        </colgroup>
        <tr>
          <th class="t_left">{{ $t('message.nickname') }}</th>
          <td><input type="text" :value="nickname" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.contact') }}</th>
          <td><input type="text" :value="phone" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.email') }}</th>
          <td><input type="text" :value="email" disabled></td>
        </tr>
        <tr>
          <th class="t_left">
            {{ $t('message.content') }}
            <p class="price_red mb-0">{{ $t('message.required') }}</p>
          </th>
          <td>
            <textarea :placeholder="$t('message.enterContentClaim')" v-model="claimInput.contents" v-show="!claimInfo.contents"></textarea>
            <textarea :placeholder="$t('message.enterContentClaim')" v-html="claimInfo.contents" v-show="claimInfo.contents" disabled></textarea>
          </td>
        </tr>
        <tr>
          <th class="t_left">
            {{ $t('message.file') }}
          </th>
          <td>
            <div class="file">
              <div v-show="!claimInfo.contents">
                <input type="text" :placeholder="$t('message.doAttach')" v-model="claimInput.fileName" disabled>
                <button class="btn btn-point position-relative">
                  <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
                  <a>{{ $t('message.attach') }}</a>
                </button>
              </div>
              <img class="w-100" v-show="claimInfo.fileLink" :src="claimInfo.fileLink" alt="claim">
            </div>
          </td>
        </tr>
      </table>
      <button class="btn btn-point col-12 mb-5" @click="claim()">{{ $t('message.registration') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtcClaim",

  data() {
    return {
      nickname: '',
      phone: '',
      email: '',
      tradeIdx: this.$route.params.tradeIdx,
      claimInfo: {
        contents: '',
        fileHash: '',
        fileLink: ''
      },
      claimInput: {
        contents: '',
        fileName: '',
        fileList: {}
      },
      didClaim: false,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.claimTit'), false, true)

    if(!this.tradeIdx) {
      this.$router.back()
      return
    }

    this.$get(`${this.$OTC_TEMP_CLAIM}?tradeIdx=${this.tradeIdx}`, 'OtcTempClaimGet', true, (result) => {
      if(result.data[0]) {
        this.claimInfo = result.data[0]
        if(this.claimInfo.fileHash) {
          this.getFile()
        }
        this.didClaim = true
      }
    }, (result) => {
      console.log(result)
    })

    this.getUserProfile((result) => {
      if(result.success) {
        this.nickname = result.userProfile.nickName
        this.phone = result.userProfile.phone
        this.email = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.$parent.focusMenu(3)
  },

  methods: {
    getFile() {
      this.$download(`${this.$IMAGE}?key=${this.claimInfo.fileHash}`, 'GetClaimImg_' + Math.random(), false, (result) => {
        this.claimInfo.fileLink = result.config.url
        this.$forceUpdate()
      }, (result) => {
        console.log(result)
      })
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.claimInput.fileName = file[0].name
      this.claimInput.fileList = file[0]
    },

    claim() {
      if(this.didClaim) {
        this.notify('error', this.$t('message.alreadyClaim'))
        return
      }

      if(!this.claimInput.contents) {
        this.notify('error', this.$t('message.enterContent'))
        return
      }

      let formData = new FormData()
      this.claimInput.fileList ? formData.append('file', this.claimInput.fileList) : ''
      formData.append('contents', this.claimInput.contents)
      formData.append('tradeIdx', this.tradeIdx)

      this.$post(this.$OTC_TEMP_CLAIM, 'OtcTempClaim', formData, true, (result) => {
        console.log(result)
        this.notify('error', this.$t('message.doneClaim'))
        this.$router.push({ name: 'OtcSuggestionHistoryList' })
      }, (result) => {
        console.log(result)
      })
    },
  },
}
</script>

<style scoped>

</style>
