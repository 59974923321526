<template>
  <div class="cv-market cv-container-sub">

    <Modal ref="Modal">
      <div>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.coinName') }}</dt>
          <dd class="wallet_form_text t_left dblue_02">{{ getCoinFullName(priCurr) }}({{ priCurr }})</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.tradeOrderType') }}</dt>
          <dd class="wallet_form_text t_left">{{ orderType === 'limit' ? $t('message.limit') : orderType === 'marketPrice' ? $t('message.marketPrice') : $t('message.StopLimit') }}</dd>
        </dl>
        <dl class="wallet_form2" v-show="orderType !== 'marketPrice'">
          <dt class="wallet_form_tit">{{ $t('message.orderMarketPrice') }}</dt>
          <dd class="wallet_form_text t_left">{{ modalTrade === 'buy' ? subDec(buyPrice, secCurr) : subDec(sellPrice, secCurr) | numberWithCommas }}<em>{{ secCurr }}</em></dd>
        </dl>
        <dl class="wallet_form2" v-show="orderType !== 'marketPrice'">
          <dt class="wallet_form_tit">{{ $t('message.orderQuantity') }}</dt>
          <dd class="wallet_form_text t_left">{{ modalTrade === 'buy' ? buyQuantity : sellQuantity }}<em>{{ priCurr }}</em></dd>
        </dl>
        <dl class="wallet_form2" v-show="!(modalTrade === 'sell' && orderType === 'marketPrice')">
          <dt class="wallet_form_tit">{{ $t('message.orderPrice') }}</dt>
          <dd class="wallet_form_text t_left">
            {{ modalTrade === 'buy' ? subDec(buyTotal, secCurr) : subDec(sellTotal, secCurr) | numberWithCommas }}
            <em>{{ secCurr }}</em>
          </dd>
        </dl>
        <dl class="wallet_form2" v-show="modalTrade === 'sell' && orderType === 'marketPrice'">
          <dt class="wallet_form_tit">{{ $t('message.totalOrderPrice') }}</dt>
          <dd class="wallet_form_text t_left">{{ sellQuantity | numberWithCommas }} {{ priCurr }}</dd>
        </dl>
      </div>
    </Modal>

    <div class="market_priceinfo d-flex bg-gray-100 align-items-baseline py-3">
      <span class="mb-0 mr-auto">
          <span class="h5 p-0 font-weight-bold">
            <span id="trade_info_price">{{ subDec(currentPrice, secCurr) | numberWithCommas }}</span><small> {{ secCurr }}</small>
          </span>
      </span>
      <span>
        {{ $t('message.netChange') }}
        <span :class="{ price_red: changeRatio > 0, price_blue: changeRatio < 0 }">{{ changeRatio > 0 ? '+' : '' }}{{ changeRatio }} %</span>
      </span>
    </div>
    <ul class="maintab">
      <li class="maintab_li" :class="{ active: mtab === 0 }" @click="onTabClick(0)">{{ $t('message.order') }}</li>
      <li class="maintab_li" :class="{ active: mtab === 1 }" @click="onTabClick(1)">{{ $t('message.marketTrades') }}</li>
      <li class="maintab_li" :class="{ active: mtab === 2 }" @click="onTabClick(2)">{{ $t('message.chart') }}</li>
      <li class="maintab_li" :class="{ active: mtab === 3 }" @click="onTabClick(3)">{{ $t('message.history') }}</li>
    </ul>

    <div class="tab-content">
      <!-- 주문 -->
      <div class="div-ctt tab-pane" :class="{ active: mtab === 0 }">
        <div class="row mx-0">
          <div class="col-6 px-0">
            <div class="hoga_table" id="orderBook-con">
              <div class="hoga-sell">
                <table class="table">
                  <colgroup>
                    <col width="53%">
                    <col width="47%">
                  </colgroup>
                  <tbody id="orderBook-body">
                  <tr class="in" v-for="n in orderSell.length < orderBookLength ? (orderBookLength - orderSell.length) : 0" :key="n + Math.random()">
                    <td class="text-right"></td>
                    <td class="text-right"></td>
                  </tr>
                  <tr class="in" v-for="sell in orderSell" :key="sell.price">
                    <td class="text-right" @click="setPrice(sell.price)">
                      <span :class="{ 'price_red': sell.perc > 0, 'price_blue': sell.perc < 0 }" >{{ subDec(sell.price, secCurr) | numberWithCommas }}</span><br/>
                      <span :class="{ 'price_red': sell.perc > 0, 'price_blue': sell.perc < 0 }" class="d-block">{{ sell.perc > 0 ? '+' + subDec(sell.perc) : subDec(sell.perc) }}%</span>
                    </td>
                    <td class="text-right">
                      <span class="text-muted">{{ sell.fills | numberWithCommas }}</span>
                      <div>
                        <div class="progress">
                          <div class="progress-bar progress-bar-danger"
                               role="progressbar"
                               style="height: 54%; position: absolute; right: 0; top: 6px; z-index: 1; opacity: 0.1;"
                               aria-valuenow="25"
                               aria-valuemin="0"
                               aria-valuemax="100"
                               data-value="0">
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!--end 호가 리스트 10개-->
                  </tbody>
                </table>
              </div>
              <div class="hoga-buy">
                <table class="table">
                  <colgroup>
                    <col width="53%">
                    <col width="47%">
                  </colgroup>
                  <tbody class="hoga-buy-t">
                  <tr class="in" v-show="buyIdx < orderBookLength" v-for="(buy, buyIdx) in orderBuy" :key="buy.price">
                    <td class="text-right" @click="setPrice(buy.price)">
                      <span :class="{ 'price_red': buy.perc > 0, 'price_blue': buy.perc < 0 }">{{ subDec(buy.price, secCurr) | numberWithCommas }}</span><br/>
                      <span :class="{ 'price_red': buy.perc > 0, 'price_blue': buy.perc < 0 }" class="d-block">{{ buy.perc > 0 ? '+' + subDec(buy.perc) : subDec(buy.perc) }}%</span>
                    </td>
                    <td class="text-right">
                      <span class="text-muted text-right">{{ buy.fills | numberWithCommas }}</span>
                      <div>
                        <div class="progress">
                            <div class="progress-bar progress-bar-danger"
                                 role="progressbar"
                                 style="height: 54%; position: absolute; right: 0; top: 6px; z-index: 1; opacity: 0.1; background-color:red;"
                                 aria-valuenow="25"
                                 aria-valuemin="0"
                                 aria-valuemax="100"
                                 data-value="0">
                            </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="in" v-for="n in orderBuy.length < orderBookLength ? (orderBookLength - orderBuy.length) : 0" :key="n + Math.random()">
                    <td class="text-right"></td>
                    <td class="text-right"></td>
                  </tr>
                  <!--end 호가 리스트 10개-->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6 px-0">
            <!-- 매도 / 매수 / 거래내역 탭 -->
            <ul class="nav nav-tabs nav-fill market_tab1">
              <li class="nav-item" @click="tabTrade('buy')"><a class="a-order-tab nav-link" :class="{ active: tradeType === 'buy' }">{{ $t('message.buy') }}</a></li>
              <li class="nav-item" @click="tabTrade('sell')"><a class="a-order-tab nav-link" :class="{ active: tradeType === 'sell' }">{{ $t('message.sell') }}</a></li>
            </ul>
            <!-- 매도 / 매수 / 거래내역 컨텐츠 -->
            <div class="tab-content">
              <!-- 구분-->
              <div class="px-2 pt-1 pb-0">
                <div class="form-group row mx-0 mb-0">
                  <label class="col-12 col-form-label">{{ $t('message.sort') }}</label>
                  <div class="dropdown market_radio col-12" @click="typeDropdown = !typeDropdown">
                    <button class="btn btn-block btn-outline-secondary dropdown-toggle text-left" type="button">
                      {{ orderType === 'limit' ? $t('message.limit') : orderType === 'marketPrice' ? $t('message.marketPrice') : $t('message.StopLimit') }}
                    </button>
                    <div class="dropdown-menu m-0" :class="{ active: typeDropdown }">
                      <a class="dropdown-item a-sell-type" @click="orderType = 'limit'">{{ $t('message.limit') }}</a>
                      <a class="dropdown-item a-sell-type" @click="orderType = 'marketPrice'">{{ $t('message.marketPrice') }}</a>
                      <a class="dropdown-item a-sell-type" @click="orderType = 'stopLimit'">{{ $t('message.StopLimit') }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 매수 -->
              <div class="tab-pane fade px-2" :class="{ show: tradeType === 'buy', active: tradeType === 'buy' }">
                <div>
                  <div class="form-horizontal">
                    <!-- 잔고 고정값 -->
                    <div class="form-group row mx-0 mb-2">
                      <label class="col-form-label col-12 mt-2">{{ $t('message.balance') }}</label>
                      <div class="col-12 market_feesbox">
                        <div class="input-group">
                          <div class="form-control text-right">{{ subDec(mySecondBal, secCurr) | numberWithCommas }}</div>
                        </div>
                      </div>
                    </div>
                    <!-- 매수 예약 지정가 -->
                    <div class="trade_type div-sell-box" style="display: none;" v-show="orderType === 'stopLimit'">
                      <div class="form-group row mx-0 mb-2">
                        <label class="col-form-label col-12 pt-0">{{ $t('message.stop') }}</label>
                        <div class="col-12">
                          <div class="input-group">
                            <input type="text" class="form-control text-right" placeholder="0" autocomplete="off" v-model="buyWatchPrice">
                            <button class="btn ipt_comBtn btn-sm bg-white rounded-0 border-left-0" type="button" @click="decreaseBuyWatchPrice">
                              -
                            </button>
                            <button class="btn ipt_comBtn ipt_pls_btn btn-sm bg-white ipt_rnd_con border-left-0" type="button" @click="increaseBuyWatchPrice">
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 매수 지정가 -->
                    <div class="trade_type div-sell-box" v-show="orderType === 'limit' || orderType === 'stopLimit'">
                      <div class="form-group row mx-0 mb-2">
                        <label class="col-form-label col-12 pt-0">{{ $t('message.price') }}</label>
                        <div class="col-12">
                          <div class="input-group">
                            <input type="text" class="form-control text-right" placeholder="0" autocomplete="off" v-model="buyPrice"/>
                            <button class="btn ipt_comBtn btn-sm bg-white rounded-0 border-left-0"
                                    type="button"
                                    @click="decreaseBuyPrice"
                            >
                              -
                            </button>
                            <button class="btn ipt_comBtn ipt_pls_btn btn-sm bg-white ipt_rnd_con border-left-0"
                                    type="button"
                                    @click="increaseBuyPrice"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div class="col-12" style="margin-top: 10px;">
                          <div class="form-group row mx-0 mb-2">
                            <label class="col-form-label col-3">{{ $t('message.qty') }}</label>
                            <div class="dropdown market_radio col-9" @click="isQtyDropdown = !isQtyDropdown">
                              <button class="btn btn-block btn-outline-secondary dropdown-toggle text-left" type="button" style="background: #eee;">
                                {{ qtyRatio !== 0 ? `${qtyRatio}%` : $t('message.enter') }}
                              </button>
                              <div class="dropdown-menu" :class="{ active: isQtyDropdown }">
                                <a class="dropdown-item" @click="qtyRatio = 0">{{ $t('message.enter') }}</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'buy', 10)">10%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'buy', 25)">25%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'buy', 50)">50%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'buy', 100)">100%</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <input type="text" class="form-control text-right" :placeholder="$t('message.quantity')" autocomplete="off" v-model="buyQuantity"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 매수 시장가 -->
                    <div class="trade_type div-sell-box" style="display: none;" v-show="orderType === 'marketPrice'">
                      <div class="form-group row mx-0 mb-2">
                        <div class="col-12" style="margin-top: 10px;">
                          <div class="form-group row mx-0 mb-2">
                            <label class="col-form-label col-12">{{ $t('message.estiBuyAmt') }}</label>
                            <div class="dropdown market_radio col-12" @click="isEstiDropdown = !isEstiDropdown">
                              <button class="btn btn-block btn-outline-secondary dropdown-toggle text-left" type="button" style="background: #eee;">
                                {{ qtyRatio !== 0 ? `${qtyRatio}%` : $t('message.enter') }}
                              </button>
                              <div class="dropdown-menu" :class="{ active: isEstiDropdown }">
                                <a class="dropdown-item" @click="qtyRatio = 0">{{ $t('message.enter') }}</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'buy', 10)">10%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'buy', 25)">25%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'buy', 50)">50%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'buy', 100)">100%</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 수수료 및 합계 -->
                    <div>
                      <label class="col-form-label col-12">{{ $t('message.transactionPrice') }}({{ secCurr }})</label>
                      <div class="col-12">
                        <div class="input-group">
                          <input type="text" :key="rerenderKey" class="form-control text-right" v-model="buyTotal" @change="changeBuyTotal" />
<!--                          <span class="form-control text-right" v-else>{{ subDec(buyTotal, secCurr) }}</span>-->
                        </div>
                      </div>
                    </div>
                    <div class="bottom_btn mt-2 d-flex input-lg">
                      <button type="button" class="btn btn-secondary btn-block p-0 m-0" @click="inputValInit('buy')">
                        <span class="visible-lg">{{ $t('message.reset') }}</span>
                      </button>
                      <button type="button" class="btn btn-danger btn-block text-white p-0 m-0" @click="doTrade('buy')">{{ $t('message.buy') }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 매도 -->
              <div class="tab-pane fade px-2" :class="{ show: tradeType === 'sell', active: tradeType === 'sell' }">
                <div class="form-input-sell" id="sell">
                  <div class="form-horizontal">
                    <!-- 잔고 고정값 -->
                    <div class="form-group row mx-0 mb-2">
                      <label class="col-form-label col-12 mt-2">{{ $t('message.balance') }}</label>
                      <div class="col-12 market_feesbox">
                        <div class="input-group">
                          <div class="form-control text-right">{{ subDec(myPrimaryBal, priCurr) | numberWithCommas }}</div>
                        </div>
                      </div>
                    </div>
                    <!-- 매도 예약 지정가 -->
                    <div class="trade_type " style="display: none;" v-show="orderType === 'stopLimit'">
                      <div class="form-group row mx-0 mb-2">
                        <label class="col-form-label col-12 pt-0">{{ $t('message.stop') }}</label>
                        <div class="col-12">
                          <div class="input-group">
                            <input type="text" class="form-control text-right" placeholder="0" autocomplete="off" v-model="sellWatchPrice">
                            <button class="btn ipt_comBtn btn-sm bg-white rounded-0 border-left-0"
                                    type="button"
                                    @click="decreaseSellWatchPrice">
                              -
                            </button>
                            <button class="btn ipt_comBtn ipt_pls_btn btn-sm bg-white ipt_rnd_con border-left-0"
                                    type="button"
                                    @click="increaseSellWatchPrice">
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 매도 지정가 -->
                    <div class="trade_type" v-show="orderType === 'limit' || orderType === 'stopLimit'">
                      <div class="form-group row mx-0 mb-2">
                        <label class="col-form-label col-12 pt-0">{{ $t('message.price') }}</label>
                        <div class="col-12">
                          <div class="input-group">
                            <input type="text" class="form-control text-right" placeholder="0" autocomplete="off" v-model="sellPrice"/>
                            <span class="unit hidden">KRW</span>
                            <input type="hidden" class="form-control text-right" :placeholder="$t('message.price')" autocomplete="off">
                            <span class="unit hidden">KRW</span>
                            <button class="btn ipt_comBtn btn-sm bg-white rounded-0 border-left-0"
                                    type="button"
                                    @click="decreaseSellPrice">
                              -
                            </button>
                            <button class="btn ipt_comBtn ipt_pls_btn btn-sm bg-white ipt_rnd_con border-left-0"
                                    type="button"
                                    @click="increaseSellPrice">
                              +
                            </button>
                          </div>

                        </div>
                        <div class="col-12" style="margin-top: 10px;">
                          <div class="form-group row mx-0 mb-2">
                            <label class="col-form-label col-3">{{ $t('message.qty') }}</label>
                            <div class="dropdown market_radio col-9" @click="isQtyDropdown = !isQtyDropdown">
                              <button class="btn btn-block btn-outline-secondary dropdown-toggle text-left" type="button" style="background: #eee;">
                                {{ qtyRatio !== 0 ? `${qtyRatio}%` : $t('message.enter') }}
                              </button>
                              <div class="dropdown-menu" :class="{ active: isQtyDropdown }">
                                <a class="dropdown-item" @click="qtyRatio = 0">{{ $t('message.enter') }}</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'sell', 10)">10%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'sell', 25)">25%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'sell', 50)">50%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'sell', 100)">100%</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <input type="text" class="form-control text-right" placeholder="수량" autocomplete="off" v-model="sellQuantity"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 매도 시장가 -->
                    <div class="trade_type " style="display: none;" v-show="orderType === 'marketPrice'">
                      <div class="form-group row mx-0 mb-2">
                        <div class="col-12" style="margin-top: 10px;">
                          <div class="form-group row mx-0 mb-2">
                            <label class="col-form-label col-12">{{ $t('message.estiSellAmt') }}</label>
                            <div class="dropdown market_radio col-12" @click = "isEstiDropdown = !isEstiDropdown">
                              <button class="btn btn-block btn-outline-secondary dropdown-toggle text-left" type="button" style="background: #eee;">
                                {{ qtyRatio !== 0 ? `${qtyRatio}%` : $t('message.enter') }}
                              </button>
                              <div class="dropdown-menu" :class="{ active: isEstiDropdown }">
                                <a class="dropdown-item" @click="qtyRatio = 0">{{ $t('message.enter') }}</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'sell', 10)">10%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'sell', 25)">25%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'sell', 50)">50%</a>
                                <a class="dropdown-item" @click="setPriceQuick($event, 'sell', 100)">100%</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 수수료 및 합계 -->
                    <div>
                      <label class="col-form-label col-12">{{ $t('message.transactionPrice') }}({{ orderType === 'marketPrice' ? priCurr : secCurr }})</label>
                      <div class="col-12">
                        <div class="input-group">
                          <input type="text" :key="rerenderKey" class="form-control text-right" v-model="sellTotal" v-if="orderType !== 'marketPrice'" @change="changeSellTotal"/>
                          <input type="text" class="form-control text-right" v-model="sellQuantity" v-if="orderType === 'marketPrice'" />
<!--                          <span class="form-control text-right" v-else>{{ subDec(sellTotal, secCurr) }}</span>-->
                        </div>
                      </div>
                    </div>
                    <!-- 버튼 -->
                    <div class="bottom_btn mt-2 d-flex input-lg">
                      <button type="button" class="btn btn-secondary btn-block p-0 m-0" @click="inputValInit('sell')">
                        <span class="visible-lg">{{ $t('message.reset') }}</span>
                      </button>
                      <button type="button" class="btn btn-primary btn-block text-white p-0 m-0" @click="doTrade('sell')">{{ $t('message.sell') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 호가 -->
      <div class="div-ctt tab-pane" :class="{ active: mtab === 1 }">
        <div class="mx-0">
              <!-- 거래정보 -->
          <ul class="p-2 trans_info m-0 mb-1">
            <li>
              <div class="">
                <div class="text-left">{{ $t('message.high') }}</div>
                <div class="text-right up">
                  <div class="market_high">{{ high }}</div>
                </div>
              </div>
              <div class="">
                <div class="text-left">{{ $t('message.low') }}</div>
                <div class="text-right down">
                  <div class="market_low">{{ low }}</div>
                </div>
              </div>
            </li>
            <li>
              <div class="">
                <div class="text-left font-weight-bold">{{ $t('message.totalOrderAmt') }}</div>
                <div class="text-right">
                  <span class="market_volume"></span>{{ orderBookTotal }}<sub class="text-muted"> {{ priCurr }}</sub>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="px-3">
          <!-- 테이블 헤더 -->
          <div class="mk_t_header">
            <table class="basic_mk basic_line">
              <colgroup>
                <col style="width: 20%;">
                <col style="width: 40%;">
                <col style="width: 40%;">
              </colgroup>
              <thead>
              <tr>
                <th rowspan="2" class="text-center" style="border-right : 1px solid #e9ecef;" scope="col">{{ $t('message.tradedTime') }}</th>
                <th scope="col" class="text-center" style="border-bottom : 1px solid #e9ecef;">{{ $t('message.price') }}({{ secCurr }})</th>
                <th rowspan="2" class="text-center" style="border-left : 1px solid #e9ecef;" scope="col">{{ $t('message.transactionPrice') }}</th>
              </tr>
              <tr>
                <th scope="col" class="text-center">{{ $t('message.quantity') }}({{ priCurr }})</th>
              </tr>
              </thead>
            </table>
          </div>
          <!-- 리스트 -->
          <div class="mk_t_content">
            <table class="basic_mk_con basic_line">
              <colgroup>
                <col style="width: 20%;">
                <col style="width: 40%;">
                <col style="width: 40%;">
              </colgroup>
              <tbody v-for="(trade, tradeIdx) in traded.slice().reverse()" :key="tradeIdx">
              <tr>
                <td rowspan="2" style="border-bottom : 1px solid #e9ecef;" class="text-center">{{ trade.date }}</td>
                <td class="t_right">{{ trade.price | numberWithCommas }}</td>
                <td rowspan="2" :class="{ price_red: trade.isBuy, price_blue: !trade.isBuy }" style="border-bottom : 1px solid #e9ecef;" class="t_right">
                  {{ subDec((trade.price * trade.tradedAmount), secCurr) | numberWithCommas }}
                </td>
              </tr>
              <tr>
                <td class="t_right" style="border-bottom : 1px solid #e9ecef;">{{ trade.tradedAmount | numberWithCommas }}</td>
              </tr>
              </tbody>
              <tbody v-show="!traded.length">
              <tr>
                <td colspan="3" class="text-center" v-show="$store.getters.getLoginState">{{ $t('message.noHistory') }}</td>
                <td colspan="3" class="border-right-none text-center" v-show="!$store.getters.getLoginState">{{ $t('message.doLogin') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- 차트 -->
      <div class="div-ctt tab-pane" :class="{ active: mtab === 2 }">
        <div class="accordion-body__contents chart_wrap">
          <div class="coin_chart pt-2" style="background: #eceff2;padding-bottom: 33px;">
            <div id="chart"></div>
          </div>
        </div>
      </div>
      <!-- 결재내역 -->
      <div class="div-ctt tab-pane" :class="{ active: mtab === 3 }">
        <div class="accordion-body__contents chart_wrap" style="height: 472px;">
          <div>
            <!-- 거래내역 -->
            <div class="mylist px-2">
              <!-- 거래내역 -->
              <ul class="nav nav-tabs py-3 justify-content-center subtab">
                <li class="nav-item py-0 w-50" @click="tabHistory(0)"><a class="nav-link pt-1 pb-1" :class="{ active: showMyTraded }">{{ $t('message.recentHistory') }}</a></li>
                <li class="nav-item py-0 w-50" @click="tabHistory(1)"><a class="nav-link pt-1 pb-1" :class="{ active: !showMyTraded }">{{ $t('message.openOrders') }}</a></li>
              </ul>
              <div class="tab-content">
                <div class="invest_trade__box">
                  <div class="trade_listTable outer">
                    <div class="d-flex justify-content-between align-items-center px-2 pb-3">
                      <span class="h5 mb-0">{{ showMyTraded ? $t('message.recentHistory') : $t('message.openOrders') }}</span>
                    </div>
                    <div class="inner">
                      <table v-show="showMyTraded">
                        <thead>
                          <tr class="fst_inner">
                            <th rowspan="2" class="lft_td__color next_hardTitle">{{ $t('message.time') }}</th>
                            <th class="lft_td__color">{{ $t('message.sort') }}</th>
                            <th rowspan="2" class="lft_td__color">{{ $t('message.price') }}({{ secCurr }})</th>
                            <th rowspan="2" class="rit_td__color next_hardTitle">{{ $t('message.tradedAmt') }}({{ priCurr }})</th>
                          </tr>
                          <tr class="fst_inner">
                            <th class="lft_td__color">{{ $t('message.tradedPrice') }}({{ secCurr }})</th>
<!--                            <th class="lft_td__color">{{ $t('message.orderQuantity') }}({{ priCurr }})</th>-->
                          </tr>
                        </thead>
                        <tbody :class="{ 'border-bottom-bold': myTradedList.length - 1 === tradedListIdx }"
                               v-for="(tradedList, tradedListIdx) in myTradedList" :key="tradedListIdx"
                        >
                        <tr>
                          <td rowspan="2">{{ tradedList.date }}</td>
                          <td :class="{price_red: tradedList.isBuy === 'buy', price_blue: tradedList.isBuy === 'sell'}">{{ $t(`message.${tradedList.isBuy}`) }}</td>
                          <td rowspan="2">{{ subDec(tradedList.price, secCurr) | numberWithCommas }}</td>
                          <td rowspan="2" class="border-right-none">{{ subDec(tradedList.tradedAmount, priCurr) |  numberWithCommas }}</td>
                        </tr>
                        <tr>
                          <td class="border-top-light">{{ tradedList.tradedValue | numberWithCommas }}</td>
<!--                          <td class="border-top-light">{{ subDec(tradedList.initAmount, priCurr) | numberWithCommas }}</td>-->
                        </tr>
                        </tbody>
                        <tbody v-show="!myTradedList.length">
                          <tr>
                            <td colspan="4" class="text-center" v-show="$store.getters.getLoginState">{{ $t('message.noHistory') }}</td>
                            <td colspan="4" class="border-right-none text-center" v-show="!$store.getters.getLoginState">{{ $t('message.doLogin') }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table v-show="!showMyTraded">
                        <thead>
                          <tr class="fst_inner">
                            <th rowspan="2" class="lft_td__color next_hardTitle">{{ $t('message.orderTime') }}</th>
                            <th class="lft_td__color">{{ $t('message.orderPrice') }}</th>
                            <th class="lft_td__color">{{ $t('message.orderQuantity') }}</th>
                            <th rowspan="2" class="rit_td__color border-bottom-bold">{{ $t('message.cancel') }}</th>
                          </tr>
                          <tr class="fst_inner">
                            <th class="lft_td__color">{{ $t('message.sort') }}</th>
                            <th class="lft_td__color">{{ $t('message.remaining') }}</th>
                          </tr>
                        </thead>
                        <tbody :class="{ 'border-bottom-bold': myTradeWaitList.length - 1 === waitListIdx }" v-for="(waitList, waitListIdx) in myTradeWaitList" :key="waitListIdx">
                          <tr>
                            <td rowspan="2">{{ waitList.date }}</td>
                            <td>{{ subDec(waitList.price, secCurr) }}</td>
                            <td>{{ waitList.initAmount }}</td>
                            <td rowspan="2" class="text-center border-right-none">
                              <button class="btn" @click="cancelOrder(waitList.exid)">{{ $t('message.cancel') }}</button>
                            </td>
                          </tr>
                          <tr>
                            <td :class="{price_red: waitList.isBuy === 'buy', price_blue: waitList.isBuy === 'sell'}">{{ $t(`message.${waitList.isBuy}`) }}</td>
                            <td>{{ waitList.remainAmount | numberWithCommas }}</td>
                          </tr>
                        </tbody>
                        <tbody v-show="!myTradeWaitList.length">
                          <tr>
                            <td colspan="4" v-show="$store.getters.getLoginState" class="border-right-none text-center">{{ $t('message.noHistory') }}</td>
                            <td colspan="4" v-show="!$store.getters.getLoginState" class="border-right-none text-center">{{ $t('message.doLogin') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datafeed from '@/commonjs/datafeed'
import Socket from '../commonjs/socket'
import Modal from '@/components/cmp/modal/Modal'
import Big from 'big-js'

export default {
  name: "General_Exchange",

  data() {
    return {
      marketPair: this.$route.params.marketPair,
      feeInfo: this.$route.params.feeInfo,
      tradeType: 'buy',
      orderSell: [],
      orderBuy: [],
      orderBookLength: 10,
      orderType: 'limit',
      buyPrice: '',
      sellPrice: '',
      isQtyDropdown: false,
      qtyRatio: 0,
      isEstiDropdown: false,
      mySecondBal: 0,
      myPrimaryBal: 0,
      buyTotal: '',
      sellTotal: '',
      buyQuantity: '',
      sellQuantity: '',
      buyWatchPrice: '',
      sellWatchPrice: '',
      buyFee: '',
      sellFee: '',
      modalTrade: 'buy',
      currentPrice: 0,
      changeRatio: 0,
      priCurr: '',
      secCurr: '',
      traded: [],
      high: '0',
      low: '0',
      orderBookTotal: 0,
      showMyTraded: true,
      myTradedList: [],
      myTradeWaitList: [],
      marketList: [],
      typeDropdown: false,
      mtab: 0,
      dataToSend: {
        type: '',
        pair: [],
        here: '',
        message: 0,
      },
      previousPrice: 0,
      rgx: /^[0-9]*\.?[0-9]*$/, // 정수 및 소수점 형식만 허용
      rerenderKey: 0,
      minBuyPrice: 0,
      minSellPrice: 0,
      minBuyAmount: 0,
      minSellAmount: 0,
      setFlag: false,
      interval: null,
      loaded: true,
    }
  },

  components: {
    'Modal': Modal,
  },

  created() {
    if(this.marketPair) {
      sessionStorage.setItem('tradePair', this.marketPair)
    } else {
      this.marketPair = sessionStorage.getItem('tradePair')
    }

    this.priCurr = this.marketPair.split('-')[0]
    this.secCurr = this.marketPair.split('-')[1]

    let headerTit = this.getCoinFullName(this.marketPair.split('-')[0]) + '(' + this.marketPair.replace('-', '/') + ')'

    this.$parent.setHeaderTitle(headerTit, true, false)

    Socket.$on("message", this.handleMessage)

    this.buyFee = this.$t('message.chkAfterLogin')
    this.sellFee = this.$t('message.chkAfterLogin')
    if(this.$store.state.isLoggedIn) {
      this.$get(`${this.$USER_MYPAGE_FEE}?marketType=SPOT&primary=${this.priCurr}&secondary=${this.secCurr}`, 'getUserFee', true, (result) => {
        let data = result.data[0]
        this.buyFee = Number(data.feeRateBuy) * 100 + '%'
        this.sellFee = Number(data.feeRateSell) * 100 + '%'
      }, (result) => {
        console.log(result)
      })
    }

    this.interval = setInterval(() => {
      this.loadBalance()
    }, 1000)

    this.$get(`${this.$HOME_MARKET}?secondary=${this.secCurr}`, 'MainGetHomeMarket', false, (result) => {
      this.marketList = result.data
      this.marketList.forEach((market) => {
        if (market.marketPair === this.marketPair) {
          this.previousPrice = this.subDec(market.lastPrice, this.marketPair.split('-')[1])
          this.currentPrice = this.subDec(market.latest ? market.latest : market.present, this.marketPair.split('-')[1])
        }
      })
      this.sendData()
    }, (result) => {
      console.log(result)
    })

    let marketPairs = JSON.parse(sessionStorage.getItem('marketPairs'))
    if(!marketPairs) {
      return
    }
    marketPairs.forEach(market => {
      if(market.pair === this.marketPair) {
        this.minBuyPrice = Number(market.buyMinValue)
        this.minSellPrice = Number(market.sellMinValue)
        this.minBuyAmount = Number(market.minBuyAmount)
        this.minSellAmount = Number(market.minSellAmount)
      }
    })
  },

  mounted() {
    this.$parent.focusMenu(0)
    localStorage.setItem('focusMenu', 0)

    // 스크롤 중간 위치
    let resizeObserver = new ResizeObserver(function() {
      if(document.getElementById('orderBook-con')) {
        let orderBook = document.getElementById('orderBook-con')
        orderBook.scrollTop = orderBook.scrollHeight / 2 - orderBook.clientHeight / 2
      }
    })
    resizeObserver.observe(document.getElementById('orderBook-body'))

    let tabNo = localStorage.getItem('tradeTab')
    if(!tabNo) {
      tabNo = 1
    }
    tabNo = Number(tabNo)
    this.onTabClick(tabNo)

    this.createChart(localStorage.getItem('lang'))
    this.getMyTradedList()
  },

  methods: {
    loadBalance() {
      if(!this.$store.getters.getLoginState) {
        return
      }

      if(!this.loaded) {
        return
      }

      this.loaded = false

      this.getMyBalance((result) => {
        if(result.success) {
          this.myPrimaryBal = result.myBalance.available
        }
      }, this.priCurr)

      this.getMyBalance((result) => {
        if(result.success) {
          this.mySecondBal = result.myBalance.available
        }
      }, this.secCurr)

      this.loaded = true
    },
    increaseBuyWatchPrice() {
      if(!this.buyWatchPrice) {
        this.buyWatchPrice = 0
      }
      this.buyWatchPrice = new Big(this.buyWatchPrice)
      this.buyWatchPrice = this.buyWatchPrice.plus(100)
    },

    decreaseBuyWatchPrice() {
      if(this.buyWatchPrice > 100) {
        this.buyWatchPrice = new Big(this.buyWatchPrice)
        this.buyWatchPrice = this.buyWatchPrice.minus(100)
      }
    },

    increaseBuyPrice() {
      if(!this.buyPrice) {
        this.buyPrice = 0
      }
      this.buyPrice = new Big(this.buyPrice)
      this.buyPrice = this.buyPrice.plus(100)

      this.changeBuyPrice()
    },

    decreaseBuyPrice() {
      if(this.buyPrice > 100) {
        this.buyPrice = new Big(this.buyPrice)
        this.buyPrice = this.buyPrice.minus(100)
      }

      this.changeBuyPrice()
    },

    increaseSellWatchPrice() {
      if(!this.sellWatchPrice) {
        this.sellWatchPrice = 0
      }
      this.sellWatchPrice = new Big(this.sellWatchPrice)
      this.sellWatchPrice = this.sellWatchPrice.plus(100)
    },

    decreaseSellWatchPrice() {
      if (this.sellWatchPrice > 100) {
        this.sellWatchPrice = new Big(this.sellWatchPrice)
        this.sellWatchPrice = this.sellWatchPrice.minus(100)
      }
    },

    increaseSellPrice() {
      if(!this.sellPrice) {
        this.sellPrice = 0
      }
      this.sellPrice = new Big(this.sellPrice)
      this.sellPrice = this.sellPrice.plus(100)

      this.changeSellPrice()
    },

    decreaseSellPrice() {
      if(this.sellPrice > 100) {
        this.sellPrice = new Big(this.sellPrice)
        this.sellPrice = this.sellPrice.minus(100)
      }

      this.changeSellPrice()
    },

    changeBuyQuantity() {
      if(this.buyQuantity * 1 <= 0) {
        return
      }

      if(this.orderType === 'marketPrice' || !this.buyQuantity || (!this.buyPrice && !this.buyTotal)) {
        return
      }

      if(this.buyPrice * 1 > 0) {
        this.buyTotal = this.subDec(Number(this.buyQuantity) * Number(this.buyPrice), this.secCurr)
      }

      this.buyPrice = this.subDec(this.buyPrice, this.secCurr)

      // this.rerenderKey += 1
    },

    changeSellQuantity() {
      if(this.sellQuantity * 1 <= 0) {
        return
      }

      if(this.orderType === 'marketPrice' || !this.sellQuantity || (!this.sellPrice && !this.sellTotal)) {
        return
      }

      if(this.sellPrice * 1 > 0) {
        this.sellTotal = this.subDec(Number(this.sellQuantity) * Number(this.sellPrice), this.secCurr)
      }

      this.sellPrice = this.subDec(this.sellPrice, this.secCurr)

      // this.rerenderKey += 1
    },

    changeBuyPrice() {
      if(this.buyPrice * 1 <= 0) {
        return
      }

      if(this.orderType === 'marketPrice' || !this.buyPrice || (!this.buyTotal && !this.buyQuantity)) {
        return
      }

      if(this.buyQuantity) {
        this.buyTotal = this.subDec(Number(this.buyQuantity) * Number(this.buyPrice), this.secCurr)
      }

      // this.buyQuantity = this.subDec(this.buyTotal / this.buyPrice, this.secCurr)
      //
      // this.rerenderKey += 1
    },

    changeSellPrice() {
      if(this.sellPrice * 1 <= 0) {
        return
      }

      if(this.orderType === 'marketPrice' || !this.sellPrice || (!this.sellTotal && !this.sellQuantity)) {
        return
      }

      if(this.sellQuantity) {
        this.sellTotal = this.subDec(Number(this.sellQuantity) * Number(this.sellPrice), this.secCurr)
      }

      // this.sellQuantity = this.subDec(this.sellTotal / this.sellPrice, this.secCurr)
      //
      // this.rerenderKey += 1
    },


    changeBuyTotal() {
      if(this.buyPrice * 1 <= 0 && this.buyQuantity * 1 > 0) {
        // this.buyPrice = this.subDec(this.buyTotal / this.buyQuantity, this.secCurr)
      } else if(this.buyPrice * 1 > 0) {
        this.buyQuantity = this.subDec(Number(this.buyTotal) / Number(this.buyPrice), this.secCurr)
      }

      // this.rerenderKey += 1
    },

    changeSellTotal() {
      if(this.sellPrice * 1 <= 0 && !this.sellQuantity * 1 > 0) {
        // this.sellPrice = this.subDec(this.sellTotal / this.sellQuantity, this.secCurr)
      } else if(this.sellPrice * 1 > 0) {
        this.sellQuantity = this.subDec(Number(this.sellTotal) / Number(this.sellPrice), this.secCurr)
      }

      // this.rerenderKey += 1
    },

    setPrice(price) {
      let priceWithDec = this.subDec(price, this.secCurr)

      this.buyPrice = priceWithDec
      this.buyWatchPrice = priceWithDec
      this.sellPrice = priceWithDec
      this.sellWatchPrice = priceWithDec
    },


    cancelOrder(orderNo) {
      this.$get(`${this.$ORDER_SPOT_CANCEL}?exid=${orderNo}`, 'genaralMarketOrderCancel', true, (result) => {
        this.notify('success', result.message)
        this.getMyTradeWaitList()
      }, (result) => {
        console.log(result)
      })
    },

    tabHistory(tabNo) {
      if(tabNo === 0) {
        this.showMyTraded = true
        this.getMyTradedList()
      } else {
        this.showMyTraded = false
        this.getMyTradeWaitList()
      }
    },

    getMyTradeWaitList() {
      let accessToken = localStorage.getItem('accessToken')
      if(accessToken) {

        this.$get(`${this.$ORDER_SPOT_LIST}?size=10&marketPair=${this.marketPair}`, 'generalMarketGetSpotList', true, (result) => {
          this.myTradeWaitList = result.data
          this.myTradeWaitList.map(list => {
            list.date = this.formatDate(list.date, 'full')
            list.isBuy ? list.isBuy = 'buy' : list.isBuy = 'sell'
            list.initAmount = this.toFloat(list.initAmount)
            list.price = this.toFloat(list.price)
            list.remainAmount = this.toFloat(list.remainAmount)
          })
        }, (result) => {
          this.notify('error', result.message)
        })
      }
    },

    getMyTradedList() {
      let accessToken = localStorage.getItem('accessToken')
      if(accessToken) {
        this.$get(`${this.$ORDER_SPOT_LIST_TRADED}?size=10&marketPair=${this.marketPair}`, 'generalMarketGetSpotListTraded', true, (result) => {
          this.myTradedList = result.data
          if(this.myTradedList.length) {
            this.myTradedList.map(list => {
              // let date = list.exid.split(':')[5]
              // list.date = this.formatDate(Number(date), 'full')
              list.date = this.formatDate(list.createdAt, 'full')
              list.isBuy ? list.isBuy = 'buy' : list.isBuy = 'sell'
              list.initAmount = list.exid.split(':')[4]
              list.feeAmount = list.feeAmount ? list.feeAmount : 0
            })
          }
        }, (result) => {
          console.log(result)
        })
      }
    },

    createChart(locale) {
      let res = sessionStorage.getItem('chartResolution')
      if(!res) {
        res = '1'
      }

      Datafeed.onReady(() => {
        (window.tvWidget = new window.TradingView.widget({
          symbol: `UPBIT:${this.marketPair}`,
          datafeed: Datafeed,
          interval: res,
          // time_frames: [
          //   {text: "1y", resolution: "1W"},
          //   {text: "6m", resolution: "3D"},
          //   {text: "3m", resolution: "1D"},
          //   {text: "1m", resolution: "1D"},
          //   {text: "1w", resolution: "30"},
          //   {text: "3d", resolution: "30"},
          //   {text: "1d", resolution: "30"},
          //   {text: "6h", resolution: "15"},
          //   {text: "1h", resolution: "1"}],
          container_id: "chart",
          library_path: "/js/trading_view/charting_library/",
          locale: locale,
          // disabled_features: ["use_localstorage_for_settings"],
          chartsStorageUrl: "https://saveload.tradingview.com",
          chartsStorageApiVersion: "1.1",
          client_id: 'tradingview.com',
          user_id: 'public_user_id',
          fullscreen: false,
          autosize: true,
          disabled_features: [
            "header_compare",
          ],
          enabled_features: [
            "dont_show_boolean_study_arguments",
            "use_localstorage_for_settings",
            "remove_library_container_border",
            "save_chart_properties_to_local_storage",
            "side_toolbar_in_fullscreen_mode",
            "hide_last_na_study_output",
            "constraint_dialogs_movement",
            // "study_templates"
          ],
          studies_overrides: {
            "volume.volume.color.0": "#e95514",
            "volume.volume.color.1": "#63c0ce",
            "volume.volume.transparency": 75,
          },
          // loading_screen:{ backgroundColor: "#00ff00",foregroundColor: "#000000", },
          // timeframe:'',
          // toolbar_bg: "#20334d",
          // allow_symbol_change: false,
          timezone: 'UTC+9',
          debug: false,
          overrides: {
            "mainSeriesProperties.showCountdown": true,
            "paneProperties.background": "#fff",
            "paneProperties.vertGridProperties.color": "#E6EFF4",
            "paneProperties.horzGridProperties.color": "#E6EFF4",
            "symbolWatermarkProperties.transparency": 90,
            "scalesProperties.textColor": "#AAA",
            "mainSeriesProperties.candleStyle.wickUpColor": "#3e7881",
            "mainSeriesProperties.candleStyle.wickDownColor": "#9c390d",
            "mainSeriesProperties.candleStyle.upColor": "#63c0ce",
            "mainSeriesProperties.candleStyle.downColor": "#e95514",
            "mainSeriesProperties.candleStyle.borderUpColor": "#63c0ce",
            "mainSeriesProperties.candleStyle.borderDownColor": "#e95514"
          }
        }))
      })
    },

    doTrade(trade) {
      if(!this.$store.getters.getLoginState) {
        this.notify('error', this.$t('message.doLogin'))
        return
      }

      if(trade === 'buy') {
        if(!this.mySecondBal) {
          this.notify('error', this.$t('message.noBal'))
          return
        }
      } else {
        if(!this.myPrimaryBal) {
          this.notify('error', this.$t('message.noBal'))
          return
        }
      }

      if(trade === 'buy' && !this.buyTotal) {
        this.notify('error', this.$t('message.chkPriceAndAmt'))
        return
      }

      if(trade === 'sell' && !this.sellTotal && this.orderType !== 'marketPrice') {
        this.notify('error', this.$t('message.chkPriceAndAmt'))
        return
      }

      if(trade === 'sell' && !this.sellQuantity && this.orderType === 'marketPrice') {
        this.notify('error', this.$t('message.enterTotalOrder'))
        return
      }

      if(trade === 'buy' && this.orderType !== 'marketPrice' && (!Number(this.buyTotal) || !Number(this.buyPrice) || !Number(this.buyQuantity))) {
        this.notify('error', this.$t('message.chkPriceAndAmt'))
        return
      } else if(trade === 'sell' && this.orderType !== 'marketPrice' && (!Number(this.sellTotal) || !Number(this.sellPrice) || !Number(this.sellQuantity))) {
        this.notify('error', this.$t('message.chkPriceAndAmt'))
        return
      }

      if(trade === 'buy') {
        if(this.minBuyPrice > this.buyTotal) {
          this.notify('error', this.$t('message.chkMinPrice'))
          return
        } else if(this.minBuyAmount > this.buyQuantity && this.orderType !== 'marketPrice') {
          this.notify('error', this.$t('message.chkMinAmt'))
          return
        }
      }

      if(trade === 'sell') {
        if(this.minSellPrice > this.sellTotal && this.orderType !== 'marketPrice') {
          this.notify('error', this.$t('message.chkMinPrice'))
          return
        } else if(this.minSellAmount > this.sellQuantity) {
          this.notify('error', this.$t('message.chkMinAmt'))
          return
        }
      }

      this.modalTrade = trade

      this.$refs.Modal.showDialog(this.$t('message.orderConfirm'), this.$t(`message.${trade}`), this.$t('message.close'), () => {
        let isBuy
        let price
        let amount
        let type = this.orderType === 'limit' ? 'LIMIT' : this.orderType === 'marketPrice' ? 'MARKET' : 'RESERVE'
        let stopPrice
        if(trade === 'buy') {
          isBuy = true
          price = this.orderType === 'marketPrice' ? '' : this.buyPrice
          amount = this.orderType === 'marketPrice' ? this.buyTotal : this.buyQuantity
          stopPrice = this.orderType === 'stopLimit' ? this.buyWatchPrice : ''
        } else {
          isBuy = false
          price = this.orderType === 'marketPrice' ? '' : this.sellPrice
          amount = this.sellQuantity
          stopPrice = this.orderType === 'stopLimit' ? this.sellWatchPrice : ''
        }
        let formData = new FormData()
        formData.append('marketPair', this.marketPair)
        formData.append('isBuy', isBuy)
        formData.append('price', price.toString())
        formData.append('initAmount', amount.toString())
        formData.append('orderType', type)
        formData.append('stopPrice', stopPrice.toString())

        this.$post(this.$ORDER_SPOT, 'generalMarketOrderSpotSell', formData, true, (result) => {
          this.notify('success', result.message)

          if(trade === 'buy') {
            this.buyPrice = ''
            this.buyQuantity = ''
            this.buyTotal = ''
          } else {
            this.sellPrice = ''
            this.sellQuantity = ''
            this.sellTotal = ''
          }


        }, (result) => {
          if(result.message === 'order.fail.qualified') {
            this.notify('error', this.$t('message.orderBlocked'))
          } else {
            this.notify('error', result.message)
          }
        })
      }, ()=>{}, true, trade === 'buy' ? 'red' : '')
    },

    setPriceQuick(e, type, percentage) {
      this.qtyRatio = percentage

      if(type === 'buy') {
        if(this.orderType !== 'marketPrice' && !this.buyPrice) {
          this.notify('error', this.$t('message.enterOrderPrice'))
          return
        }

        if(this.mySecondBal) {
          if(percentage === 100) {
            this.buyTotal = this.mySecondBal
          } else if(percentage === 50) {
            this.buyTotal = 0.5 * this.mySecondBal
          } else if(percentage === 25) {
            this.buyTotal = 0.25 * this.mySecondBal
          } else if(percentage === 10) {
            this.buyTotal = 0.1 * this.mySecondBal
          } else {
            this.buyTotal = ''
          }
        }

        this.buyTotal = this.subDec(this.buyTotal, this.secCurr)
        this.buyQuantity = this.subDec(Number(this.buyTotal) / Number(this.buyPrice), this.secCurr)

        if(!isFinite(this.buyQuantity) || isNaN(this.buyQuantity)) {
          this.buyQuantity = ''
        }
      }

      if(type === 'sell') {
        if(this.orderType !== 'marketPrice' && !this.sellPrice) {
          this.notify('error', this.$t('message.enterOrderPrice'))
          return
        }

        if(this.myPrimaryBal) {
          if(percentage === 100) {
            this.sellQuantity = this.myPrimaryBal
          } else if (percentage === 50) {
            this.sellQuantity = 0.5 * this.myPrimaryBal
          } else if (percentage === 25) {
            this.sellQuantity = 0.25 * this.myPrimaryBal
          } else if (percentage === 10) {
            this.sellQuantity = 0.1 * this.myPrimaryBal
          } else {
            this.sellQuantity = ''
          }
        }

        this.sellQuantity = this.subDec(this.sellQuantity, this.priCurr)
        if(!isFinite(this.sellQuantity) || isNaN(this.sellQuantity)) {
          this.sellQuantity = ''
        }

        this.sellTotal = this.subDec(Number(this.sellPrice) * Number(this.sellQuantity), this.secCurr)
      }
    },

    inputValInit(tradeType) {
      this.qtyRatio = 0
      if(tradeType === 'buy') {
        this.buyPrice = ''
        this.buyQuantity = ''
        this.buyTotal = ''
        this.buyWatchPrice = ''
      } else {
        this.sellPrice = ''
        this.sellQuantity = ''
        this.sellTotal = ''
        this.sellWatchPrice = ''
      }
    },

    sendData() {
      let pairDataToSend = []
      this.marketList.forEach(market => {
        pairDataToSend.push(market.marketPair)
      })

      if(!pairDataToSend.includes(this.pair)) {
        pairDataToSend.push(this.pair)
      }

      this.dataToSend = {
        type: 'set',
        pair: pairDataToSend,
        here: this.marketPair,
        message: this.orderBookLength.toString(),
      }
      let uint8array = new TextEncoder("utf-8").encode(JSON.stringify(this.dataToSend))
      Socket.send(uint8array)
    },

    onTabClick(tabNo) {
      this.mtab = tabNo
      localStorage.setItem('tradeTab', tabNo)
    },

    tabTrade(tradeType) {
      if(tradeType === 'buy') {
        this.tradeType = 'buy'
      } else {
        this.tradeType = 'sell'
      }
    },

    handleMessage(msg) {
      let promise = new Promise(resolve => {
        resolve(msg.text())
      })
      promise.then((value) => {
        let jsonData = JSON.parse(value)

        if (jsonData.t === 'p') {
          // price
          this.getPriceData(jsonData.p, jsonData.m[0])
        } else if(jsonData.t === 'o') {
          // orderBook
          this.getOrderBookData(jsonData)
        } else if (jsonData.t === 't') {
          // traded
          this.getTradedData(jsonData)
        }
      })
    },

    getTradedData(jsonData) {
      if(jsonData.p !== this.marketPair) {
        this.traded = []
        return
      }

      if(this.traded.length > 50) {
        this.traded.splice(0, 1)
      }

      jsonData.m[0].d = this.formatDate(jsonData.m[0].d, 'time')

      let data = jsonData.m[0]
      this.traded.push({
        exid: data.e,
        marketPair: data.m,
        isBuy: data.i,
        price: data.p,
        tradedAmount: data.ta,
        feeAmount: data.fa,
        date: data.d
      })
    },

    getPriceData(pair, price) {
      if(this.marketPair === pair) {
        if(this.setFlag) {
          this.currentPrice = price
        }

        this.setFlag = true;

        this.marketList.forEach((market) => {
          if(market.marketPair === this.marketPair) {
            this.changeRatio = (Number(price) - Number(market.lastPrice)) / Number(market.lastPrice) * 100
            this.changeRatio = Number(this.subDec(this.changeRatio))
            this.high = this.subDec(market.high, this.secCurr)
            this.low = this.subDec(market.low, this.secCurr)
          }
        })
      }
    },

    getOrderBookData(jsonData) {
      if(jsonData.p !== this.marketPair) {
        this.orderBuy = []
        this.orderSell = []
        return
      }

      let currentPrice = new Big(jsonData.m[0].p)

      let previousPrice = new Big(this.previousPrice)
      let calculated = 0
      try{
        calculated = (currentPrice.minus(previousPrice)).div(previousPrice) * 100
      } catch (e) {
        console.log(e)
      }

      let orderInfo = {
        fills: jsonData.m[0].f,
        isBuy: jsonData.m[0].i,
        price: jsonData.m[0].p,
        perc: calculated
      }

      if (orderInfo.fills === '0') {
        if (orderInfo.isBuy === 'true') {
          let tempOrderBuy = [...this.orderBuy]
          for (let i=0; i< this.orderBuy.length; i++) {
            let currentOrder = this.orderBuy[i]
            if (orderInfo.price === currentOrder.price) {
              tempOrderBuy.splice(i, 1)
            }
          }
          this.orderBuy = tempOrderBuy
        } else {
          let tempOrderSell = [...this.orderSell]
          for (let i=0; i< this.orderSell.length; i++) {
            let currentOrder = this.orderSell[i]
            if (orderInfo.price === currentOrder.price) {
              tempOrderSell.splice(i, 1)
            }
          }
          this.orderSell = tempOrderSell
        }
      } else if(orderInfo.isBuy === 'true') {
        let isAdd = false
        for (let i=0; i< this.orderBuy.length; i++) {
          let currentOrder = this.orderBuy[i]
          if (orderInfo.price === currentOrder.price) {
            this.orderBuy[i].fills = orderInfo.fills
            isAdd = true
          }
        }
        if (!isAdd) {
          this.orderBuy.push(orderInfo)
        }
        this.orderBuy = this.sortByPrice(this.orderBuy)
        // if (this.orderBuy.length > this.orderBookLength) {
        //   this.orderBuy = this.orderBuy.slice(0, this.orderBookLength)
        // }
      } else {
        let isAdd = false
        for (let i=0; i< this.orderSell.length; i++) {
          let currentOrder = this.orderSell[i]
          if (orderInfo.price === currentOrder.price) {
            this.orderSell[i].fills = orderInfo.fills
            isAdd = true
          }
        }
        if (!isAdd) {
          this.orderSell.push(orderInfo)
        }
        this.orderSell = this.sortByPrice(this.orderSell)
        if (this.orderSell.length > this.orderBookLength) {
          this.orderSell = this.orderSell.slice(this.orderSell.length - this.orderBookLength, this.orderBookLength + (this.orderSell.length - this.orderBookLength))
          // this.orderSell = this.orderSell.slice(this.orderSell.length - this.orderBookLength - 1, this.orderBookLength)
        }
      }

      // 주문전략 합계
      let totalBuy = 0
      let totalSell = 0
      this.orderBuy.forEach(buy => {
        totalBuy += Number(buy.fills)
      })
      this.orderSell.forEach(sell => {
        totalSell += Number(sell.fills)
      })
      this.orderBookTotal = totalBuy + totalSell
      this.orderBookTotal = this.subDec(this.orderBookTotal, this.priCurr)
    },

    sortByPrice(arr) {
      arr.sort(function (a, b) {
        if (Number(a.price) > Number(b.price)) {
          return -1
        }
        if (Number(a.price) < Number(b.price)) {
          return 1
        }
        return 0
      })
      return arr
    },
  },

  beforeDestroy() {
    this.$parent.showFooter(true)

    Socket.$off("message", this.handleMessage)
    Datafeed.unsubscribeBars(null)

    clearInterval(this.interval)
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        if(this.$root.$i18n.locale !== 'cn') {
          this.createChart(this.$root.$i18n.locale)
        } else {
          this.createChart('zh')
        }
      }
    },

    'buyPrice': {
      handler() {
        if(!this.buyPrice.toString().match(this.rgx)) {
          this.buyPrice = this.buyPrice.toString().slice(0, this.buyPrice.length - 1)
        }

        if(this.buyTotal && this.buyQuantity) {
          this.buyQuantity = this.subDec(this.buyQuantity, this.secCurr)
        }

        this.changeBuyPrice()
      }
    },

    'sellPrice': {
      handler() {
        if(!this.sellPrice.toString().match(this.rgx)) {
          this.sellPrice = this.sellPrice.toString().slice(0, this.sellPrice.length - 1)
        }

        if(this.sellTotal && this.sellQuantity) {
          this.sellQuantity = this.subDec(this.sellQuantity, this.secCurr)
        }

        this.changeSellPrice()
      }
    },

    'buyWatchPrice': {
      handler() {
        if(!this.buyWatchPrice.toString().match(this.rgx)) {
          this.buyWatchPrice = this.buyWatchPrice.toString().slice(0, this.buyWatchPrice.length - 1)
        }
      }
    },

    'sellWatchPrice': {
      handler() {
        if(!this.sellWatchPrice.toString().match(this.rgx)) {
          this.sellWatchPrice = this.sellWatchPrice.toString().slice(0, this.sellWatchPrice.length - 1)
        }
      }
    },

    'buyQuantity': {
      handler() {
        if(!this.buyQuantity.toString().match(this.rgx)) {
          this.buyQuantity = this.buyQuantity.toString().slice(0, this.buyQuantity.length - 1)
        }

        this.changeBuyQuantity()
      }
    },

    'sellQuantity': {
      handler() {
        if(!this.sellQuantity.toString().match(this.rgx)) {
          this.sellQuantity = this.sellQuantity.toString().slice(0, this.sellQuantity.length - 1)
        }

        this.changeSellQuantity()
      }
    },

    'buyTotal': {
      handler() {
        if(!this.buyTotal.toString().match(this.rgx)) {
          this.buyTotal = this.buyTotal.toString().slice(0, this.buyTotal.length - 1)
        }
      }
    },

    'sellTotal': {
      handler() {
        if(!this.sellTotal.toString().match(this.rgx)) {
          this.sellTotal = this.sellTotal.toString().slice(0, this.sellTotal.length - 1)
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
