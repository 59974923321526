<template>
  <footer class="footer_common fixed-bottom" v-if="show">
    <ul class="nav nav-pills nav-fill footer_nav" id="footer-menu">
      <li class="nav-item" @click="$router.push({ name: 'Dashboard' })">
        <a class="nav-link" :class="{ active: menuNo === 0 }">
          <i class="xi-home"></i>
          <p class="mb-0">{{ $t('message.exchange') }}</p>
        </a>
      </li>
      <li class="nav-item" @click="$router.push({ name: 'HistoryGeneral' })">
        <a class="nav-link" :class="{ active: menuNo === 1 }">
          <i class="xi-paper-o"></i>
          <p class="mb-0">{{ $t('message.myTradingHistory') }}</p>
        </a>
      </li>
      <li class="nav-item" @click="$router.push({ name: 'CoinList' })">
        <a class="nav-link" :class="{ active: menuNo === 2 }">
          <i class="xi-chip-o"></i>
          <p class="mb-0">{{ $t('message.manageMyAsset') }}</p>
        </a>
      </li>
      <li class="nav-item" @click="$router.push({ name: 'OtcSuggestionMarketList' })">
        <a class="nav-link" :class="{ active: menuNo === 3 }">
          <i class="xi-chart-pie-o"></i>
          <p class="mb-0">{{ $t('message.otcShort') }}</p>
        </a>
      </li>
      <li class="nav-item" @click="$router.push({ name: 'MyInfo' })">
        <a class="nav-link" :class="{ active: menuNo === 4 }">
          <i class="xi-profile-o"></i>
          <p class="mb-0">{{ $t('message.myInfo') }}</p>
        </a>
      </li>
    </ul>
  </footer>
</template>

<script>
import Vue from 'vue'

export default {
  name: "Footer",

  data() {
    return {
      show: true,
      menuNo: 0,
    }
  },

  methods: {
    showFooter(bool) {
      this.show = bool
    },

    focusMenu(menuNo) {
      Vue.nextTick(() => {
        let footerMenu = document.getElementById('footer-menu')
        if(footerMenu === null) {
          return
        }

        this.menuNo = menuNo
      })
    }
  },
}
</script>

<style scoped>

</style>
