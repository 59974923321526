let rp = require('request-promise').defaults({json: true})

const api_root = `${process.env.VUE_APP_DOMAIN}/chart`
const history = {}

export default {
	history: history,
    getBars: function(symbolInfo, resolution, from, to, first) {
		let split_symbol = symbolInfo.name.split(/[:-]/)

		let interval = 'MINUTES'
		if(resolution.indexOf('D') !== -1) {
			interval = 'DAYS'
		} else {
			if(Number(resolution) >= 60) {
				interval = 'HOURS'
			}
		}

		const qs = {
			pair: `${split_symbol[1]}-${split_symbol[2]}`,
			from: from ? from * 1000 : 0,
			to: to ? to * 1000 : 0,
			interval: interval
		}
		return rp({
			url: api_root,
			qs
		}).then(async (res) => {
			let bars = []

			if(res.data.length === 0) {
				return bars
			}

			bars.push({
				time: Number(res.data[0].t),
				low: Number(res.data[0].l),
				high: Number(res.data[0].h),
				open: Number(res.data[0].c),
				close: Number(res.data[0].c),
				volume: Number(res.data[0].v)
			})

			for (let i = 1; i < res.data.length; i++) {
				if (Number(res.data[i - 1].t) === Number(res.data[i].t)) {
					bars[bars.length - 1].volume = Number(bars[bars.length - 1].volume) + Number(res.data[i].v)
				} else {
					bars.push({
						time: Number(res.data[i].t),
						low: Number(res.data[i].l),
						high: Number(res.data[i].h),
						open: Number(res.data[i - 1].c),
						close: Number(res.data[i].c),
						volume: Number(res.data[i].v)
					})
				}
			}

			if (first) {
				let lastBar = bars[bars.length - 1]
				this.history[symbolInfo.name] = {
					lastBar: lastBar
				}
			}

			return bars
		})
	}
}











// const api_root = 'https://min-api.cryptocompare.com'
// const history = {}
//
// export default {
// 	history: history,
//
// 	getBars: function(symbolInfo, resolution, from, to, first, limit) {
// 		let split_symbol = symbolInfo.name.split(/[:-]/)
// 		const url = resolution === 'D' ? '/data/histoday' : resolution >= 60 ? '/data/histohour' : '/data/histominute'
// 		const qs = {
// 			e: split_symbol[0],
// 			fsym: split_symbol[1],
// 			tsym: split_symbol[2],
// 			toTs:  to ? to : '',
// 			limit: limit ? limit : 2000,
// 			// aggregate: 1//resolution
// 		}
// 		console.log('qs', {qs})
//
// 		return rp({
// 			url: `${api_root}${url}`,
// 			qs,

// url: api_root + qs.fsym + '-' + qs.tsym,

// 		})
// 			.then(data => {
// 				console.log('{data}', {data})
// 				if (data.Response && data.Response === 'Error') {
// 					console.log('CryptoCompare API error:',data.Message)
// 					return []
// 				}
// 				if (data.Data.length) {
// 					console.log(`Actually returned: ${new Date(data.TimeFrom * 1000).toISOString()} - ${new Date(data.TimeTo * 1000).toISOString()}`)
// 					console.log('bars', data.Data)
// 					var bars = data.Data.map(el => {
// 						return {
// 							time: el.time * 1000, //TradingView requires bar time in ms
// 							low: el.low,
// 							high: el.high,
// 							open: el.open,
// 							close: el.close,
// 							volume: el.volumefrom
// 						}
// 					})
// 					if (first) {
// 						var lastBar = bars[bars.length - 1]
// 						history[symbolInfo.name] = {lastBar: lastBar}
// 					}
//
// 					return bars
// 				} else {
// 					return []
// 				}
// 			})
// 	}
// }
