<template>
  <div class="cv-container-sub pb-3">
    <div class="px-3 bbs-right">
      <div class="desc_top">
        <h6>{{ $t('message.feeExplain') }}</h6>
        <div class="small">
          <p>{{ $t('message.feeNote1') }}</p>
          <p>{{ $t('message.feeNote2') }}</p>
          <p>{{ $t('message.feeNote3') }}</p>
        </div>
      </div>
      <h6>{{ $t('message.tradeFee') }}</h6>
      <table class="basic">
        <colgroup>
          <col style="width: 25%;">
          <col style="width: 25%;">
          <col style="width: 25%;">
          <col style="width: auto;">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('message.tradingType') }}</th>
            <th>{{ $t('message.sort') }}</th>
            <th>{{ $t('message.buy') }} {{ $t('message.fees') }}</th>
            <th>{{ $t('message.sell') }} {{ $t('message.fees') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(marketPair, idx) in marketPairs" :key="idx">
            <td v-show="marketPair === marketPairs[0]" :rowspan="marketPairs.length">{{ $t('message.exchange') }}</td>
            <td>{{ marketPair.pair.replace('-', ' / ') }}</td>
            <td class="price_red fwm">{{ Number(marketPair.buyFeeRatio) * 100 }}%</td>
            <td class="dblue_02 fwm">{{ Number(marketPair.sellFeeRatio) * 100 }}%</td>
          </tr>
          <tr v-for="(otcMarket, idx) in otcMarketList" :key="idx + Math.random()">
            <td v-show="otcMarket === otcMarketList[0]" :rowspan="otcMarketList.length">OTC</td>
            <td>{{ otcMarket.marketPair.split('-')[0] }}</td>
            <td class="price_red fwm">{{ otcMarket.buyFeeRatio * 100 }}%</td>
            <td class="dblue_02 fwm">{{ otcMarket.sellFeeRatio * 100 }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerSupportFee",

  data() {
    return {
      marketPairs: [],
      otcMarketList: [],
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.feeInfo'), false, true)

    this.marketPairs = JSON.parse(sessionStorage.getItem('marketPairs'))

    this.$get(this.$HOME_LIST_MARKET, 'otcGetListMarketSuggestion', false, (result) => {
      this.otcMarketList = result.data
    }, (result) => {
      console.log(result)
    })
  },
  mounted() {
    this.$parent.showFooter(false)
  }
}
</script>

<style scoped>

</style>
