<template>
  <div class="dots-loader"></div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>
