<template>
  <div class="form-register text-center pb-5">
    <div class="form-horizontal">
      <div class="input-group mb-3">
        <label class="col-12 text-basic text-left px-0 pt-3">{{ $t('message.nickname') }}</label>
        <input type="text" class="form-control" :placeholder="$t('message.nicknameSignUp')" v-model="nickName">
      </div>
      <div class="input-group mb-2 right-inner-addon">
        <label class="col-12 text-basic text-left px-0 pt-1">{{ $t('message.pwd') }}</label>
        <input :type="passwordType" class="form-control" :placeholder="$t('message.pwdLimit')" v-model="pwd">
        <a class="pwi" @click="hiddenPassword"><i class="fa fa-eye fa-lg"></i></a>
      </div>
      <div class="input-group mb-3 right-inner-addon">
        <input :type="passwordConfirmType" class="form-control" :placeholder="$t('message.placeholderPwdConfirm')" v-model="pwdConfirm">
        <a class="pwci" @click="hiddenPasswordConfirm"><i class="fa fa-eye fa-lg"></i></a>
      </div>
      <div class="input-group mb-3">
        <label class="col-12 text-basic text-left px-0 pt-1">{{ $t('message.email') }}</label>
        <input type="text" class="form-control" :placeholder="$t('message.placeholderEmail')" v-model="email">
        <button type="button" class="btn btn-basic btn-md" @click="sendCodeToEmail">{{ $t('message.authCode') }}</button>
      </div>
      <div class="input-group mb-3" v-show="showInputEmailVerify">
        <input type="number" class="form-control verticode-text" :placeholder="$t('message.placeholderPutAuthCode')" v-model="emailVerifyCode"/>
        <div class="d-md-block" @click="confirmEmailCode">
          <button type="button" class="btn btn-basic">{{ $t('message.confirm') }}</button>
        </div>
      </div>
      <div class="cv-alert-txt"></div>
      <div class="input-group mb-3">
        <label class="col-12 text-basic text-left px-0 pt-1">{{ $t('message.phoneNum') }}</label>
        <vue-country-code @onSelect="onSelect" :preferredCountries="['kr', 'cn', 'us']" defaultCountry="kr"></vue-country-code>
        <input type="text" class="form-control phone" :placeholder="$t('message.placeholderPhoneNo')" v-model="phone">
        <button type="button" class="btn btn-basic btn-md" @click="sendCodeToSMS">{{ $t('message.authCode') }}</button>
      </div>
      <div class="input-group mb-3" v-show="showInputSmsVerify">
        <input type="number" class="form-control verticode-text" :placeholder="$t('message.placeholderPutAuthCode')" v-model="smsVerifyCode"/>
        <div class="d-md-block" @click="confirmSmsCode">
          <button type="button" class="btn btn-basic">{{ $t('message.confirm') }}</button>
        </div>
      </div>
      <div class="input-group mb-3">
        <label class="col-12 text-basic text-left px-0 pt-1">{{ $t('message.promotionCode') }}</label>
        <input type="text" class="form-control" :placeholder="$t('message.placeholderPutPromotionCode')" v-model="agentCode" />
      </div>
      <div class="custom-control custom-checkbox mt-2 mr-sm-2 mb-5 pb-5 text-left agree_register">
        <input type="checkbox" class="custom-control-input" id="chkAgree" v-model="chkAgree">
        <label class="custom-control-label text-basic mb-4" for="chkAgree" v-html="$t('message.agreeChk')"></label>
      </div>
      <div class="fixed-bottom text-center">
        <button class="btn btn-lg btn-point btn-block p-3" style="font-size: 15px;" @click="signup">{{ $t('message.signup') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signup",

  data() {
    return {
      nickName: '',
      pwd: '',
      pwdConfirm: '',
      email: '',
      phone: '',
      emailVerifyCode: '',
      smsVerifyCode: '',
      agentCode: '',
      countryCode: 'KR',
      showInputEmailVerify: false,
      showInputSmsVerify: false,
      emailHash: '',
      smsHash: '',
      chkAgree: false,
      passwordType: 'password',
      passwordConfirmType: 'password'
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.signup'), false, true)
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    onSelect({name, iso2, dialCode}) {
      console.log(name, iso2, dialCode)
      this.countryCode = iso2
    },

    signup() {
      if(this.chkAgree) {
        let formData = new FormData()
        formData.append('email', this.email)
        formData.append('nickName', this.nickName)
        formData.append('password', this.pwd)
        formData.append('passwordConfirm', this.pwdConfirm)
        formData.append('code', this.agentCode.toString())
        formData.append('countryCode', this.countryCode)
        formData.append('phone', this.phone)
        formData.append('emailVerifyCode', this.emailHash)
        formData.append('phoneVerifyCode', this.smsHash)

        this.$post(this.$USER_SIGNUP, 'signup', formData, false, (result) => {
          this.notify('success', result.message)
          this.$router.push({ name: 'Login' })
        }, (result) => {
          this.notify('error', result.message)
        })
      } else {
        this.notify('error', this.$t('message.chkTerms'))
      }

    },

    sendCodeToEmail() {
      if(this.email === '') {
        return
      }
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_SIGNUP_EMAIL_SEND, 'signupEmailSend', formData, false, (result) => {
        this.notify('success', result.message)
        this.showInputEmailVerify = true
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    sendCodeToSMS() {
      if(this.phone === '') {
        return
      }
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_SIGNUP_SMS_SEND, 'signupSmsSend', formData, false, (result) => {
        this.notify('success', result.message)
        this.showInputSmsVerify = true
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    confirmEmailCode() {
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('code', this.emailVerifyCode)

      this.$post(this.$USER_SIGNUP_EMAIL_CERT, 'signupEmailCert', formData, false, (result) => {
        this.emailHash = result.data[0].verifyCode
        this.notify('success', this.$t('message.authSuccess'))
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    confirmSmsCode() {
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('code', this.smsVerifyCode.toString())
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_SIGNUP_SMS_CERT, 'signupEmailCert', formData, false, (result) => {
        this.smsHash = result.data[0].verifyCode
        this.notify('success', this.$t('message.authSuccess'))
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    hiddenPassword() {
      if ('password' === this.passwordType) {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },

    hiddenPasswordConfirm() {
      if ('password' === this.passwordConfirmType) {
        this.passwordConfirmType = 'text';
      } else {
        this.passwordConfirmType = 'password';
      }
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.signup'), false, true, 'blue')
      }
    },
  }
}
</script>

<style scoped>
/* 클릭 이벤트로 비밀번호 숨기기 보여주기 */
.right-inner-addon {
  position: relative;
}

.right-inner-addon > input {
}


.right-inner-addon > .pwi {
  position: absolute;
  right: 10px;
  top: 44px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
}

.right-inner-addon > .pwci {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
}

</style>
