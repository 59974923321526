<template>
  <div class="body_wrap">
    <audio id="audio" src="/data/chat.mp3"></audio>
    <div class="sub_container p-2 w-90">
      <div class="otc_purchase_right otc_s_chat">
        <div class="form-group row mx-0 py-2 mb-0">
          <select class="form-control w-100" @change="($event)=>{this.$router.replace({name:$event.currentTarget.value})}">
            <option value="OtcSuggestionMarketList" :selected="tabNo === 0">{{ $t('message.otcMarket') }}</option>
            <option value="OtcSuggestionHistoryList" :selected="tabNo === 1">{{ $t('message.tradingHis') }}</option>
            <option value="OtcSuggestionChatList" :selected="tabNo === 2">{{ $t('message.otcChat') }}</option>
            <option value="OtcSuggestionMypostList"  :selected="tabNo === 3">{{ $t('message.myPost') }}</option>
          </select>
        </div>
        <div class="chatting_con mt-0 mb-1">
          <div class="chat_tit">
            <div>
              <i class="fas fa-comment"></i><span>{{ $t('message.chat') }}</span>
            </div>
          </div>
          <div>
            <div ref="Scroll" class="chatting scroll" style="height: calc(100vh - 420px);">
              <div class="msg_con">
                <p class="fs12 mb-0">{{ formatDate(new Date(), 'full') }}</p>
                <p class="fs16 fwb t_center mb-0" v-if="post">
                  {{ $t('message.writing') }}: {{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '(직접거래)' }}
                  {{ post.boardIsBuy ? $t('message.doBuy') : $t('message.doSell') }}
                  | {{ $t('message.writer') }}: {{ post.writerNickname }}
                </p>
                <p class="fs16 fwb t_center mb-0">{{ $t('message.doConference') }}</p>
              </div>
              <div class="msg_balls">
                <div class="msg_sent p-3" v-for="(msg, msgIdx) in msgAll" :key="msgIdx" :class="{ flex_end: msg.userIdx === myIdx, flex_start: msg.userIdx !== myIdx}">
                  <p class="msg m-0" v-show="msg.comment">{{ msg.comment }}</p>
                  <img v-if="msg.fileHash && msg.fileImage && msg.fileLoaded" :src="msg.fileLink" :alt="msg.fileName" />
                  <p v-if="msg.fileHash && !msg.fileImage" class="msg">{{ msg.fileName }}</p>
                  <div v-if="msg.fileHash && msg.fileLoaded">
                    <a :download="msg.fileName" :href="$DOMAIN + $OTC_TEMP_CHAT_IMAGE + '?chatroomIdx=' + chatroomIdx + '&fileHash=' + msg.fileHash + '&comment=' + chatKey" target="hidden_frame" class="btn btn-point btn-block">{{ $t('message.download') }}</a>
                  </div>
                  <p class="fs12 m-0" :class="{ time_r: msg.userIdx === myIdx, time_l: msg.userIdx !== myIdx}">{{ formatDate(msg.timestamp, 'full') }}</p>
                </div>
              </div>
            </div>
            <div class="msg_tit pl-1">
              <i class="fas fa-comment-dots"></i>
              <span class="ml-1">{{ $t('message.msg') }}</span>
            </div>
            <div class="txt_con">
              <textarea :placeholder="$t('message.enterMsg')" v-model="msg" @keyup.enter="sendTxt"></textarea>
            </div>
            <div class="upload_con" :class="{flex_col: fileName}">
              <div class="left">
                <div class="upload_btn">
                  <input type="file" class="custom-input" @change="upload($event)" accept="*">
                  <i class="fas fa-paperclip mr-1"></i>
                  <a class="m_left5">{{ $t('message.fileAttach') }}</a>
                </div>
                <div v-show="fileName" class="input_con">
                  <input type="text" class="input_file" v-model="fileName" disabled>
                  <span @click="deleteFile"><i class="fas fa-times"></i></span>
                </div>
              </div>
              <div class="middle_btn1 text-center" @click="sendMsg"><a>{{ $t('message.send') }}</a></div>
            </div>
          </div>
        </div>
        <iframe src="about:blank" name="hidden_frame" id="hidden_frame" style="height: 1px;width: 100%; opacity: 0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtcSuggestionChatDetail",

  data() {
    return {
      msg: '',
      post: this.$route.params.post,
      chatroomIdx: this.$route.params.chatroomIdx,
      tabNo: this.$route.params.tabNo,
      msgAll: [],
      chatInterval: null,
      chatIntervalLoaded: false,
      lastTimestamp: 0,
      imgFile: null,
      fileName: '',
      myIdx: 0,
      loaded: false,
      chatKey: '',
      busy: false,
    }
  },

  computed: {
    fileChanged: {
      get: function() {
        return this.fileName
      },
      set: function(newValue) {
        this.fileName = newValue
      }
    },
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.otcMarket'), false, true)

    if(!this.post) {
      this.tabNo = 2
      this.$get(`${this.$OTC_TEMP_CHATROOM_BOARDIDX}?chatroomIdx=${this.chatroomIdx}`, 'getChatroomBoardIdx', true, (result) => {
        this.post = result.data[0]
        this.init()
      }, () => {
        this.$router.back()
      })
    } else {
      this.init()
    }
  },

  beforeDestroy() {
    clearInterval(this.chatInterval)
  },

  mounted() {
    this.$parent.focusMenu(this.tabNo)
  },

  methods: {
    init() {
      this.getChatHistory()

      this.chatInterval = setInterval(() => {
        if(!this.loaded) {
          return
        }
        this.getChatHistory()
      }, 1000)

      this.getUserProfile((result) => {
        if(result.success) {
          this.myIdx = result.userProfile.userIdx
        } else {
          console.log(result.userProfile)
        }
      })
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.fileChanged = file[0].name
      this.imgFile = [...file]
    },

    deleteFile() {
      this.imgFile = []
      this.fileChanged = ''
    },

    sendMsg() {
      if(!this.busy) {
        this.sendTxt()
        this.sendImg()
      } else {
        this.notify('error', this.$t('message.btnBusy'))
      }
    },

    sendTxt() {
      if(this.msg.trim() === '') {
        this.msg = ''
        return
      }

      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('chatroomIdx', this.chatroomIdx)
      formData.append('comment', this.msg)

      this.$post(this.$OTC_TEMP_CHAT_ADD, 'chatAdd', formData, true, (result) => {
        console.log(result)
        this.msg = ''
      }, (result) => {
        console.log(result)
      })
    },

    sendImg() {
      if(!this.imgFile || this.imgFile.length === 0) {
        return
      }

      this.busy = true
      this.notify('success', this.$t('message.imageUploading'))

      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('chatroomIdx', this.chatroomIdx)
      formData.append('file', this.imgFile[0])

      this.$post(this.$OTC_TEMP_CHAT_UPLOAD, 'OtcChattingUpload', formData, true, () => {
        this.busy = false

        this.imgFile = []
        this.fileChanged = ''
      }, (result) => {
        console.log(result)
      })
    },

    getChatHistory() {
      if(!this.chatroomIdx) {
        return
      }

      let formData = new FormData()
      formData.append('chatroomIdx', this.chatroomIdx)
      formData.append('timestamp', this.lastTimestamp + 1)

      this.$post(this.$OTC_TEMP_CHAT_LIST, 'OtpProcessTradeChatDetail', formData, true, (result) => {
        let msgList = result.data
        if(msgList.length) {
          if(this.chatIntervalLoaded) {
            for (let i = 0; i < msgList.length; i++) {
              if (msgList[i].userIdx !== this.myIdx) {
                document.getElementById('audio').play()
                break
              }
            }
          }

          this.modifyChatData(msgList)
          this.chatIntervalLoaded = true
        }
      }, (result) => {
        console.log(result)
      })
    },

    modifyChatData(msgList) {
      msgList.forEach((chat) => {
        if(this.chatKey === '' && chat.chatKey) {
          this.chatKey = chat.chatKey
        }

        chat.fileImage = false
        chat.fileLoaded = false

        if(chat.fileHash !== null) {
          if(!chat.fileLink) {
            let formData = new FormData()
            formData.append('idx', this.post.idx)
            formData.append('chatroomIdx', this.chatroomIdx)
            formData.append('fileHash', chat.fileHash)

            this.$downloadPost(this.$OTC_TEMP_CHAT_DOWNLOAD, 'ChatDownLoad_' + Math.random(), formData, true, (result) => {
              let file = this.$fileDownload(result)

              chat.fileLink = ''
              chat.fileName = file[1]

              let reader = new FileReader()
              reader.readAsDataURL(result.data)
              reader.onloadend = function () {
                chat.fileLink = reader.result
                chat.fileLoaded = true
              }

              if(chat.fileName.lastIndexOf('.') !== -1) {
                let filetype = chat.fileName.substring(chat.fileName.lastIndexOf('.') + 1)
                if(filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png' || filetype === 'gif') {
                  chat.fileImage = true
                }
              }
            }, (result) => {
              this.notify('error', result.message)
            })
          }
        }
      })

      let msgAll = [...this.msgAll, ...msgList]
      msgAll.sort(function(a, b) {
        return a.timestamp - b.timestamp
      })

      this.msgAll = msgAll

      this.lastTimestamp = this.msgAll[this.msgAll.length - 1].timestamp

      this.loaded = true

      setTimeout(() => {
        let scrollBox = this.$refs.Scroll
        scrollBox.scrollTop = scrollBox.scrollHeight
      }, 1000)
    },
  },
}
</script>

<style scoped>

</style>
