<template>
  <div class="cv-container-sub cv-level-wrap pb-3">
    <div class="px-3">
      <p class="mt-2">{{ authDetail.title }}</p>
      <table class="table_m">
        <tr>
          <th class="t_left">{{ $t('message.createdTime') }}</th>
          <td><input type="text" v-model="authDetail.date" disabled></td>
        </tr>
      </table>
      <div class="view_content">
        <p v-html="escapeTag(authDetail.contents)"></p>
        <img v-show="authDetail.file" :src="authDetail.file" :alt="$t('message.file')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "authView",
  data() {
    return {
      authIdx: this.$route.params.articleIdx,
      authDetail: {},
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.auth') + " / OTP", false, true)

    if(this.authIdx) {
      sessionStorage.setItem('authIdx', this.authIdx)
    } else {
      this.authIdx = sessionStorage.getItem('authIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('authIdx')
  },

  mounted() {
    this.$parent.showFooter(false)

    this.$get(`${this.$BOARD_DETAIL}/${this.authIdx}`, this.$options.name + '_mountedDetail', false, (result) => {
      this.authDetail = result.data[0]
      this.authDetail.date = this.formatDate(this.authDetail.createdAt, 'full')
    }, (result) => {
      this.notify('error', result.message)
    })
  },

  methods: {

  },

}
</script>

<style scoped>

</style>
