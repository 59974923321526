<template>
  <div class="cv_wallet_sub">
    <div class="position-relative">
      <div class="col-12 px-0">
        <div class="pl-0">
          <div class="bg-white cv-wallet-tab">
            <div class="wallet-content wallet-deposit-content" style="display: block;">
              <div class="form-group px-3 py-2 mb-0 bg-basicGray" v-show="address !== '0'">
                <label class="first-title mb-0">{{ $t('message.depositDesc2', { coin: getCoinFullName(coin) }) }}</label>
              </div>
              <div class="form-group row mx-0 border-bottom" v-show="address !== '0'">
                <label class="col-8 col-form-label">{{ getCoinFullName(coin) }} {{ $t('message.deposit') }} {{ $t('message.address') }}</label>
              </div>
              <div class="d-flex justify-content-center" v-if="address !== '0'">
                <qrcode :value="address" :options="{ width: 260 }"></qrcode>
              </div>
              <div class="col-12 pb-3" v-show="showAddress">
                <div class="user-qrcode d-flex flex-column align-items-center">
                  <select class="wallet_address_list" v-model="address">
                    <option>{{ address }}</option>
                  </select>
                  <button type="button" class="btn btn-outline-primary btn-block" @click="copyAddress()">{{ $t('message.copy') }}</button>
                </div>
              </div>
              <div class="col-12" v-show="!showAddress">
                <div class="user-qrcode alert alert-dark rounded-0 mt-3" role="alert">
                  <p class="mb-0">{{ $t('message.depNote6') }}</p>
                  <p class="mb-0">{{ $t('message.depNote7') }}</p>
                </div>
                <button type="button" class="btn btn-outline-primary btn-block" @click="makeAddress">{{ $t('message.chkMakeAddress') }}</button>
              </div>
              <div class="px-3 py-3" v-show="showAddress">
                <div class="alert alert-dark rounded-0 mb-0" role="alert">
                  <div class="alert-heading">※{{ $t('message.refNote') }}※</div>
                  <ul class="pl-3">
                    <li v-show="coin === 'UBIT'">{{ $t('message.depNote1') }}</li>
                    <li>{{ $t('message.depNote2', { coinFull: coinFull() }) }}</li>
                    <li>{{ $t('message.depNote3') }}</li>
                    <li v-show="coin !== 'ETH'">{{ $t('message.depNote4') }}</li>
                    <li v-show="coin === 'ETH'">{{ $t('message.depNote5') }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Deposit",

  data() {
    return {
      coin: this.$route.params.coin,
      address: '0',
      showAddress: false,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.doDeposit'), false, true)

    if(this.coin) {
      sessionStorage.setItem('depositCoin', this.coin)
    } else {
      this.coin = sessionStorage.getItem('depositCoin')
    }

    this.getAddress()
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
    sessionStorage.removeItem('depositCoin')
  },

  methods: {
    coinFull() {
      if(this.coin === 'ETH') {
        return 'ETH(ERC20)'
      } else if(this.coin === 'BTC') {
        return 'Bitcoin'
      } else if(this.coin === 'USDT') {
        return 'USDT(ERC20)'
      } else if(this.coin === 'TRX') {
        return 'Tron(TRX)'
      } else if(this.coin === 'UBIT') {
        return 'UBIT'
      } else {
        return this.coin
      }
    },

    copyAddress() {
      let tempInput = document.createElement('input')
      tempInput.value = this.address
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      this.notify('success', this.$t('message.copied'))
    },

    makeAddress() {
      let formData = new FormData()
      formData.append('ticker', this.coin)

      this.$post(this.$WALLET, 'AssetPostMakeWallet', formData, true, () => {
        this.getAddress()
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    getAddress() {
      this.$get(`${this.$WALLET}?ticker=${this.coin}`, 'AssetDetailGetWallet',true, (result) => {
        if(result.data[0]) {
          this.showAddress = true
          if(result.data[0].RPC) {
            this.address = result.data[0].RPC.address
          } else if (result.data[0].RPCSUB) {
            this.address = result.data[0].RPCSUB.address
          } else {
            this.address = '0'
          }
        } else {
          this.showAddress = false
        }
      }, (result) => {
        console.log(result)
      })
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.doDeposit'), false, true)
      }
    },
  }
}
</script>

<style scoped>

</style>
