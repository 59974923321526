<template>
  <div class="invest_coinsBox invest_trade__box">
    <div class="mt-5">
      <div class="trade_listTable outer">
        <div class="h6 mb-0">
          <div>
            <ul class="nav nav-pills nav-fill justify-content-center invest_navBox">
              <li class="nav-item" @click="$router.push({ name: 'OfferList' })">
                <a class="nav-link" :class="{ active: $route.path === '/otc/list' }">OTC</a>
              </li>
              <li class="nav-item" @click="$router.push({ name: 'OtcSuggestionMarketList' })">
                <a class="nav-link" :class="{ active: $route.path === '/otc/suggestion/all' }">OTC 마켓</a>
              </li>
            </ul>
          </div>
        </div>
        <Search
            @changeOfferType="changeOfferType"
            @changePair="changePair"
        />
        <span class="col-12 h5 float-left text-left pb-1" v-if="offerType === 'buy'">{{ $t('message.buy') }} {{ $t('message.offer') }} {{ $t('message.list') }}</span>
        <span class="col-12 h5 float-left text-left pb-1" v-else>{{ $t('message.sell') }} {{ $t('message.offer') }} {{ $t('message.list') }}</span>
        <div class="otc_listBox mx-2">
          <table class="table otc_table mb-0 table-bordered">
            <thead class="thead-light">
              <tr>
                <th rowspan="2" class="p-1">{{ $t('message.nickname') }}</th>
                <th class="p-1">{{ $t('message.tradeLimit') }}</th>
                <th rowspan="2" class="p-1">국가</th>
                <th rowspan="2" class="p-1">{{ $t('message.state') }}</th>
              </tr>
              <tr>
<!--                <th class="p-1">{{ $t('message.unitPrice') }}</th>-->
                <th class="p-1">{{ $t('message.tradePrice') }}</th>
<!--                <th class="p-1">{{ $t('message.payMethod') }}</th>-->
              </tr>
            </thead>
            <tbody v-for="(offer, offerIdx) in offerList" :key="offerIdx">
              <tr>
                <td rowspan="2" class="p-1">{{ offer.nickname }}</td>
                <td class="p-1">{{ offer.remainAmount }} {{ selectedCoin }}</td>
                <td rowspan="2" class="p-1">{{ offer.countryCode | setFullCountryName }}</td>
                <td rowspan="2" class="otc_table_p p-1">
                  <button class="btn btn-outline-basic btn-sm px-1" type="button" @click="goOtcDetail(offer)">{{ $t('message.doTrade') }}</button>
                </td>
              </tr>
              <tr>
<!--                <td class="p-1">{{ subDec(offer.price, selectedCurrency) | numberWithCommas }} {{ selectedCurrency }}</td>-->
                <td class="p-1">{{ offer.exchanges === 'NONE' ? '협상가' : offer.exchanges }} {{ offer.exchanges === 'NONE' ? '' : (offer.markUp >= 0 ? '기준 +' : '기준 ') + (offer.markUp * 100) + '%' }}</td>
<!--                <td class="p-1">{{ offer.price }}%</td>-->
<!--                <td class="p-1">-->
<!--                  <div class="d-flex justify-content-center">-->
<!--                    <img v-show="offer.bank" src="/img/Icon_bank.svg" alt="icon_bank" style="display: block">-->
<!--                    <img v-show="offer.pps" src="/img/Icon_pps.svg" alt="icon_pps" style="display: block">-->
<!--                    <img v-show="offer.wechat" src="/img/Icon_wechat.svg" alt="icon_wechat" style="display: block">-->
<!--                    <img v-show="offer.alipay" src="/img/Icon_alipay.svg" alt="Icon_alipay" style="display: block">-->
<!--                  </div>-->
<!--                </td>-->
              </tr>
            </tbody>
            <tbody v-show="!offerList.length">
              <tr>
                <td colspan="4" class="p-1">{{ $t('message.noHistory') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from './Search'

export default {
  name: "Offer_List",

  data() {
    return {
      offerType: this.$route.params.offerType ? this.$route.params.offerType : 'buy',
      offerList: [],
      selectedCurrency: '',
      selectedCoin: '',
    }
  },

  components: {
    'Search': Search
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.otc'), false, false)
  },

  mounted() {
    this.$parent.focusMenu(3)
    localStorage.setItem('focusMenu', 3)
  },

  filters: {
    setFullCountryName: (value) => {
      if (value === 'KR') {
        return '대한민국'
      } else if(value === 'EN') {
        return '미국'
      } else if(value === 'CN') {
        return '중국'
      } else  {
        return ''
      }
    },
  },

  methods: {
    goOtcDetail(offer) {
      offer.pair = `${this.selectedCoin}-${this.selectedCurrency}`
      offer.offerType = this.offerType
      this.$router.push({ name: 'OfferApply', params: { 'offer': offer }})
    },

    getExchangePrice(offer) {
      if(offer.exchanges === 'BITFINEX') {
        let marketPair = `${this.selectedCoin}usd`
        marketPair = marketPair.toLowerCase()
        this.$get(`${this.$OTC_DISPLAY_TICKER_BITFINEX}/${marketPair}`, 'otcListGetBitfinex_' + offer.offerIdx, true, (result) => {
          let usdPrice = result.data[0].mid
          if(usdPrice && this.selectedCurrency === 'KRW') {
            this.$get(`${this.$OTC_DISPLAY_USDKRW}`, 'otcOfferGetUSDKRW_' + offer.offerIdx, true, (result) => {
              let rate = result.data[0].rate
              offer.price = this.subDec((rate * usdPrice), this.selectedCurrency)

              if(offer.markUp !== '0' && offer.markUp !== null) {
                offer.markUp = Number(offer.markUp)
                offer.price = Number(offer.price)
                offer.price = offer.price + (offer.price * offer.markUp)
              }
            }, (result) => {
              console.log(result)
            })
          } else {
            // 환율계산하는 api 화폐당 더 나와야함
          }
        }, (result) => {
          this.notify('error', result.message)
        })
      } else if (offer.exchanges === 'UPBIT') {
        let marketPair = `${this.selectedCurrency}-${this.selectedCoin}`
        this.$get(`${this.$OTC_DISPLAY_TICKER_UPBIT}/${marketPair}`, 'otcListGetUpbit_' + offer.offerIdx, true, (result) => {
          let midPrice = result.data[0].mid
          if(midPrice && this.selectedCurrency === 'KRW') {
            offer.price = this.subDec(midPrice, this.selectedCurrency)

            if(offer.markUp !== '0' && offer.markUp !== null) {
              offer.markUp = Number(offer.markUp)
              offer.price = Number(offer.price)
              offer.price = offer.price + (offer.price * offer.markUp)
            }
          } else {
            // 환율계산하는 api 화폐당 더 나와야함
          }
        }, (result) => {
          this.notify('error', result.message)
        })
      } else if(offer.exchanges === 'BINANCE') {
        let marketPair = `${this.selectedCoin}${this.selectedCurrency}`
        this.$get(`${this.$OTC_DISPLAY_TICKER_BINANCE}/${marketPair}`, 'otcListGetBinance_' + offer.offerIdx, true, (result) => {
          let midPrice = result.data[0].price
          console.log(midPrice)
        }, (result) => {
          this.notify('error', result.message)
        })
      } else {
        offer.price = 0
      }
    },

    changeOfferType(offerType) {
      this.offerType = offerType
      this.getAvailableList(1)
    },

    changePair(coin, curr) {
      this.selectedCoin = coin
      this.selectedCurrency = curr
      this.getAvailableList(1)
    },

    getAvailableList(page) {
      this.page = page
      if(!this.selectedCurrency || !this.selectedCoin) {
        return
      }

      let offerType
      this.offerType === 'buy' ? offerType = true : offerType = false

      let formData = new FormData()
      let limit = 10

      formData.append('isBuy', offerType)
      formData.append('limit', limit)
      formData.append('page', page)
      formData.append('marketPair', `${this.selectedCoin}-${this.selectedCurrency}`)
      formData.append('sort', 'CREDIT')
      formData.append('direction', 'ASC')

      this.$post(this.$OTC, 'otcGetList', formData,true, (result) => {
        this.offerList = result.data

        this.offerList.forEach((offer) => {
          if(!offer.price) {
            offer.price = 0
            // this.getExchangePrice(offer)
          }
        })
      }, () => {
        this.offerList = []
      })
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.otc'), false, false)
      }
    },
  }
}
</script>

<style scoped>

</style>
