<template>
  <div class="cv-container-sub pb-3">
    <div class="px-3 bbs-right">
      <div class="small">
        <p>{{ $t('message.coinListing1') }}</p>
        <p>{{ $t('message.coinListing2') }}</p>
        <p>{{ $t('message.coinListing3') }}</p>
      </div>
      <h6 class="coin_subtitle dblue_02">{{ $t('message.form') }}</h6>
      <div class="scroll">
        <table class="table_inquiry">
          <tr v-for="(formCol) in formCols" :key="formCol.colName">
            <th class="t_left fwm">
              {{ $t(`message.${formCol.colName}`) }}
              <small class="required">{{ formCol.require ? $t('message.required') : '' }}</small>
            </th>
            <td>
              <input class="input1" :type="formCol.type" v-show="formCol.colName !== 'contents' && formCol.colName !== 'file'" :maxlength="formCol.length.toString()" v-model="formCol.filled">
              <textarea v-show="formCol.colName === 'contents'" :maxlength="formCol.length.toString()" :placeholder="$t('message.enterContent')" v-model="formCol.filled"></textarea>
              <div v-show="formCol.type === 'file'" class="file p-0">
                <input type="file" class="form-control" @change="upload($event)" accept="image/*">
                <span class="pt-2 pb-0">* {{ limitFile(formCol.length) }}</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <button class="btn btn-point form-control input-lg" @click="register">
        {{ $t('message.registration') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerSupportCoinRequest",

  data() {
    return {
      columns: [],
      type: [],
      fileName: '',
      fileList: {},
      formCols: [],
      originalCols: [],
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.requestNewCoin'), false, true)

    this.getForm()
  },
  mounted() {
    this.$parent.showFooter(false)
  },

  methods: {
    getForm() {
      this.formCols = []

      this.$get(`${this.$BOARD_FORM}?code=coin-add`, 'boardForm', false, (result) => {
        let columns = result.data[0]
        let colKeys = Object.keys(columns)
        let colVals = Object.values(columns)

        colKeys.forEach((col, colIdx) => {
          if(col === 'boardType') {
            return
          }

          this.originalCols.push({
            colName: col,
            type: colVals[colIdx].type === 'text' ? 'text' : 'file',
          })

          this.formCols.push({
            colName: col,
            length: colVals[colIdx].length,
            require: colVals[colIdx].require,
            type: colVals[colIdx].type === 'text' ? 'text' : 'file',
            filled: ''
          })
        })

      }, (result) => {
        console.log(result)
      })
    },

    limitFile(leng) {
      return this.$t('message.maxLeng', { leng: leng })
    },

    register() {
      let formData = new FormData()

      let jsonData = {}
      for(let i=0; i<this.originalCols.length; i++) {
        if(this.originalCols[i].type === 'text') {
          jsonData[this.originalCols[i].colName] = this.formCols[i].filled
        } else {
          formData.append(this.originalCols[i].colName, this.fileList)
        }
      }

      console.log(this.originalCols, this.fileList)
      console.log(jsonData)
      formData.append('boardCode', 'coin-add')
      formData.append('request', new Blob([JSON.stringify(jsonData)], {type: "application/json"}))

      this.$post(this.$BOARD_LIST, 'postBoardList', formData, true, (result) => {
        this.notify('success', result.message)
        this.$router.push({ name: 'Main' })
      }, (result) => {
        console.log(result)
        this.notify('error', result.message)
      })
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.fileName = file[0].name
      this.fileList = file[0]
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.getForm()
      }
    },
  }
}
</script>

<style scoped>

</style>
