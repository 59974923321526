<template>
  <div class="cv-container-sub cv-level-wrap pb-3 px-2 py-2 advanced">
    <span class="col-12 h5 price_blue">{{ $t('message.appPush') }}</span>
    <div>
      <div class="ad_con">
        <ul class="ad_list">
          <li>{{ $t('message.isUsed') }}</li>
        </ul>
        <div class="ad_option">
          <label class="label_checkradio">
            <input type="radio" value="1" v-model="appPush.using">
            <span class="radio_icon"></span>
            <span class="radio_text">{{ $t('message.use') }}</span>
          </label>
          <label class="label_checkradio">
            <input type="radio" value="0" v-model="appPush.using"/>
            <span class="radio_icon"></span>
            <span class="radio_text">{{ $t('message.unuse') }}</span>
          </label>
        </div>
      </div>
      <div class="middle_btn1 btn_100 d-flex justify-content-center align-items-center input-lg" @click="setAppPush"><a>{{ $t('message.option') }}</a></div>
    </div>
    <span class="col-12 h5 price_blue">{{ $t('message.smsLang') }}</span>
    <div>
      <div class="ad_con">
        <ul class="ad_list">
          <li>{{ $t('message.lang') }}</li>
        </ul>
        <div class="ad_option">
          <select class="wallet_address_list" v-model="smsLang">
            <option v-if="smsLang === ''" value="">{{ $t('message.unuse') }}</option>
            <option value="kr">{{ $t('message.ko') }}</option>
            <option value="en">{{ $t('message.en') }}</option>
            <option value="cn">{{ $t('message.ch') }}</option>
          </select>
        </div>
      </div>
      <span style="font-size: 3px">{{ $t("message.smsLangNotify")}}</span>
      <div class="middle_btn1 btn_100 d-flex justify-content-center align-items-center input-lg" @click="setSMSLang"><a>{{ $t('message.option') }}</a></div>
    </div>
    <span class="col-12 h5 price_blue">{{ $t('message.autoReply') }}</span>
    <div>
      <div class="ad_con">
        <ul class="ad_list">
          <li>{{ $t('message.isUsed') }}</li>
        </ul>
        <div class="ad_option">
          <label class="label_checkradio">
            <input type="radio" value="1" v-model="autoMsg.using">
            <span class="radio_icon"></span>
            <span class="radio_text">{{ $t('message.use') }}</span>
          </label>
          <label class="label_checkradio">
            <input type="radio" value="0" v-model="autoMsg.using"/>
            <span class="radio_icon"></span>
            <span class="radio_text">{{ $t('message.unuse') }}</span>
          </label>
        </div>
      </div>
      <div class="ad_con">
        <ul class="ad_list">
          <li>{{ $t('message.greet') }}</li>
        </ul>
        <div class="ad_option">
          <input type="text" class="input_f" :placeholder="$t('message.accExample')" v-model="autoMsg.opening" />
        </div>
      </div>
      <div class="ad_con">
        <ul class="ad_list">
          <li>{{ $t('message.endMsg') }}</li>
        </ul>
        <div class="ad_option">
          <input type="text" class="input_f" :placeholder="$t('message.endMsgExample')" v-model="autoMsg.ending"/>
        </div>
      </div>
      <div class="middle_btn1 btn_100 d-flex justify-content-center align-items-center input-lg" @click="setAutoMsg"><a>{{ $t('message.option') }}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advanced",

  data() {
    return {
      bank: 'false',
      pps: 'false',
      alipay: 'false',
      wechat: 'false',
      bankUser: '',
      bankName: '',
      bankNumber: '',
      ppsId: '',
      ppsNumber: '',
      aliUser: '',
      aliId: '',
      wechatUser: '',
      wechatId: '',
      smsLang: '',
      autoMsg: {
        using: '0',
        opening: '',
        ending: '',
      },
      appPush: {
        using: '0',
        fcm: '',
      },
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.advancedOptions'), false, true)
    this.getSMSLang()
    this.getAutoMsg()
    this.getAppPush()
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  mounted() {
    this.$parent.showFooter(false)
    if(this.isMobileApp()) {
      this.appPush.fcm = window.getFCMToken()
    }
  },

  methods: {
    getAppPush() {
      this.$get(this.$USER_MYPAGE_EXTRA_FCM, 'getHomeExtraFCM', true, (result) => {
        if(result.data.length === 0) {
          this.appPush.using = '0'
        } else {
          this.appPush.using = result.data[0].using === true ? '1' : '0'
        }
      }, (result) => {
        console.log(result)
      })
    },

    setAppPush() {
      let formData = new FormData()
      formData.append('using', this.appPush.using === '1' ? true : false)
      formData.append('fcm', this.appPush.fcm)

      this.$post(this.$USER_MYPAGE_EXTRA_FCM, 'postHomeExtraFCM', formData, true, (result) => {
        this.getAppPush()
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    setAutoMsg() {
      let formData = new FormData()
      formData.append('using', this.autoMsg.using === '1' ? true : false)
      formData.append('opening', this.autoMsg.opening)
      formData.append('ending', this.autoMsg.ending)

      this.$post(this.$USER_MYPAGE_EXTRA_AUTO, 'postHomeExtraAuto', formData, true, (result) => {
        this.getAutoMsg()
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    getAutoMsg() {
      this.getUserProfile((result) => {
        if(result.success) {
          let myIdx = result.userProfile.userIdx

          this.$get(`${this.$HOME_EXTRA_AUTO}/${myIdx}`, 'getHomeExtraAuto', false, (result) => {
            if(result.data.length) {
              let data = result.data[0]
              if(data.using) {
                data.using = '1'
              } else {
                data.using = '0'
              }
              this.autoMsg = data
            }
          }, (result) => {
            console.log(result)
          })
        } else {
          console.log(result.userProfile)
        }
      })
    },

    setSMSLang() {
      let formData = new FormData()
      formData.append('value', this.smsLang)
      formData.append('append', true)

      this.$post(this.$USER_MYPAGE_EXTRA_SMSLANG, 'postExtraSMSLang', formData, true, (result) => {
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    getSMSLang() {
      this.$get(this.$USER_MYPAGE_EXTRA_SMSLANG, 'extraSMSLang', true, (result) => {
        this.smsLang = result.data[0]
        if(!this.smsLang) {
          this.smsLang = ''
        }
      }, (result) => {
        console.log(result)
      })
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.advancedOptions'), false, true)
      }
    },
  }
}
</script>

<style scoped>

</style>
