<template>
  <div class="invest_coinsBox invest_trade__box">
    <Modal ref="Modal">
      <div v-show="showConfirm">
        <div class="otc_detail">
          <dl class="wallet_form2">
            <dt class="wallet_form_tit">{{ $t('message.coin') }}</dt>
            <dd class="wallet_form_text t_left">{{ offer.pair.split('-')[0] }}</dd>
          </dl>
<!--          <dl class="wallet_form2">-->
<!--            <dt class="wallet_form_tit">{{ $t('message.payMethod') }}</dt>-->
<!--            <dd class="wallet_form_text t_left">{{ selectedPayMethod }}</dd>-->
<!--          </dl>-->
          <dl class="wallet_form2">
            <dt class="wallet_form_tit">{{ $t('message.quantity') }}</dt>
            <dd class="wallet_form_text t_left">{{ coinCal }} {{ offer.pair.split('-')[0] }}</dd>
          </dl>
          <dl class="wallet_form2" v-show="offer.offerType === 'buy'">
            <dt class="wallet_form_tit">{{ $t('message.fees') }}({{ fee }} %)</dt>
            <dd class="wallet_form_text t_left">{{ subDec(coinCal * (fee / 100), offer.pair.split('-')[0]) }} {{ offer.pair.split('-')[0] }}</dd>
          </dl>
          <dl class="wallet_form2">
            <dt class="wallet_form_tit">{{ $t('message.tradePrice') }}</dt>
<!--            <dd class="wallet_form_text t_left">{{ offer.price | numberWithCommas }} {{ offer.pair.split('-')[1] }}</dd>-->
            <dd class="wallet_form_text t_left">{{ offer.exchanges === 'NONE' ? '협상가' : offer.exchanges }} {{ offer.exchanges === 'NONE' ? '' : (offer.markUp >= 0 ? '기준 +' : '기준 ') + (offer.markUp * 100) + '%' }}</dd>
          </dl>
<!--          <dl class="wallet_form2">-->
<!--            <dt class="wallet_form_tit">{{ $t('message.totalTradePrice') }}</dt>-->
<!--            <dd class="wallet_form_text t_left">{{ coinCal * offer.price | numberWithCommas}} {{ offer.pair.split('-')[1] }}</dd>-->
<!--          </dl>-->
          <dl class="wallet_form2" v-show="offer.offerType === 'buy'">
            <dt class="wallet_form_tit">{{ $t('message.ActualTransaction') }}</dt>
            <dd class="wallet_form_text t_left">{{ Number(coinCal) + (Number(coinCal) * (fee / 100)) }} {{ offer.pair.split('-')[0] }}</dd>
          </dl>
          <dl class="wallet_form2">
            <dt class="wallet_form_tit">{{ $t('message.bal') }}</dt>
            <dd class="wallet_form_text t_left">{{ myBalance | numberWithCommas }} {{ offer.pair.split('-')[0] }}</dd>
          </dl>
          <dl class="wallet_form2" v-show="offer.offerType === 'buy'">
            <dt class="wallet_form_tit">{{ $t('message.balAfterTrade') }}</dt>
            <dd class="wallet_form_text t_left">
              <em>{{ myBalance | numberWithCommas }} {{ offer.pair.split('-')[0] }} - {{ Number(coinCal) + (Number(coinCal) * (fee / 100)) }} {{ offer.pair.split('-')[0] }} = </em>
              <em class="dblue_02"> {{ myBalance - (Number(coinCal) + (Number(coinCal) * (fee / 100)))  | numberWithCommas }} {{ offer.pair.split('-')[0] }}</em>
            </dd>
          </dl>
        </div>
        <div class="alert_box">
          <p v-show="offer.offerType === 'buy'"><i class="fas fa-exclamation-triangle"></i>{{ $t('message.otcDesc1') }}</p>
          <div v-show="offer.offerType === 'sell'">
            <p>
              <i class="fas fa-exclamation-triangle"></i>
              <span class="m_left10">{{ $t('message.otcDesc2') }}</span>
            </p>
            <p>{{ $t('message.otcDesc3') }}</p>
            <p>{{ $t('message.otcDesc4') }}</p>
          </div>
        </div>
        <div class="checks otc_offer_ckh">
          <input type="checkbox" id="check_03" v-model="confirmChk" @change="toggleChk" />
          <label for="check_03">{{ $t('message.otcDesc5', { coin: offer.pair.split('-')[0] }) }}</label>
        </div>
      </div>
      <div v-show="showMemo" class="otc_confirm">
        <div v-show="offer.memo" class="fs16">{{ offer.memo }}</div>
      </div>
    </Modal>
    <div class="mt-5">
      <div class="trade_listTable outer">
        <Search @getPairList="getFee"/>
        <div class="p-2">
          <div v-show="showDesc">
            <div class="alert offer_notice_basic rounded-0 text-left mb-0" role="alert">
              <div class="desc pb-2">{{ $t('message.tradeDesc') }}</div>
              <div class="desctxt">
                <p class="p1 pl-3 pb-2" v-html="$t('message.tradeDesc1')"></p>
                <p class="p2 pl-3 pb-2" v-html="$t('message.tradeDesc2')"></p>
                <p class="p3 pl-3" v-html="$t('message.tradeDesc3')"></p>
              </div>
            </div>
            <div class="alert offer_notice_gray rounded-0 text-left" role="alert">
              <div class="desc pb-2">{{ $t('message.note') }}</div>
              <p class="pl-2 mb-1">{{ $t('message.noteDesc1') }}</p>
              <p class="pl-2 mt-1">{{ $t('message.bankAcc') }}: xxxx</p>
              <p class="pl-2">PPS{{ $t('message.acc') }}: xxxxxxx</p>
            </div>
          </div>
          <button class="btn btn-outline-primary" @click="showDesc = !showDesc">
            {{ $t('message.tradeDesc') }} {{ showDesc ? $t('message.close') : $t('message.open')}}
          </button>
        </div>
        <div class="px-2 py-2 clearfix">
          <span class="col-12 h5 float-left text-left px-0" v-if="offer.offerType === 'buy'">{{ $t('message.sellOrder') }}</span>
          <span class="col-12 h5 float-left text-left px-0" v-else>{{ $t('message.buyOrder') }}</span>
          <hr style="display: block; margin-top: 35px;">
<!--          <div class="form-group row col-12 m-0 py-2 px-0">-->
<!--            <label class="col-form-label text-left col-12 px-0">{{ $t('message.unitPrice') }}</label>-->
<!--            <div class="col-12 px-0">-->
<!--              <div class="inputwrap2 grey_back">-->
<!--                <input type="text" disabled="disabled" :value="offer.price | numberWithCommas">-->
<!--                <span>{{ offer.pair.split('-')[1] }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="text-center wallet-history-check">* {{ $t('message.noFee') }}</div>-->
<!--          </div>-->
<!--          <hr>-->
          <label class="col-form-label col-12 text-left px-0">{{ $t('message.qty') }}</label>
          <div class="col d-flex px-0">
            <div class="inputwrap1 col d-flex px-0">
              <input type="text" v-model="coinCal">
              <span>{{ offer.pair.split('-')[0] }}</span>
            </div>
<!--            <div class="i_change col-1 px-0">-->
<!--              <img src="/img/icon_reverse.svg" alt="change">-->
<!--            </div>-->
<!--            <div class="inputwrap1 col d-flex px-0">-->
<!--              <input type="text" v-model="currCal">-->
<!--              <span>{{ offer.pair.split('-')[1] }}</span>-->
<!--            </div>-->
          </div>
<!--          <button-->
<!--              class="col-12 btn btn-outline-primary btn-md my-3"-->
<!--              @click="coinCal = Number(offer.remainAmount); currCal = offer.price * offer.remainAmount"-->
<!--          >-->
          <button
              class="col-12 btn btn-outline-primary btn-md my-3"
              @click="coinCal = Number(offer.remainAmount)"
          >
            {{ $t('message.max') }}
          </button>
<!--          <hr>-->
<!--          <div class="form-group row mx-0 py-2 mb-0">-->
<!--            <label class="col-form-label text-left">{{ $t('message.payMethod') }}</label>-->
<!--            <select class="col-12 form-control" v-model="selectedPayMethod">-->
<!--              <option v-for="pay in payMethod" :key="pay">{{ $t(`message.${pay}`) }}</option>-->
<!--            </select>-->
<!--          </div>-->
          <hr>
          <div class="form-group row my-0 mx-0 mb-0">
            <div class="col-form-label px-0 text-left col-12">{{ $t('message.nickname') }}</div>
            <p class="text-left py-2 px-0 col-12">{{ offer.nickname }}</p>
          </div>
          <hr>
          <div class="mk_t_content">
            <table class="table_o_1 my-2">
              <thead>
                <tr>
                  <th>{{ $t('message.tradeLimit') }}</th>
<!--                  <th>{{ $t('message.unitPrice') }}</th>-->
                  <th>{{ $t('message.tradePrice') }}</th>
<!--                  <th>{{ $t('message.payMethod') }}</th>-->
                  <th>{{ $t('message.tradeTime') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">{{ offer.minUnit }} {{ offer.minUnit ? '~' : '' }} {{ offer.remainAmount }} {{ offer.pair.split('-')[0] }}</td>
<!--                  <td class="text-center">{{ offer.price | numberWithCommas }} {{ offer.pair.split('-')[1] }}</td>-->
                  <td class="text-center">{{ offer.exchanges === 'NONE' ? '협상가' : offer.exchanges }} {{ offer.exchanges === 'NONE' ? '' : (offer.markUp >= 0 ? '기준 +' : '기준 ') + (offer.markUp * 100) + '%' }}</td>
<!--                  <td class="text-center">{{ offer.price }}%</td>-->
<!--                  <td class="text-center">-->
<!--                    <img src="/img/Icon_bank.svg" alt="icon_bank" v-show="offer.bank">-->
<!--                    <img src="/img/Icon_pps.svg" alt="icon_pps" v-show="offer.pps">-->
<!--                    <img src="/img/Icon_wechat.svg" alt="icon_wechat" v-show="offer.wechat">-->
<!--                    <img src="/img/Icon_alipay.svg" alt="Icon_alipay" v-show="offer.alipay">-->
<!--                  </td>-->
                  <td>15{{ $t('message.min') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex col-12 justify-content-between px-0 py-2">
            <button class="w-48 btn btn-basic btn-md" @click="openModal">{{ $t('message.confirm') }}</button>
            <button class="w-48 btn btn-secondary btn-md" @click="$router.push({ name: 'OfferList' })">{{ $t('message.back') }}</button>
          </div>

          <div class="alert offer_notice_gray rounded-0 text-left" v-html="$t('message.otcWarn1')"></div>
          <div class="alert offer_notice rounded-0 text-left">
            <p class="pl-2 mb-1">{{ $t('message.offerRegistDesc1') }}</p>
            <p class="pl-2 mt-3" style="word-break: keep-all; word-wrap: break-word;">
              {{ $t('message.otcWarn2') }}
            </p>
            <p class="pl-2 mt-3" style="word-break: keep-all; word-wrap: break-word;">
              {{ $t('message.otcWarn3') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from './Search'
import Modal from "@/components/cmp/modal/Modal";

export default {
  name: "OfferApply",

  data() {
    return {
      offer: this.$route.params.offer,
      coinCal: 0,
      currCal: 0,
      myBalance: 0,
      payMethod: [],
      selectedPayMethod: '',
      fee: 0,
      confirmChk: false,
      showConfirm: false,
      showMemo: false,
      showDesc: true,
    }
  },

  components: {
    'Search': Search,
    'Modal': Modal
  },

  computed: {
    modalTitle() {
      if(this.offer.offerType === 'buy') {
        return this.$t('message.sellOrder')
      } else {
        return this.$t('message.buyOrder')
      }
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.otcShort'), false, true)

    if(!this.offer) {
      this.$router.push({ name: 'OfferList' })
    }
  },

  mounted() {
    if(!this.offer) {
      this.$router.push({ name: 'OfferList' })
    } else {
      if(this.offer.memo) {
        this.showMemo = true
        this.$refs.Modal.showDialog(this.$t('message.msg'), this.$t('message.confirm'), '', () => {}, ()=>{})
      }

      if(this.offer.alipay) {
        this.payMethod.push('aliPay')
      }
      if(this.offer.bank) {
        this.payMethod.push('bankTrans')
      }
      if(this.offer.pps) {
        this.payMethod.push('ppsBank')
      }
      if(this.offer.wechat) {
        this.payMethod.push('wechatPay')
      }
      this.selectedPayMethod = this.$t(`message.${this.payMethod[0]}`)

      this.getMyBalance((result) => {
        if(result.success) {
          this.myBalance = result.myBalance.available
        }
      }, this.offer.pair.split('-')[0])

      if(this.offer.minUnit) {
        this.coinCal = Number(this.offer.minUnit)
      }
    }
  },

  methods: {
    getFee(pairList) {
      pairList.forEach((pair => {
        if(pair.marketPair === this.offer.pair) {
          this.fee = pair.sellFeeRatio
        }
      }))
    },

    openModal() {
      if(this.offer.minUnit) {
        if(this.coinCal < this.offer.minUnit) {
          this.notify('error', this.$t('message.lessThanAvail'))
          return
        }
      }
      // if(!this.coinCal || !this.currCal) {
      if(!this.coinCal) {
        this.notify('error', this.$t('message.enterPrice'))
        return
      }
      this.confirmChk = false
      this.showConfirm = true
      this.showMemo = false
      this.$refs.Modal.showDialog(this.modalTitle, null, this.$t('message.cancel'), () => {}, ()=>{})
    },

    toggleChk() {
      if(this.offer.offerType === 'buy' && this.myBalance - (this.coinCal + this.coinCal * this.fee) < 0) {
        this.notify('error', this.$t('message.shortBalance'))
        return
      }

      this.showConfirm = true
      this.showMemo = false
      if(this.confirmChk) {
        this.$refs.Modal.showDialog(this.modalTitle, this.$t('message.confirm'), this.$t('message.cancel'), () => {
          let formData = new FormData()

          if(this.selectedPayMethod === this.$t('message.bankTrans')){
            formData.append('otcPayType', 'BANK')
          } else if(this.selectedPayMethod === this.$t('message.aliPay')) {
            formData.append('otcPayType', 'ALIPAY')
          } else if(this.selectedPayMethod === this.$t('message.ppsBank')) {
            formData.append('otcPayType', 'PPS')
          } else {
            formData.append('otcPayType', 'WECHAT')
          }
          formData.append('offerIdx', this.offer.offerIdx)
          formData.append('amount', this.coinCal)
          // formData.append('buy', this.offer.offerType !== 'buy')

          this.$post(this.$OTC_TRADE, 'OtcDetailPostTrade', formData, true, (result) => {
            this.$router.push({ name: 'OfferProcess', params: { tradeIdx: result.data[0].tradeIdx, offerNickname: this.offer.nickname }})
          }, (result) => {
            console.log(result)
          })
        }, ()=>{})
      }
    },
  },

  watch: {
    // 'coinCal': {
    //   handler() {
    //     this.currCal = this.offer.price * this.coinCal
    //   }
    // },

    '$root.$i18n.locale': {
      handler() {
        this.selectedPayMethod = this.$t(`message.${this.payMethod[0]}`)
        this.$parent.setHeaderTitle(this.$t('message.otcShort'), false, true)
      }
    },
  }
}
</script>

<style scoped>

</style>
