import axios from 'axios'

function getSelectedLang() {
    let lang = localStorage.getItem('lang')
    if(lang === 'en') {
        return 'en-US'
    } else if (lang === 'ko') {
        return 'ko-KR'
    } else {
        return 'zh-CN'
    }
}

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': getSelectedLang()
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
            'Accept-Language': getSelectedLang()
        },
        timeout: 60000
    }
}

let requestOptionForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': getSelectedLang()
        },
        timeout: 60000,
        responseType: 'blob',
    }
}

let requestOptionWithTokenForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
            'Accept-Language': getSelectedLang()
        },
        timeout: 60000,
        responseType: 'blob',
    }
}

let requestOptionWithTokenForJson = (method, data) => {
    return {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
            'Accept-Language': getSelectedLang()
        },
        timeout: 60000,
        body: data
    }
}

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze  ({
    install(Vue) {
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN

        // otc 제안
        Vue.prototype.$OTC_TEMP_LIST = "/otc/temp/list"
        Vue.prototype.$OTC_TEMP_ADD = "/otc/temp/add"
        Vue.prototype.$OTC_TEMP_COUNT = "/otc/temp/count"
        Vue.prototype.$OTC_TEMP_UPDATE = "/otc/temp/update"
        Vue.prototype.$OTC_TEMP_TRADE_DONE = "/otc/temp/trade/done"
        Vue.prototype.$OTC_TEMP_LIST_SEARCH = "/otc/temp/list/search"
        Vue.prototype.$OTC_TEMP_DELETE = "/otc/temp/delete"
        Vue.prototype.$OTC_TEMP_CHATROOM = "/otc/temp/chatroom"
        Vue.prototype.$OTC_TEMP_CHAT_UPLOAD = "/otc/temp/chat/upload"
        Vue.prototype.$OTC_TEMP_CHAT_DOWNLOAD = "/otc/temp/chat/download"
        Vue.prototype.$OTC_TEMP_CHAT_ADD = "/otc/temp/chat/add"
        Vue.prototype.$OTC_TEMP_ADD = "/otc/temp/add"
        Vue.prototype.$OTC_TEMP_CHAT_LIST = "/otc/temp/chat/list"
        Vue.prototype.$OTC_TEMP_CHAT_CHATROOM = "/otc/temp/chat/chatroom"
        Vue.prototype.$OTC_TEMP_CHAT_IMAGE = "/otc/temp/chat/image"
        Vue.prototype.$OTC_TEMP_MYLIST = "/otc/temp/myList"
        Vue.prototype.$OTC_TEMP_TRADE_REQUEST = "/otc/temp/trade/request"
        Vue.prototype.$OTC_TEMP_CLAIM = "/otc/temp/claim"
        Vue.prototype.$OTC_TEMP_TRADE_ACCEPT = "/otc/temp/trade/accept"
        Vue.prototype.$OTC_TEMP_TRADE_LIST = "/otc/temp/trade/list"
        Vue.prototype.$OTC_TEMP_CHATROOM_LIST = "/otc/temp/chatroom/list"
        Vue.prototype.$OTC_TEMP_TICKERS = "/otc/temp/tickers"
        Vue.prototype.$OTC_TEMP_TRADE = "/otc/temp/trade"
        Vue.prototype.$OTC_TEMP_CHATROOM_BOARDIDX = "/otc/temp/chat/chatroom/boardidx"

        //user
        Vue.prototype.$USER_SIGNUP = "/user/sign-up"
        Vue.prototype.$USER_SIGNUP_EMAIL_SEND = "/user/sign-up/email/send"
        Vue.prototype.$USER_SIGNUP_EMAIL_CERT = "/user/sign-up/email/cert"
        Vue.prototype.$USER_SIGNUP_SMS_SEND = "/user/sign-up/sms/send"
        Vue.prototype.$USER_SIGNUP_SMS_CERT = "/user/sign-up/sms/cert"
        Vue.prototype.$USER_LOSS_PASSWORD_EMAIL = "/user/loss/password/email"
        Vue.prototype.$USER_LOSS_PASSWORD_NEW = "/user/loss/password/new"
        Vue.prototype.$USER_LOSS_PASSWORD_EMAIL_CERT = "/user/loss/password/email/cert"
        Vue.prototype.$USER_LOSS_PASSWORD_SMS = "/user/loss/password/sms"
        Vue.prototype.$USER_LOSS_EMAIL = "/user/loss/email"
        Vue.prototype.$USER_LOSS_EMAIL_CERT = "/user/loss/email/cert"
        Vue.prototype.$USER_LOSS_PASSWORD_SMS_CERT = "/user/loss/password/sms/cert"
        Vue.prototype.$USER_LOGIN = "/user/login"
        Vue.prototype.$USER_LOGIN_SMS = "/user/login/sms"
        Vue.prototype.$USER_LOGIN_SMS_CERT = "/user/login/sms/cert"
        Vue.prototype.$USER_LOGIN_PIN = "/user/login/pin"
        Vue.prototype.$USER_LOGIN_OTP = "/user/login/otp"
        Vue.prototype.$USER_LOGIN_OAUTH_TOKEN = "/user/login/oauth/token"
        Vue.prototype.$USER_LOGIN_OAUTH_TOKENINFO = "/user/login/oauth/tokenInfo"
        Vue.prototype.$USER_LOGIN_LOGOUT = "/user/login/logout"

        //my-page
        Vue.prototype.$USER_MYPAGE_SECURITY = "/user/myPage/security"
        Vue.prototype.$USER_MYPAGE_SECURITY_PIN = "/user/myPage/security/pin"
        Vue.prototype.$USER_MYPAGE_SECURITY_SECOND_PIN = "/user/myPage/security/second/pin"
        Vue.prototype.$USER_MYPAGE_SECURITY_SECOND_PIN_CERT = "/user/myPage/security/second/pin/cert"
        Vue.prototype.$USER_MYPAGE_SECURITY_SECOND_PHONE = "/user/myPage/security/second/phone"
        Vue.prototype.$USER_MYPAGE_PROFILE_PASSWORD = "/user/myPage/profile/password"
        Vue.prototype.$USER_MYPAGE_PROFILE_IMAGE = "/user/myPage/profile/image"
        Vue.prototype.$USER_MYPAGE_SECURITY_OTP = "/user/myPage/security/otp"
        Vue.prototype.$USER_MYPAGE_SECURITY_IDCARD = "/user/myPage/security/idCard"
        Vue.prototype.$USER_MYPAGE_QNA = "/user/myPage/qna"
        Vue.prototype.$USER_MYPAGE_QNA_DETAIL = "/user/myPage/qna/detail"
        Vue.prototype.$USER_MYPAGE_QNA_FORM = "/user/myPage/qna/form"
        Vue.prototype.$USER_MYPAGE_PROFILE = "/user/myPage/profile"
        Vue.prototype.$USER_MYPAGE_LOGIN_LOG = "/user/myPage/login/log"
        Vue.prototype.$USER_MYPAGE_PROFILE_IMAGE_USERS = "/user/myPage/profile/image/users"
        Vue.prototype.$USER_MYPAGE_OPTION_PAY = "/user/myPage/option/pay"
        Vue.prototype.$USER_MYPAGE_FAV = "/user/myPage/fav"
        Vue.prototype.$USER_MYPAGE_POLICY_WITHDRAW = "/user/myPage/policy/withdraw"
        Vue.prototype.$USER_MYPAGE_EXTRA_SMSLANG = "/user/myPage/extra/smsLang"
        Vue.prototype.$USER_MYPAGE_EXTRA_AUTO = "/user/myPage/extra/auto"
        Vue.prototype.$USER_MYPAGE_FEE = "/user/myPage/fee"
        Vue.prototype.$USER_MYPAGE_MAX_WITHDRAWAL_AMOUNT = "/user/myPage/withdrawal/level"

        // appPush
        Vue.prototype.$USER_MYPAGE_EXTRA_FCM = "/user/myPage/extra/fcm"
        Vue.prototype.$USER_MYPAGE_EXTRA_FCM_TOKEN = "/user/myPage/extra/fcm/token"

        // home
        Vue.prototype.$HOME_CARD = "/home/card"
        Vue.prototype.$HOME_SECONDARY = "/home/secondary"
        Vue.prototype.$HOME_MARKET = "/home/market"
        Vue.prototype.$HOME_MARKET_VALUE = "/home/market/value"
        Vue.prototype.$HOME_WITHDRAW_SECURELEVEL = "/home/withdraw/secureLevel"
        Vue.prototype.$HOME_EXTRA_AUTO = "/home/extra/auto"
        Vue.prototype.$HOME_LIST_MARKET = "/home/list/market"

        //wallet
        Vue.prototype.$WALLET = "/wallet"
        Vue.prototype.$WALLET_WITHDRAW = "/wallet/withdraw"
        Vue.prototype.$WALLET_TRANSFER = "/wallet/transfer"
        Vue.prototype.$WALLET_BALANCE = "/wallet/balance"
        Vue.prototype.$WALLET_BALANCE_TICKER = "/wallet/balance/ticker"
        Vue.prototype.$WALLET_AVAILABLE = "/wallet/available"
        Vue.prototype.$WALLET_FEE = "/wallet/fee"
        Vue.prototype.$WALLET_TRANSFER_BALANCE = "/wallet/transfer/balance"
        Vue.prototype.$WALLET_CONVERT = "/wallet/convert"
        Vue.prototype.$WALLET_TRANSFER_COIN = "/wallet/transfer/coin"
        Vue.prototype.$WALLET_ADDRESS_WITHDRAW = "/wallet/address/withdraw"
        Vue.prototype.$WALLET_SMS = "/wallet/sms"

        // ORDER
        Vue.prototype.$ORDER_SPOT = "/order/spot"
        Vue.prototype.$ORDER_SPOT_LOG = "/order/spot/log"
        Vue.prototype.$ORDER_SPOT_CANCEL = "/order/spot/cancel"
        Vue.prototype.$ORDER_SPOT_LIST = "/order/spot/list"
        Vue.prototype.$ORDER_SPOT_LIST_TRADED = "/order/spot/list/traded"
        Vue.prototype.$ORDER_SPOT_HISTORY_TRADED = "/order/spot/history/traded"

        // CHART
        Vue.prototype.$CHART = "/chart"

        // OTC
        Vue.prototype.$OTC_TRADE = "/otc/trade"
        Vue.prototype.$OTC_TRADE_TRANSFER = "/otc/trade/transfer"
        Vue.prototype.$OTC_TRADE_TIMEOUT = "/otc/trade/timeout"
        Vue.prototype.$OTC_TRADE_EXTEND = "/otc/trade/extend"
        Vue.prototype.$OTC_TRADE_DEPOSIT = "/otc/trade/deposit"
        Vue.prototype.$OTC_TRADE_COMMENT = "/otc/trade/comment"
        Vue.prototype.$OTC_TRADE_CHAT = "/otc/trade/chat"
        Vue.prototype.$OTC_TRADE_CHAT_UPLOAD = "/otc/trade/chat/upload"
        Vue.prototype.$OTC_TRADE_CANCEL = "/otc/trade/cancel"
        Vue.prototype.$OTC_TRADE_PAY = "/otc/trade/pay"
        Vue.prototype.$OTC_TRADE_LOG = "/otc/trade/log"
        Vue.prototype.$OTC_OFFER = "/otc/offer"
        Vue.prototype.$OTC_OFFER_DELETE = "/otc/offer/delete"
        Vue.prototype.$OTC_OFFER_CANCEL = "/otc/offer/cancel"
        Vue.prototype.$OTC = "/otc"
        Vue.prototype.$OTC_TRADE_LIST = "/otc/trade/list"
        Vue.prototype.$OTC_TRADE_CHAT_DOWNLOAD = "/otc/trade/chat/download"
        Vue.prototype.$OTC_OFFER_LIST = "/otc/offer/list"
        Vue.prototype.$OTC_LIST_USER_OFFER = "/otc/list/user/offer"
        Vue.prototype.$OTC_LIST_MARKET = "/otc/list/market"
        Vue.prototype.$OTC_LIST_EXCHANGES = "/otc/list/exchanges"
        Vue.prototype.$OTC_DISPLAY_USDKRW = "/otc/display/usdKrw"
        Vue.prototype.$OTC_DISPLAY_TICKER_BITFINEX = "/otc/display/ticker/bitfinex"
        Vue.prototype.$OTC_DISPLAY_TICKER_UPBIT = "/otc/display/ticker/upbit"
        Vue.prototype.$OTC_DISPLAY_TICKER_BINANCE = "/otc/display/ticker/binance"
        Vue.prototype.$OTC_NICKNAME = "/otc/nickname"

        // BOARD
        Vue.prototype.$BOARD_LIST = "/user/board/board" // 게시판 게시물 목록 조회, 게시물 등록
        Vue.prototype.$BOARD_CHANGE = "/user/board/board/" // 게시물 수정, 게시물 삭제
        Vue.prototype.$BOARD_FORM = "/user/board/board/form" // 게시판 폼
        Vue.prototype.$BOARD_DETAIL = "/user/board/board/detail" // 해당 게시판의 게시물 상세보기
        Vue.prototype.$BOARD_SEARCH = "/user/board/search"

        Vue.prototype.$post = async function (callUrl, caller, postData, useToken, success, fail, retry = true){
            console.log(arguments[0])
            if (pending_post[arguments[0] + caller]) {
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                } else {
                    this.$router.replace({ name: 'Login' })
                }
            }
            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false

                if(callUrl === this.$USER_LOGIN_OAUTH_TOKEN && !useToken) {
                    if(!response.data.result) {
                        if (response.data.message && response.data.message === '유효기간이 다 되었거나 잘못된 토큰입니다.') {
                            fail(response.data)
                            return
                        }
                    }
                }

                if(!response.data.result) {
                    if (response.data.message) {
                        if(caller === 'PostTradeRequest' && (response.data.message === '통화의 기준 소수점 길이보다 크게 입력하였습니다.' || response.data.message === 'scale of input value doesn\'t match the policy.')) {
                            this.notify('error', this.$t('message.chkMinAmt'))
                        } else {
                            if(response.data.message === 'order.fail.qualified') {
                                this.notify('error', this.$t('message.orderBlocked'))
                            } else {
                                this.notify('error', response.data.message)
                            }
                        }
                    } else if (response.data.error.length) {
                        if(response.data.error[0].message === 'order.fail.qualified') {
                            this.notify('error', this.$t('message.orderBlocked'))
                        } else {
                            this.notify('error', response.data.error[0].message)
                        }
                    }
                    return
                }
                success(response.data)
            }).catch(e => {
                pending_post[arguments[0] + caller] = false
                console.log(e)
                fail(e.response.data)
                if(e.response.data.status === 403 || e.response.data.status === 401) {
                    if(retry) {
                        this.refreshTokenShot(() => {
                            this.$post(callUrl, caller, postData, useToken, success, fail, false)
                        })
                    } else {
                        this.$router.replace({name: 'Login'})
                        localStorage.removeItem('accessToken')
                        this.$store.state.isLoggedIn = false
                    }
                }

            })
        }

        Vue.prototype.$get = async function (callUrl, caller, useToken, success, fail, retry = true){
            console.log(arguments[0])
            if (pending_get[arguments[0] + caller]) {
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                } else {
                    this.$router.replace({name: 'Login'})
                }
            }
            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if(!response.data.result) {
                    if (response.data.message) {
                        this.notify('error', response.data.message)
                    } else if (response.data.error.length) {
                        this.notify('error', response.data.error[0].message)
                    }
                    return
                }
                success(response.data)
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                console.log(e)
                fail(e.response.data)
                if(e.response.data.status === 403 || e.response.data.status === 401) {
                    if(retry) {
                        this.refreshTokenShot(() => {
                            this.$get(callUrl, caller, useToken, success, fail, false)
                        })
                    } else {
                        this.$router.replace({name: 'Login'})
                        localStorage.removeItem('accessToken')
                        this.$store.state.isLoggedIn = false
                    }
                }
            })
        }

        Vue.prototype.$put = async function (callUrl, caller, postData, useToken, success, fail, retry = true){
            console.log(arguments[0])
            if (pending_put[arguments[0] + caller]) {
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                } else {
                    this.$router.replace({name: 'Login'})
                }
            }
            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                if(!response.data.result) {
                    if (response.data.message) {
                        this.notify('error', response.data.message)
                    } else if (response.data.error.length) {
                        this.notify('error', response.data.error[0].message)
                    }
                    return
                }
                success(response.data)
            }).catch(e => {
                pending_put[arguments[0] + caller] = false
                console.log(e)
                fail(e.response.data)
                if(e.response.data.status === 403 || e.response.data.status === 401) {
                    if(retry) {
                        this.refreshTokenShot(() => {
                            this.$put(callUrl, caller, postData, useToken, success, fail, false)
                        })
                    } else {
                        this.$router.replace({name: 'Login'})
                        localStorage.removeItem('accessToken')
                        this.$store.state.isLoggedIn = false
                    }
                }
            })
        }

        Vue.prototype.$axios_delete = async function (callUrl, caller, useToken, success, fail, retry = true){
            console.log(arguments[0])
            if (pending_delete[arguments[0] + caller]) {
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                } else {
                    this.$router.replace({name: 'Login'})
                }
            }
            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                if(!response.data.result) {
                    if (response.data.message) {
                        this.notify('error', response.data.message)
                    } else if (response.data.error.length) {
                        this.notify('error', response.data.error[0].message)
                    }
                    return
                }
                success(response.data)
            }).catch(e => {
                pending_delete[arguments[0] + caller] = false
                console.log(e)
                fail(e.response.data)
                if(e.response.data.status === 403 || e.response.data.status === 401) {
                    if(retry) {
                        this.refreshTokenShot(() => {
                            this.$axios_delete(callUrl, caller, useToken, success, fail, false)
                        })
                    } else {
                        this.$router.replace({name: 'Login'})
                        localStorage.removeItem('accessToken')
                        this.$store.state.isLoggedIn = false
                    }
                }
            })
        }

        Vue.prototype.$download = function (callUrl, caller, useToken, success, fail, retry = true){
            console.log(arguments[0])
            if (pending_delete[arguments[0] + caller]) {
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOptionForDownload()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithTokenForDownload()
                } else {
                    this.$router.replace({name: 'Login'})
                }
            }
            axios.get(this.$DOMAIN + callUrl, _requestOption).then(response => {
                pending_get[arguments[0] + caller] = false
                if (response.headers['content-type'] === 'application/octet-stream' || response.headers['content-type'].includes('image/')) {
                    success(response)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                console.log(e)
                fail(e.response)
                if(e.response.data.status === 403 || e.response.data.status === 401) {
                    if(retry) {
                        this.refreshTokenShot(() => {
                            this.$download(callUrl, caller, useToken, success, fail, false)
                        })
                    } else {
                        this.$router.replace({name: 'Login'})
                        localStorage.removeItem('accessToken')
                        this.$store.state.isLoggedIn = false
                    }
                }
            })
        }

        Vue.prototype.$downloadPost = function (callUrl, caller, postData, useToken, success, fail, retry = true){
            console.log(arguments[0])
            if (pending_post[arguments[0] + caller]) {
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOptionForDownload()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithTokenForDownload()
                } else {
                    this.$router.replace({name: 'Login'})
                }
            }
            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then(response => {
                pending_post[arguments[0] + caller] = false
                if (response.headers['content-type'] === 'application/octet-stream') {
                    success(response)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                console.log(e)
                fail(e.response)
                if(e.response.data.status === 403 || e.response.data.status === 401) {
                    if(retry) {
                        this.refreshTokenShot(() => {
                            this.$downloadPost(callUrl, caller, postData, useToken, success, fail, false)
                        })
                    } else {
                        this.$router.replace({name: 'Login'})
                        localStorage.removeItem('accessToken')
                        this.$store.state.isLoggedIn = false
                    }
                }
            })
        }

        Vue.prototype.$fileDownload = function (response){
            let filename = response.headers['content-disposition'].split('filename=')[1].split('"')[1]
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            link.innerText = '다운로드'
            return [link, filename]
        }

        Vue.prototype.$postJson = async function (callUrl, caller, method, data, success, fail){
            console.log(arguments[0])
            if (pending_post[arguments[0] + caller]) {
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOptionWithTokenForJson(method, data)

            fetch(this.$DOMAIN + callUrl, _requestOption)
                .then(response => {
                    pending_post[arguments[0] + caller] = false
                    success(response)
                }).catch(e => {
                    pending_post[arguments[0] + caller] = false
                    fail(e)
            })
        }

        Vue.prototype.$getJson = async function (callUrl, caller, method, success, fail){
            console.log(arguments[0])
            if (pending_post[arguments[0] + caller]) {
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOptionWithTokenForJson(method)

            fetch(this.$DOMAIN + callUrl, _requestOption)
                .then(response => {
                    pending_post[arguments[0] + caller] = false
                    let promise = new Promise(resolve => {
                        resolve(response.json())
                    })
                    promise.then((result) => {
                        success(result)
                    })
                }).catch(e => {
                pending_post[arguments[0] + caller] = false
                fail(e)
            })
        }
    }
})
