<template>
  <div class="cv-container-sub cv-level-wrap pb-3">
    <div class="px-3">
      <p class="mt-2">{{ title }}</p>
      <table class="table_m">
        <tr>
          <th class="t_left">{{ $t('message.contact') }}</th>
          <td><input type="text" v-model="userPhone" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.email') }}</th>
          <td><input type="text" v-model="userEmail" disabled></td>
        </tr>
        <tr v-for="qna in qnaInfo" :key="qna.title" v-show="qna.content">
          <th class="t_left">{{ qna.title }}</th>
          <td v-show="qna.title !== 'file'"><textarea class="scroll" :placeholder="qna.content" disabled></textarea></td>
          <td v-show="qna.title === 'file'"><div><img :src="qna.content" class="mw-100" :alt="$t('message.file')"/></div></td>
        </tr>
        <tr v-for="(answer, answerIdx) in answers" :key="answerIdx" v-show="answer.comment">
          <th class="t_left">{{ $t('message.answer') }}</th>
          <td><textarea :placeholder="answer.comment" disabled></textarea></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Inquiry_Detail",

  data() {
    return {
      qnaInfo: [],
      answers: [],
      userPhone: '',
      userEmail: '',
      qnaIdx: this.$route.params.qnaIdx,
      title : '',
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.myInquiries'), false, true)

    this.$get(`${this.$USER_MYPAGE_QNA_DETAIL}/${this.qnaIdx}`, 'MyPageQnaListDetail', true, (result) => {
      this.qnaInfo = []
      this.answers = []

      let qnaDetail = result.data[0]
      let qnaKeys = Object.keys(qnaDetail)
      let qnaValues = Object.values(qnaDetail)

      this.getUserProfile((result) => {
        if(result.success) {
          this.userPhone = result.userProfile.phone
          this.userEmail = result.userProfile.email
        } else {
          console.log(result.userProfile)
        }
      })

      qnaKeys.forEach((qna, idx) => {
        if(qna === 'title') {
          this.title = qnaValues[idx]
        } else if (qna === 'comments'){
          this.answers = qnaValues[idx]
        } else if(qna !== 'state'){
          this.qnaInfo.push({
            title: qna,
            content: qnaValues[idx]
          })
        }
      })
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.myInquiries'), false, true)
      }
    },
  },
}
</script>

<style scoped>

</style>
