<template>
  <div class="cv-container-sub">
    <table class="table table-sm mb-0 border-bottom text-center">
      <colgroup>
        <col style="width: auto;">
        <col style="width: 30%;">
      </colgroup>
      <thead class="thead-light">
      <tr>
        <th>{{ $t('message.title') }}</th>
        <th>{{ $t('message.date') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="transfer in transferList" :key="transfer.idx" @click="goToDetail(transfer.idx)">
        <td class="t_left">{{ transfer.title }}</td>
        <td>{{ transfer.date }}</td>
      </tr>
      </tbody>
    </table>
    <div class="buttonwrap text-center" v-show="transferList.length !== 0">
      <paginate
          :page-count="pageLast"
          :page-range="2"
          :margin-pages="1"
          :click-handler="getTransferList"
          :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :first-last-button=true
          :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
          :container-class="'pagination'">
      </paginate>
    </div>
  </div>
</template>

<script>
export default {
  name: "transferList",
  data() {
    return {
      code : 'transfer', // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, 입출금 : transfer
      codeLocale : '',
      transferList: [],
      transferInfo: [],
      title : '',
      rerenderKey: 0,
      pageLast: 0,
    }

  },
  created() {
    this.$parent.setHeaderTitle(this.$t('message.deposit') + "/" + this.$t('message.withdrawal'), false, true)
  },
  mounted() {
    this.$parent.showFooter(false)
    if (this.$root.$i18n.locale !== 'ko') {
      this.codeLocale = '_' + this.$root.$i18n.locale
    } else {
      this.codeLocale = ''
    }

    this.getTransferList(1)
  },
  methods: {
    getTransferList(page) {
      let limit = 10
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getTransferList', false, (result) => {
        this.transferList = result.data

        this.transferList.map(transfer => {
          transfer.date = this.formatDate(transfer.createdAt, 'full')
        })

        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    goToDetail(transferIdx) {
      this.$router.push({ name: 'CustomerSupportTransferView', params: { articleIdx : transferIdx }} )
    },
  },
  watch: {
    '$root.$i18n.locale': {
      handler() {
        if (this.$root.$i18n.locale !== 'ko') {
          this.codeLocale = '_' + this.$root.$i18n.locale
        } else {
          this.codeLocale = ''
        }

        this.getTransferList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>
