<template>
  <div id="alert-wrap" class="alert-wrap">
    <div class="alert-content" v-show="show">
      <i class="fa fa-check mr-1" v-show="type !== 'error'"></i>
      <i class="fa fa-exclamation-circle" v-show="type === 'error'"></i>
      <span v-html="msg"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",

  data() {
    return {
      msg: '',
      type: '',
      show: false,
    }
  },

  methods: {
    notify(type, msg) {
      if(!type || !msg) {
        return
      }

      this.show = true
      if(msg.includes('.')) {
        msg = msg.replaceAll('.', '.<br/>')
      }
      this.msg = msg
      this.type = type

      setTimeout(() => {
        this.show = false
      }, 3000)
    }
  }
}
</script>

<style scoped>
.alert-content svg {
  color: white;
}
</style>
