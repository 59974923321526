<template>
  <div class="invest_coinsBox invest_trade__box history">
    <div class="position-relative pb-2">
      <div class="mt-5">
        <div class="bg-white">
          <div class="trade_listTable outer">
            <div class="px-2 pb-3 invest_tradeAllBox clearfix">
              <div class="col-12 d-flex px-0">
                <select class="col-6 border custom-select" v-model="buyOrSell" @change="getHistory(1)">
                  <option value="all">{{ $t('message.buy') }}/{{ $t('message.sell') }}</option>
                  <option value="buy">{{ $t('message.buy') }}</option>
                  <option value="sell">{{ $t('message.sell') }}</option>
                </select>
                <select class="col-6 border custom-select" v-model="selectedPair" @change="getHistory(1)">
                  <option value="all">{{ $t('message.tradePair') }}</option>
                  <option v-for="marketPair in marketPairs" :key="marketPair">{{ marketPair }}</option>
                </select>
              </div>
              <div class="col-12 d-flex px-0 mt-2">
                <select class="col-6 border custom-select" v-model="orderMethod" @change="getHistory(1)">
                  <option value="all">{{ $t('message.orderMethod') }}</option>
                  <option value="limit">{{ $t('message.limit') }}</option>
                  <option value="market">{{ $t('message.marketPrice') }}</option>
                  <option value="reserve">{{ $t('message.StopLimit') }}</option>
                </select>
                <select class="col-6 border custom-select" v-model="tradeState" @change="getHistory(1)">
                  <option value="all">{{ $t('message.tradedState') }}</option>
                  <option value="traded">{{ $t('message.tradeTraded') }}</option>
                  <option value="cancel">{{ $t('message.cancel') }}</option>
                </select>
              </div>
              <div class="date_search input-group">
                <span class="date_input d-flex col-12 px-0 mt-2">
                  <label class="col-3 px-0 py-2">{{ $t('message.start') }}</label>
                  <date-picker id="start" v-model="startDate" :key="rerenderKey" :config="calendarOptions()" @dp-change="getHistory(1)"></date-picker>
                </span>
                <span class="date_input d-flex col-12 px-0 mt-2">
                  <label class="col-3 px-0 py-2">{{ $t('message.end') }}</label>
                  <date-picker id="end" v-model="endDate" :key="rerenderKey" :config="calendarOptions()" @dp-change="getHistory(1)"></date-picker>
                </span>
                <button class="col-12 btn btn-basic btn-md mt-2" @click="getHistory(1)">{{ $t('message.search') }}</button>
              </div>
            </div>
            <div class="inner">
              <table>
                <thead>
                  <tr class="fst_inner">
                    <th class="lft_td__color next_hardTitle">{{ $t('message.date') }}</th>
                    <th class="lft_td__color next_leftTitle">{{ $t('message.tradedState') }}</th>
                    <th class="tradeList_t lft_td__color">{{ $t('message.exchange') }}</th>
                    <th class="lft_td__color">{{ $t('message.transactionType') }}</th>
                    <th class="rit_td__color">{{ $t('message.orderMethod') }}</th>
                  </tr>
                  <tr class="fst_inner">
                    <th class="lft_td__color">{{ $t('message.tradeAmt') }}</th>
                    <th class="lft_td__color">{{ $t('message.tradeUnitPrice') }}</th>
                    <th class="lft_td__color">{{ $t('message.transactionPrice') }}</th>
                    <th class="lft_td__color">{{ $t('message.fees') }}</th>
                    <th class="rit_td__color">{{ $t('message.calPrice') }}</th>
                  </tr>
                </thead>
                <tbody :class="{ 'border-bottom-bold': tradeList.length - 1 === tradeIdx }" v-for="(trade, tradeIdx) in tradeList" :key="tradeIdx">
                  <tr>
                    <td>{{ trade.createdAt }}</td>
                    <td :class="{ fwb: trade.orderState !== 'CANCEL' }">{{ trade.orderState === 'CANCEL' ? $t('message.cancel') : $t('message.tradeTraded') }}</td>
                    <td>{{ trade.marketPair.replace('-', ' / ') }}</td>
                    <td :class="{ price_red: trade.isBuy, price_blue: !trade.isBuy }">{{ trade.isBuy ? $t('message.buy') : $t('message.sell') }}</td>
                    <td class="border-right-none">{{ trade.orderType }}</td>
                  </tr>
                  <tr>
                    <td class="border-top-light">{{ subDec(trade.tradedAmount, trade.primary) | numberWithCommas }}</td>
                    <td class="border-top-light">{{ subDec(trade.tradedPrice, trade.secondary) | numberWithCommas }}</td>
                    <td class="border-top-light">{{ trade.orderState !== 'CANCEL' ? subDec(trade.tradeValue, trade.secondary) : '' | numberWithCommas }}</td>
                    <td class="border-top-light">{{ trade.orderState !== 'CANCEL' ? trade.feeAmount + ' ' + trade.feeTicker : '' | numberWithCommas }}</td>
                    <td class="border-top-light border-right-none" :class="{ price_red: trade.isBuy, price_blue: !trade.isBuy }">{{ trade.orderState !== 'CANCEL' ? trade.isBuy ? subDec(trade.total, trade.primary) : subDec(trade.total, trade.secondary) : '' | numberWithCommas }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr v-show="!tradeList.length">
                    <td colspan="5" class="border-right-none text-center">{{ $t('message.noTradeHistory') }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="buttonwrap t_center" v-show="tradeList.length">
                <paginate
                    :page-count="pageLast"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="getHistory"
                    :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :first-last-button=true
                    :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :container-class="'pagination'">
                </paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)
import Loader from '@/components/cmp/Loader'

export default {
  name: "General",

  data() {
    return {
      loader: null,
      tradeState: 'all',
      orderMethod: 'all',
      selectedPair: 'all',
      buyOrSell: 'all',
      startDate: '',
      endDate: '',
      marketPairs: [],
      pageLast: 0,
      tradeList: [],
      rerenderKey: 1,
      limit: 10,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.myTradingHistory'), false, false)

    let d = new Date()
    d.setMonth(d.getMonth() - 1)
    this.startDate = this.formatDate(d.getTime(), 'date')
    this.endDate = this.formatDate(Date.now(), 'date')

    let pairs = JSON.parse(sessionStorage.getItem('marketPairs'))
    if(pairs === null) {
      return
    }
    pairs.forEach((p) => {
      this.marketPairs.push(p.pair.replace('-', ' / '))
    })
    this.marketPairs = [...new Set(this.marketPairs)]
  },

  mounted() {
    this.$parent.focusMenu(1)
    localStorage.setItem('focusMenu', 1)
    this.getHistory(1)
  },

  beforeDestroy() {
    this.loader.hide()
  },

  methods: {
    getHistory(page) {
      this.loader = this.$loading.show({
        backgroundColor: '#65686F',
        opacity: 0.5,
        zIndex: 999,
      }, {
        default: this.$createElement(Loader)
      })

      let formData = new FormData()

      let startDateArr = this.startDate.split('-')
      let endDateArr = this.endDate.split('-')
      let startInSecond = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2], 0, 0, 0)
      let endInSecond = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2], 23, 59, 59)

      let timeGap = endInSecond - startInSecond
      // 조회가능기간: 최대 3개월(7884000000밀리초)
      if(timeGap > 7884000000) {
        this.notify('error', this.$t('message.max3'))
        return
      }

      formData.append('start', startInSecond.getTime())
      formData.append('end', endInSecond.getTime())
      formData.append('limit', this.limit)
      formData.append('page', page)

      if(this.selectedPair !== 'all') {
        let marketPair = this.selectedPair.replace(' / ', '-')
        formData.append('marketPair', marketPair)
      }

      if(this.buyOrSell !== 'all') {
        let isBuy = this.buyOrSell === 'buy'
        formData.append('isBuy', isBuy)
      }

      if(this.orderMethod !== 'all') {
        let orderType = this.orderMethod.toUpperCase()
        formData.append('orderType', orderType)
      }

      if(this.tradeState !== 'all') {
        let orderState = this.tradeState.toUpperCase()
        formData.append('orderState', orderState)
      }

      this.$post(this.$ORDER_SPOT_HISTORY_TRADED, 'HistoryGeneralPostLog', formData, true, (result) => {
        this.loader.hide()
        this.tradeList = result.data
        this.tradeList.forEach((trade) => {
          trade.createdAt = this.formatDate(trade.createdAt, 'full')
          trade.secondary = trade.marketPair.split('-')[1]
          trade.primary = trade.marketPair.split('-')[0]
          trade.orderType = this.getOrderType(trade.orderType)
        })
        this.pageLast = Math.ceil(result.total / this.limit)
      }, (result) => {
        console.log(result)
      })
    },

    getOrderType(orderType) {
      // LIMIT, LIMIT_M, LIMIT_R, TRADED_LIMIT
      if(orderType.includes('LIMIT')) {
        return this.$t('message.limit')
      } else if(orderType === 'MARKET') {
        return this.$t('message.marketPrice')
      } else if(orderType === 'RESERVE') {
        return this.$t('message.StopLimit')
      } else {
        return orderType
      }
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.myTradingHistory'), false, false)
        this.rerenderKey += 1

        this.selectedPair = 'all'
        this.orderMethod = 'all'
        this.buyOrSell = 'all'
        this.tradeState = 'all'
        this.rerenderKey += 1
      }
    },
  },
}
</script>

<style scoped>

</style>
