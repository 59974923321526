<template>
  <div class="intro">
    <div class="cv-loading p-4 pt-5">
      <div class="mt-5 mb-4 pb-5">
        <router-link :to="{name: 'Intro'}">
          <img src="/img/logo_white.png" alt="intro-logo">
        </router-link>
        <div class="mt-4 text-white intro-desc pb-5 mb-5" v-html="$t('message.introDesc')"></div>
      </div>
      <div class="fixed-bottom p-4 mb-0">
        <div class="btn btn-primary btn-block mb-5" @click="$router.push({ name: 'Dashboard' })">{{ $t('message.goToExchange') }}</div>
        <LanguageHorizon/>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageHorizon from "@/components/cmp/language/Lang_Horizon.vue";

export default {
  name: "Intro",
    components: {LanguageHorizon},

  mounted() {
    this.$parent.showHeader(false)
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showHeader(true)
    this.$parent.showFooter(true)
  },
}
</script>

<style scoped>

</style>
