<template>
  <div class="cv-container-sub cv-level-wrap pb-3">
    <div class="px-3">
      <div class="bg-white cv-mypage">
        <div class="cv-mypage-tit h5">{{ $t('message.memberLv') }}
          <div class="myLevel_box">{{ $t('message.myLv') }} : <span class="text-primary">Level {{ lv }}</span></div>
        </div>
        <div class="mb-3">
          <ul class="cv-level-con nav nav-tabs nav-fill">
            <li class="nav-item">
              <a class="nav-link active">
                <div class="cv-level-box cv-level-confirm">
                  <div class="row d-flex align-items-center text-left active-level-off">
                    <div class="col-8 level_contentsBox pr-0">
                      <div>
                        <h6 class="text-primary mb-1">{{ $t('message.lv') }} 1</h6>
                        <p class="mb-0 text-basic">{{ $t('message.email') }}, {{ $t('message.phone') }} {{ $t('message.auth') }}</p>
                        <span class="d-block mt-1">{{ email }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <div class="cv-level-box" :class="{ 'cv-level-confirm': lv === '2' || lv === '3' || lv === '4' }">
                  <div class="row d-flex align-items-center text-left active-level-on">
                    <div class="col-7 level_contentsBox pr-0">
                      <div>
                        <h6 class="text-primary mb-1">{{ $t('message.lv') }} 2</h6>
                        <p class="mb-0 text-basic">{{ $t('message.pinCode') }} {{ $t('message.auth') }}</p>
                      </div>
                    </div>
                    <div class="col-5 pl-0 text-right">
                      <button
                          v-show="!authVeri.pin"
                          type="button"
                          class="btn btn-point input-lg"
                          @click="$router.push({ name: 'AuthPin' })"
                      >
                        {{ $t('message.auth') }}
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <div class="cv-level-box" :class="{ 'cv-level-confirm': lv === '3' || lv === '4' }">
                  <div class="row d-flex align-items-center text-left active-level-on">
                    <div class="col-7 level_contentsBox pr-0">
                      <div>
                        <h6 class="text-primary mb-1">{{ $t('message.lv') }} 3</h6>
                        <p class="mb-0 text-basic">OTP{{ $t('message.auth') }}</p>
                      </div>
                    </div>
                    <div class="col-5 pl-0 text-right">
                      <button
                          v-show="!authVeri.otp && lv !== '1'"
                          type="button"
                          class="btn btn-point input-lg"
                          @click="$router.push({ name: 'SecurityInfo' })"
                      >
                        {{ $t('message.auth') }}
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <div class="cv-level-box" :class="{ 'cv-level-confirm': lv === '4' }">
                  <div class="row d-flex align-items-center text-left active-level-on">
                    <div class="col-7 level_contentsBox pr-0">
                      <div>
                        <h6 class="text-primary mb-1">{{ $t('message.lv') }} 4</h6>
                        <p class="mb-0 text-basic">{{ $t('message.idCard') }} {{ $t('message.auth') }}</p>
                      </div>
                    </div>
                    <div class="col-5 pl-0 text-right">
                      <button
                          v-show="!isIdUploaded && (lv === '3' || lv === '4')"
                          type="button"
                          class="btn btn-point input-lg"
                          @click="$router.push({ name: 'AuthId' })"
                      >
                        {{ $t('message.auth') }}
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="cv-mypage-tit h5">{{ $t('message.withdrawalLimit') }}</div>
        <div class="levelTable_wrap">
          <p class="text-muted text-left"><span class="d-block">* {{ $t('message.lvDesc1') }}</span>* {{ $t('message.lvDesc2') }}</p>
          <div class="clearfix" @click="toggleShowLimit">
            <a class="btn btn-basic btn-block form-lg" style="font-size: 13px;" v-show="!showLimit">
              {{ $t('message.lvDesc3') }}
              <i class="fas fa-chevron-up"></i>
            </a>
            <a class="btn btn-basic btn-block form-lg" style="font-size: 13px;" v-show="showLimit">
              {{ $t('message.lvDesc3') }}
              <i class="fas fa-chevron-down"></i>
            </a>
          </div>
        </div>

        <div class="mt-3" v-show="showLimit">
          <ul class="cv-level-con nav nav-tabs nav-fill">
            <li class="nav-item" v-for="limit in limitByLv" :key="limit.level">
              <a class="nav-link active">
                <div class="cv-level-box cv-level-confirm">
                  <div class="row d-flex align-items-center text-left active-level-off">
                    <div class="col-8 level_contentsBox pr-0">
                      <div>
                        <h6 class="text-primary mb-1">{{ $t('message.lv') }} {{ limit.level }}</h6>
                        <p class="mb-0 text-basic">{{ $t('message.1dayLimit') }}: {{ limit.maxBuyValue ? subDec(limit.maxBuyValue, limit.tickerForLimit) : $t('message.noLimit') | numberWithCommas }} {{ limit.maxBuyValue ? limit.tickerForLimit : '' }}</p>
                        <p class="mb-0 text-basic">{{ $t('message.1daySellLimit') }}: {{ limit.maxSellValue ? subDec(limit.maxSellValue, limit.tickerForLimit) : $t('message.noLimit') | numberWithCommas }} {{ limit.maxSellValue ? limit.tickerForLimit : '' }}</p>
                        <p class="mb-0 text-basic">{{ $t('message.1dayWithLimit') }}: {{ limit.maxDayWithdrawValue ? subDec(limit.maxDayWithdrawValue, limit.tickerForWithdraw) : $t('message.noLimit') | numberWithCommas }} {{ limit.maxDayWithdrawValue ? limit.tickerForWithdraw : '' }}</p>
                        <p class="mb-0 text-basic">{{ $t('message.1timeWithLimit') }}: {{ limit.maxOnceWithdrawValue ? subDec(limit.maxOnceWithdrawValue, limit.tickerForWithdraw) : $t('message.noLimit') | numberWithCommas }} {{ limit.maxOnceWithdrawValue ? limit.tickerForWithdraw : '' }}</p>
                        <p class="mb-0 text-basic">{{ $t('message.possWith') }}: {{ limit.stateWithdraw ? $t('message.poss') : $t('message.imposs') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Level",

  data() {
    return {
      lv: '',
      email: '',
      showLimit: false,
      isFirstCall: true,
      limitByLv: [],
      authVeri: {
        otp: false,
        pin: false,
      },
      isIdUploaded: false,
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.membershipLevel'), true, false)

    this.getUserProfile((result) => {
      if(result.success) {
        let lv = result.userProfile.level
        this.lv = lv.substr(2, 1)
        this.email = result.userProfile.email
        this.isIdUploaded = result.userProfile.idCardUpward
      } else {
        console.log(result.userProfile)
      }
    })

    this.$get(this.$USER_MYPAGE_SECURITY, 'lvAuthSecurityMethod', true, (result) => {
      this.authVeri = result.data[0]
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  methods: {
    toggleShowLimit() {
      this.showLimit = !this.showLimit

      if(this.isFirstCall) {
        this.$get(this.$HOME_WITHDRAW_SECURELEVEL, 'withdrawSecureLevel', false, (result) => {
          this.limitByLv = result.data
          this.isFirstCall = false
        }, (result) => {
          console.log(result)
        })
      }
    },
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.membershipLevel'), true, false)
      }
    },
  }
}
</script>

<style scoped>

</style>
