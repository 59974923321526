<template>
  <div class="cv-container-sub cv-level-wrap pb-3">
    <div class="px-3 inq-regist">
      <div class="bg-white cv-mypage">
        <div class="cv-mypage-tit h5">1:1 {{ $t('message.doInquiry') }}</div>
        <p class="text-muted text-left">
          <span class="d-block pb-2">* {{ $t('message.inquiryDesc1') }}</span>
          * {{ $t('message.inquiryDesc2') }}</p>
      </div>
      <table class="table_inquiry">
        <colgroup>
          <col style="width: 14%;">
          <col style="width: 86%;">
        </colgroup>
        <tr>
          <th class="t_left">{{ $t('message.nickname') }}</th>
          <td><input type="text" :value="nickname" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.contact') }}</th>
          <td><input type="text" :value="phone" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.email') }}</th>
          <td><input type="text" :value="email" disabled></td>
        </tr>
        <tr v-for="(formCol) in formCols.slice().reverse()" :key="formCol.colName">
          <th class="t_left">{{ formCol.colName }}
            <p class="price_red mb-0">{{ formCol.require ? $t('message.required') : '' }}</p>
          </th>
          <td>
            <input class="input1" :type="formCol.type" v-show="formCol.colName === $t('message.title')" :maxlength="formCol.length.toString()" :placeholder="$t('message.enterTit')" v-model="formCol.filled">
            <textarea v-show="formCol.colName !== $t('message.title') && formCol.colName !== $t('message.file')" :maxlength="formCol.length.toString()" :placeholder="$t('message.enterContent')" v-model="formCol.filled"></textarea>
            <div v-show="formCol.type === 'file'" class="file">
              <div>
                <input type="text" :placeholder="$t('message.doAttach')" v-model="fileName" disabled>
                <button class="btn btn-point position-relative">
                  <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
                  <a>{{ $t('message.attach') }}</a>
                </button>
              </div>
              <p class="inquiry_p mb-0">* {{ limitFile(formCol.length) }}</p>
            </div>
          </td>
        </tr>
      </table>
      <button class="btn btn-point col-12 input-lg" @click="register">{{ $t('message.registration') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",

  data() {
    return {
      columns: [],
      type: [],
      nickname: '',
      phone: '',
      email: '',
      fileName: '',
      fileList: {},
      formCols: [],
      originalCols: [],
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.myInquiries'), false, true)

    this.getUserProfile((result) => {
      if(result.success) {
        this.nickname = result.userProfile.nickName
        this.phone = result.userProfile.phone
        this.email = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })

    this.getForm()
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    getForm() {
      this.formCols = []

      this.$get(this.$USER_MYPAGE_QNA_FORM, 'myPageQnaForm', true, (result) => {
        let columns = result.data[0]
        let colKeys = Object.keys(columns)
        let colVals = Object.values(columns)

        colKeys.forEach((col, colIdx) => {
          if(col === 'boardType') {
            return
          }

          this.originalCols.push({
            colName: col,
            type: colVals[colIdx].type === 'text' ? 'text' : 'file',
          })

          if(col === 'file') {
            col = this.$t('message.file')
          } else if (col === 'contents') {
            col = this.$t('message.content')
          } else if (col === 'title') {
            col = this.$t('message.title')
          }
          this.formCols.push({
            colName: col,
            length: colVals[colIdx].length,
            require: colVals[colIdx].require,
            type: colVals[colIdx].type === 'text' ? 'text' : 'file',
            filled: ''
          })
        })
      }, (result) => {
        console.log(result)
      })
    },

    limitFile(leng) {
      return this.$t('message.maxLeng', { leng: leng })
    },

    register() {
      let formData = new FormData()

      let jsonData = {}
      for(let i=0; i<this.originalCols.length; i++) {
        if(this.originalCols[i].type === 'text') {
          jsonData[this.originalCols[i].colName] = this.formCols[i].filled
        } else {
          formData.append(this.originalCols[i].colName, this.fileList)
        }
      }

      formData.append('request', new Blob([JSON.stringify(jsonData)], {type: "application/json"}))

      this.$post(this.$USER_MYPAGE_QNA, 'myPageQnaRegistPost', formData, true, (result) => {
        this.notify('success', result.message)
        this.$router.push({ name: 'Inquiry' })
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.fileName = file[0].name
      this.fileList = file[0]
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.myInquiries'), false, true)

        this.getForm()
      }
    },
  }
}
</script>

<style scoped>

</style>
