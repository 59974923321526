<template>
  <header class="fixed-top" :class="{ 'header-blue': color === 'blue' }" v-if="show">
    <div class="h5 clearfix">
      <div class="float-left" v-if="isHomeBtn" @click="goHome">
        <a class="back_btn"><i class="xi-home-o position-relative text-basic"></i></a>
      </div>
      <div class="float-left" v-if="isBackBtn" @click="$router.back()">
        <a class="back_btn text-basic">
          <i class="xi-angle-left position-relative" style="font-size: 21px; top: 0;"></i>
        </a>
      </div>
      <span class="header_tit">{{ title }}</span>
      <LangVerti/>
    </div>
  </header>
</template>

<script>
import LangVerti from '@/components/cmp/language/Lang_Verti'

export default {
  name: "Header",

  components: {
    'LangVerti': LangVerti
  },

  data() {
    return {
      show: true,
      title: '',
      isHomeBtn: false,
      isBackBtn: false,
      color: ''
    }
  },

  methods: {
    showHeader(bool) {
      this.show = bool
    },

    setHeaderTitle(title, isHomeBtn, isBackBtn, color = '') {
      this.title = title
      this.isHomeBtn = isHomeBtn
      this.isBackBtn = isBackBtn
      this.color = color
    },

    goHome() {
      let menuNo = localStorage.getItem('focusMenu')
      if(menuNo === '0') {
        this.$router.push({ name: 'Dashboard' })
      } else if (menuNo === '1') {
        this.$router.push({ name: 'HistoryGeneral' })
      } else if(menuNo === '2') {
        this.$router.push({ name: 'CoinList' })
      } else if (menuNo === '3') {
        this.$router.push({ name: 'OfferList' })
      } else if (menuNo === '4') {
        this.$router.push({ name: 'MyInfo' })
      }

    },
  }
}
</script>

<style scoped>

</style>
