<template>
  <div class="cv-container-sub pb-3">
    <div class="px-3 bbs-right">
      <div class="mb-3">
        <h6 class="fs18">{{ $t('message.helpComment') }}</h6>
        <div class="input-group">
          <input type="text" :placeholder="$t('message.enterKeyword')" v-model="keyword" @keyup.enter="search" class="form-control"/>
          <div class="input-group-append" @click="search">
            <button class="btn btn-basic"><i class="fas fa-search"></i></button>
          </div>
        </div>
      </div>
      <div class="mb-3" v-show="isSearched">
        <h5 class="help_tit">{{ $t('message.searchResult') }}</h5>
        <ul v-if="!searchList.length">
          <li class="t_center">{{ $t('message.noData') }}</li>
        </ul>
        <ul v-for="search in searchList" :key="search.idx">
          <li>
            <a class="point t_left" @click="goToDetail(search.code, search.idx)">{{ search.title }}</a>
          </li>
        </ul>
      </div>
      <div class="mb-3" v-show="!isSearched">
        <div class="d-flex">
          <h5 class="help_tit mr-auto">{{ $t('message.announcement') }}</h5>
          <router-link :to="{ name: 'CustomerSupportList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
        </div>
        <ul v-if="noticeList.length === 0">
          <li>
            {{ $t('message.noData') }}
          </li>
        </ul>
        <ul v-for="notice in noticeList" :key="notice.idx">
          <li>
            <a class="point" @click="goToDetail('notice', notice.idx)">{{ notice.title }}</a>
          </li>
        </ul>
      </div>
      <div class="mb-3" v-show="!isSearched">
        <div class="d-flex">
          <h5 class="help_tit mr-auto">{{ $t('message.faq') }}</h5>
          <router-link :to="{ name: 'CustomerSupportFAQList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
        </div>
        <ul v-if="faqList.length === 0">
          <li>
            {{ $t('message.noData') }}
          </li>
        </ul>
        <ul v-for="faq in faqList" :key="faq.idx">
          <li>
            <a class="point" @click="goToDetail('faq', faq.idx)">{{ faq.title }}
            </a>
          </li>
        </ul>
      </div>
      <div class="mb-3" v-show="!isSearched">
        <div class="d-flex">
          <h5 class="help_tit mr-auto">{{ $t('message.deposit') }} / {{ $t('message.withdrawal') }}</h5>
          <router-link :to="{ name: 'CustomerSupportTransferList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
        </div>
        <ul v-if="transferList.length === 0">
          <li>
            {{ $t('message.noData') }}
          </li>
        </ul>
        <ul v-for="transfer in transferList" :key="transfer.idx">
          <li>
            <a class="point" @click="goToDetail('transfer', transfer.idx)">{{ transfer.title }}
            </a>
          </li>
        </ul>
      </div>
      <div class="mb-3" v-show="!isSearched">
        <div class="d-flex">
          <h5 class="help_tit mr-auto">{{ $t('message.trading') }}</h5>
          <router-link :to="{ name: 'CustomerSupportTradeList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
        </div>
        <ul v-if="tradeList.length === 0">
          <li>
            {{ $t('message.noData') }}
          </li>
        </ul>
        <ul v-for="trade in tradeList" :key="trade.idx">
          <li>
            <a class="point" @click="goToDetail('trade', trade.idx)">{{ trade.title }}
            </a>
          </li>
        </ul>
      </div>
      <div class="mb-3" v-show="!isSearched">
        <div class="d-flex">
          <h5 class="help_tit mr-auto">{{ $t('message.auth') }} / OTP</h5>
          <router-link :to="{ name: 'CustomerSupportAuthList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
        </div>
        <ul v-if="authList.length === 0">
          <li>
            {{ $t('message.noData') }}
          </li>
        </ul>
        <ul v-for="auth in authList" :key="auth.idx">
          <li>
            <a class="point" @click="goToDetail('auth', auth.idx)">{{ auth.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerSupportCenter",
  data() {
    return {
      codeLocale : '',
      showMenuBtns: true,
      code : '', // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, transfer : 입출금, faq : 자주 묻는 질문, qna : 문의
      noticeList : [],
      faqList : [],
      qnaList : [],
      tradeList : [],
      authList : [],
      transferList : [],
      qnaInfo: [],
      answers: [],
      title : '',
      userPhone: '',
      userEmail: '',
      rerenderKey: 0,
      pageLast: 0,
      keyword: '',
      isSearched: false,
      searchList: [],
    }
  },
  created() {
    this.$parent.setHeaderTitle(this.$t('message.customerCenter'), true, false)
  },
  mounted() {
    this.$parent.showFooter(false)

    if (this.$root.$i18n.locale !== 'ko') {
      this.codeLocale = '_' + this.$root.$i18n.locale
    } else {
      this.codeLocale = ''
    }

    this.getAnnounceList(1)
  },

  methods: {
    search() {
      this.$get(`${this.$BOARD_SEARCH}?search=${this.keyword}&page=1&limit=10`, 'searchBoard', false, (result) => {
        this.isSearched = true
        this.searchList = result.data
      }, (result) => {
        console.log(result)
      })
    },

    goToMobile() {
      if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPod/i)) {
        window.location.href = process.env.VUE_APP_MOBILE_DOMAIN
      }
    },

    getAnnounceList(page) {
      let limit = 5
      // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, transfer : 입출금, faq : 자주 묻는 질문, qna : 문의

      this.code = 'notice'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.noticeList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })

      this.code = 'trade'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.tradeList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })

      this.code = 'auth'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.authList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })

      this.code = 'transfer'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.transferList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })

      this.code = 'faq'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.faqList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    goToDetail(code, idx) {
      if(code === 'notice') {
        this.$router.push({ name: 'CustomerSupportView', params: { articleIdx : idx }} )
      } else if(code === 'faq') {
        this.$router.push({ name: 'CustomerSupportFAQView', params: { articleIdx : idx }} )
      } else if(code === 'transfer') {
        this.$router.push({ name: 'CustomerSupportTransferView', params: { articleIdx : idx }} )
      } else if(code === 'trade') {
        this.$router.push({ name: 'CustomerSupportTradeView', params: { articleIdx : idx }} )
      } else if(code === 'auth') {
        this.$router.push({ name: 'CustomerSupportAuthView', params: { articleIdx : idx }} )
      }
    },
  },

  watch: {
    'keyword': {
      handler() {
        if(!this.keyword) {
          this.isSearched = false
        }
      }
    },
    '$root.$i18n.locale': {
      handler() {
        if (this.$root.$i18n.locale !== 'ko') {
          this.codeLocale = '_' + this.$root.$i18n.locale
        } else {
          this.codeLocale = ''
        }

        this.getAnnounceList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>
