<template>
  <div class="invest_coinsBox invest_trade__box">
    <div class="mt-5">
      <div class="trade_listTable outer">
        <div class="px-2 py-2 clearfix">
          <div class="form-group row mx-0 py-2 mb-0">
            <select class="form-control w-100" @change="($event)=>{this.$router.replace({name:$event.currentTarget.value})}">
              <option value="OtcSuggestionMarketList">{{ $t('message.otcMarket') }}</option>
              <option value="OtcSuggestionHistoryList" selected>{{ $t('message.tradingHis') }}</option>
              <option value="OtcSuggestionChatList">{{ $t('message.otcChat') }}</option>
              <option value="OtcSuggestionMypostList">{{ $t('message.myPost') }}</option>
            </select>
          </div>
        </div>
        <div class="checks mb-2">
          <div class="mb-1">
            <i class="fa fa-user" aria-hidden="true"></i> :
            <span class="mr-2"> {{ $t('message.myPost') }}</span>
            <i class="fa fa-users" aria-hidden="true"></i> :
            <span> {{ $t('message.postIApplied') }}</span>
          </div>
          <input type="checkbox" id="check_01" v-model="filter.chk1" @change="getList(1 )"/>
          <label for="check_01" class="text-warning">{{ $t('message.waitingAccept') }}</label>
          <input type="checkbox" id="check_02" v-model="filter.chk2" @change="getList(1)" />
          <label for="check_02" class="text-danger">{{ $t('message.waiting') }}</label>
          <input type="checkbox" id="check_03" v-model="filter.chk3" @change="getList(1)" />
          <label for="check_03" class="text-primary">{{ $t('message.deposited') }}</label>
          <input type="checkbox" id="check_04" v-model="filter.chk4" @change="getList(1)" />
          <label for="check_04" class="text-info">{{ $t('message.traded') }}</label>
          <input type="checkbox" id="check_05" v-model="filter.chk5" @change="getList(1)" />
          <label for="check_05" class="text-success">{{ $t('message.tradeCancel') }}</label>
          <input type="checkbox" id="check_06" v-model="filter.chk6" @change="getList(1)" />
          <label for="check_06" class="text-secondary">{{ $t('message.tradeRefuse') }}</label>
        </div>
        <div class="otc_listBox mx-2">
          <table class="table otc_table mb-0 table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="p-1">{{ $t('message.writing') }}</th>
              <th class="p-1">{{ $t('message.transactionPrice') }}</th>
              <th class="p-1">{{ $t('message.market') }}</th>
              <th class="p-1">{{ $t('message.tradeAmt') }}</th>
            </tr>
            <tr>
              <th class="p-1">{{ $t('message.tradingNum') }}</th>
              <th class="p-1">{{ $t('message.trading') }}{{ $t('message.nickname') }}</th>
              <th class="p-1">{{ $t('message.tradeDate') }}</th>
              <th class="p-1">{{ $t('message.tradeState') }}</th>
            </tr>
            </thead>
            <tbody v-for="(trade, idx) in tradeList" :key="'trade' + idx" @click="goToDetail(trade)">
            <tr>
              <td class="p-1">
                <i class="fa fa-user" aria-hidden="true" v-show="trade.writerUserIdx === myIdx"></i>
                <i class="fa fa-users" aria-hidden="true" v-show="trade.writerUserIdx !== myIdx"></i>
              </td>
              <td class="p-1">{{ trade.priceToGo | numberWithCommas }}</td>
              <td class="p-1">
                {{ !trade.marketPair.includes('UNLISTED') ? trade.marketPair.split('-')[0] : trade.marketPair.split('-')[1] + '('+ $t('message.directTran') +')'  }}
                <span :class="{ 'price_blue': trade.tradeIsBuy, 'price_red': !trade.tradeIsBuy }">{{ trade.tradeIsBuy ? $t('message.doSell') : $t('message.doBuy') }}</span>
              </td>
              <td class="p-1">{{ trade.amountView | numberWithCommas }}</td>
            </tr>
            <tr>
              <td scope="row" class="t_center">{{ trade.tradeIdx }}</td>
              <td class="p-1">{{ trade.oppositeNickname }}</td>
              <td scope="row" class="t_center">{{ trade.timestamp }}</td>
              <td class="p-1" :class="{
                        'text-warning': trade.otcTradeState === 'ACCEPT_READY',
                        'text-danger': trade.otcTradeState === 'WAITING',
                        'text-primary': trade.otcTradeState === 'DEPOSITED',
                        'text-info': trade.otcTradeState === 'TRADED',
                        'text-success': trade.otcTradeState === 'CANCEL',
                        'text-secondary': trade.otcTradeState === 'DECLINED'
                      }">{{ $t(`message.${tradeStateInLang(trade.otcTradeState)}`) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="buttonwrap t_center">
          <paginate
              :page-count="pageLast"
              :page-range="3"
              :margin-pages="2"
              :click-handler="getList"
              :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :first-last-button=true
              :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OtcSuggestionHistoryList",

  data() {
    return {
      pageLast: 0,
      tradeList: [],
      limit: 10,
      myIdx: null,
      filter: {
        chk1: false,
        chk2: false,
        chk3: false,
        chk4: false,
        chk5: false,
        chk6: false,
      }
    }
  },


  created() {
    this.$parent.setHeaderTitle(this.$t('message.otcMarket'), false, false)

    this.getUserProfile((result) => {
      if(result.success) {
        this.myIdx = result.userProfile.userIdx
        this.getList(1)
      } else {
        console.log(result.userProfile)
      }
    })

    let filter = JSON.parse(sessionStorage.getItem('otcHistoryFilter'))
    if(filter) {
      this.filter = filter
    }
  },

  mounted() {
    this.$parent.focusMenu(3)
  },

  methods: {
    goToDetail(trade) {
      this.$router.push({ name: 'OtcSuggestionHistoryDetail',
        params: {
          tradeIdx: trade.tradeIdx,
          tabNo: 1,
        }})
    },

    getList(page) {
      let formData = new FormData()
      formData.append('limit', this.limit)
      formData.append('page', page)
      this.filter.chk1 ? formData.append('stateList', 'ACCEPT_READY') : ''
      this.filter.chk2 ? formData.append('stateList', 'WAITING') : ''
      this.filter.chk3 ? formData.append('stateList', 'DEPOSITED') : ''
      this.filter.chk4 ? formData.append('stateList', 'TRADED') : ''
      this.filter.chk5 ? formData.append('stateList', 'CANCEL') : ''
      this.filter.chk6 ? formData.append('stateList', 'DECLINED') : ''

      this.$post(this.$OTC_TEMP_TRADE_LIST, 'otcTempMyTradeList', formData, true, (result) => {
        this.pageLast = Math.ceil(result.total / this.limit)
        this.tradeList = result.data

        this.tradeList.forEach(trade => {
          trade.timestamp = this.formatDate(trade.timestamp, 'date')
        })

        sessionStorage.setItem('otcHistoryFilter', JSON.stringify(this.filter))
      }, (result) => {
        console.log(result)
      })
    }
  }
}
</script>

<style scoped>

</style>
