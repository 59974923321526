<template>
  <div class="cv_wallet_sub">
    <div class="wallet-content wallet-withdrawal-content" style="display: block;">
      <div class="px-3">
        <div class="form-group row mb-2">
        </div>
        <div class="wallet_content1 mk_t_content my-3 book">
          <div :key="rerenderKey" class="alert rounded-0 mb-2 input-group" role="alert">
            <div class="w-100 mb-3 border-bottom address-con" v-for="addressObj in addressBook" :key="addressObj.idx">
              <p class="d-flex align-items-center"><span class="w-25">{{ $t('message.title') }}</span><input type="text" class="form-control w-75" v-model="addressObj.name" :disabled="!addressObj.isInputActive" /></p>
              <p class="d-flex align-items-center"><span class="w-25">{{ $t('message.address') }}</span><input type="text" class="form-control w-75" v-model="addressObj.address" :disabled="!addressObj.isInputActive" /></p>
              <p class="d-flex align-items-center justify-content-between">
                <a class="outline_s_btn red w-48 text-center" @click="deleteAddressBook(addressObj.idx)">{{ $t('message.delete') }}</a>
                <a class="outline_s_btn black w-48 text-center" v-show="!addressObj.isInputActive" @click="activeInput(addressObj)">{{ $t('message.modify') }}</a>
                <a class="outline_s_btn black w-48 text-center" v-show="addressObj.isInputActive" @click="changeAddressBook(addressObj)">{{ $t('message.save') }}</a>
              </p>
            </div>
            <div class="w-100">
              <p class="d-flex align-items-center"><span class="w-25">{{ $t('message.title') }}</span><input type="text" class="form-control w-75" v-model="addressBookTitle" /></p>
              <p class="d-flex align-items-center"><span class="w-25">{{ $t('message.address') }}</span><input type="text" class="form-control w-75" v-model="addressBookAddress" /></p>
              <p class="d-flex align-items-center justify-content-center mb-0"><a class="outline_s_btn w-100 text-center" @click="addAddressBook">{{ $t('message.add') }}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Withdrawal",

    data() {
      return {
        addressBook: [],
        addressBookTitle: '',
        addressBookAddress: '',
        rerenderKey: 0,
        addressToSend: '',
        coin: '',
      }
    },

    created() {
      this.coin = sessionStorage.getItem('withdrawalCoin')
      this.$parent.setHeaderTitle(this.$t('message.manageBook'), false, true)
      this.getAddressBook()
    },

    mounted() {
      this.$parent.showFooter(false)
    },

    beforeDestroy() {
      this.$parent.showFooter(true)
    },

    methods: {
      activeInput(addressObj) {
        this.addressBook.forEach(address => {
          address.isInputActive = false
        })
        addressObj.isInputActive = true
        this.rerenderKey += 1
      },

      getAddressBook() {
        this.$get(`${this.$WALLET_ADDRESS_WITHDRAW}?ticker=${this.coin}`, 'walletDetailGetAddress', true, (result) => {
          this.addressBook = result.data
          this.addressBook.forEach(addressObj => {
            addressObj.isInputActive = false
          })
        }, (result) => {
          console.log(result)
        })
      },

      deleteAddressBook(idx) {
        this.$axios_delete(`${this.$WALLET_ADDRESS_WITHDRAW}/${idx}`, 'walletDetailDeleteAddress', true, (result) => {
          console.log(result)
          this.getAddressBook()
        }, (result) => {
          console.log(result)
        })
      },

      changeAddressBook(addressObj) {
        console.log(addressObj)
        console.log(addressObj)
        let formData = new FormData()
        formData.append('address', addressObj.address)
        formData.append('ticker', this.coin)
        formData.append('name', addressObj.name)
        this.$put(`${this.$WALLET_ADDRESS_WITHDRAW}/${addressObj.idx}`, 'walletDetailPutAddress', formData, true, (result) => {
          console.log(result)
          this.getAddressBook()
        }, (result) => {
          console.log(result)
        })
      },

      addAddressBook() {
        if(!this.addressBookAddress || !this.addressBookTitle) {
          this.notify('error', this.$t('message.warningEmptyBlank'))
          return
        }

        let formData = new FormData()
        formData.append('address', this.addressBookAddress)
        formData.append('ticker', this.coin)
        formData.append('name', this.addressBookTitle)
        this.$post(this.$WALLET_ADDRESS_WITHDRAW, 'walletDetailPostAddress', formData, true, () => {
          this.addressBookTitle = ''
          this.addressBookAddress = ''
          this.getAddressBook()
        }, (result) => {
          console.log(result)
        })
      },
    },

    watch: {
      '$root.$i18n.locale': {
        handler() {
          this.$parent.setHeaderTitle(this.$t('message.doWithdrawal'), false, true)
        }
      },
    }
  }
</script>

<style scoped>

</style>