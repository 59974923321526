<template>
  <div class="cv-container-sub pb-3">
    <div class="px-3 bbs-right">
      <div class="form-horizontal" v-show="showSetting">
        <input type="hidden">
        <div class="cv-div-tbl1 mypage_security_selectBox">
          <div class="form-group row m-0">
            <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.securityMethod') }}</label>
            <div class="col-12 px-0">
              <select v-model="securityMethod" @change="changeSecurityMethod" class="form-control custom-select">
                <option value="phone">{{ $t('message.changeToPhone') }}</option>
                <option value="pin">{{ $t('message.changeToPin') }}</option>
              </select>
            </div>
          </div>
          <div v-show="changeToPhone">
            <div class="form-group row m-0">
              <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.pwd') }}</label>
              <div class="col-12 px-0">
                <input type="password" class="form-control" v-model="pwdForPin" :placeholder="$t('message.placeholderPwd')">
              </div>
            </div>
            <div class="form-group row m-0">
              <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.pinCode') }}</label>
              <div class="col-12 px-0">
                <input type="password" class="form-control" v-model="pin" :placeholder="$t('message.enterPin')">
              </div>
            </div>
            <div class="mt-3">
              <button class="btn btn-basic btn-block mb-3" @click="confirmPin">{{ $t('message.setSecurity') }}</button>
            </div>
          </div>
          <div v-show="!changeToPhone">
            <div class="form-group row m-0">
              <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.pwd') }}</label>
              <div class="col-12 px-0">
                <input type="password" class="form-control" v-model="pwdForPhone" :placeholder="$t('message.placeholderPwd')">
              </div>
            </div>
            <div class="input-group mb-1">
              <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.phoneNo') }}</label>
              <vue-country-code @onSelect="onSelect" :preferredCountries="['kr', 'cn', 'us']" defaultCountry="kr"></vue-country-code>
              <input type="text" class="form-control input-phone" v-model="phone" :placeholder="$t('message.placeholderPhoneNo')">
              <button type="button" class="btn btn-basic btn-md" @click="sendCodeToSMS">{{ $t('message.authCode') }}</button>
            </div>
            <div class="form-group row m-0" v-show="showInputSmsVerify">
              <label class="col-12 col-form-label cv-icon px-0">{{ $t('message.verificationCode') }}</label>
              <div class="col-12 px-0">
                <input type="password" class="form-control" v-model="smsCode" :placeholder="$t('message.placeholderPutAuthCode')">
              </div>
            </div>
            <div class="mt-3">
              <button @click="confirmSmsCode" class="btn btn-basic btn-block mb-3">{{ $t('message.setSecurity') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="cv-mypage-tit h5 pt-3 mb-0">OTP {{ $t('message.auth') }}</div>
      <div>
        <div class="cv-div-tbl1 text-center px-0 py-3 otp_box">
          <div class="mb-4 otp-t text-mute">
            <span v-html="$t('message.otpDesc1')"></span>
          </div>
          <div class="col-12 py-3 bg-light border border-bottom-0 android_wrap">
            <div class="row">
              <div class="col-12">
                <img src="/img/otp_img2.png" :alt="$t('message.playStore')" class="mr-md-3 mr-0 otp_imgBox">
              </div>
              <div class="col-12 text-center otp_contentBox">
                <h5 class="mt-0 font-weight-bold">{{ $t('message.android') }}</h5>
                <p class="mb-lg-3 mb-md-2">{{ $t('message.searchPlayStore') }}</p>
                <a :href="otpData.playStore" target="_blank" class="btn btn-point">
                  {{ $t('message.playStore') }} OTP
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 py-3 bg-light border ios_wrap">
            <div class="row">
              <div class="col-12">
                <img src="/img/otp_img.png" :alt="$t('message.playStore')" class="mr-md-3 mr-0 otp_imgBox">
              </div>
              <div class="col-12 text-center otp_contentBox">
                <h5 class="mt-0 font-weight-bold">{{ $t('message.iPhone') }}</h5>
                <p class="mb-lg-3 mb-md-2">{{ $t('message.searchAppStore') }}</p>
                <a :href="otpData.appStore" target="_blank" class="btn btn-basic">
                  {{ $t('message.appStore') }} OTP
                </a>
              </div>
            </div>
          </div>
          <p class="pt-3 opt-b mb-0" v-html="$t('message.otpDesc4')"></p>
        </div>
        <div v-show="!isOtpVeri && userLv !== '1'">
          <router-link :to="{ name: 'SecurityInfo' }" class="btn btn-basic btn-block form-lg">OTP {{ $t('message.verificationApplied') }}</router-link>
        </div>
        <div v-show="isOtpVeri">
          <span class="btn btn-basic btn-block form-lg">{{ $t('message.securityDone') }}</span>
        </div>
        <div v-show="userLv === '1' && !isOtpVeri">
          <span class="btn btn-basic btn-block form-lg">{{ $t('message.otpNotPos') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Security",

  data() {
    return {
      otpData: {
        key: '',
        url: '',
        playStore: '',
        appStore: '',
      },
      pin: '',
      oldPin: '',
      securityMethod: 'phone',
      isOtpVeri: false,
      changeToPhone: true,
      userLv: '',
      showSetting: false,
      pwd: '',
      pwdForPin: '',
      countryCode: '',
      phone: '',
      pwdForPhone: '',
      showInputSmsVerify: false,
      smsCode: '',
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.security'), false, true)

    this.getUserProfile((result) => {
      if(result.success) {
        this.userLv = result.userProfile.level.substring(3, 2)
        if(this.userLv === '2') {
          this.showSetting = true
        }
      } else {
        console.log(result.userProfile)
      }
    })

    this.$get(this.$USER_MYPAGE_SECURITY, 'myPageGetSecurityMethod', true, (result) => {
      this.isOtpVeri = result.data[0].otp
    }, (result) => {
      console.log(result)
    })

    this.$get(this.$USER_MYPAGE_SECURITY_OTP, 'myPageSecurityOtpGetOTP', true, (result) => {
      this.otpData = result.data[0]
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  methods: {
    confirmSmsCode() {
      let formData = new FormData()
      formData.append('code', this.smsCode.toString())

      this.$post(this.$USER_MYPAGE_SECURITY_SECOND_PIN_CERT, 'SecurityPhoneCert', formData, true, (result) => {
        this.notify('success', result.message)
        this.pwdForPhone = ''
        this.phone = ''
        this.smsCode = ''
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    sendCodeToSMS() {
      if(this.phone === '') {
        return
      }
      let formData = new FormData()
      formData.append('password', this.pwdForPhone)
      formData.append('phone', this.phone)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_MYPAGE_SECURITY_SECOND_PIN, 'SecuritySmsSend', formData, true, (result) => {
        this.notify('success', result.message)
        this.showInputSmsVerify = true
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    confirmPin() {
      let formData = new FormData()
      formData.append('password', this.pwdForPin)
      formData.append('pin', this.pin)

      this.$post(this.$USER_MYPAGE_SECURITY_SECOND_PHONE, 'SecuritySecondPhone', formData, true, (result) => {
        this.notify('success', result.message)
        this.pwdForPin = ''
        this.pin = ''
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    changeSecurityMethod() {
      this.changeToPhone = this.securityMethod === 'phone'
    },

    onSelect({name, iso2, dialCode}) {
      console.log(name, iso2, dialCode)
      this.countryCode = iso2
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.security'), false, true)
      }
    },
  }
}
</script>

<style scoped>

</style>
