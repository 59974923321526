<template>
  <div id="app">
    <Header ref="Header"></Header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Alert ref="Alert"></Alert>
    <Footer ref="Footer"></Footer>
  </div>
</template>

<script>
import Header from "./components/cmp/Header"
import Footer from "./components/cmp/Footer"
import Socket from "@/commonjs/socket"
import Alert from "@/components/cmp/Alert"

export default {
  name: 'App',
  components: {
    'Header': Header,
    'Footer': Footer,
    'Alert': Alert,
  },

  created () {
    if(localStorage.getItem('lang')) {
      this.$i18n.locale = localStorage.getItem('lang')
    } else {
      let locale = navigator.language
      locale = locale.substring(0, 2)
      this.$i18n.locale = locale
      localStorage.setItem('lang', this.$i18n.locale)
    }

    let accessToken = localStorage.getItem('accessToken')
    if(accessToken) {
      this.refreshToken()
      this.setFCMToken()
    }

    Socket.$on("message", this.handleMessage)

    window.addEventListener('$EVENT_NOTIFICATIONS', this.processNotification)
  },

  methods: {
    processNotification(e) {
      if(e.detail.otcTrade != null && e.detail.otcTrade !== "") {
        this.$router.push({ name: 'OtcSuggestionHistoryDetail',
          params: {
            tradeIdx: e.detail.otcTrade,
            tabNo: 1,
          }
        })

        return
      }

      if(e.detail.otcBoard != null && e.detail.otcBoard !== "") {
        this.$router.push({ name: 'OtcSuggestionMarketDetail',
          params: {
            postIdx: e.detail.otcBoard,
            tabNo: 0
          }
        })

        return
      }

      if(e.detail.chatroom != null && e.detail.chatroom !== "") {
        this.$router.push({ name: 'OtcSuggestionChatDetail',
          params: {
            chatroomIdx: e.detail.chatroom,
            tabNo: 2
          }
        })
      }
    },

    handleMessage(msg) {
      let promise = new Promise(resolve => {
        resolve(msg.text())
      })
      promise.then((value) => {
        let jsonData = JSON.parse(value)

        if(jsonData.t === 'tickers') {
          sessionStorage.setItem('tickers', JSON.stringify(jsonData.m))
        } else if(jsonData.t === 'marketPairs'){
          this.storeData(jsonData.m)
        }
      })
    },

    storeData(data) {
      let pairsWS = JSON.parse(JSON.stringify(data))
      pairsWS.sort(function(a, b) {
        return a.ordering - b.ordering
      })
      let marketPairs = []
      pairsWS.forEach((pair) => {
        marketPairs.push({
          pair: pair.marketPair,
          sellFeeRatio: pair.sellFeeRatio,
          buyFeeRatio: pair.buyFeeRatio,
          buyMinValue: pair.buyMinValue,
          sellMinValue: pair.sellMinValue,
          minBuyAmount: pair.minBuyAmount,
          minSellAmount: pair.minSellAmount
        })
      })
      sessionStorage.setItem('marketPairs', JSON.stringify(marketPairs))
      Socket.$off("message", this.handleMessage)
    },

    showHeader(state) {
      this.$refs.Header.showHeader(state)
    },

    showFooter(state) {
      this.$refs.Footer.showFooter(state)
    },

    focusMenu(MenuNo) {
      this.$refs.Footer.focusMenu(MenuNo)
    },

    setHeaderTitle(title, isHomeBtn, isBackBtn, color = '') {
      this.$refs.Header.setHeaderTitle(title, isHomeBtn, isBackBtn, color)
    }
  },

  beforeCreate() {
    // let console = (function(oldCons){
    //   return {
    //     log: function(){
    //       if (process.env.VUE_APP_DEBUG === "1") {
    //         oldCons.log.apply(console, arguments)
    //       }
    //     }
    //   }
    // }(window.console))
    // window.console = console
  }

}
</script>

<style>

</style>
