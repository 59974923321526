<template>
  <div class="invest_coinsBox invest_trade__box history">
    <div class="position-relative pb-2">
      <div class="mt-5">
        <div class="bg-white">
          <div class="h6 mb-0">
            <div>
              <ul class="nav nav-pills nav-fill justify-content-center invest_navBox">
                <li class="nav-item" @click="$router.push({ name: 'HistoryGeneral' })">
                  <a class="nav-link" :class="{ active: $route.path === '/history/general' }">{{ $t('message.exchange') }}</a>
                </li>
                <li class="nav-item" @click="$router.push({ name: 'HistoryOtcSellOffers' })">
                  <a class="nav-link" :class="{ active: $route.path !== '/history/general' }">{{ $t('message.otc') }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="trade_listTable outer">
            <div class="px-2 pb-3 invest_tradeAllBox clearfix">
              <SelectOtcHistory/>
              <span class="col-12 h5 float-left text-left px-1 pt-2 mb-0">{{ $t('message.sellOfferSum') }}</span>
            </div>
            <div class="inner">
              <table>
                <thead>
                <tr class="fst_inner">
                  <th class="lft_td__color">{{ $t('message.registNum') }}</th>
<!--                  <th class="lft_td__color">{{ $t('message.totalTradePrice') }}</th>-->
                  <th class="lft_td__color">{{ $t('message.tradePrice') }}</th>
<!--                  <th class="lft_td__color">{{ $t('message.payMethod') }}</th>-->
                  <th class="lft_td__color">{{ $t('message.firstAmt') }}</th>

                  <!--                          <th>{{ $t('message.unitPrice') }}</th>-->
                  <th class="lft_td__color">{{ $t('message.delete') }}</th>
                  <th class="rit_td__color" rowspan="2">{{ $t('message.detail') }}</th>
                </tr>
                <tr class="fst_inner">
                  <th class="lft_td__color next_leftTitle">{{ $t('message.coin') }}</th>
                  <th class="lft_td__color">{{ $t('message.registDate') }}</th>
                  <th class="lft_td__color">{{ $t('message.tradeLimit') }}</th>
                  <th class="lft_td__color">{{ $t('message.state') }}</th>
                </tr>
                </thead>
                <tbody :class="{ 'border-bottom-bold': myOfferList.length - 1 === myOfferIdx }" v-for="(myOffer, myOfferIdx) in myOfferList" :key="myOffer.offerIdx">
                <tr>
                  <td>{{ myOffer.offerIdx }}</td>
<!--                  <td class="border-top-light">{{ myOffer.totalAmount * myOffer.price | numberWithCommas }} {{ myOffer.marketPair.split('-')[1] }}</td>-->
                  <td class="border-top-light">{{ myOffer.exchanges === 'NONE' ? '협상가' : myOffer.exchanges }} {{ myOffer.exchanges === 'NONE' ? '' : (myOffer.markUp >= 0 ? '기준 +' : '기준 ') + (myOffer.markUp * 100) + '%' }}</td>
<!--                  <td class="border-top-light">{{ myOffer.price }}%</td>-->
<!--                  <td>-->
<!--                    <img v-show="myOffer.bank" src="/img/Icon_bank.svg" alt="icon_bank">-->
<!--                    <img v-show="myOffer.pps" src="/img/Icon_pps.svg" alt="icon_pps">-->
<!--                    <img v-show="myOffer.wechat" src="/img/Icon_wechat.svg" alt="icon_wechat">-->
<!--                    <img v-show="myOffer.alipay" src="/img/Icon_alipay.svg" alt="Icon_alipay">-->
<!--                  </td>-->
                  <td>{{ myOffer.totalAmount }} {{ myOffer.marketPair.split('-')[0] }}</td>

                  <!--                          <td>{{ myOffer.price | numberWithCommas }} {{ myOffer.marketPair.split('-')[1] }}</td>-->
                  <td class="outline_btn2">
                    <span class="red_f px-1" v-show="myOffer.otcState !== 'TRADING' && myOffer.otcState !== 'OPEN'" @click="deleteOffer(myOffer.offerIdx)">{{ $t('message.delete') }}</span>
                  </td>
                  <td class="outline_btn2 border-right-none" rowspan="2">
                    <span class="blue_f fs10 p-1" v-show="myOffer.otcState === 'SOLDOUT' || myOffer.otcState === 'TRADING'" @click="goDetailList(myOffer.offerIdx)">{{ $t('message.viewDetail') }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="border-top-light">{{ myOffer.marketPair.split('-')[0] }}</td>
                  <td class="border-top-light" v-html="myOffer.createdAt"></td>
                  <td class="border-top-light">{{ myOffer.isSplit ? myOffer.minUnit : '' }} {{ myOffer.isSplit ? '~' : '' }} {{ myOffer.remainAmount }} {{ myOffer.marketPair.split('-')[0] }}</td>
                  <td class="border-top-light">
                    <span class="outline_s_btn red" v-show="myOffer.otcState === 'OPEN'" @click="cancelOffer(myOffer.offerIdx)">{{ $t('message.registCancel') }}</span>
                    <span class="outline_s_btn" v-show="myOffer.otcState === 'CANCEL'" @click="$router.push({ name: 'OfferRegister', params: { offerType: 'buy' }})">{{ $t('message.registAgain') }}</span>
                    <p v-show="myOffer.otcState === 'SOLDOUT'">{{ $t('message.traded') }}</p>
                    <p v-show="myOffer.otcState === 'TRADING'">{{ $t('message.doTrading') }}</p>
                  </td>
                </tr>
                </tbody>
                <tbody v-show="!myOfferList.length">
                <tr>
                  <td colspan="5" class="border-right-none text-center">{{ $t('message.noTradeHistory') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectOtcHistory from './Menu'

export default {
  name: "SellOffers",

  components: {
    'SelectOtcHistory': SelectOtcHistory
  },

  data() {
    return {
      myOfferList: [],
    }
  },

  created() {
    this.$parent.setHeaderTitle(this.$t('message.myTradingHistory'), false, false)
  },

  mounted() {
    this.$parent.focusMenu(1)
    localStorage.setItem('focusMenu', 1)

    this.getMyOfferList(1)
  },

  methods: {
    deleteOffer(idx) {
      let formData = new FormData()
      formData.append('offerIdx', idx)

      this.$post(this.$OTC_OFFER_DELETE, 'HistoryOtcSellOffersDelete', formData, true, (result) => {
        this.notify('success', result.message)
        this.getMyOfferList(1)
      }, (result) => {
        console.log(result)
      })
    },

    cancelOffer(idx) {
      let formData = new FormData()
      formData.append('offerIdx', idx)

      this.$post(this.$OTC_OFFER_CANCEL, 'HistoryOtcSellOffersCancel', formData, true, (result) => {
        this.notify('success', result.message)
        this.getMyOfferList(1)
      }, (result) => {
        console.log(result)
      })
    },

    goDetailList(idx) {
      this.$router.push({ name: 'OfferDetailList', params: { offerIdx: idx }})
    },

    getMyOfferList(page) {
      let formData = new FormData()
      let limit = 10

      // formData.append('isBuy', false)
      formData.append('page', page)
      formData.append('limit', limit)
      this.$post(this.$OTC_OFFER_LIST, 'HistoryOtcSellOffers', formData,true, (result) => {
        this.myOfferList = result.data

        // this.myOfferList.forEach((myOffer) => {
        //   if(!myOffer.price) {
        //     this.getExchangePrice(myOffer)
        //   }
        // })

        this.myOfferList.map((myOffer) => {
          let date = new Date(myOffer.createdAt)
          let userTimezoneOffset = date.getTimezoneOffset() * 60000
          let timestamp = date.getTime() - userTimezoneOffset
          myOffer.createdAt = this.formatDate(timestamp, 'full')
        })
      }, (result) => {
        console.log(result)
      })
    },

    getExchangePrice(myOffer) {
      if(myOffer.exchanges === 'BITFINEX') {
        let marketPair = `${myOffer.marketPair.split('-')[0]}usd`
        marketPair = marketPair.toLowerCase()
        this.$get(`${this.$OTC_DISPLAY_TICKER_BITFINEX}/${marketPair}`, 'historySellOffersBitfinex_' + myOffer.offerIdx, true, (result) => {
          let usdPrice = result.data[0].mid
          if(usdPrice && myOffer.marketPair.split('-')[1] === 'KRW') {
            this.$get(`${this.$OTC_DISPLAY_USDKRW}`, 'historySellOffersGetUSDKRW_' + myOffer.offerIdx, true, (result) => {
              let rate = result.data[0].rate
              myOffer.price = this.subDec((rate * usdPrice), myOffer.marketPair.split('-')[1])

            }, (result) => {
              console.log(result)
            })
          } else {
            // 환율계산하는 api 화폐당 더 나와야함
          }
        }, (result) => {
          this.notify('error', result.message)
        })
      } else if (myOffer.exchanges === 'UPBIT'){
        let marketPair = `${myOffer.marketPair.split('-')[1]}-${myOffer.marketPair.split('-')[0]}`
        this.$get(`${this.$OTC_DISPLAY_TICKER_UPBIT}/${marketPair}`, 'historySellOffersUpbit_' + myOffer.offerIdx, true, (result) => {
          let midPrice = result.data[0].mid
          if(midPrice && myOffer.marketPair.split('-')[1] === 'KRW') {
            myOffer.price = this.subDec(midPrice, myOffer.marketPair.split('-')[1])
          } else {
            // 환율계산하는 api 화폐당 더 나와야함
          }
        }, (result) => {
          this.notify('error', result.message)
        })
      } else if(myOffer.exchanges === 'BINANCE') {
        let marketPair = `${myOffer.marketPair.split('-')[0]}${myOffer.marketPair.split('-')[1]}`
        this.$get(`${this.$OTC_DISPLAY_TICKER_BINANCE}/${marketPair}`, 'historySellOffersBinance_' + myOffer.offerIdx, true, (result) => {
          let midPrice = result.data[0].price
          console.log(midPrice)
        }, (result) => {
          this.notify('error', result.message)
        })
      } else {
        myOffer.price = 0
      }
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.$parent.setHeaderTitle(this.$t('message.myTradingHistory'), false, false)
      }
    },
  },
}
</script>

<style scoped>

</style>
