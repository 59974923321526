<template>
  <div class="cv_wallet_sub">
    <Modal ref="Modal">
      <div>
        <dl class="wallet_form">
          <dt class="wallet_form_tit">TXID</dt>
          <dd class="wallet_form_text text-left dblue_02">{{ transfer.txid === 'safe-withdraw-pending' ? $t('message.waitForSafeWith') : transfer.txid }}</dd>
        </dl>
        <dl class="wallet_form">
          <dt class="wallet_form_tit">{{ $t('message.curr') }}</dt>
          <dd class="wallet_form_text text-left">{{ transfer.tickerName.charAt(0).toUpperCase() + transfer.tickerName.slice(1) }}</dd>
        </dl>
      </div>
      <hr>
      <div>
        <h6 class="modal_sub_tit left price_blue">From</h6>
        <dl class="wallet_form">
          <dt class="wallet_form_tit">ID</dt>
          <dd class="wallet_form_text text-left">{{ transfer.type === $t('message.exWithdrawal') ? userEmail : $t('message.exWallet') }}</dd>
        </dl>
        <dl class="wallet_form">
          <dt class="wallet_form_tit">{{ $t('message.quantity') }}</dt>
          <dd class="wallet_form_text text-left">{{ toFloat(transfer.amount) }} {{ getSymbol(coin) }}</dd>
        </dl>
      </div>
      <hr>
      <div>
        <h6 class="modal_sub_tit left price_blue">To</h6>
        <dl class="wallet_form">
          <dt class="wallet_form_tit">ID</dt>
          <dd class="wallet_form_text text-left">{{ transfer.type === $t('message.exWithdrawal') ? $t('message.exWallet') : userEmail }}</dd>
        </dl>
        <dl class="wallet_form">
          <dt class="wallet_form_tit">{{ $t('message.quantity') }}</dt>
          <dd class="wallet_form_text text-left">{{ toFloat(Number(transfer.amount) - Number(transfer.feeAmount)) }} {{ getSymbol(coin) }}</dd>
        </dl>
        <dl class="wallet_form">
          <dt class="wallet_form_tit">{{ $t('message.fees') }}</dt>
          <dd class="wallet_form_text text-left">{{ toFloat(transfer.feeAmount) }} {{ getSymbol(coin) }}</dd>
        </dl>
      </div>
      <hr>
    </Modal>
    <div class="position-relative">
      <div class="col-12 px-0">
        <div id="currency_info" class="pl-0">
          <ul class="list-group wallet-list-group w-100 bg-basicGray">
            <li class="my-0">
              <div class="py-2 px-3 row mx-0" style="font-size: 17px;">
                <span class="col-4 px-0 font-weight-bold" style="font-size: 17px;">{{ $t('message.holdingAmt') }}</span>
                <span class="text-right col-8 px-0 font-weight-bold text-basic">{{ myBalance | numberWithCommas }} <sub>{{ getSymbol(coin) }}</sub></span>
              </div>
            </li>
            <li class="my-0">
              <div class="py-2 px-3 row mx-0" style="font-size: 17px;">
                <span class="col-4 px-0 font-weight-bold" style="font-size: 17px;">{{ $t('message.inOrdersQuantity') }}</span>
                <span class="text-right col-8 px-0 font-weight-bold text-basic">{{ myOrders | numberWithCommas }} <sub>{{ getSymbol(coin) }}</sub></span>
              </div>
            </li>
          </ul>

          <!-- ------------ 입출금 버튼 -------------- -->
          <div class="row mx-0 text-center border-top bg-basicGray">
            <div class="col-6 py-2 border-right" @click="$router.push({ name: 'Deposit', params: { coin: coin }})">
              <a class="text-basic"><i class="xi-log-in position-relative" style="font-size: 16px; top: 2px;"></i>{{ $t('message.doDeposit') }}</a>
            </div>
            <div class="col-6 py-2" @click="$router.push({ name: 'Withdrawal', params: { coin: coin } })">
              <a class="text-basic"><i class="xi-log-out position-relative" style="font-size: 16px; transform: rotate(-180deg); top: 2px;"></i>{{ $t('message.doWithdrawal') }}</a>
            </div>
          </div>
        </div>
        <div class="col-12 wallet-history-wrap">
          <div class="row">
            <div class="col-8 my-3">
              <h6 class="mb-0" style="font-size: 13px;">{{ coin }} {{ $t('message.statements') }}</h6>
            </div>
            <div class="wallet_section mb-0">
              <div class="wallet_content1 mk_t_content">
                <table class="basic">
                  <thead>
                    <tr>
                      <th rowspan="2" class="lft_td__color border-right-bold border-bottom-bold border-top-bold">{{ $t('message.sort') }}</th>
                      <th class="lft_td__color next_leftTitle border-right-bold border-top-bold border-bottom-bold">{{ $t('message.fees') }}</th>
                      <th rowspan="2" class="lft_td__color border-right-bold border-bottom-bold border-top-bold">{{ $t('message.time') }}</th>
                      <th rowspan="2" class="lft_td__color border-bottom-bold border-top-bold">{{ $t('message.moreInfo') }}</th>
                    </tr>
                    <tr>
                      <th class="rit_td__color border-right-none border-right-bold border-bottom-bold">{{ $t('message.quantity') }}</th>
                    </tr>
                  </thead>
                  <tbody v-for="(transfer, idx) in transferList" :key="idx">
                    <tr>
                      <td rowspan="2" class="text-center border-right-light border-bottom-bold" key="rerenderKey" :class="{ price_blue: transfer.type.split(' ')[1] === $t('message.withdrawal'), price_red: transfer.type.split(' ')[1] === $t('message.deposit') }">{{ transfer.type }}</td>
                      <td class="text-center border-right-light">{{ toFloat(transfer.feeAmount) }} {{ getSymbol(coin) }}</td>
                      <td rowspan="2" class="text-center border-right-light border-bottom-bold">{{ transfer.date.split(' ')[0] }}<br/>{{ transfer.date.split(' ')[1] }}</td>
                      <td rowspan="2" class="border-bottom-bold">
                        <a class="outline_s_btn" v-show="transfer.type === $t('message.exWithdrawal') || transfer.type === $t('message.exDeposit')" @click="openDetail(transfer)">{{ $t('message.blockChain') }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center border-right-light border-bottom-bold">{{ toFloat(transfer.amount) | numberWithCommas }} {{ getSymbol(coin) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-show="!transferList.length">
                    <tr>
                      <td colspan="4">{{ $t('message.noHistory') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="buttonwrap text-center" v-show="transferList.length">
                <paginate
                    :page-count="pageLast"
                    :page-range="2"
                    :margin-pages="1"
                    :click-handler="getTransferList"
                    :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :first-last-button=true
                    :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :container-class="'pagination'">
                </paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Modal from '@/components/cmp/modal/Modal'

export default {
  name: "Detail",

  components: {
    'Modal': Modal
  },

  data() {
    return {
      coin: '',
      myBalance: 0,
      myOrders: 0,
      transferList: [],
      transfer: {
        amount: '',
        date: 0,
        feeAmount: '',
        opposite: '',
        tickerName: '',
        txid: '',
        type: '',
      },
      pageLast: 0,
      userEmail: '',
      rerenderKey: 0,
    }
  },

  created() {
    this.coin = sessionStorage.getItem('withdrawalCoin')

    this.getUserProfile((result) => {
      if(result.success) {
        this.userEmail = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })

    this.$parent.setHeaderTitle(this.coin + ' ' + this.$t('message.depositWithdrawal'), true, false)

    this.getBalance()
    this.getTransferList(1)
  },

  mounted() {
    this.$parent.showFooter(false)
  },

  methods: {
    openDetail(transfer) {
      this.transfer = transfer
      this.$refs.Modal.showDialog(transfer.type === this.$t('message.exWithdrawal') ? this.$t('message.walletWithdrawal') : this.$t('message.walletDeposit'), '', this.$t('message.close'), () => {}, ()=>{})
    },

    getTransferList(page) {
      this.transferList = []
      let formData = new FormData()
      let limit = 10

      formData.append('limit', limit)
      formData.append('page', page)
      formData.append('ticker', this.coin)

      this.$post(this.$WALLET_TRANSFER, 'AssetWalletDetailPostTransfer', formData, true, (result) => {
        this.pageLast = Math.ceil(result.total / limit)
        this.transferList = result.data

        this.transferList.forEach((transfer) => {
          transfer.type = this.getTransferType(transfer.type, transfer.txid)
          transfer.date = this.formatDate(transfer.date * 1000, 'full')
        })
      }, (result) => {
        console.log(result)
      })
    },

    getTransferType(type, txid) {
      let transferType = type

      if(type === 'HOUSE_IN') {
        transferType = this.$t('message.inDeposit')
      } else if (type === 'HOUSE_OUT') {
        transferType = this.$t('message.inWithdrawal')
      } else if (type === 'TX_IN') {
        transferType = this.$t('message.exDeposit')
      } else if(type === 'TX_OUT') {
        transferType = this.$t('message.exWithdrawal')
      } else if (type === 'ESCROW_IN') {
        transferType = this.$t('message.escrowIn')
      } else if(type === 'ESCROW_OUT'){
        transferType = this.$t('message.escrowOut')
      } else if(type === 'ADMIN_IN') {
        transferType = this.$t('message.inDeposit')
      } else if(type === 'ADMIN_OUT') {
        if(txid === 'internal') {
          transferType = this.$t('message.inWithdrawal')
        } else {
          transferType = this.$t('message.exWithdrawal')
        }
      }

      return transferType
    },

    getBalance() {
      this.getMyBalance((result) => {
        if(result.success) {
          this.myBalance = result.myBalance.available
          this.myOrders = result.myBalance.inOrders
        }
      }, this.coin)
    },
  },

  beforeDestroy() {
    this.$parent.showFooter(true)
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.getTransferList(1)
        this.$parent.setHeaderTitle(this.coin + ' ' + this.$t('message.depositWithdrawal'), true, false)
        this.rerenderKey += 1
      }
    },
  }
}
</script>

<style scoped>

</style>
